import React, { Component } from "react";
import {Button, Card, CardHeader,CardBody, CardFooter, Label, FormGroup, Form,
  Input, Row, Col } from "reactstrap";

class MovimientoVobo extends Component {
  constructor(props) {
    super(props);
    console.log("=>MovimientoVobo: ", props.data);

    this.state = {
      data: props.data || ""
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.Nombre !== nextProps.Nombre) {
      this.setState({
        data: nextProps.data
      });
    }
  }

  render() {
    console.log("==> State Validate: ", this.state.data);

    return (
      <div>
       <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <h5 className="title">Propuesta</h5>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col className="pr-1" md="5">
                      <FormGroup>
                        <label>Nombre</label>
                        <Input
                          defaultValue={`${this.state.data.CandidatoRSNombre} ${this.state.data.CandidatoRSPaterno} ${this.state.data.CandidatoRSMaterno}`}
                          disabled
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-1" md="5">
                      <FormGroup>
                        <label>Propone</label>
                        <Input
                          //defaultValue={`${this.state.data.CandidatoRSPropone.Nombre} ${this.state.data.CandidatoRSPropone.Paterno} ${this.state.data.CandidatoRSPropone.Materno}`}
                          disabled
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="3">
                      <FormGroup>
                        <Label className="text-left">Sexo</Label>
                        <Input
                          defaultValue={this.state.data.CandidatoRSsexo.label}
                          disabled
                          type="text"
                        />
                      </FormGroup>
                    </Col>

                    <Col sm="3">
                      <FormGroup>
                        <Label className="text-left">Estado Civil</Label>
                        <Input
                          defaultValue={
                            this.state.data.CandidatoRSestado_civil.label
                          }
                          disabled
                          type="text"
                        />
                      </FormGroup>
                    </Col>

                    <Col sm="3">
                      <FormGroup>
                        <Label className="text-left">INE</Label>
                        <Input
                          defaultValue={this.state.data.CandidatoRSINE}
                          disabled
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="3">
                      <FormGroup>
                        <Label className="text-left">CURP</Label>
                        <Input
                          defaultValue={this.state.data.CandidatoRSCURP}
                          disabled
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="3">
                      <FormGroup>
                        <Label className="text-left">Municipio </Label>
                        <Input
                          defaultValue={this.state.data.CandidatoRSmunicipios.label}
                          disabled
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="3">
                      <FormGroup>
                        <Label className="text-left">Último Empleo</Label>
                        <Input
                          defaultValue={this.state.data.CandidatoRSUltimoTrabajo}
                          disabled
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="3">
                      <FormGroup>
                        <Label className="text-left">Nivel de Estudios</Label>
                        <Input
                          defaultValue={
                            this.state.data.CandidatoRSestudios.label
                          }
                          disabled
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="3">
                      <FormGroup>
                        <Label className="text-left">Grado Obtenido</Label>
                        <Input
                          defaultValue={this.state.data.CandidatoRSGradoObtenido}
                          disabled
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="4">
                      <FormGroup>
                        <Label className="text-left">Celular</Label>
                        <Input
                          defaultValue={this.state.data.CandidatoRSCelular1}
                          disabled
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="4">
                      <FormGroup>
                        <Label className="text-left">Tel Casa</Label>
                        <Input
                          defaultValue={this.state.data.CandidatoRSTelCasa}
                          disabled
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="4">
                      <FormGroup>
                        <Label className="text-left">Correo Electrónico</Label>
                        <Input
                          defaultValue={
                            this.state.data.CandidatoRSCorreoElectronico
                          }
                          disabled
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="4">
                      <FormGroup>
                        <Label className="text-left">Facebook</Label>
                        <Input
                          defaultValue={this.state.data.CandidatoRSFacebook}
                          disabled
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="4">
                      <FormGroup>
                        <Label className="text-left">Twitter</Label>
                        <Input
                          defaultValue={this.state.data.CandidatoRSTwitter}
                          disabled
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="4">
                      <FormGroup>
                        <Label className="text-left">Instagram</Label>
                        <Input
                          defaultValue={this.state.data.CandidatoRSInstagram}
                          disabled
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                        <FormGroup>
                          <Label className="text-left">Observaciones Contrapropuesta </Label>
                          <Input
                            //defaultValue={this.state.data.CandidatoRSInstagram}
                            //disabled
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                  </Row>
                </Form>
              </CardBody>
              
                <CardFooter>
                  <div className="form-row  text-center">
                    <Col>
                      <Button
                        onClick={() => this.props.autorizar(this.state.data)}
                        className="ml-3"
                        color="info"
                      >
                        Visto Bueno
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        onClick={() => this.props.noautorizar(this.state.data)}
                        className="ml-3"
                        color="danger"
                      >
                        Rechazado
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        //onClick={() => this.props.noautorizar(this.state.data)}
                        className="ml-3"
                        color="success"
                      >
                        Contrapropuesta
                      </Button>
                    </Col>
                  </div>
                </CardFooter>
              
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default MovimientoVobo;
