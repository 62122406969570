import React, { Component } from "react";
import {
  Button,
  Label,
  FormGroup,
  Input,
  Row,
  Col,
  Card,
  CardBody
} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import Select from "react-select";
import TagsInput from "react-tagsinput";
import ReactTable from "react-table";
import { CircleSpinner } from 'react-spinners-kit';

import EjecutarApi from "../../data/Consultas";
import getFile from "../../data/GetFile";
import Notify from "../../data/Notificacion";

import DashboardNavbar from "../../components/Navbars/DashboardNavbar.jsx";
import Footer from "../../components/Footer/Footer.jsx";
import Sidebar from "../../components/Sidebar/MenuSidebar.jsx";
import Pagination from "../components/Paginacion";

import { getVars,URL_PUBLICA } from "../../GlobalStore";

var global = "";

const Campos = [
  { value: "m.Movimiento as M", label: "Movimiento" },
  { value: "pm.QuienProponeConsideracion", label: "QuienProponeConsideracion" },
  { value: "pm.SUELDO_MENSUAL_HONORARIOS", label: "SUELDO_MENSUAL_HONORARIOS" },
  { value: "pm.FechaAlta", label: "FechaAlta" },
  { value: "ms.Estatus as MS", label: "Estatus Movimiento" },
  { value: "d2.Dependencia as DProp", label: "Dependencia Propone" },
  { value: "pm.FolioDependencia", label: "FolioDependencia" },
  { value: "pm.FechaRecepcion", label: "FechaRecepcion" },
  { value: "pm.FechaMovimiento", label: "FechaMovimiento" },
  { value: "pm.FechaHora", label: "FechaHora" },
  { value: "pm.isActivo", label: "isActivo" },
  { value: "prop.Nombre as NProp", label: "Nombre Propone" },
  { value: "prop.Puesto", label: "Puesto Propone" },
  { value: "pm.DEPENDENCIA_ORGANISMO", label: "DEPENDENCIA ORGANISMO" },
  { value: "pm.PLAZA", label: "PLAZA" },
  { value: "pm.NIVEL", label: "NIVEL" },
  { value: "pm.TIPO", label: "TIPO" },
  { value: "pm.MUNICIPIO", label: "MUNICIPIO PLAZA" },
  { value: "pm.DENOMINACION_TABULAR", label: "DENOMINACION TABULAR" },
  { value: "pm.DENOMINACION_FUNCIONAL", label: "DENOMINACION FUNCIONAL" },
  { value: "c.INE", label: "INE" },
  { value: "c.CURP", label: "CURP" },
  { value: "c.Nombre", label: "Nombre Candidato" },
  { value: "ne1.NivelEstudios as NEC", label: "NivelEstudios" },
  { value: "c.GradoObtenido", label: "GradoObtenido" },
  { value: "ec1.EstadoCivil as ECC", label: "EstadoCivil" },
  { value: "c.Celular1", label: "Celular1" },
  { value: "c.Celular2", label: "Celular2" },
  { value: "c.CorreoElectronico", label: "CorreoElectronico" },
  { value: "c.TelCasa", label: "TelCasa" },
  { value: "c.UltimoTrabajo", label: "UltimoTrabajo" },
  { value: "m1.Municipio as MC", label: "Municipio Candidato" },
  { value: "cs.Estatus as CSC", label: "Estatus Candidato" },
  { value: "c.NivelPropuesto", label: "NivelPropuesto" },
  { value: "c.Facebook", label: "Facebook" },
  { value: "c.Twitter", label: "Twitter" },
  { value: "c.Instagram", label: "Instagram" },
  { value: "c.Comentarios", label: "Comentario Candidato" },

  { value: "cv.actorespoliticos", label: "Cruce Actores" },
  { value: "cv.electoralabogados", label: "Cruce Abogados" },
  { value: "cv.electoralCasasAzules", label: "Cruce Casa Azul" },
  { value: "cv.electoralrc", label: "Cruce RC" },
  { value: "cv.electoralrg", label: "Cruce RG" },
  { value: "cv.estpromocion", label: "Cruce Promoción" },
  { value: "cv.pangto", label: "Cruce PAN" },
  { value: "cv.prigto", label: "Cruce PRI" },
  { value: "cv.prdgto", label: "Cruce PRD" },
  { value: "cv.pvemgto", label: "Cruce PVEM" },
  { value: "cv.morenagto", label: "Cruce Morena" },
  { value: "cv.vota", label: "Cruce Vota" },
  { value: "cv.plantilla", label: "Cruce Plantilla" },
  { value: "cv.isEspecial", label: "Cuce Especial" },
  { value: "cv.osc", label: "Cruce OSC" }
];
const CamposMovimiento = [
  { value: "m.Movimiento as M", label: "Movimiento" },
  { value: "pm.QuienProponeConsideracion", label: "QuienProponeConsideracion" },
  { value: "ms.Estatus as MS", label: "Estatus Movimiento" },
  { value: "d2.Dependencia as DProp", label: "Dependencia Propone" },
  { value: "pm.FolioDependencia", label: "FolioDependencia" },
  { value: "pm.FechaRecepcion", label: "FechaRecepcion" },
  { value: "pm.FechaMovimiento", label: "FechaMovimiento" },
  { value: "pm.FechaHora", label: "FechaHora" },
  { value: "pm.isActivo", label: "isActivo" },
  { value: "prop.Nombre as NProp", label: "Nombre Propone" },
  { value: "prop.Puesto", label: "Puesto Propone" }
];
const CamposPlaza = [
  { value: "pm.DEPENDENCIA_ORGANISMO", label: "DEPENDENCIA ORGANISMO" },
  { value: "pm.PLAZA", label: "PLAZA" },
  { value: "pm.NIVEL", label: "NIVEL" },
  { value: "pm.TIPO", label: "TIPO" },
  { value: "pm.MUNICIPIO", label: "MUNICIPIO PLAZA" },
  { value: "pm.DENOMINACION_TABULAR", label: "DENOMINACION TABULAR" },
  { value: "pm.DENOMINACION_FUNCIONAL", label: "DENOMINACION FUNCIONAL" },
  { value: "pm.SUELDO_MENSUAL_HONORARIOS", label: "SUELDO_MENSUAL_HONORARIOS" },
  { value: "pm.FechaAlta", label: "FechaAlta" }
];
const CamposCandidato = [
  { value: "c.INE", label: "INE" },
  { value: "c.CURP", label: "CURP" },
  { value: "c.Nombre", label: "Nombre Candidato" },
  { value: "ne1.NivelEstudios as NEC", label: "NivelEstudios" },
  { value: "c.GradoObtenido", label: "GradoObtenido" },
  { value: "ec1.EstadoCivil as ECC", label: "EstadoCivil" },
  { value: "c.Celular1", label: "Celular1" },
  { value: "c.Celular2", label: "Celular2" },
  { value: "c.CorreoElectronico", label: "CorreoElectronico" },
  { value: "c.TelCasa", label: "TelCasa" },
  { value: "c.UltimoTrabajo", label: "UltimoTrabajo" },
  { value: "m1.Municipio as MC", label: "Municipio Candidato" },
  { value: "cs.Estatus as CSC", label: "Estatus Candidato" },
  { value: "c.NivelPropuesto", label: "NivelPropuesto" },
  { value: "c.Facebook", label: "Facebook" },
  { value: "c.Twitter", label: "Twitter" },
  { value: "c.Instagram", label: "Instagram" },
  { value: "c.Comentarios", label: "Comentario Candidato" }
];

const CamposValidaciones = [
  { value: "cv.actorespoliticos", label: "Cruce Actores" },
  { value: "cv.electoralabogados", label: "Cruce Abogados" },
  { value: "cv.electoralCasasAzules", label: "Cruce Casa Azul" },
  { value: "cv.electoralrc", label: "Cruce RC" },
  { value: "cv.electoralrg", label: "Cruce RG" },
  { value: "cv.estpromocion", label: "Cruce Promoción" },
  { value: "cv.pangto", label: "Cruce PAN" },
  { value: "cv.prigto", label: "Cruce PRI" },
  { value: "cv.prdgto", label: "Cruce PRD" },
  { value: "cv.pvemgto", label: "Cruce PVEM" },
  { value: "cv.morenagto", label: "Cruce Morena" },
  { value: "cv.vota", label: "Cruce Vota" },
  { value: "cv.plantilla", label: "Cruce Plantilla" },
  { value: "cv.isEspecial", label: "Cuce Especial" },
  { value: "cv.osc", label: "Cruce OSC" }
];

class ReporteGenerador extends Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "black",
      activeColor: "info",
      loading: false,
      showExport: false,
      showFilter: true,
      sidebarMini: false,
      total: 0,
      resultsRS: [],
      columnas: [],
      tabCols: [],
      multiValueCondicion: [],
      tags: [],
      tagsCruces: [],
      Condicion: { value: "", label: "" },
      ValorCampo: "",
      multiValueEstatus: [],
      multiValueMovEstatus: [],
      multiValueDependencia: [],
      multiValueNivel: [],
      multiValueNivelEstudios: [],
      multiValueMunicipio: [],
      multiValueTipo: [],
      multiValueMovimiento: [],
      multiValuePropone: [],
      multiValueProponeC: [],
      multiValueActivo: [],
      catProponen: [],
      filterMunicipio: [],
      filterActivo: [
        { value: "1", label: "PROCESO" },
        { value: "0", label: "CONCLUIDO" }
      ],
      filterMovimiento: [
        { value: "1", label: "CONSIDERACIÓN" },
        { value: "2", label: "INGRESO" },
        { value: "3", label: "PROMOCIÓN" },
        { value: "4", label: "BAJA" },
        { value: "5", label: "LABORANDO" }
      ],
      filterTipo: [
        { value: "1", label: "CONSIDERACIÓN" },
        { value: "2", label: "INGRESO" },
        { value: "3", label: "PROMOCIÓN" },
        { value: "4", label: "BAJA" },
        { value: "5", label: "LABORANDO" }
      ],
      filterEstatus: [
        { value: "OCUPADA", label: "OCUPADA" },
        { value: "VACANTE", label: "VACANTE" },
        { value: "VACANTE POR LICENCIA", label: "VACANTE POR LICENCIA" },
        { value: "NULL", label: "NULL" }
      ],
      filterMovEstatus: [
        { value: "1", label: "Por Autorizar" },
        { value: "2", label: "Por Validar" },
        { value: "3", label: "Validado" },
        { value: "4", label: "Autorizado" },
        { value: "5", label: "Rechazado" },
        { value: "6", label: "Con Oficio" },
        { value: "7", label: "Rechazado Dependencia" },
        { value: "8", label: "Aprobado Dependencia" }
      ],
      Nombre: "",
      Secuencial: ""
    };
  }

  componentDidMount() {
    const data = {
      page: 0,
      tipo: "and",
      pageSize: 99999,
      sorted: [{ id: "Nombre", desc: false }],
      filtered: []
    };

    const token = global.access_token; //localstorage

    console.log("=Get Propone");

    EjecutarApi(data, "getProponen", token)
      .then(function(res) {
        return res;
      })
      .then(items => {
        console.log("===> Data getProponeXX: ", items);

        if (items.results) {
          this.setState({
            catProponen: items.data.map((prop, key) => {
              return { label: prop.NombrePropone, value: prop.id };
            })
          });
        }
      })
      .catch(e => {
        console.log("error getPlazaMovimientos:[] ", e);
        const sid =  e.message.indexOf('Unauthorized')
        console.log('=>Error:[Unauthorized] ', sid)
      });

    fetch(URL_PUBLICA+"obtenerDatosCandidatos", {
      headers: new Headers({ Authorization: `Bearer ${token}` })
    })
      .then(response => {
        switch (response.status) {
          case 200:
            console.log("==>Error Status: ", response.status);
            break;

          default:
            break;
        }

        if (response.ok) {
          return response.json();
        }
        throw new Error(
          "Ocurrio un error: obtenerDatosCandidatos: ",
          response.statusText
        );
      })
      .then(items => {
        console.log("===> Data Catalogos: ", items);

        this.setState({
          filterDependencia: items.dependencias.map((prop, key) => {
            return {
              label: prop.dependencia.label,
              value: prop.dependencia.value
            };
          }),
          filterMunicipio: items.municipios
        });
      })
      .catch(e => {
        console.log("error: ", e.message);
      });
  }

  notify = (color, place, message) => {
		var type;
		switch (color) {
			case 1:
				type = 'primary';
				break;
			case 2:
				type = 'success';
				break;
			case 3:
				type = 'danger';
				break;
			case 4:
				type = 'warning';
				break;
			case 5:
				type = 'info';
				break;
			default:
				break;
		}
		var options = {};
		options = {
			place: place,
			message: (
				<div>
					<div>{message}</div>
				</div>
			),
			type: type,
			icon: 'now-ui-icons ui-1_bell-53',
			autoDismiss: 4,
		};
		this.refs.notificationAlert.notificationAlert(options);
	};

  handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      this.setState({ sidebarMini: false });
    } else {
      this.setState({ sidebarMini: true });
    }
    document.body.classList.toggle("sidebar-mini");
  };

  handleMultiEstatus = option => {
    this.setState(state => {
      return {
        multiValueEstatus: option
      };
    });
  };

  handleCondicion = option => {
    this.setState(state => {
      return {
        Condicion: option
      };
    });
  };

  handleSetCondicion = () => {
    if (this.state.ValorCampo.length < 1) {
      Notify(3, "tc", `Se requiere un Valor`, this.refs);
      return true;
    }

    const Info = this.state.multiValueCondicion;

    Info.push({
      id: `${this.state.Condicion.value}[]`,
      value: this.state.ValorCampo
    });

    this.setState({ multiValueCondicion: Info });
  };

  downloadFile = () => {
    const token = global.access_token;

    getFile({}, "getReporteGeneradorXLS", token);
  };

  TagsAdd = item => {
    const Info = this.state.tags;
    Info.push(item);
    this.setState({ tags: Info });
  };

  TagsCrucesAdd = item => {
    const Info = this.state.tagsCruces;
    Info.push(item);
    this.setState({ tagsCruces: Info });
  };

  handleOnChange = (tags, changed, changedIndexes) => {
    console.log("tags=> ", tags);
    console.log("changed=> ", changed);
    console.log("changedIndexes=> ", changedIndexes);

    const mistags = this.state.tags;
    console.log("=>Total tags: ", mistags);
    mistags.splice(changedIndexes, 1);
    this.setState({ tags: mistags });
    console.log("=>After tags: ", mistags);
  };

  handleOnChangeCruces = (tags, changed, changedIndexes) => {
    console.log("tags=> ", tags);
    console.log("changed=> ", changed);
    console.log("changedIndexes=> ", changedIndexes);

    const mistags = this.state.tagsCruces;
    console.log("=>Total tags: ", mistags);
    mistags.splice(changedIndexes, 1);
    this.setState({ tagsCruces: mistags });
    console.log("=>After tagsCruces: ", mistags);
  };

  handleMultiTipo = option => {
    this.setState(state => {
      return {
        multiValueTipo: option
      };
    });
  };
  handleMultiMovimiento = option => {
    this.setState(state => {
      return {
        multiValueMovimiento: option
      };
    });
  };

  handleMultiActivo = option => {
    this.setState(state => {
      return {
        multiValueActivo: option
      };
    });
  };

  handleMultiMunicipio = option => {
    this.setState(state => {
      return {
        multiValueMunicipio: option
      };
    });
  };

  handleMultiDependencia = option => {
    this.setState(state => {
      return {
        multiValueDependencia: option
      };
    });
  };

  handleMultiNivel = option => {
    this.setState(state => {
      return {
        multiValueNivel: option
      };
    });
  };

  handleMultiPropone = option => {
    this.setState(state => {
      return {
        multiValuePropone: option
      };
    });
  };

  handleMultiProponeC = option => {
    this.setState(state => {
      return {
        multiValueProponeC: option
      };
    });
  };

  handleMultiNivelEstudios = option => {
    this.setState(state => {
      return {
        multiValueNivelEstudios: option
      };
    });
  };

  handleMultiEstatus = option => {
    this.setState(state => {
      return {
        multiValueEstatus: option
      };
    });
  };

  handleMultiMovEstatus = option => {
    this.setState(state => {
      return {
        multiValueMovEstatus: option
      };
    });
  };

  btnGenerar = () => {
    console.log("=>Generar Reporte: ");
    const {
      Nombre,
      Secuencial,
      multiValueEstatus,
      multiValueDependencia,
      multiValueNivel,
      multiValueMunicipio,
      multiValuePropone,
      multiValueProponeC,
      multiValueTipo,
      multiValueMovimiento,
      multiValueNivelEstudios,
      multiValueMovEstatus,
      multiValueActivo,
      GradoObtenido,
      tags, tagsCruces
    } = this.state;

    // const campos = tags.forEach(element => {
    // 	return Campos.filter(item => {
    // 		console.log();
    // 		if (element === item) return true;
    // 		else return false;
    // 	});
    // });

    var Select = [];

    for (let x = 0; x < tags.length; x++) {
      Campos.forEach(item => {
        if (tags[x] === item.label) Select.push(item);
      });
    }

    var where= {
      Nombre,
      Secuencial,
      multiValueEstatus,
      multiValueDependencia,
      multiValueNivel,
      multiValueMunicipio,
      multiValuePropone,
      multiValueProponeC,
      multiValueTipo,
      multiValueMovimiento,
      multiValueNivelEstudios,
      multiValueMovEstatus,
      multiValueActivo,
      GradoObtenido
    }

    for (let x = 0; x < tagsCruces.length; x++) {

      if (tagsCruces[x] === "Cruce Actores") where["actorespoliticos"]=1;
      if (tagsCruces[x] === "Cruce Abogados") where["electoralabogados"]=1;
      if (tagsCruces[x] === "Cruce Casa Azul") where["electoralcasasazules"]=1;
      if (tagsCruces[x] === "Cruce RC") where["electoralrc"]=1;
      if (tagsCruces[x] === "Cruce RG") where["electoralrg"]=1;
      if (tagsCruces[x] === "Cruce Promoción") where["estpromocion"]=1;
      if (tagsCruces[x] === "Cruce PAN") where["pangto"]=1;
      if (tagsCruces[x] === "Cruce PRI") where["prigto"]=1;
      if (tagsCruces[x] === "Cruce PRD") where["prdgto"]=1;
      if (tagsCruces[x] === "Cruce PVEM") where["pvemgto"]=1;
      if (tagsCruces[x] === "Cruce Morena") where["morenagto"]=1;
      if (tagsCruces[x] === "Cruce Vota") where["vota"]=1;
      if (tagsCruces[x] === "Cruce Plantilla") where["plantilla"]=1;
      if (tagsCruces[x] === "Cuce Especial") where["isEspecial"]=1;
      if (tagsCruces[x] === "Cruce OSC") where["isOSC"]=1;

    } 

    console.log('=> Mis wheres: ', where)

    this.setState({ tabCols: Select });

    const data = {
      page: 0,
      pageSize: 100,
      tipo: "and",
      where,
      campos: Select
    };

    console.log("=> Data to Api: ", data);

    const token = global.access_token;

    this.setState({ loading : true})

    EjecutarApi(data, "getReporteGenerador", token)
      .then(function(res) {
        return res;
      })
      .then(items => {
        this.setState({ loading : false})
        console.log("===> Data getReporteGenerador: ", items);
        this.setState({
          showExport: true,
          total: items.total,
          resultsRStmp: items.data,
          resultsRS: items.data.map((item, index, array) => {
            var miObjeto = {};
            item.forEach((dato, dindex, darray) => {
              miObjeto[`dato${dindex}`] = dato;
            });
            //console.log("=> MiObjeto: ", miObjeto);
            return miObjeto;
          }),
          columnas: Select.map((item, index, array) => {
            return {
              Header: Select[index].label,
              id: `dato${index}`,
              accessor: d => d[`dato${index}`]
            };
          })
        });
      })
      .catch(e => {
        Notify(3, "tc", e.message, this.refs);
        console.log("error getReporteGenerador: ", e.message);
        this.setState({ loading : false})
        
      });
  };

  render() {
    console.log("=>=> Mis stats: ", this.state);

    global = getVars("Token");

    const { resultsRS, columnas, loading } = this.state;

    console.log("=>MisCols: ", columnas);
    console.log("=>MisRS: ", resultsRS);

    return (
      <div className="wrapper">
        <Sidebar
          {...this.props}
          bgColor={this.state.backgroundColor}
          activeColor={this.state.activeColor}
        />
        <div className="main-panel" ref="mainPanel">
          <DashboardNavbar
            {...this.props}
            handleMiniClick={this.handleMiniClick}
          />
          <NotificationAlert ref="notificationAlert" />
          <div className="content">
            <Card>
              <CardBody style={{ marginLeft: 10 }}>
                {this.state.showExport && (
                  <Button color="primary" onClick={this.downloadFile}>
                    Exportar
                  </Button>
                )}
                {this.state.showFilter && (
                  <div>
                    <Row>
                      <Label>
                        <strong>Datos del Movimiento</strong>
                      </Label>
                    </Row>
                    <Row>
                      {CamposMovimiento.map((item, index) => {
                        return (
                          <Col
                            key={index}
                            className="ml-auto mr-auto"
                            lg="4"
                            md="4"
                          >
                            <Input
                              onChange={() => this.TagsAdd(item.label)}
                              type="checkbox"
                            />{" "}
                            {item.label}
                          </Col>
                        );
                      })}
                    </Row>
                    <Row>
                      <Label>
                        <strong>Datos de la Plaza</strong>
                      </Label>
                    </Row>
                    <Row>
                      {CamposPlaza.map(item => {
                        return (
                          <Col className="ml-auto mr-auto" lg="4" md="4">
                            <Input
                              onChange={() => this.TagsAdd(item.label)}
                              type="checkbox"
                            />{" "}
                            {item.label}
                          </Col>
                        );
                      })}
                    </Row>
                    <Row>
                      <Label>
                        <strong>Datos del Candidato</strong>
                      </Label>
                    </Row>
                    <Row>
                      {CamposCandidato.map(item => {
                        return (
                          <Col className="ml-auto mr-auto" lg="4" md="4">
                            <Input
                              onChange={() => this.TagsAdd(item.label)}
                              type="checkbox"
                            />{" "}
                            {item.label}
                          </Col>
                        );
                      })}
                    </Row>
                    <Row>
                      <Label>
                        <strong>Datos Validaciones</strong>
                      </Label>
                    </Row>
                    <Row>
                      {CamposValidaciones.map(item => {
                        return (
                          <Col className="ml-auto mr-auto" lg="3" md="3">
                            <Input
                              onChange={() => this.TagsAdd(item.label)}
                              type="checkbox"
                            />{" "}
                            {item.label}
                          </Col>
                        );
                      })}
                    </Row>
                    <Row>
                      <Col className="ml-auto mr-auto" lg="12" md="12">
                        <br></br>
                        <TagsInput
                          value={this.state.tags}
                          onChange={this.handleOnChange}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col className="ml-auto mr-auto" lg="12" md="12">
                        <label>Condición (Where)</label>
                        <Select
                          name="EstCamposatus"
                          className="SelectSize"
                          onChange={this.handleCondicion}
                          options={this.state.tags.map(item => {
                            return { value: item, label: item };
                          })}
                          placeholder="Seleccione"
                        />
                      </Col>
                      <Col lg="3" md="3">
                        <FormGroup>
                          <Label className="text-left">Valor</Label>
                          
                          {this.state.Condicion.label === "PLAZA" && (
                            <Input
                              placeholder="Secuencial"
                              id="Secuencial"
                              value={this.state.Secuencial}
                              type="text"
                              onChange={e =>
                                this.setState({ Secuencial: e.target.value })
                              }
                            />
                          )}
                          {this.state.Condicion.label === "GradoObtenido" && (
                            <Input
                              placeholder="GradoObtenido"
                              id="GradoObtenido"
                              value={this.state.GradoObtenido}
                              type="text"
                              onChange={e =>
                                this.setState({ GradoObtenido: e.target.value })
                              }
                            />
                          )}
                          {this.state.Condicion.label === "NIVEL" && (
                            <Select
                              className="SelectSize"
                              name="Nivel"
                              value={this.state.multiValueNivel}
                              onChange={this.handleMultiNivel}
                              placeholder="Seleccione Nivel"
                              options={[
                                { value: "1", label: "Nivel 1" },
                                { value: "2", label: "Nivel 2" },
                                { value: "3", label: "Nivel 3" },
                                { value: "4", label: "Nivel 4" },
                                { value: "5", label: "Nivel 5" },
                                { value: "6", label: "Nivel 6" },
                                { value: "7", label: "Nivel 7" },
                                { value: "8", label: "Nivel 8" },
                                { value: "9", label: "Nivel 9" },
                                { value: "10", label: "Nivel 10" },
                                { value: "11", label: "Nivel 11" },
                                { value: "12", label: "Nivel 12" },
                                { value: "13", label: "Nivel 13" },
                                { value: "14", label: "Nivel 14" },
                                { value: "15", label: "Nivel 15" },
                                { value: "16", label: "Nivel 16" },
                                { value: "17", label: "Nivel 17" },
                                { value: "18", label: "Nivel 18" },
                                { value: "19", label: "Nivel 19" },
                                { value: "20", label: "Nivel 20" },
                                { value: "21", label: "Nivel 21" }
                              ]}
                              isMulti
                            />
                          )}
                          {this.state.Condicion.label === "MUNICIPIO PLAZA" && (
                            <Select
                              className="SelectSize"
                              name="Municipio"
                              placeholder="Municipio"
                              value={this.state.multiValueMunicipio}
                              options={this.state.filterMunicipio}
                              onChange={this.handleMultiMunicipio}
                              isMulti
                            />
                          )}
                          {this.state.Condicion.label ===
                            "DEPENDENCIA ORGANISMO" && (
                            <Select
                              className="SelectSize"
                              name="Dependencia"
                              placeholder="Dependencia"
                              value={this.state.multiValueDependencia}
                              options={this.state.filterDependencia}
                              onChange={this.handleMultiDependencia}
                              isMulti
                            />
                          )}
                          {this.state.Condicion.label === "TIPO" && (
                            <Select
                              className="SelectSize"
                              name="Tipo"
                              placeholder="Tipo"
                              value={this.state.multiValueTipo}
                              options={this.state.filterTipo}
                              onChange={this.handleMultiTipo}
                              isMulti
                            />
                          )}
                          {this.state.Condicion.label === "NivelEstudios" && (
                            <Select
                              className="SelectSize"
                              name="NivelEstudios"
                              value={this.state.multiValueNivelEstudios}
                              onChange={this.handleMultiNivelEstudios}
                              placeholder="Seleccione Nivel Estudios"
                              options={[
                                { value: "1", label: "No definido" },
                                { value: "2", label: "Analfabeto(a)" },
                                { value: "3", label: "Primaria trunca" },
                                { value: "4", label: "Primaria completa" },
                                { value: "5", label: "Secundaria trunca" },
                                { value: "6", label: "Secundaria completa" },
                                { value: "7", label: "Preparatoria trunca" },
                                { value: "8", label: "Preparatoria completa" },
                                { value: "9", label: "Licenciatura trunca" },
                                { value: "10", label: "Licenciatura completa" },
                                { value: "11", label: "Licenciatura Técnica" },
                                { value: "12", label: "Maestría trunca" },
                                { value: "13", label: "Maestría completa" },
                                { value: "14", label: "Doctorado trunco" },
                                { value: "14", label: "Doctorado completo" }
                              ]}
                              isMulti
                            />
                          )}
                          {this.state.Condicion.label === "Nombre Propone" && (
                            <Select
                              className="SelectSize"
                              name="Propone"
                              value={this.state.multiValuePropone}
                              onChange={this.handleMultiPropone}
                              placeholder="Seleccione Propone"
                              options={this.state.catProponen}
                              isMulti
                            />
                          )}
                          {this.state.Condicion.label ===
                            "QuienProponeConsideracion" && (
                            <Select
                              className="SelectSize"
                              name="Propone"
                              value={this.state.multiValueProponeC}
                              onChange={this.handleMultiProponeC}
                              placeholder="Seleccione Propone C"
                              options={this.state.catProponen}
                              isMulti
                            />
                          )}
                          {this.state.Condicion.label === "Movimiento" && (
                            <Select
                              className="SelectSize"
                              name="Movimiento"
                              value={this.state.multiValueMovimiento}
                              options={this.state.filterMovimiento}
                              onChange={this.handleMultiMovimiento}
                              placeholder="Seleccione Movimiento"
                              isMulti
                            />
                          )}
                          {this.state.Condicion.label ===
                            "Estatus Movimiento" && (
                            <Select
                              name="Estatus"
                              className="SelectSize"
                              placeholder="Estatus"
                              value={this.state.multiValueMovEstatus}
                              options={this.state.filterMovEstatus}
                              onChange={this.handleMultiMovEstatus}
                              isMulti
                            />
                          )}
                          {this.state.Condicion.label === "isActivo" && (
                            <Select
                              name="Activo"
                              className="SelectSize"
                              placeholder="Estatus"
                              value={this.state.multiValueActivo}
                              options={this.state.filterActivo}
                              onChange={this.handleMultiActivo}
                              isMulti
                            />
                          )}
                          {this.state.Condicion.label === "Cruce Actores" && (<div><Input
                              onChange={() => this.TagsCrucesAdd("Cruce Actores")}
                              type="checkbox"
                            />
                            Cruce Actores</div>
                          )}
                          {this.state.Condicion.label === "Cruce Abogados" && (<div><Input
                            onChange={() => this.TagsCrucesAdd("Cruce Abogados")}
                            type="checkbox"
                          />
                          Cruce Abogados</div>
                        )}
                        {this.state.Condicion.label === "Cruce Casa Azul" && (<div><Input
                              onChange={() => this.TagsCrucesAdd("Cruce Casa Azul")}
                              type="checkbox"
                            />
                            Cruce Casa Azul</div>
                          )}
                          {this.state.Condicion.label === "Cruce RC" && (<div><Input
                            onChange={() => this.TagsCrucesAdd("Cruce RC")}
                            type="checkbox"
                          />
                          Cruce RC</div>
                        )}{this.state.Condicion.label === "Cruce RG" && (<div><Input
                          onChange={() => this.TagsCrucesAdd("Cruce RG")}
                          type="checkbox"
                        />
                        Cruce RG</div>
                      )}
                      {this.state.Condicion.label === "Cruce Promoción" && (<div><Input
                        onChange={() => this.TagsCrucesAdd("Cruce Promoción")}
                        type="checkbox"
                      />
                      Cruce Promoción</div>
                    )}{this.state.Condicion.label === "Cruce PAN" && (<div><Input
                      onChange={() => this.TagsCrucesAdd("Cruce PAN")}
                      type="checkbox"
                    />
                    Cruce PAN</div>
                  )}
                  {this.state.Condicion.label === "Cruce PRI" && (<div><Input
                    onChange={() => this.TagsCrucesAdd("Cruce PRI")}
                    type="checkbox"
                  />
                  Cruce PRI</div>
                )}{this.state.Condicion.label === "Cruce PRD" && (<div><Input
                  onChange={() => this.TagsCrucesAdd("Cruce PRD")}
                  type="checkbox"
                />
                Cruce PRD</div>
              )}
              {this.state.Condicion.label === "Cruce PVEM" && (<div><Input
                onChange={() => this.TagsCrucesAdd("Cruce PVEM")}
                type="checkbox"
              />
              Cruce PVEM</div>
            )}{this.state.Condicion.label === "Cruce Morena" && (<div><Input
              onChange={() => this.TagsCrucesAdd("Cruce Morena")}
              type="checkbox"
            />
            Cruce Morena</div>
          )}
          {this.state.Condicion.label === "Cruce Vota" && (<div><Input
            onChange={() => this.TagsCrucesAdd("Cruce Vota")}
            type="checkbox"
          />
          Cruce Vota</div>
        )}{this.state.Condicion.label === "Cruce Plantilla" && (<div><Input
          onChange={() => this.TagsCrucesAdd("Cruce Plantilla")}
          type="checkbox"
        />
        Cruce Plantilla</div>
      )}
      {this.state.Condicion.label === "Cruce Especial" && (<div><Input
        onChange={() => this.TagsCrucesAdd("Cruce Especial")}
        type="checkbox"
      />
      Cruce Especial</div>
    )}{this.state.Condicion.label === "Cruce OSC" && (<div><Input
      onChange={() => this.TagsCrucesAdd("Cruce OSC")}
      type="checkbox"
    />
    Cruce OSC</div>
  )}
  
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="ml-auto mr-auto" lg="12" md="12">
                        <br></br>
                        <TagsInput
                          value={this.state.tagsCruces}
                          onChange={this.handleOnChangeCruces}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col md="3">
                        {!loading &&<Button color="primary" onClick={this.btnGenerar}>
                          Generar
                        </Button>}
                        {loading && <CircleSpinner
															className="text-center"
															size={30}
															color="#686769"
															loading={loading}
														/>}
                      </Col>
                    </Row>
                  </div>
                )}
                {this.state.showFilter && (
                  <ReactTable
                    data={resultsRS}
                    showPagination={true}
                    showPageJump={false}
                    previousText="Anterior"
                    nextText="Siguiente"
                    canNextFromData={true}
                    defaultPageSize={10}
                    total={this.state.total}
                    pages={Math.floor(this.state.total / 10)}
                    columns={columnas}
                    manual
                    loading={loading}
                    PaginationComponent={Pagination}
                    className="-striped -highlight primary-pagination"
                  />
                )}
              </CardBody>
            </Card>
          </div>
          <Footer fluid />
        </div>
      </div>
    );
  }
}

export default ReporteGenerador;
