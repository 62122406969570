import React, { Component } from 'react';
// react plugin used to create switch buttons
import { CircleSpinner } from 'react-spinners-kit';
import Select from 'react-select';
import { Button, Input } from 'reactstrap';
import './styles.css';

class FixedPlugin extends Component {
	constructor(props) {
		super(props);

		this.state = {
			classes: 'dropdown',
			isLoading: false,
			multiValueEstatus: [],
			multiValueMovEstatus: this.props.idMovStatus.length > 0 ? this.props.idMovStatus : [],
			multiValueDependencia: [],
			multiValueNivel: [],
			multiValueNivelEstudios: [],
			multiValueMunicipio: [],
			multiValueTipo: [],
			multiValuePropone: [],
			multiValueProponeC: [],
			multiValueActivo: [],
			filterActivo: [
				{ value: '1', label: 'PROCESO' },
				{ value: '0', label: 'CONCLUIDO' },
			],
			filterTipo: [
				{ value: '1', label: 'CONSIDERACIÓN' },
				{ value: '2', label: 'INGRESO' },
				{ value: '3', label: 'PROMOCIÓN' },
				{ value: '4', label: 'BAJA' },
				{ value: '5', label: 'LABORANDO' },
				{ value: '6', label: 'NO PROPONER' },
			],
			filterEstatus: [
				{ value: 'OCUPADA', label: 'OCUPADA' },
				{ value: 'VACANTE', label: 'VACANTE' },
				{ value: 'VACANTE POR LICENCIA', label: 'VACANTE POR LICENCIA' },
				{ value: 'NULL', label: 'NULL' },
			],
			filterMovEstatus: [
				{ value: '1', label: 'Por Autorizar' },
				{ value: '2', label: 'Por Validar' },
				{ value: '3', label: 'Validado' },
				{ value: '4', label: 'Autorizado' },
				{ value: '5', label: 'Rechazado' },
				{ value: '6', label: 'Con Oficio' },
				{ value: '7', label: 'Rechazado Dependencia' },
				{ value: '8', label: 'Aprobado Dependencia' },
			],
			Nombre: '',
			Secuencial: '',
			GradoObtenido: '',
			FolioDependencia: '',
			FechaHoraInicio: '',
			FechaHoraFinal: '',
			FechaCapturaInicio: '',
			FechaCapturaFin:'',
			multiValueTieneCV: [],
			filterTieneCV: [
				{ value: 1, label: 'SI' },
				{ value: 0, label: 'NO' },
			],
			multiValueArchivoCV: [],
			filterArchivoCV: [
				{ value: 1, label: 'SI' },
				{ value: 0, label: 'NO' },
			]
		};
		this.handleClick = this.handleClick.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		const { isLoading } = this.state;
		if (nextProps.isLoading !== isLoading) {
			this.setState({ isLoading });
		}
	}

	handleClick() {
		if (this.state.classes === 'dropdown') {
			this.setState({ classes: 'dropdown show' });
		} else {
			this.setState({ classes: 'dropdown' });
		}
	}

	handleMultiTipo = option => {
		this.setState(state => {
			return {
				multiValueTipo: option,
			};
		});
	};

	handleMultiActivo = option => {
		this.setState(state => {
			return {
				multiValueActivo: option,
			};
		});
	};
	handleTieneCV = option => {
		this.setState(state => {
			return {
				multiValueTieneCV: option,
			};
		});
	};
	handleArchivoCV = option => {
		this.setState(state => {
			return {
				multiValueArchivoCV: option,
			};
		});
	};

	handleMultiMunicipio = option => {
		this.setState(state => {
			return {
				multiValueMunicipio: option,
			};
		});
	};

	handleMultiDependencia = option => {
		this.setState(state => {
			return {
				multiValueDependencia: option,
			};
		});
	};

	handleMultiNivel = option => {
		this.setState(state => {
			return {
				multiValueNivel: option,
			};
		});
	};

	handleMultiPropone = option => {
		this.setState(state => {
			return {
				multiValuePropone: option,
			};
		});
	};

	handleMultiProponeC = option => {
		this.setState(state => {
			return {
				multiValueProponeC: option,
			};
		});
	};

	handleMultiNivelEstudios = option => {
		this.setState(state => {
			return {
				multiValueNivelEstudios: option,
			};
		});
	};

	handleMultiEstatus = option => {
		this.setState(state => {
			return {
				multiValueEstatus: option,
			};
		});
	};

	handleMultiMovEstatus = option => {
		this.setState(state => {
			return {
				multiValueMovEstatus: option,
			};
		});
	};

	setFilter = () => {

		const {
			Nombre,
			Secuencial,
			FolioDependencia,
			multiValueEstatus,
			multiValueDependencia,
			multiValueNivel,
			multiValueMunicipio,
			multiValuePropone,
			multiValueProponeC,
			multiValueTipo,
			multiValueNivelEstudios,
			multiValueMovEstatus,
			multiValueActivo,
			multiValueTieneCV,
			multiValueArchivoCV,
			GradoObtenido,
			FechaHoraFinal,
			FechaHoraInicio,
			FechaCapturaInicio,
			FechaCapturaFin,
			
		} = this.state;
		const myFiltro = [];

		if (FechaHoraInicio.length > 1) myFiltro.push({ id: 'FechaHoraInicio', value: FechaHoraInicio });
		if (FechaHoraFinal.length > 1) myFiltro.push({ id: 'FechaHoraFinal', value: FechaHoraFinal });
		if (FechaCapturaInicio.length > 1) myFiltro.push({ id: 'FechaCapturaInicio', value: FechaCapturaInicio });
		if (FechaCapturaFin.length > 1) myFiltro.push({ id: 'FechaCapturaFin', value: FechaCapturaFin });

		if (Nombre.length > 1) myFiltro.push({ id: 'candidato', value: Nombre });
		if (Secuencial.length > 1) myFiltro.push({ id: 'plaza', value: Secuencial });
		if (GradoObtenido.length > 1) myFiltro.push({ id: 'GradoObtenido', value: GradoObtenido });
		if (FolioDependencia.length > 1) myFiltro.push({ id: 'FolioDependencia', value: FolioDependencia });

		var x = 0;
		if (multiValueEstatus != null)
			for (x = 0; x < multiValueEstatus.length; x++)
				myFiltro.push({ id: 'Estatus', value: multiValueEstatus[x].value });
		if (multiValueActivo != null)
			for (x = 0; x < multiValueActivo.length; x++)
				myFiltro.push({ id: 'isActivo', value: multiValueActivo[x].value });
		/////////////////
		if (multiValueTieneCV != null)
			for (x = 0; x < multiValueTieneCV.length; x++)
				myFiltro.push({ id: 'tieneCV', value: multiValueTieneCV[x].value });

		if (multiValueArchivoCV != null)
			for (x = 0; x < multiValueArchivoCV.length; x++)
				myFiltro.push({ id: 'ArchivoCV', value: multiValueArchivoCV[x].value });
		///////////////////
		// for (x = 0; x < multiValuePropone.length; x++)
		// 	myFiltro.push({ id: 'propone', value: multiValuePropone[x].value });
		if (multiValuePropone != null)
			for (x = 0; x < multiValuePropone.length; x++)
				myFiltro.push({ id: 'propone', value: multiValuePropone[x].value });
		if (multiValueProponeC != null)
			for (x = 0; x < multiValueProponeC.length; x++)
				myFiltro.push({ id: 'QuienProponeConsideracion', value: multiValueProponeC[x].label });
		if (multiValueDependencia != null)
			for (x = 0; x < multiValueDependencia.length; x++)
				myFiltro.push({ id: 'dependencia', value: multiValueDependencia[x].value });
		if (multiValueNivel != null)
			for (x = 0; x < multiValueNivel.length; x++)
				myFiltro.push({ id: 'nivel', value: multiValueNivel[x].value });
		if (multiValueNivelEstudios != null)
			for (x = 0; x < multiValueNivelEstudios.length; x++)
				myFiltro.push({ id: 'idNivelEstudios', value: multiValueNivelEstudios[x].value });
		if (multiValueMunicipio != null)
			for (x = 0; x < multiValueMunicipio.length; x++)
				myFiltro.push({ id: 'Municipio', value: multiValueMunicipio[x].value });
		if (multiValueTipo != null)
			for (x = 0; x < multiValueTipo.length; x++)
				myFiltro.push({ id: 'idMovimiento', value: multiValueTipo[x].value });
		if (multiValueMovEstatus != null)
			for (x = 0; x < multiValueMovEstatus.length; x++)
				myFiltro.push({ id: 'idMovimientoStatus', value: multiValueMovEstatus[x].value });

		console.log('=>Mis Filtros: ', myFiltro);
		this.setState({ Filtered: myFiltro });

		this.props.filtrar(myFiltro);
	};

	onChange = (startDate, endDate) => this.setState({ startDate, endDate });

	render() {
		console.log('=>Stats: ', this.state);

		const { isLoading } = this.state;

		return (
			<div className="fixed-plugin">
				<div className={this.state.classes}>
					<div onClick={this.handleClick}>
						<i className="fa fa-cog fa-2x" />
					</div>

					<ul className="dropdown-menu show ul">
						<li className="header-title">Filtrar Resultados</li>
						<li className="adjustments-line"></li>
						<div class="form-group">
							<label for="exampleDate" class="">
								Fecha Inicio
							</label>
							<input
								type="date"
								name="FechaCapturaInicio"
								id="FechaCapturaInicio"
								placeholder="date placeholder"
								value={this.state.FechaCapturaInicio}
								onChange={e => this.setState({ FechaCapturaInicio: e.target.value })}
								class="form-control"
							/>
						</div>
						<div class="form-group">
							<label for="exampleDate" class="">
								Fecha Fin
							</label>
							<input
								type="date"
								name="FechaCapturaFin"
								id="FechaCapturaFin"
								value={this.state.FechaCapturaFin}
								onChange={e => this.setState({ FechaCapturaFin: e.target.value })}
								placeholder="date placeholder"
								class="form-control"
							/>
						</div>
						<div class="form-group">
							<label for="exampleDate" class="">
								Fecha Mov Inicio
							</label>
							<input
								type="date"
								name="FechaHoraInicio"
								id="FechaHoraInicio"
								placeholder="date placeholder"
								value={this.state.FechaHoraInicio}
								onChange={e => this.setState({ FechaHoraInicio: e.target.value })}
								class="form-control"
							/>
						</div>
						<div class="form-group">
							<label for="exampleDate" class="">
								Fecha Mov Fin
							</label>
							<input
								type="date"
								name="FechaHoraFinal"
								id="FechaHoraFinal"
								value={this.state.FechaHoraFinal}
								onChange={e => this.setState({ FechaHoraFinal: e.target.value })}
								placeholder="date placeholder"
								class="form-control"
							/>
						</div>
						<li className="li">
							<Select
								className="SelectSize"
								name="ProponeC"
								value={this.state.multiValueProponeC}
								onChange={this.handleMultiProponeC}
								placeholder="Seleccione ProponeC"
								options={this.props.data.Propone}
								isMulti
							/>
						</li>
						<li className="adjustments-line" />
						<li className="li">
							<Select
								className="SelectSize"
								name="Movimiento"
								value={this.state.multiValueMovEstatus}
								onChange={this.handleMultiMovEstatus}
								placeholder="Seleccione Tipo"
								options={
									this.props.idMovStatus.length > 0
										? this.props.idMovStatus
										: this.state.filterMovEstatus
								}
								isMulti
							/>
						</li>
						<li className="adjustments-line" />
						<li className="li">
							<Select
								className="SelectSize"
								name="Tipo"
								placeholder="Tipo"
								value={this.state.multiValueTipo}
								options={this.state.filterTipo}
								onChange={this.handleMultiTipo}
								isMulti
							/>
						</li>
						<li className="adjustments-line">
							<Input
								placeholder="Candidato"
								id="Nombre"
								value={this.state.Nombre}
								type="text"
								onChange={e => this.setState({ Nombre: e.target.value })}
							/>
						</li>
						<li className="adjustments-line" />
						<li className="li">
							<Select
								className="SelectSize"
								name="NivelEstudios"
								value={this.state.multiValueNivelEstudios}
								onChange={this.handleMultiNivelEstudios}
								placeholder="Seleccione Nivel Estudios"
								options={[
									{ value: '1', label: 'No definido' },
									{ value: '2', label: 'Analfabeto(a)' },
									{ value: '3', label: 'Primaria trunca' },
									{ value: '4', label: 'Primaria completa' },
									{ value: '5', label: 'Secundaria trunca' },
									{ value: '6', label: 'Secundaria completa' },
									{ value: '7', label: 'Preparatoria trunca' },
									{ value: '8', label: 'Preparatoria completa' },
									{ value: '9', label: 'Licenciatura trunca' },
									{ value: '10', label: 'Licenciatura completa' },
									{ value: '11', label: 'Licenciatura Técnica' },
									{ value: '12', label: 'Maestría trunca' },
									{ value: '13', label: 'Maestría completa' },
									{ value: '14', label: 'Doctorado trunco' },
									{ value: '14', label: 'Doctorado completo' },
								]}
								isMulti
							/>
						</li>
						<li className="adjustments-line">
							<Input
								placeholder="GradoObtenido"
								id="GradoObtenido"
								value={this.state.GradoObtenido}
								type="text"
								onChange={e => this.setState({ GradoObtenido: e.target.value })}
							/>
						</li>
						<li className="adjustments-line" />
						<li className="li">
							<Select
								className="SelectSize"
								name="Municipio"
								placeholder="Municipio"
								value={this.state.multiValueMunicipio}
								options={this.props.data.Municipios}
								onChange={this.handleMultiMunicipio}
								isMulti
							/>
						</li>
						<li className="adjustments-line" />
						<li className="li">
							<Select
								className="SelectSize"
								name="Dependencia"
								placeholder="Dependencia"
								value={this.state.multiValueDependencia}
								options={this.props.data.Dependencias}
								onChange={this.handleMultiDependencia}
								isMulti
							/>
						</li>
						<li className="header-title">Propone</li>
						<li className="li">
							<Select
								className="SelectSize"
								name="Propone"
								value={this.state.multiValuePropone}
								onChange={this.handleMultiPropone}
								placeholder="Seleccione Propone"
								options={this.props.data.Propone}
								isMulti
							/>
						</li>
						<li className="adjustments-line">
							<Input
								placeholder="Plaza"
								id="Secuencial"
								value={this.state.Secuencial}
								type="text"
								onChange={e => this.setState({ Secuencial: e.target.value })}
							/>
						</li>
						<li className="li">
							<Select
								className="SelectSize"
								name="Nivel"
								value={this.state.multiValueNivel}
								onChange={this.handleMultiNivel}
								placeholder="Seleccione Nivel"
								options={[
									{ value: '1', label: 'Nivel 1' },
									{ value: '2', label: 'Nivel 2' },
									{ value: '3', label: 'Nivel 3' },
									{ value: '4', label: 'Nivel 4' },
									{ value: '5', label: 'Nivel 5' },
									{ value: '6', label: 'Nivel 6' },
									{ value: '7', label: 'Nivel 7' },
									{ value: '8', label: 'Nivel 8' },
									{ value: '9', label: 'Nivel 9' },
									{ value: '10', label: 'Nivel 10' },
									{ value: '11', label: 'Nivel 11' },
									{ value: '12', label: 'Nivel 12' },
									{ value: '13', label: 'Nivel 13' },
									{ value: '14', label: 'Nivel 14' },
									{ value: '15', label: 'Nivel 15' },
									{ value: '16', label: 'Nivel 16' },
									{ value: '17', label: 'Nivel 17' },
									{ value: '18', label: 'Nivel 18' },
									{ value: '19', label: 'Nivel 19' },
									{ value: '20', label: 'Nivel 20' },
									{ value: '21', label: 'Nivel 21' },
								]}
								isMulti
							/>
						</li>
						<li className="header-title">Estatus</li>
						<li className="li">
							<Select
								name="Estatus"
								className="SelectSize"
								placeholder="Estatus"
								value={this.state.multiValueEstatus}
								options={this.state.filterEstatus}
								onChange={this.handleMultiEstatus}
								isMulti
							/>
						</li>
						<li className="header-title">Activo</li>
						<li className="li">
							<Select
								name="Activo"
								className="SelectSize"
								placeholder="Estatus"
								value={this.state.multiValueActivo}
								options={this.state.filterActivo}
								onChange={this.handleMultiActivo}
								isMulti
							/>
						</li>
						<li className="header-title">Tiene CV</li>
						<li className="li">
							<Select
								name="Activo"
								className="SelectSize"
								placeholder="TieneCV"
								value={this.state.multiValueTieneCV}
								options={this.state.filterTieneCV}
								onChange={this.handleTieneCV}
								isMulti
							/>
						</li><li className="header-title">Archivo CV</li>
						<li className="li">
							<Select
								name="Activo"
								className="SelectSize"
								placeholder="ArchivoCV"
								value={this.state.multiValueArchivoCV}
								options={this.state.filterArchivoCV}
								onChange={this.handleArchivoCV}
								isMulti
							/>
						</li>
						<li className="button-container">
							<span>
								{!isLoading && (
									<Button color="info" block className="btn-round" onClick={this.setFilter}>
										Filtrar
									</Button>
								)}
								{isLoading && (
									<CircleSpinner
										className="text-center"
										size={30}
										color="#686769"
										loading={isLoading}
									/>
								)}
							</span>
						</li>
					</ul>
				</div>
			</div>
		);
	}
}

export default FixedPlugin;
