import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getVars } from "./GlobalStore";

const isAuth = () => {
  if (getVars("Token") !== null) {
    return true;
  }
  return false;
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        isAuth() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { message: "Usuario no autorizado" }
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
