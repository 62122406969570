import { URL_PUBLICA } from "../GlobalStore";
const getFile = (data, api, token) => {

    var url = new URL(`${URL_PUBLICA+api}?token=${token}`);

    if(data.length)
     {
       data.forEach(item => {
         console.log('=>Param: ', item);
         url.searchParams.append(item.id, item.value);
       })
    }

    console.log('=>getFile: ', url);

    window.open(url, '_blank');
}

export default getFile;