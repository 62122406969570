export const Pastel = (Val1, Val2, texto, Label1, Label2) =>{
  return {
  data: {
    labels: [Label1, Label2],
    datasets: [
      {
        label: Label1,
        pointRadius: 0,
        pointHoverRadius: 0,
        backgroundColor: ["#4acccd", "#f4f3ef"],
        borderWidth: 0,
        data: [Val1, Val2]
      }
    ]
  },
  options: {
    elements: {
      center: {
        text: `${texto}`,
        color: "#66615c", // Default is #000000
        fontStyle: "Arial", // Default is Arial
        sidePadding: 60 // Defualt is 20 (as a percentage)
      }
    },
    cutoutPercentage: 90,
    legend: {
      display: false
    },
    tooltips: {
      enabled: true
    },
    scales: {
      yAxes: [
        {
          ticks: {
            display: false
          },
          gridLines: {
            drawBorder: false,
            zeroLineColor: "transparent",
            color: "rgba(255,255,255,0.05)"
          }
        }
      ],
      xAxes: [
        {
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: "rgba(255,255,255,0.1)",
            zeroLineColor: "transparent"
          },
          ticks: {
            display: false
          }
        }
      ]
    }
  }
};
} 