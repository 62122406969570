import React from 'react';
import { Button, Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import NotificationAlert from 'react-notification-alert';
import ReactTable from 'react-table';
import matchSorter from 'match-sorter';

import EjecutarApi from '../../data/Consultas';
import Notify from '../../data/Notificacion';
import FixedPlugin from '../../components/FixedPlugin/FixedPlugin';

import DashboardNavbar from '../../components/Navbars/DashboardNavbar.jsx';
import Footer from '../../components/Footer/Footer.jsx';
import Sidebar from '../../components/Sidebar/MenuSidebar.jsx';

import Pagination from '../components/Paginacion';

import Movimiento from '../components/Autorizar/index';

import { getVars,URL_PUBLICA } from '../../GlobalStore';

var global = '';
class AutorizarMovimiento extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			backgroundColor: 'black',
			activeColor: 'info',
			sidebarMini: false,
			loading: false,
			isLoading: false,
			firstTime: true,
			showData: true,
			total: 0,
			data: {},
			catProponen: [],
			filterMunicipio: [],
			filterDependencia: [],
			Filtered: [],
			dataRS: [],
			dataRsPlus: [],
		};
	}

	componentDidMount() {
		const data = {
			page: 0,
			tipo: 'and',
			pageSize: 99999,
			sorted: [{ id: 'Nombre', desc: false }],
			filtered: [],
		};

		const token = global.access_token; //localstorage

		console.log('=Get Propone');

		EjecutarApi(data, 'getProponen', token)
			.then(function(res) {
				return res;
			})
			.then(items => {
				console.log('===> Data getPropone: ', items);

				if (items.results) {
					this.setState({
						catProponen: items.data.map((prop, key) => {
							return { label: prop.NombrePropone, value: prop.id };
						}),
					});
				}
			})
			.catch(e => {
				console.log('error getProponen: ', e.message);
			});

		fetch(URL_PUBLICA+'obtenerDatosCandidatos', {
			headers: new Headers({ Authorization: `Bearer ${token}` }),
		})
			.then(response => {
				switch (response.status) {
					case 200:
						console.log('==>Error Status: ', response.status);
						break;

					default:
						break;
				}

				if (response.ok) {
					return response.json();
				}
				throw new Error('Ocurrio un error: obtenerDatosCandidatos: ', response.statusText);
			})
			.then(items => {
				console.log('===> Data Catalogos: ', items);

				this.setState({
					filterDependencia: items.dependencias.map((prop, key) => {
						return { label: prop.dependencia.label, value: prop.dependencia.value };
					}),
					filterMunicipio: items.municipios,
				});
			})
			.catch(e => {
				console.log('error: ', e.message);
			});
	}

	setValidate = obj => {
		this.setState({ data: obj, showData: false });
	};

	setBack = () => {
		this.setState({ showData: true });
	};

	btnAuthorizar = item => {
		console.log('=>Autorizar: ', item);

		const token = global.access_token; //localstorage

		const formData = new FormData();
		formData.append('idMovimientoStatus', 4);
		formData.append('id', item.id);

		fetch(URL_PUBLICA+`validarMovimiento`, {
			method: 'POST',
			headers: new Headers({ Authorization: `Bearer ${token}` }),
			body: formData,
		})
			.then(response => {
				switch (response.status) {
					case 200:
						console.log('==>Error Status: ', response.status);
						break;

					default:
						console.log('==>Unknow Status: ', response.status);
						/*
                this.props.history.push({
                    pathname: '/logout',
                    state: {token},
                    message: 'Su cuenta ya expiro'
                });
                */
						return;
				}

				if (response.ok) {
					return response.json();
				}
				throw new Error('Ocurrio un error: obtenerDatosCandidatos: ', response.statusText);
			})
			.then(items => {
				console.log('===> Data Catalogos: ', items);
				this.setState({ showData: true });

				var data = this.state.dataRsPlus;
				data.find((o, i) => {
					console.log(`==> ${o.id} === ${item.key}`);
					if (o.id === item.id) {
						// here you should add some custom code so you can delete the data
						// from this component and from your server as well
						data.splice(i, 1);
						console.log('=>[data]<= ', data);
						return true;
					}
					return false;
				});

				Notify(2, 'tc', 'Movimiento Autorizado!', this.refs);

				this.setState({ dataRsPlus: data });
			})
			.catch(e => {
				console.log('error: ', e.message);
				this.setState({ showData: true });
				Notify(2, 'tc', `Error: ${e.message}`, this.refs);
			});
	};

	btnNoAuthorizar = item => {
		console.log('=>NoAuthorizar: ', item);
		this.setState({ showData: true });

		const token = global.access_token; //localstorage

		const formData = new FormData();
		formData.append('idMovimientoStatus', 5);
		formData.append('id', item.id);

		fetch(URL_PUBLICA+`validarMovimiento`, {
			method: 'POST',
			headers: new Headers({ Authorization: `Bearer ${token}` }),
			body: formData,
		})
			.then(response => {
				switch (response.status) {
					case 200:
						console.log('==>Error Status: ', response.status);
						break;

					default:
						console.log('==>Unknow Status: ', response.status);
						/*
                this.props.history.push({
                    pathname: '/logout',
                    state: {token},
                    message: 'Su cuenta ya expiro'
                });
                */
						return;
				}

				if (response.ok) {
					return response.json();
				}
				throw new Error('Ocurrio un error: obtenerDatosCandidatos: ', response.statusText);
			})
			.then(items => {
				console.log('===> Data Catalogos: ', items);
				this.setState({ showData: true });

				var data = this.state.dataRsPlus;
				data.find((o, i) => {
					console.log(`==> ${o.id} === ${item.key}`);
					if (o.id === item.id) {
						// here you should add some custom code so you can delete the data
						// from this component and from your server as well
						data.splice(i, 1);
						console.log('=>[data]<= ', data);
						return true;
					}
					return false;
				});

				Notify(2, 'tc', 'El movimiento fue rechazado', this.refs);

				this.setState({ dataRsPlus: data });
			})
			.catch(e => {
				console.log('error: ', e.message);
				this.setState({ showData: true });
				Notify(2, 'tc', `Error: ${e.message}`, this.refs);
			});
	};

	setFiltered = Filtered => {
		this.setState({ Filtered, isLoading: true });

		const { menu } = this.state;

		const token = global.access_token;

		const data = {
			page: 0,
			tipo: 'and',
			pageSize: 10,
			sorted: [],
			filtered: Filtered,
		};

		console.log('==>Filtered PropuestasVIP: ', data);

		EjecutarApi(data, 'getPlazaMovimientos', token)
			.then(function(res) {
				return res;
			})
			.then(items => {
				console.log('===> Data getPlazaMovimientos: ', items);
				this.setState({ isLoading: false });

				if (items.results) {
					const dataTable = items.data;

					console.log('==>Hay RS: ', dataTable);

					this.setState({
						loading: false,
						total: items.total,
						dataRsPlus: dataTable.map((prop, key) => {
							return {
								id: prop.id,
								key: key,
								FechaMovimiento: prop.FechaMovimiento || '',
								PuestoQuienPropone: prop.Propone.Puesto || '',
								Movimiento: prop.movimiento.label,
								FolioDependencia: prop.FolioDependencia,
								QuienProponeConsideracion: prop.QuienProponeConsideracion,
								QuienPropone: `${prop.Propone.Nombre} ${prop.Propone.Paterno}, ${
									prop.Propone.Puesto === null ? '' : prop.Propone.Puesto
								}`,
								Candidato: (
									<div>
										{menu.Edit === 1 && (
											<Button
												className="btn-link"
												color="primary"
												onClick={() => {
													let obj = this.state.dataRsPlus.find(o => o.key === key);
													this.setEdit(obj);
												}}
											>
												<i className="fa fa-edit" />
											</Button>
										)}
										{prop.movimiento.value === 1 && (
											<Button
												className="btn-link"
												color="primary"
												onClick={() => {
													let obj = this.state.dataRsPlus.find(o => o.key === key);
													this.btnCheck(obj);
												}}
											>
												<i className="fa fa-star" />
												{prop.movimiento.value}
											</Button>
										)}
										{prop.movimiento.value === 5 && (
											<Button
												className="btn-link"
												color="secunday"
												onClick={() => {
													let obj = this.state.dataRsPlus.find(o => o.key === key);
													this.btnCheck(obj);
												}}
											>
												<i className="fa fa-check" />
												{prop.movimiento.value}
											</Button>
										)}
										{`${prop.candidato.Nombre} ${prop.candidato.Paterno} ${prop.candidato.Materno}`}
									</div>
								),

								dataCandidato: prop,
								btnCandidato: (
									<div>
										{menu.Edit === 1 && (
											<Button
												className="btn-link"
												color="primary"
												onClick={() => {
													let obj = this.state.dataRsPlus.find(o => o.key === key);
													this.setEdit(obj);
												}}
											>
												<i className="fa fa-edit" />
												{prop.movimiento.value}
											</Button>
										)}
										{prop.movimiento.value === 1 && (
											<Button
												className="btn-link"
												color="primary"
												onClick={() => {
													let obj = this.state.dataRsPlus.find(o => o.key === key);
													this.btnCheck(obj);
												}}
											>
												<i className="fa fa-star" />
												{prop.movimiento.value}
											</Button>
										)}

										{prop.movimiento.value === 5 && (
											<Button
												className="btn-link"
												color="secunday"
												onClick={() => {
													let obj = this.state.dataRsPlus.find(o => o.key === key);
													this.btnCheck(obj);
												}}
											>
												<i className="fa fa-check" />
											</Button>
										)}

										<Button
											className="btn-link"
											color="primary"
											onClick={() => {
												let obj = this.state.dataRsPlus.find(o => o.key === key);
												this.setValidate(obj);
											}}
										>
											{prop.movimiento.value !== 4
												? `${prop.candidato.Nombre} ${prop.candidato.Paterno} ${prop.candidato.Materno}`
												: prop.candidato.NombreCompleto}
										</Button>
									</div>
								),
								Plaza: prop.plaza.Puesto || '',
								Municipio: prop.plaza.Municipio,
								GradoObtenido: prop.candidato.GradoObtenido,
								NivelEstudios: prop.candidato.NivelEstudios.label,
								MunicipioCandidato: prop.candidato.Municipio.label,
								Nivel: prop.movimiento.value === 1 ? prop.candidato.NivelPropuesto : prop.plaza.Nivel,
								isActivo: prop.isActivo,
								isJefatura: prop.candidato.isJefatura,
								Estatus: prop.Movimiento_status.label,
								Dependencia: prop.plaza.Dependencia || '',
								idMovimientoStatus: prop.Movimiento_status.label || '',
							};
						}),
					});
				}
			})
			.catch(e => {
				console.log('error getPlazaMovimientos: ', e.message);
				this.setState({ isLoading: false });
			});
	};

	clearFiltered = () => {
		this.setState({ Filtered: [], isLoading: false });
	};

	render() {
		console.log('=>Mis Estados: ', this.state);
		global = getVars('Token');

		const { dataRsPlus, catProponen } = this.state;
		console.log('dataRsPlus: ', dataRsPlus);
		return (
			<div className="wrapper">
				<Sidebar {...this.props} bgColor={this.state.backgroundColor} activeColor={this.state.activeColor} />
				<div className="main-panel" ref="mainPanel">
					<DashboardNavbar {...this.props} handleMiniClick={this.handleMiniClick} />
					<NotificationAlert ref="notificationAlert" />
					<div className="content">
						{/*!this.state.showData && (
							<Movimiento
								showButtons={true}
								setBack={this.setBack}
								data={this.state.data}
								autorizar={this.btnAuthorizar}
								noautorizar={this.btnNoAuthorizar}
							/>
						)*/}
						{this.state.showData && (
							<Card>
								<CardHeader>
									<CardTitle tag="h4">Autorizar Movimientos</CardTitle>
								</CardHeader>
								<CardBody>
									<ReactTable
										data={dataRsPlus}
										showPagination={true}
										showPageJump={false}
										previousText="Anterior"
										nextText="Siguiente"
										canNextFromData={true}
										defaultPageSize={10}
										total={this.state.total}
										pages={Math.floor(this.state.total / 10)}
										columns={[
											{
												Header: 'Fecha Mov',
												minWidth: 80,
												id: 'fecha_movimiento',
												accessor: d => d.FechaMovimiento,
												filterMethod: (filter, rows) =>
													matchSorter(rows, filter.value, { keys: ['FechaMovimiento'] }),
												filterAll: true,
											},
											{
												Header: 'Folio',
												minWidth: 60,
												id: 'FolioDependencia',
												accessor: d => d.FolioDependencia,
												filterMethod: (filter, rows) =>
													matchSorter(rows, filter.value, { keys: ['FolioDependencia'] }),
												filterAll: true,
											},
											{
												Header: 'ProponeC',
												minWidth: 60,
												id: 'QuienProponeConsideracion',
												accessor: d => d.QuienProponeConsideracion,
												filterMethod: (filter, rows) =>
													matchSorter(rows, filter.value, {
														keys: ['QuienProponeConsideracion'],
													}),
												filterAll: true,
											},
											{
												Header: 'Tipo',
												minWidth: 60,
												id: 'idMovimiento',
												accessor: d => d.Movimiento,
												filterAll: true,
											},
											{
												Header: 'Candidato',
												minWidth: 180,
												id: 'candidato',
												accessor: d => d.btnCandidato,
												filterMethod: (filter, rows) =>
													matchSorter(rows, filter.value, { keys: ['Candidato'] }),
												filterAll: true,
											},
											{
												Header: 'Dependencia',
												id: 'dependencia',
												accessor: d => d.Dependencia,
												filterAll: true,
											},
											{
												Header: 'Propone',
												id: 'propone',
												accessor: d => d.QuienPropone,
												filterAll: true,
											},

											{
												Header: 'Plaza',
												id: 'plaza',
												accessor: d => d.Plaza,
												filterMethod: (filter, rows) =>
													matchSorter(rows, filter.value, { keys: ['Plaza'] }),
												filterAll: true,
											},
											{
												Header: 'Nivel',
												minWidth: 50,
												id: 'nivel',
												accessor: d => d.Nivel,
												filterAll: true,
											},
										]}
										manual
										loading={this.state.loading}
										PaginationComponent={Pagination}
										onFetchData={(stateVoBo, instance) => {
											console.log('==>stateVoBo: ', stateVoBo);

											const token = global.access_token;

											this.setState({ isLoading: true });

											const data = {
												page: stateVoBo.page,
												tipo: 'and',
												pageSize: stateVoBo.pageSize,
												sorted: stateVoBo.sorted,
												filtered: [{ id: 'idMovimientoStatus', value: '3' }],
											};

											console.log('==>Filtered: ', JSON.stringify(data));

											EjecutarApi(data, 'getPlazaMovimientos', token)
												.then(function(res) {
													return res;
												})
												.then(items => {
													console.log('===> Data getPlazaMovimientos: ', items);

													if (items.results) {
														const dataTable = items.data;

														console.log('==>Hay RS: ', dataTable);

														this.setState({
															isLoading: false,
															total: items.total,
															dataRsPlus: dataTable.map((prop, key) => {
																return {
																	id: prop.id,
																	key: key,
																	FechaMovimiento: prop.FechaMovimiento || '',
																	QuienProponeConsideracion:
																		prop.QuienProponeConsideracion,
																	FolioDependencia: prop.FolioDependencia,
																	PuestoQuienPropone: prop.Propone.Puesto || '',
																	Movimiento: prop.movimiento.label,
																	isBaja: prop.movimiento.value === 4 ? 1 : 0,
																	QuienPropone: `${prop.Propone.Nombre} ${
																		prop.Propone.Paterno
																	}, ${
																		prop.Propone.Puesto === null
																			? ''
																			: prop.Propone.Puesto
																	}`,
																	Candidato: (
																		<div>
																			{prop.movimiento.value === 4
																				? prop.candidato.NombreCompleto
																				: `${prop.candidato.Nombre} ${prop.candidato.Paterno} ${prop.candidato.Materno}`}
																		</div>
																	),

																	dataCandidato: prop,
																	btnCandidato: (
																		<div>
																			<Button
																				className="btn-link"
																				color="primary"
																				onClick={() => {
																					let obj = this.state.dataRsPlus.find(
																						o => o.key === key
																					);
																					this.setValidate(obj);
																				}}
																			>
																				{prop.movimiento.value !== 4
																					? `${prop.candidato.Nombre} ${prop.candidato.Paterno} ${prop.candidato.Materno}`
																					: prop.candidato.NombreCompleto}
																			</Button>
																		</div>
																	),
																	Plaza: prop.plaza.Puesto || '',
																	Municipio: prop.plaza.Municipio,
																	GradoObtenido: prop.candidato.GradoObtenido,
																	NivelEstudios: prop.candidato.NivelEstudios.label,
																	MunicipioCandidato: prop.candidato.Municipio.label,
																	Nivel:
																		prop.movimiento.value === 1
																			? prop.candidato.NivelPropuesto
																			: prop.plaza.Nivel,
																	isActivo: prop.isActivo,
																	isJefatura: prop.candidato.isJefatura,
																	Estatus: prop.Movimiento_status.label,
																	Dependencia: prop.plaza.Dependencia || '',
																	idMovimientoStatus:
																		prop.Movimiento_status.value || '',
																};
															}),
														});
													}
												})
												.catch(e => {
													console.log('error VoBo: ', e.message);
												});
										}}
										/*
                                      You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                                    */
										className="-striped -highlight primary-pagination"
									/>
								</CardBody>
							</Card>
						)}
					</div>
					<FixedPlugin
						idMovStatus={[{ value: '3', label: 'Validado' }]}
						isLoading={this.state.isLoading}
						filtrar={this.setFiltered}
						data={{
							Propone: catProponen,
							Dependencias: this.state.filterDependencia,
							Municipios: this.state.filterMunicipio,
						}}
					/>
					<Footer fluid />
				</div>
			</div>
		);
	}
}

export default AutorizarMovimiento;
