import React, { Component } from 'react';
import { Button, Label, FormGroup, Input, Row, Col, Modal } from 'reactstrap';
import Select from 'react-select';
import NotificationAlert from 'react-notification-alert';
import { getVars,URL_PUBLICA } from '../../GlobalStore';

var global = '';

class ModalNuevaPlaza extends Component {
	constructor(props) {
		super(props);

		console.log('====>', props);

		this.state = {
			Resultados: [],
			TotalRegistros: 0,
			NumSerie: '',
			Puesto: '',
			Nivel: {
				value: 'undefined',
				label: 'Seleccione',
			},
			Municipio: {
				value: 61,
				label: 'No definido',
			},
			TipoPlaza: {
				value: 1,
				lanel: 'No definido',
			},
		};
	}

	searchUser = () => {
		console.log(`==> Buscar Modal User
        ${this.state.Nombre}
        ${this.state.Paterno}
        ${this.state.Materno}
        `);

		const data = {
			Nombre: this.state.Nombre,
			Paterno: this.state.Paterno,
			Materno: this.state.Materno,
		};

		var url = new URL(URL_PUBLICA+'candidatos');

		Object.keys(data).forEach(key => {
			const Valor = data[key];
			if (Valor !== '') {
				url.searchParams.append(key, data[key]);
			}
		});

		const token = global.access_token;

		fetch(url, { headers: new Headers({ Authorization: `Bearer ${token}` }) })
			.then(response => {
				switch (response.status) {
					case 200:
						console.log('==>Status: ', response.status);
						break;

					default:
						this.props.onError('Error al consultar candiadtos');
						break;
				}

				if (response.ok) {
					return response.json();
				}
				throw new Error('Ocurrio un error: candidatos: ', response.statusText);
			})
			.then(items => this.setState({ Resultados: items.data, TotalRegistros: items.data.length }))
			.catch(e => {
				console.log('error: ', e.message);
				this.props.onError(e.message);
			});
	};

	notify = (color, place, message) => {
		var type;
		switch (color) {
			case 1:
				type = 'primary';
				break;
			case 2:
				type = 'success';
				break;
			case 3:
				type = 'danger';
				break;
			case 4:
				type = 'warning';
				break;
			case 5:
				type = 'info';
				break;
			default:
				break;
		}
		var options = {};
		options = {
			place: place,
			message: (
				<div>
					<div>{message}</div>
				</div>
			),
			type: type,
			icon: 'now-ui-icons ui-1_bell-53',
			autoDismiss: 4,
		};
		this.refs.notificationAlert.notificationAlert(options);
	};

	toggle = () => {
		// if(this.state.NumSerie.length < 2)
		// {
		//     this.notify(3, 'tc','Error: Especifique número de serie');
		//     return;
		// }

		const token = global.access_token; //localstorage

		fetch(URL_PUBLICA+
			`getValidationPlaza?NumSerie=${this.state.NumSerie}&idDependencia=${this.props.Dependencia.value}`,
			{ headers: new Headers({ Authorization: `Bearer ${token}` }) }
		)
			.then(response => {
				console.log('Mis Respuesta: ', response);
				switch (response.status) {
					case 200:
						console.log('==>Error Status: ', response.status);
						break;

					default:
						console.log('==>Unknow Status: ', response.status);
						this.props.history.push({
							pathname: '/logout',
							state: { token },
							message: 'Su cuenta ya expiro',
						});
						break;
				}

				if (response.ok) {
					return response.json();
				}
				throw new Error('Ocurrio un error: obtenerDatosCandidatos: ', response.statusText);
			})
			.then(items => {
				console.log('=>RS: ', items);
				console.log('=>States: ', this.state);

				if (items.success) {
					if (items.result === 'true') {
						this.notify(3, 'tc', items.message);
						return;
					} else {
						if (this.state.Puesto.length < 2) {
							this.notify(3, 'tc', 'Error: Especifique puesto');
							return;
						}

						if (this.state.Nivel.value === 'undefined') {
							this.notify(3, 'tc', 'Error: Especifique nivel');
							return;
						}

						if (this.state.Municipio.value === 61) {
							this.notify(3, 'tc', 'Error: Especifique municipio');
							return;
						}

						if (this.state.TipoPlaza.value === 1) {
							this.notify(3, 'tc', 'Error: Especifique tipo de plaza');
							return;
						}

						//Guardar Objeto

						const Plaza = {
							NumSerie: this.state.NumSerie,
							Puesto: this.state.Puesto,
							Nivel: this.state.Nivel,
							Municipio: this.state.Municipio,
							TipoPlaza: this.state.TipoPlaza,
						};

						this.props.setNuevaPlaza(Plaza);
						return;
					}
				}

				/*          
            this.setState({ catalogos: {
                EstadoCivil: items.EstadoCivil,
                dependencias: items.dependencias,
                municipios: items.municipios,
                nivel: items.nivel
            }});
            */
			})
			.catch(e => {
				console.log('error: ', e.message);
				this.notify(3, 'tc', e.message);
				return;
			});
	};

	render() {
		const { NumSerie, Puesto } = this.state;
		global = getVars('Token');

		return (
			<Modal size="lg" isOpen={this.props.isOpen} toggle={this.props.toggle}>
				<NotificationAlert ref="notificationAlert" />
				<div className="modal-header">
					<button
						aria-hidden={true}
						className="close"
						data-dismiss="modal"
						type="button"
						onClick={this.props.toggle}
					>
						<i className="nc-icon nc-simple-remove" />
					</button>
					<h5 className="modal-title" id="myModalLabel">
						Agregar Plaza para {this.props.Dependencia.label}
					</h5>
				</div>
				<div className="modal-body">
					<Row>
						<Col sm="3">
							<FormGroup>
								<Label className="text-left">Num Serie</Label>
								<Input
									placeholder="NumSerie"
									id="NumSerie"
									type="text"
									value={NumSerie}
									onChange={e => this.setState({ NumSerie: e.target.value })}
								/>
							</FormGroup>
						</Col>
						<Col sm="5">
							<FormGroup>
								<Label className="text-left">Puesto</Label>
								<Input
									placeholder="Puesto"
									id="Puesto"
									type="text"
									value={Puesto}
									onChange={e => this.setState({ Puesto: e.target.value })}
								/>
							</FormGroup>
						</Col>
						<Col sm="3">
							<FormGroup>
								<Label className="text-left">Nivel </Label>
								<Select
									className="react-select primary"
									classNamePrefix="react-select"
									name="Nivel"
									value={this.state.Nivel}
									onChange={value => this.setState({ Nivel: value })}
									options={[
										{
											value: '',
											label: 'Seleccione',
											isDisabled: true,
										},
										{ value: 1, label: '1' },
										{ value: 2, label: '2' },
										{ value: 3, label: '3' },
										{ value: 4, label: '4' },
										{ value: 5, label: '5' },
										{ value: 6, label: '6' },
										{ value: 7, label: '7' },
										{ value: 8, label: '8' },
										{ value: 9, label: '9' },
										{ value: 10, label: '10' },
										{ value: 11, label: '11' },
										{ value: 12, label: '12' },
										{ value: 13, label: '13' },
										{ value: 14, label: '14' },
										{ value: 15, label: '15' },
										{ value: 16, label: '16' },
										{ value: 17, label: '17' },
										{ value: 18, label: '18' },
										{ value: 19, label: '19' },
										{ value: 20, label: '20' },
									]}
									placeholder="Seleccione Nivel"
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col sm="4">
							<FormGroup>
								<Label className="text-left">Municipio </Label>
								<Select
									className="react-select primary"
									classNamePrefix="react-select"
									name="Municipio"
									value={this.state.Municipio}
									onChange={value => this.setState({ Municipio: value })}
									options={
										this.props.municipio && this.props.municipio.constructor === Array
											? this.props.municipio
											: []
									}
									placeholder="Seleccione municipio"
								/>
							</FormGroup>
						</Col>
						<Col sm="4">
							<FormGroup>
								<Label className="text-left">Tipo Plaza </Label>
								<Select
									className="react-select primary"
									classNamePrefix="react-select"
									name="TipoPlaza"
									value={this.state.TipoPlaza}
									onChange={value => this.setState({ TipoPlaza: value })}
									options={[
										{
											value: 1,
											label: 'No Definido',
											isDisabled: true,
										},
										{ value: 1, label: 'No Definido' },
										{ value: 2, label: 'Definitiva' },
										{ value: 3, label: 'Eventual' },
										{ value: 4, label: 'Honorarios' },
									]}
									placeholder="Seleccione TipoPlaza"
								/>
							</FormGroup>
						</Col>
					</Row>
				</div>
				<div className="modal-footer justify-content-center">
					<Button className="btn-round" color="info" data-dismiss="modal" type="button" onClick={this.toggle}>
						Agregar
					</Button>
				</div>
			</Modal>
		);
	}
}

export default ModalNuevaPlaza;
