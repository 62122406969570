import React from 'react';

const notify = (color, place, message, refs) => {
   
        var type;
        switch (color) {
        case 1:
            type = "primary";
            break;
        case 2:
            type = "success";
            break;
        case 3:
            type = "danger";
            break;
        case 4:
            type = "warning";
            break;
        case 5:
            type = "info";
            break;
        default:
            break;
        }
        var options = {};
        options = {
        place: place,
        message: (
            <div>
            <div>
                {message}
            </div>
            </div>
        ),
        type: type,
        icon: "now-ui-icons ui-1_bell-53",
        autoDismiss: 4
        };
        refs.notificationAlert.notificationAlert(options);
};

export default notify;