import React, { Component } from "react";

import NotificationAlert from "react-notification-alert";
import InfiniteScroll from "react-infinite-scroll-component";
import { CircleSpinner } from "react-spinners-kit";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col
} from "reactstrap";

import EjecutarApi from "../../data/Consultas";
import { getVars } from "../../GlobalStore";

import DashboardNavbar from "../../components/Navbars/DashboardNavbar.jsx";
import Footer from "../../components/Footer/Footer.jsx";
import Sidebar from "../../components/Sidebar/MenuSidebar.jsx";

var global = "";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "black",
      activeColor: "info",
      sidebarMini: false,
      totalCandidatos: 0,
      loadingCandidatos: true,
      loadingPorAutorizar: true,
      loadingPorValidar: true,
      loadingValidado: true,
      loadingAutorizado: true,
      loadingRechazado: true,
      loadingConOficio: true,
      loadingAprobadoDependencia: true,
      loadingRechazadoDependencia: true,
      loadingRechazadoUsuario: true,
      PorAutorizarPage: 0,
      PorValidarPage: 0,
      ValidadoPage: 0,
      AutorizadoPage: 0,
      RechazadoPage: 0,
      ConOficioPage: 0,
      AprobadoDependenciaPage: 0,
      RechazadoDependenciaPage: 0,
      RechazadoUsuarioPage: 0,
      PorAutorizarTotal: 0,
      PorValidarTotal: 0,
      ValidadoTotal: 0,
      AutorizadoTotal: 0,
      RechazadoTotal: 0,
      ConOficioTotal: 0,
      AprobadoDependenciaTotal: 0,
      RechazadoDependenciaTotal: 0,
      RechazadoUsuarioTotal: 0,
      PorAutorizar: [],
      PorValidar: [],
      Validado: [],
      Autorizado: [],
      Rechazado: [],
      ConOficio: [],
      RechazadoDependencia: [],
      AprobadoDependencia: [],
      RechazadoUsuario: [],
      hasMorePorAutorizar: true,
      hasMorePorValidar: true,
      hasMoreValidado: true,
      hasMoreAutorizado: true,
      hasMoreRechazado: true,
      hasMoreConOficio: true,
      hasMoreAprobadoDependencia: true,
      hasMoreRechazadoDependencia: true,
      hasMoreRechazadoUsuario: true,
      userData: props.location.state
    };
  }

  componentDidMount() {
    this.setState({ loadingPorAutorizar: true });

    let data = {
      page: 0,
      tipo: "and",
      pageSize: 10,
      sorted: [],
      filtered: [
        { id: "idMovimientoStatus", value: "1" },
        { id: "isActivo", value: "1" }
      ]
    };

    const token = global.access_token; //localstorage

    console.log("= getPlazaMovimientos: ", data);

    EjecutarApi(data, "getPlazaMovimientos", token, "POST")
      .then(function(res) {
        return res;
      })
      .then(items => {
        console.log("===> Data Por Autorizar: ", items);

        if (items.results) {
          this.setState({
            PorAutorizar: items.data,
            PorAutorizarTotal: items.total,
            loadingPorAutorizar: false
          });
        }
      })
      .catch(e => {
        console.log("error Por Autorizar getPlazaMovimientos: ", e.message);
        this.setState({
          loadingPorAutorizar: false
        });
      });

    data = {
      page: 0,
      tipo: "and",
      pageSize: 10,
      sorted: [],
      filtered: [
        { id: "idMovimientoStatus", value: "2" },
        { id: "isActivo", value: "1" }
      ]
    };

    EjecutarApi(data, "getPlazaMovimientos", token, "POST")
      .then(function(res) {
        return res;
      })
      .then(items => {
        console.log("===> Data Por Validar: ", items);

        if (items.results) {
          this.setState({
            PorValidar: items.data,
            PorValidarTotal: items.total,
            loadingPorValidar: false
          });
        }
      })
      .catch(e => {
        console.log("error getPlazaMovimientos: ", e.message);
        this.setState({
          loadingPorValidar: false
        });
      });

    data = {
      page: 0,
      tipo: "and",
      pageSize: 10,
      sorted: [],
      filtered: [
        { id: "idMovimientoStatus", value: "3" },
        { id: "isActivo", value: "1" }
      ]
    };

    EjecutarApi(data, "getPlazaMovimientos", token, "POST")
      .then(function(res) {
        return res;
      })
      .then(items => {
        console.log("===> Data Por VALIDADO: ", items);

        if (items.results) {
          this.setState({
            Validado: items.data,
            ValidadoTotal: items.total,
            loadingValidado: false
          });
        }
      })
      .catch(e => {
        console.log("error getPlazaMovimientos: ", e.message);
        this.setState({
          loadingValidado: false
        });
      });

    data = {
      page: 0,
      tipo: "and",
      pageSize: 10,
      sorted: [],
      filtered: [
        { id: "idMovimientoStatus", value: "4" },
        { id: "isActivo", value: "1" }
      ]
    };

    EjecutarApi(data, "getPlazaMovimientos", token, "POST")
      .then(function(res) {
        return res;
      })
      .then(items => {
        console.log("===> Data Autorizado: ", items);

        if (items.results) {
          this.setState({
            Autorizado: items.data,
            AutorizadoTotal: items.total,
            loadingAutorizado: false
          });
        }
      })
      .catch(e => {
        console.log("error getPlazaMovimientos: ", e.message);
        this.setState({
          loadingAutorizado: false
        });
      });

    data = {
      page: 0,
      tipo: "and",
      pageSize: 10,
      sorted: [],
      filtered: [
        { id: "idMovimientoStatus", value: "5" },
        { id: "isActivo", value: "1" }
      ]
    };

    EjecutarApi(data, "getPlazaMovimientos", token, "POST")
      .then(function(res) {
        return res;
      })
      .then(items => {
        console.log("===> Data Rechazado: ", items);

        if (items.results) {
          this.setState({
            Rechazado: items.data,
            RechazadoTotal: items.total,
            loadingRechazado: false
          });
        }
      })
      .catch(e => {
        console.log("error getPlazaMovimientos: ", e.message);
        this.setState({
          loadingRechazado: false
        });
      });

    data = {
      page: 0,
      tipo: "and",
      pageSize: 10,
      sorted: [],
      filtered: [
        { id: "idMovimientoStatus", value: "6" },
        { id: "isActivo", value: "1" }
      ]
    };

    EjecutarApi(data, "getPlazaMovimientos", token, "POST")
      .then(function(res) {
        return res;
      })
      .then(items => {
        console.log("===> Data ConOficio: ", items);

        if (items.results) {
          this.setState({
            ConOficio: items.data,
            ConOficioTotal: items.total,
            loadingConOficio: false
          });
        }
      })
      .catch(e => {
        console.log("error getPlazaMovimientos: ", e.message);
        this.setState({
          loadingConOficio: false
        });
      });

    data = {
      page: 0,
      tipo: "and",
      pageSize: 10,
      sorted: [],
      filtered: [
        { id: "idMovimientoStatus", value: "7" },
        { id: "isActivo", value: "1" }
      ]
    };

    EjecutarApi(data, "getPlazaMovimientos", token, "POST")
      .then(function(res) {
        return res;
      })
      .then(items => {
        console.log("===> Data RechazadoDependencia: ", items);

        if (items.results) {
          this.setState({
            RechazadoDependencia: items.data,
            RechazadoDependenciaTotal: items.total,
            loadingRechazadoDependencia: false
          });
        }
      })
      .catch(e => {
        console.log("error getPlazaMovimientos: ", e.message);
        this.setState({
          loadingRechazadoDependencia: false
        });
      });

    data = {
      page: 0,
      tipo: "and",
      pageSize: 10,
      sorted: [],
      filtered: [
        { id: "idMovimientoStatus", value: "8" },
        { id: "isActivo", value: "1" }
      ]
    };

    EjecutarApi(data, "getPlazaMovimientos", token, "POST")
      .then(function(res) {
        return res;
      })
      .then(items => {
        console.log("===> Data AprobadoDependencia: ", items);

        if (items.results) {
          this.setState({
            AprobadoDependencia: items.data,
            AprobadoDependenciaTotal: items.total,
            loadingAprobadoDependencia: false
          });
        }
      })
      .catch(e => {
        console.log("error getPlazaMovimientos: ", e.message);
        this.setState({
          loadingAprobadoDependencia: false
        });
      });

    data = {
      page: 0,
      tipo: "and",
      pageSize: 10,
      sorted: [],
      filtered: [
        { id: "idMovimientoStatus", value: "9" },
        { id: "isActivo", value: "1" }
      ]
    };

    EjecutarApi(data, "getPlazaMovimientos", token, "POST")
      .then(function(res) {
        return res;
      })
      .then(items => {
        console.log("===> Data RechazadoUsuario: ", items);

        if (items.results) {
          this.setState({
            RechazadoUsuario: items.data,
            RechazadoUsuarioTotal: items.total,
            loadingRechazadoUsuario: false
          });
        }
      })
      .catch(e => {
        console.log("error getPlazaMovimientos: ", e.message);
        this.setState({
          loadingRechazadoUsuario: false
        });
      });
    data = {
      page: 0,
      tipo: "and",
      pageSize: 1,
      sorted: [],
      filtered: [{ id: "isJefatura", value: "1" }]
    };

    EjecutarApi(data, "getCandidatosJefatura", token, "POST")
      .then(function(res) {
        return res;
      })
      .then(items => {
        console.log("===> Data Total Candidatos: ", items);

        if (items.results) {
          this.setState({
            totalCandidatos: items.total,
            loadingCandidatos: false
          });
        }
      })
      .catch(e => {
        console.log("error Total Candidatos: ", e.message);
        this.setState({
          loadingCandidatos: false
        });
      });
  }

  fetchMorePorAutorizar = () => {
    if (this.state.PorAutorizarPage * 10 >= this.state.PorAutorizar.total) {
      console.log(
        "=>PorAutorizar Llego a su final: ",
        this.state.PorAutorizarPage * 10
      );

      this.setState({ hasMorePorAutorizar: false });
      return;
    }

    this.setState({
      loadingPorAutorizar: true
    });

    let data = {
      page: this.state.PorAutorizarPage + 1,
      tipo: "and",
      pageSize: 10,
      sorted: [{ id: "LastUpdate", desc: true }],
      filtered: [
        { id: "idMovimientoStatus", value: "1" },
        { id: "isActivo", value: "1" }
      ]
    };

    const token = global.access_token; //localstorage

    console.log("= getPlazaMovimientos: ", data);

    EjecutarApi(data, "getPlazaMovimientos", token, "POST")
      .then(function(res) {
        return res;
      })
      .then(items => {
        console.log("===> Data Por Autorizar: ", items);

        if (items.results) {
          this.setState({
            PorAutorizar: this.state.PorAutorizar.concat(items.data),
            PorAutorizarTotal: items.total,
            PorAutorizarPage: this.state.PorAutorizarPage + 1,
            loadingPorAutorizar: false
          });
        }
      })
      .catch(e => {
        console.log("error getPlazaMovimientos: ", e.message);
        this.setState({
          loadingPorAutorizar: false
        });
      });
  };

  fetchMorePorValidar = () => {
    if (this.state.PorValidarPage * 10 >= this.state.PorValidar.total) {
      console.log(
        "=>PorValidar Llego a su final: ",
        this.state.PorValidarPage * 10
      );

      this.setState({ hasMorePorValidar: false });
      return;
    }

    this.setState({
      loadingPorValidar: true
    });

    let data = {
      page: this.state.PorValidarPage + 1,
      tipo: "and",
      pageSize: 10,
      sorted: [{ id: "LastUpdate", desc: true }],
      filtered: [
        { id: "idMovimientoStatus", value: "2" },
        { id: "isActivo", value: "1" }
      ]
    };

    const token = global.access_token; //localstorage

    console.log("= getPlazaMovimientos: ", data);

    EjecutarApi(data, "getPlazaMovimientos", token, "POST")
      .then(function(res) {
        return res;
      })
      .then(items => {
        console.log("===> Data Por Validar: ", items);

        if (items.results) {
          this.setState({
            PorValidar: this.state.PorValidar.concat(items.data),
            PorValidarTotal: items.total,
            PorValidarPage: this.state.PorValidarPage + 1,
            loadingPorValidar: false
          });
        }
      })
      .catch(e => {
        console.log("error getPlazaMovimientos: ", e.message);
        this.setState({
          loadingPorValidar: false
        });
      });
  };

  fetchMoreValidado = () => {
    if (this.state.ValidadoPage * 10 >= this.state.Validado.total) {
      console.log(
        "=>Validado Llego a su final: ",
        this.state.ValidadoPage * 10
      );

      this.setState({ hasMoreValidado: false });
      return;
    }

    this.setState({
      loadingValidado: true
    });

    let data = {
      page: this.state.ValidadoPage + 1,
      tipo: "and",
      pageSize: 10,
      sorted: [{ id: "LastUpdate", desc: true }],
      filtered: [
        { id: "idMovimientoStatus", value: "3" },
        { id: "isActivo", value: "1" }
      ]
    };

    const token = global.access_token; //localstorage

    console.log("= getPlazaMovimientos: ", data);

    EjecutarApi(data, "getPlazaMovimientos", token, "POST")
      .then(function(res) {
        return res;
      })
      .then(items => {
        console.log("===> Data Por Validar: ", items);

        if (items.results) {
          this.setState({
            Validado: this.state.Validado.concat(items.data),
            ValidadoTotal: items.total,
            ValidadoPage: this.state.ValidadoPage + 1,
            loadingValidado: false
          });
        }
      })
      .catch(e => {
        console.log("error getPlazaMovimientos: ", e.message);
        this.setState({
          loadingValidado: false
        });
      });
  };

  fetchMoreAutorizado = () => {
    if (this.state.AutorizadoPage * 10 >= this.state.Autorizado.total) {
      console.log(
        "=>Autorizado Llego a su final: ",
        this.state.AutorizadoPage * 10
      );

      this.setState({ hasMoreAutorizado: false });
      return;
    }

    this.setState({
      loadingAutorizado: true
    });

    let data = {
      page: this.state.AutorizadoPage + 1,
      tipo: "and",
      pageSize: 10,
      sorted: [{ id: "LastUpdate", desc: true }],
      filtered: [
        { id: "idMovimientoStatus", value: "4" },
        { id: "isActivo", value: "1" }
      ]
    };

    const token = global.access_token; //localstorage

    console.log("= getPlazaMovimientos: ", data);

    EjecutarApi(data, "getPlazaMovimientos", token, "POST")
      .then(function(res) {
        return res;
      })
      .then(items => {
        console.log("===> Data Por Validar: ", items);

        if (items.results) {
          this.setState({
            Autorizado: this.state.Autorizado.concat(items.data),
            AutorizadoTotal: items.total,
            AutorizadoPage: this.state.AutorizadoPage + 1,
            loadingAutorizado: false
          });
        }
      })
      .catch(e => {
        console.log("error getPlazaMovimientos: ", e.message);
        this.setState({
          loadingAutorizado: false
        });
      });
  };

  fetchMoreRechazado = () => {
    if (this.state.RechazadoPage * 10 >= this.state.Rechazado.total) {
      console.log(
        "=>Rechazado Llego a su final: ",
        this.state.RechazadoPage * 10
      );

      this.setState({ hasMoreRechazado: false });
      return;
    }

    this.setState({
      loadingRechazado: true
    });

    let data = {
      page: this.state.RechazadoPage + 1,
      tipo: "and",
      pageSize: 10,
      sorted: [{ id: "LastUpdate", desc: true }],
      filtered: [
        { id: "idMovimientoStatus", value: "5" },
        { id: "isActivo", value: "1" }
      ]
    };

    const token = global.access_token; //localstorage

    console.log("= getPlazaMovimientos: ", data);

    EjecutarApi(data, "getPlazaMovimientos", token, "POST")
      .then(function(res) {
        return res;
      })
      .then(items => {
        console.log("===> Data Por Validar: ", items);

        if (items.results) {
          this.setState({
            Rechazado: this.state.Rechazado.concat(items.data),
            RechazadoTotal: items.total,
            RechazadoPage: this.state.RechazadoPage + 1,
            loadingRechazado: false
          });
        }
      })
      .catch(e => {
        console.log("error getPlazaMovimientos: ", e.message);
        this.setState({
          loadingRechazado: false
        });
      });
  };

  handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      this.setState({ sidebarMini: false });
    } else {
      this.setState({ sidebarMini: true });
    }
    document.body.classList.toggle("sidebar-mini");
  };

  render() {
    console.log("=>=> Mis stats: ", this.state);

    global = getVars("Token");

    const {
      totalCandidatos,
      loadingCandidatos,
      PorAutorizar,
      hasMorePorAutorizar,
      PorAutorizarTotal,
      PorValidar,
      hasMorePorValidar,
      PorValidarTotal,
      Validado,
      hasMoreValidado,
      ValidadoTotal,
      Autorizado,
      hasMoreAutorizado,
      AutorizadoTotal,
      loadingAprobadoDependencia,
      loadingAutorizado,
      loadingPorAutorizar,
      loadingConOficio,
      loadingPorValidar,
      loadingRechazado,
      loadingRechazadoDependencia,
      loadingRechazadoUsuario,
      loadingValidado
    } = this.state;

    return (
      <div className="wrapper">
        <Sidebar
          {...this.props}
          bgColor={this.state.backgroundColor}
          activeColor={this.state.activeColor}
        />
        <div className="main-panel" ref="mainPanel">
          <DashboardNavbar
            {...this.props}
            handleMiniClick={this.handleMiniClick}
          />
          <NotificationAlert ref="notificationAlert" />
          <div className="content">
            <Row>
              <Col lg="2" md="6" sm="6">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <Col md="4" xs="5">
                        <div className="icon-big text-center icon-warning">
                          <i className="nc-icon nc-globe text-warning" />
                        </div>
                      </Col>
                      <Col md="8" xs="7">
                        <div className="numbers">
                          <p className="card-category">Padrón Total</p>
                          {loadingCandidatos && (
                            <CircleSpinner
                              className="text-center"
                              size={30}
                              color="#686769"
                              loading={loadingCandidatos}
                            />
                          )}
                          <CardTitle tag="p">{totalCandidatos}</CardTitle>
                          <p />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <hr />
                    <div className="stats">
                      <i className="fa fa-calendar-o" />
                      {loadingCandidatos ? "Actualizando..." : "Actualizado"}
                    </div>
                  </CardFooter>
                </Card>
              </Col>
              <Col lg="2" md="6" sm="6">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <Col md="4" xs="5">
                        <div className="icon-big text-center icon-warning">
                          <i className="nc-icon nc-money-coins text-success" />
                        </div>
                      </Col>
                      <Col md="8" xs="7">
                        <div className="numbers">
                          <p className="card-category">Por Autorizar</p>
                          <CardTitle tag="p">
                            {loadingPorAutorizar && (
                              <CircleSpinner
                                className="text-center"
                                size={30}
                                color="#686769"
                                loading={loadingPorAutorizar}
                              />
                            )}
                            {PorAutorizarTotal}
                          </CardTitle>
                          <p />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <hr />
                    <div className="stats">
                      <i className="fa fa-calendar-o" />
                      {loadingPorAutorizar ? "Actualizando..." : "Actualizado"}
                    </div>
                  </CardFooter>
                </Card>
              </Col>
              <Col lg="2" md="6" sm="6">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <Col md="4" xs="5">
                        <div className="icon-big text-center icon-warning">
                          <i className="nc-icon nc-vector text-danger" />
                        </div>
                      </Col>
                      <Col md="8" xs="7">
                        <div className="numbers">
                          <p className="card-category">Por Validar</p>
                          <CardTitle tag="p">
                            {loadingPorValidar && (
                              <CircleSpinner
                                className="text-center"
                                size={30}
                                color="#686769"
                                loading={loadingPorValidar}
                              />
                            )}
                            {PorValidarTotal}
                          </CardTitle>
                          <p />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <hr />
                    <div className="stats">
                      <i className="fa fa-clock-o" />
                      {loadingPorValidar ? "Actualizando..." : "Actualizado"}
                    </div>
                  </CardFooter>
                </Card>
              </Col>
              <Col lg="2" md="6" sm="6">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <Col md="4" xs="5">
                        <div className="icon-big text-center icon-warning">
                          <i className="nc-icon nc-favourite-28 text-primary" />
                        </div>
                      </Col>
                      <Col md="8" xs="7">
                        <div className="numbers">
                          <p className="card-category">Validado</p>
                          <CardTitle tag="p">
                            {loadingValidado && (
                              <CircleSpinner
                                className="text-center"
                                size={30}
                                color="#686769"
                                loading={loadingValidado}
                              />
                            )}
                            {ValidadoTotal}
                          </CardTitle>
                          <p />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <hr />
                    <div className="stats">
                      <i className="fa fa-refresh" />
                      {loadingValidado ? "Actualizando..." : "Actualizado"}
                    </div>
                  </CardFooter>
                </Card>
              </Col>
              <Col lg="2" md="6" sm="6">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <Col md="4" xs="5">
                        <div className="icon-big text-center icon-warning">
                          <i className="nc-icon nc-favourite-28 text-primary" />
                        </div>
                      </Col>
                      <Col md="8" xs="7">
                        <div className="numbers">
                          <p className="card-category">En Proceso</p>
                          <CardTitle tag="p">
                            {loadingAutorizado && (
                              <CircleSpinner
                                className="text-center"
                                size={30}
                                color="#686769"
                                loading={loadingAutorizado}
                              />
                            )}
                            {AutorizadoTotal}
                          </CardTitle>
                          <p />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <hr />
                    <div className="stats">
                      <i className="fa fa-refresh" />
                      {loadingAutorizado ? "Actualizando..." : "Actualizado"}
                    </div>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <Card className="card-tasks">
                  <CardHeader>
                    <CardTitle tag="h4">Por Autorizar</CardTitle>
                    <h6 className="card-category">{`Mostrando ${PorAutorizar.length} de ${PorAutorizarTotal} registros`}</h6>
                  </CardHeader>
                  <CardBody>
                    <InfiniteScroll
                      dataLength={PorAutorizar.length}
                      next={this.fetchMorePorAutorizar}
                      hasMore={hasMorePorAutorizar}
                      loader={<h4>cargando...</h4>}
                      height={400}
                      endMessage={
                        <p style={{ textAlign: "center" }}>
                          <b>Listo! Esos son todos!</b>
                        </p>
                      }
                    >
                      {PorAutorizar.map((item, index) =>
                        item.movimiento.value === 1 ? (
                          <div key={index}>
                            <span style={{ color: "#4ACCCD" }}>
                              <b>{item.movimiento.label}</b>
                            </span>
                            <h6>
                              <span
                                style={{
                                  backgroundColor:
                                    item.candidato.isJefatura === 1
                                      ? "#FFFFFF"
                                      : "#FCEBAA"
                                }}
                              >
                                {item.candidato.isJefatura === 1 && (
                                  <i className="fa fa-star" />
                                )}
                                {`${item.candidato.Nombre} ${item.candidato.Paterno} ${item.candidato.Materno}`}
                              </span>
                            </h6>
                            {item.candidato.isJefatura === 1 && (
                              <span>
                                <i className="fa fa-user" />{" "}
                                {item.QuienProponeConsideracion}
                                <br></br>
                              </span>
                            )}
                            Nivel Propuesto:{" "}
                            <b>{item.candidato.NivelPropuesto}</b>
                            <h6>FechaHora: {item.FechaHora}</h6>
                            <hr />
                          </div>
                        ) : item.movimiento.value === 2 ? (
                          <div key={index}>
                            <span style={{ color: "#329AF0" }}>
                              <b>{item.movimiento.label}</b>
                            </span>
                            <h6>
                              <span
                                style={{
                                  backgroundColor:
                                    item.candidato.isJefatura === 1
                                      ? "#FFFFFF"
                                      : "#FCEBAA"
                                }}
                              >
                                {item.candidato.isJefatura === 1 && (
                                  <i className="fa fa-star" />
                                )}
                                {`${item.candidato.Nombre} ${item.candidato.Paterno} ${item.candidato.Materno}`}
                              </span>
                            </h6>
                            {`${item.Propone.Nombre} ${item.Propone.Paterno} ${item.Propone.Materno} - ${item.Propone.Dependencia.label}`}
                            <br></br>
                            {item.plaza.PuestoFuncional} <br />
                            {item.plaza.Dependencia} <br />
                            Nivel: <b>{item.plaza.Nivel} </b>
                            <br />
                            {item.plaza.Municipio} <br />
                            {item.candidato.isJefatura === 1 && (
                              <span>
                                <i className="fa fa-user" />{" "}
                                {item.QuienProponeConsideracion}
                                <br></br>
                              </span>
                            )}
                            <h6>FechaHora: {item.FechaHora}</h6>
                            <hr />
                          </div>
                        ) : (
                          <div key={index}>
                            <span style={{ color: "#093AF6" }}>
                              <b>{item.movimiento.label}</b>
                            </span>
                            <h6>
                              <span
                                style={{
                                  backgroundColor:
                                    item.candidato.isJefatura === 1
                                      ? "#FFFFFF"
                                      : "#FCEBAA"
                                }}
                              >
                                {item.candidato.isJefatura === 1 && (
                                  <i className="fa fa-star" />
                                )}
                                {`${item.candidato.Nombre} ${item.candidato.Paterno} ${item.candidato.Materno}`}
                              </span>
                            </h6>
                            <br></br>
                            {item.plaza.PuestoFuncional} <br></br>
                            {item.plaza.Dependencia}
                            <br />
                            {item.candidato.isJefatura === 1 && (
                              <span>
                                <i className="fa fa-user" />{" "}
                                {item.QuienProponeConsideracion}
                                <br></br>
                              </span>
                            )}
                            <h6>FechaHora: {item.FechaHora}</h6>
                            <hr />
                          </div>
                        )
                      )}
                    </InfiniteScroll>
                
                  </CardBody>
                  <CardFooter>
                    <hr />
                    <div className="stats">
                      <i className="fa fa-refresh spin" />
                      Actualizado
                    </div>
                  </CardFooter>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-tasks">
                  <CardHeader>
                    <CardTitle tag="h4">Por Validar</CardTitle>
                    <h6 className="card-category">{`Mostrando ${PorValidar.length} de ${PorValidarTotal} registros`}</h6>
                  </CardHeader>
                  <CardBody>
                    <InfiniteScroll
                      dataLength={PorValidar.length}
                      next={this.fetchMorePorValidar}
                      hasMore={hasMorePorValidar}
                      loader={<h4>cargando...</h4>}
                      height={400}
                      endMessage={
                        <p style={{ textAlign: "center" }}>
                          <b>Listo! Esos son todos!</b>
                        </p>
                      }
                    >
                      {PorValidar.map((item, index) =>
                        item.movimiento.value === 1 ? (
                          <div key={index}>
                            <span style={{ color: "#4ACCCD" }}>
                              <b>{item.movimiento.label}</b>
                            </span>
                            <h6>
                              <span
                                style={{
                                  backgroundColor:
                                    item.candidato.isJefatura === 1
                                      ? "#FFFFFF"
                                      : "#FCEBAA"
                                }}
                              >
                                {item.candidato.isJefatura === 1 && (
                                  <i className="fa fa-star" />
                                )}
                                {`${item.candidato.Nombre} ${item.candidato.Paterno} ${item.candidato.Materno}`}
                              </span>
                            </h6>
                            {item.candidato.isJefatura === 1 && (
                              <span>
                                <i className="fa fa-user" />{" "}
                                {item.QuienProponeConsideracion}
                                <br></br>
                              </span>
                            )}
                            Nivel Propuesto:{" "}
                            <b>{item.candidato.NivelPropuesto}</b>
                            <h6>FechaHora: {item.FechaHora}</h6>
                            <hr />
                          </div>
                        ) : item.movimiento.value === 2 ? (
                          <div key={index}>
                            <span style={{ color: "#329AF0" }}>
                              <b>{item.movimiento.label}</b>
                            </span>
                            <h6>
                              <span
                                style={{
                                  backgroundColor:
                                    item.candidato.isJefatura === 1
                                      ? "#FFFFFF"
                                      : "#FCEBAA"
                                }}
                              >
                                {item.candidato.isJefatura === 1 && (
                                  <i className="fa fa-star" />
                                )}
                                {`${item.candidato.Nombre} ${item.candidato.Paterno} ${item.candidato.Materno}`}
                              </span>
                            </h6>
                            {`${item.Propone.Nombre} ${item.Propone.Paterno} ${item.Propone.Materno} - ${item.Propone.Dependencia.label}`}
                            <br></br>
                            {item.plaza.PuestoFuncional} <br />
                            {item.plaza.Dependencia} <br />
                            Nivel: <b>{item.plaza.Nivel} </b>
                            <br />
                            {item.plaza.Municipio} <br />
                            {item.candidato.isJefatura === 1 && (
                              <span>
                                <i className="fa fa-user" />{" "}
                                {item.QuienProponeConsideracion}
                              </span>
                            )}
                            <h6>FechaHora: {item.FechaHora}</h6>
                            <hr />
                          </div>
                        ) : (
                          <div key={index}>
                            <span style={{ color: "#093AF6" }}>
                              <b>{item.movimiento.label}</b>
                            </span>
                            <h6>
                              <span
                                style={{
                                  backgroundColor:
                                    item.candidato.isJefatura === 1
                                      ? "#FFFFFF"
                                      : "#FCEBAA"
                                }}
                              >
                                {item.candidato.isJefatura === 1 && (
                                  <i className="fa fa-star" />
                                )}
                                {`${item.candidato.Nombre} ${item.candidato.Paterno} ${item.candidato.Materno}`}
                              </span>
                            </h6>
                            <br></br>
                            {item.plaza.PuestoFuncional} <br></br>
                            {item.plaza.Dependencia}
                            <br />
                            {item.candidato.isJefatura === 1 && (
                              <span>
                                <i className="fa fa-user" />{" "}
                                {item.QuienProponeConsideracion}
                                <br></br>
                              </span>
                            )}
                            <h6>FechaHora: {item.FechaHora}</h6>
                            <hr />
                          </div>
                        )
                      )}
                    </InfiniteScroll>
                  </CardBody>
                  <CardFooter>
                    <hr />
                    <div className="stats">
                      <i className="fa fa-refresh spin" />
                      Actualizado
                    </div>
                  </CardFooter>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-tasks">
                  <CardHeader>
                    <CardTitle tag="h4">Validado</CardTitle>
                    <h6 className="card-category">{`Mostrando ${Validado.length} de ${ValidadoTotal} registros`}</h6>
                  </CardHeader>
                  <CardBody>
                    <InfiniteScroll
                      dataLength={Validado.length}
                      next={this.fetchMoreValidado}
                      hasMore={hasMoreValidado}
                      loader={<h4>cargando...</h4>}
                      height={400}
                      endMessage={
                        <p style={{ textAlign: "center" }}>
                          <b>Listo! Esos son todos!</b>
                        </p>
                      }
                    >
                      {Validado.map((item, index) =>
                        item.movimiento.value === 1 ? (
                          <div key={index}>
                            <span style={{ color: "#4ACCCD" }}>
                              <b>{item.movimiento.label}</b>
                            </span>
                            <h6>
                              <span
                                style={{
                                  backgroundColor:
                                    item.candidato.isJefatura === 1
                                      ? "#FFFFFF"
                                      : "#FCEBAA"
                                }}
                              >
                                {item.candidato.isJefatura === 1 && (
                                  <i className="fa fa-star" />
                                )}
                                {`${item.candidato.Nombre} ${item.candidato.Paterno} ${item.candidato.Materno}`}
                              </span>
                            </h6>
                            {item.candidato.isJefatura === 1 && (
                              <span>
                                <i className="fa fa-user" />{" "}
                                {item.QuienProponeConsideracion}
                                <br></br>
                              </span>
                            )}
                            Nivel Propuesto:{" "}
                            <b>{item.candidato.NivelPropuesto}</b>
                            <h6>FechaHora: {item.FechaHora}</h6>
                            <hr />
                          </div>
                        ) : item.movimiento.value === 2 ? (
                          <div key={index}>
                            <span style={{ color: "#329AF0" }}>
                              <b>{item.movimiento.label}</b>
                            </span>
                            <h6>
                              <span
                                style={{
                                  backgroundColor:
                                    item.candidato.isJefatura === 1
                                      ? "#FFFFFF"
                                      : "#FCEBAA"
                                }}
                              >
                                {item.candidato.isJefatura === 1 && (
                                  <i className="fa fa-star" />
                                )}
                                {`${item.candidato.Nombre} ${item.candidato.Paterno} ${item.candidato.Materno}`}
                              </span>
                            </h6>
                            {`${item.Propone.Nombre} ${item.Propone.Paterno} ${item.Propone.Materno} - ${item.Propone.Dependencia.label}`}
                            <br></br>
                            {item.plaza.PuestoFuncional} <br />
                            {item.plaza.Dependencia} <br />
                            Nivel: <b>{item.plaza.Nivel} </b>
                            <br />
                            {item.plaza.Municipio} <br />
                            {item.candidato.isJefatura === 1 && (
                              <span>
                                <i className="fa fa-user" />{" "}
                                {item.QuienProponeConsideracion}
                                <br></br>
                              </span>
                            )}
                            <h6>FechaHora: {item.FechaHora}</h6>
                            <hr />
                          </div>
                        ) : (
                          <div key={index}>
                            <span style={{ color: "#093AF6" }}>
                              <b>{item.movimiento.label}</b>
                            </span>
                            <h6>
                              <span
                                style={{
                                  backgroundColor:
                                    item.candidato.isJefatura === 1
                                      ? "#FFFFFF"
                                      : "#FCEBAA"
                                }}
                              >
                                {item.candidato.isJefatura === 1 && (
                                  <i className="fa fa-star" />
                                )}
                                {`${item.candidato.Nombre} ${item.candidato.Paterno} ${item.candidato.Materno}`}
                              </span>
                            </h6>
                            <br></br>
                            {item.plaza.PuestoFuncional} <br></br>
                            {item.plaza.Dependencia}
                            <br />
                            {item.candidato.isJefatura === 1 && (
                              <span>
                                <i className="fa fa-user" />{" "}
                                {item.QuienProponeConsideracion}
                                <br></br>
                              </span>
                            )}
                            <h6>FechaHora: {item.FechaHora}</h6>
                            <hr />
                          </div>
                        )
                      )}
                    </InfiniteScroll>
                  </CardBody>
                  <CardFooter>
                    <hr />
                    <div className="stats">
                      <i className="fa fa-refresh spin" />
                      Actualizado
                    </div>
                  </CardFooter>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-tasks">
                  <CardHeader>
                    <CardTitle tag="h4">En Proceso de Autorización</CardTitle>
                    <h6 className="card-category">{`Mostrando ${Autorizado.length} de ${AutorizadoTotal} registros`}</h6>
                  </CardHeader>
                  <CardBody>
                    <InfiniteScroll
                      dataLength={Autorizado.length}
                      next={this.fetchMoreAutorizado}
                      hasMore={hasMoreAutorizado}
                      loader={<h4>cargando...</h4>}
                      height={400}
                      endMessage={
                        <p style={{ textAlign: "center" }}>
                          <b>Listo! Esos son todos!</b>
                        </p>
                      }
                    >
                      {Autorizado.map((item, index) =>
                        item.movimiento.value === 1 ? (
                          <div key={index}>
                            <span style={{ color: "#4ACCCD" }}>
                              <b>{item.movimiento.label}</b>
                            </span>
                            <h6>
                              <span
                                style={{
                                  backgroundColor:
                                    item.candidato.isJefatura === 1
                                      ? "#FFFFFF"
                                      : "#FCEBAA"
                                }}
                              >
                                {item.candidato.isJefatura === 1 && (
                                  <i className="fa fa-star" />
                                )}
                                {`${item.candidato.Nombre} ${item.candidato.Paterno} ${item.candidato.Materno}`}
                              </span>
                            </h6>
                            {item.candidato.isJefatura === 1 && (
                              <span>
                                <i className="fa fa-user" />{" "}
                                {item.QuienProponeConsideracion}
                                <br></br>
                              </span>
                            )}
                            <br></br>
                            Nivel Propuesto:{" "}
                            <b>{item.candidato.NivelPropuesto}</b>
                            <h6>FechaHora: {item.FechaHora}</h6>
                            <hr />
                          </div>
                        ) : item.movimiento.value === 2 ? (
                          <div key={index}>
                            <span style={{ color: "#329AF0" }}>
                              <b>{item.movimiento.label}</b>
                            </span>
                            <h6>
                              <span
                                style={{
                                  backgroundColor:
                                    item.candidato.isJefatura === 1
                                      ? "#FFFFFF"
                                      : "#FCEBAA"
                                }}
                              >
                                {item.candidato.isJefatura === 1 && (
                                  <i className="fa fa-star" />
                                )}
                                {`${item.candidato.Nombre} ${item.candidato.Paterno} ${item.candidato.Materno}`}
                              </span>
                            </h6>
                            {`${item.Propone.Nombre} ${item.Propone.Paterno} ${item.Propone.Materno} - ${item.Propone.Dependencia.label}`}
                            <br></br>
                            {item.plaza.PuestoFuncional} <br />
                            {item.plaza.Dependencia} <br />
                            Nivel: <b>{item.plaza.Nivel} </b>
                            <br />
                            {item.plaza.Municipio} <br />
                            {item.candidato.isJefatura === 1 && (
                              <span>
                                <i className="fa fa-user" />{" "}
                                {item.QuienProponeConsideracion}
                                <br></br>
                              </span>
                            )}
                            <h6>FechaHora: {item.FechaHora}</h6>
                            <hr />
                          </div>
                        ) : (
                          <div key={index}>
                            <span style={{ color: "#093AF6" }}>
                              <b>{item.movimiento.label}</b>
                            </span>
                            <h6>
                              <span
                                style={{
                                  backgroundColor:
                                    item.candidato.isJefatura === 1
                                      ? "#FFFFFF"
                                      : "#FCEBAA"
                                }}
                              >
                                {item.candidato.isJefatura === 1 && (
                                  <i className="fa fa-star" />
                                )}
                                {`${item.candidato.Nombre} ${item.candidato.Paterno} ${item.candidato.Materno}`}
                              </span>
                            </h6>
                            <br></br>
                            {item.plaza.PuestoFuncional} <br></br>
                            {item.plaza.Dependencia}
                            <br />
                            {item.candidato.isJefatura === 1 && (
                              <span>
                                <i className="fa fa-user" />{" "}
                                {item.QuienProponeConsideracion}
                                <br></br>
                              </span>
                            )}
                            <h6>FechaHora: {item.FechaHora}</h6>
                            <hr />
                          </div>
                        )
                      )}
                    </InfiniteScroll>
                  </CardBody>
                  <CardFooter>
                    <hr />
                    <div className="stats">
                      <i className="fa fa-refresh spin" />
                      Actualizado
                    </div>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </div>
          <Footer fluid />
        </div>
      </div>
    );
  }
}

export default Dashboard;
