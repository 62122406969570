import React, { Component } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Badge
} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import ReactTable from "react-table";
import matchSorter from "match-sorter";

import { getVars } from "../../../GlobalStore";

import EjecutarApi from "../../../data/Consultas";
import Notify from "../../../data/Notificacion";
import Pagination from "../Paginacion/index";

var global = "";

class MovimientoAutorizar extends Component {
  constructor(props) {
    super(props);
    console.log("=>data MovimientoAutorizar: ", props.data);

    this.state = {
      data: props.data || "",
      loading: false,
      loadingContra: false,
      activeTab: "Propuesta",
      contrapropuesta: false,
      firstTimeFilter: true,
      History: [],
      ContraPropuesta: [],
      totalHistory: 0,
      totalContra: 0
    };
  }

  componentDidMount() {
    this.getHistory();
    this.contraPropuesta();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.Nombre !== nextProps.Nombre) {
      this.setState({
        data: nextProps.data
      });
      console.log("=>Nuevos Props e History");
      this.getHistory();
      this.contraPropuesta();
    }
  }

  getHistory = () => {
    const token = global.access_token;

    const data = {
      page: 0,
      tipo: "and",
      pageSize: 8,
      sorted: [
        {
          id: "FechaHora",
          value: true
        }
      ],
      filtered: [
        {
          id: "idCandidato",
          value: 499//this.state.data.CandidatoRS.id || 0
        }
      ]
    };

    console.log("1 ==>Data History: ", JSON.stringify(data));

    EjecutarApi(data, "getPlazaMovimientos", token)
      .then(function(res) {
        return res;
      })
      .then(items => {
        console.log("===> Data History: ", items);

        if (items.results) {
          const dataTable = items.data;

          console.log("==>History RS: ", dataTable);

          this.setState({
            loading: false,
            totalHistory: items.total,
            History: dataTable.map((prop, key) => {
              return {
                id: prop.id,
                key: key,
                FechaMovimiento: prop.FechaMovimiento || "",
                PuestoQuienPropone: prop.Propone.Puesto || "",
                Movimiento: prop.movimiento.label,
                QuienPropone:
                  `${prop.Propone.Nombre} ${prop.Propone.Paterno}, ${prop.Propone.Puesto}` ||
                  "",
                Candidato: `${prop.candidato.Nombre} ${prop.candidato.Paterno} ${prop.candidato.Materno}`,

                dataCandidato: prop,
                btnCandidato: (
                  <Button
                    className="btn-link"
                    color="primary"
                    onClick={() => {
                      let obj = this.state.dataRsPlus.find(o => o.key === key);
                      this.setValidate(obj);
                    }}
                  >
                    {`${prop.candidato.Nombre} ${prop.candidato.Paterno} ${prop.candidato.Materno}`}
                  </Button>
                ),
                Plaza: prop.plaza.Puesto || "",
                Municipio: prop.plaza.Municipio,
                Nivel: prop.plaza.Nivel || "",
                Dependencia: prop.plaza.Dependencia || "",
                Estatus: prop.Movimiento_status.label,
                idMovimientoStatus: prop.Movimiento_status.label || ""
              };
            })
          });
        }
      })
      .catch(e => {
        console.log("error VoBo: ", e.message);
        Notify(3, "tc", `Error: al obtener history ${e.message}`, this.refs);
      });
  };

  setContraPropuesta = item => {
    console.log("=>ContraPropuesta: ", item);

    if (!item.dataCandidato.Propone.value) {
      Notify(3, "tc", `Error: no tiene asignado Quien Propone`, this.refs);
      return true;
    }

    const token = global.access_token; //localstorage

    const data = {
      idMovimientoStatus: 5,
      id: item.id,
      tipo: 2,
      idCandidato: item.dataCandidato.candidato.id,
      idQuienPropone: item.dataCandidato.Propone.value,
      idPropuesta: this.state.data.ListadoPropuestas[this.state.data.CandidatoRSkeyarray].id
    }; //item.ListadoPropuestas[this.state.CandidatoRS.keyarray].id

    console.log("==>Data Validar Movmiento: ", data);

    EjecutarApi(data, "seguimientoPropuesta", token)
      .then(function(res) {
        return res;
      })
      .then(items => {
        console.log("===> Data seguimientoPropuesta: ", items);

        if (items.results) {
          this.setState({
            showData: true
          });

          var data = this.state.ContraPropuesta;
          data.find((o, i) => {
            console.log(`==> ${o.id} === ${item.key}`);
            if (o.id === item.id) {
              // here you should add some custom code so you can delete the data
              // from this component and from your server as well
              data.splice(i, 1);
              console.log("=>[data]<= ", data);
              return true;
            }
            return false;
          });

          Notify(2, "tc", "Movimiento enviado!", this.refs);

          this.setState({
            ContraPropuesta: data
          });
        }
      })
      .catch(e => {
        console.log("error validarMovimiento: ", e.message);
        Notify(3, "tc", `Error: al validarMovimiento ${e.message}`, this.refs);
      });
  };

  contraPropuesta = () => {
    const token = global.access_token;

    const data = {
      page: 0,
      tipo: "and",
      pageSize: 10,
      sorted: [
        {
          id: "FechaHora",
          value: true
        }
      ],
      filtered: [
        {
          id: "idMovimiento",
          value: "1"
        },
        {
          id: "isJefatura",
          value: "1"
        },
        {
          id: "isActivo",
          value: "1"
        }
      ]
    };

    console.log("==>Data Contra: ", data);

    EjecutarApi(data, "getPlazaMovimientos", token)
      .then(function(res) {
        return res;
      })
      .then(items => {
        console.log("===> Data contra: ", items);

        if (items.results) {
          const dataTable = items.data;

          console.log("==>ContraPropuesta RS: ", dataTable);

          this.setState({
            loading: false,
            totalContra: items.total,
            ContraPropuesta: dataTable.map((prop, key) => {
              return {
                id: prop.id,
                key: key,
                FechaMovimiento: prop.FechaMovimiento || "",
                PuestoQuienPropone: prop.Propone.Puesto || "",
                Movimiento: prop.movimiento.label,
                Propone:
                  `${prop.Propone.Nombre} ${prop.Propone.Paterno}, ${prop.Propone.Puesto}` ||
                  "",
                Candidato: `${prop.candidato.Nombre} ${prop.candidato.Paterno} ${prop.candidato.Materno}`,
                NivelEstudios: prop.candidato.NivelEstudios.label,
                GradoObtenido: prop.candidato.GradoObtenido,
                dataCandidato: prop,
                btnCandidato: (
                  <Button
                    className="btn-link"
                    color="primary"
                    onClick={() => {
                      let obj = this.state.ContraPropuesta.find(
                        o => o.key === key
                      );
                      this.setContraPropuesta(obj);
                    }}
                  >
                    {`${prop.candidato.Nombre} ${prop.candidato.Paterno} ${prop.candidato.Materno}`}
                  </Button>
                ),
                Plaza: prop.plaza.Puesto || "",
                Municipio: prop.plaza.Municipio,
                Nivel: prop.candidato.NivelPropuesto || "",
                Estatus: prop.Movimiento_status.label,
                idMovimientoStatus: prop.Movimiento_status.label || ""
              };
            })
          });
        }
      })
      .catch(e => {
        console.log("error VoBo: ", e.message);
        Notify(3, "tc", `Error: al obtener history ${e.message}`, this.refs);
      });
  };

  render() {
    global = getVars("Token");

    console.log("==> State Validate: ", this.state);
    const { dataCandidato } = this.state.data;

    const { History, ContraPropuesta } = this.state;

    //const DataValidacion = this.state.data.dataCandidato.candidato;

    //console.log("==>validacion: ", DataValidacion);

    return (
      <div>
        <NotificationAlert ref="notificationAlert" />
        <Row>
          
          <Col md="12">
            <Card>
              <CardBody>
                <div className="nav-tabs-navigation">
                  <div className="nav-tabs-wrapper">
                    <Nav id="tabs" role="tablist" tabs>
                      <NavItem>
                        <NavLink
                          aria-expanded={this.state.activeTab === "Propuesta"}
                          data-toggle="tab"
                          href="#chris"
                          role="tab"
                          className={
                            this.state.activeTab === "Propuesta" ? "active" : ""
                          }
                          onClick={() =>
                            this.setState({
                              activeTab: "Propuesta"
                            })
                          }
                        >
                          <h6 className="title"> Propuesta </h6>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          aria-expanded={
                            this.state.activeTab === "Contrapropuesta"
                          }
                          data-toggle="tab"
                          href="#chris"
                          role="tab"
                          className={
                            this.state.activeTab === "Contrapropuesta"
                              ? "active"
                              : ""
                          }
                          onClick={() =>
                            this.setState({
                              activeTab: "Contrapropuesta"
                            })
                          }
                        >
                          <h6 className="title">
                            ContraPropuesta
                            <Badge color="danger" pill>
                              {this.state.totalContra}
                            </Badge>
                          </h6>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          aria-expanded={this.state.activeTab === "Historico"}
                          data-toggle="tab"
                          href="#chris"
                          role="tab"
                          className={
                            this.state.activeTab === "Historico" ? "active" : ""
                          }
                          onClick={() =>
                            this.setState({
                              activeTab: "Historico"
                            })
                          }
                        >
                          <h6 className="title">
                            Histórico
                            <Badge color="danger" pill>
                              {this.state.totalHistory}
                            </Badge>
                          </h6>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </div>
                <TabContent
                  className="text-center"
                  id="my-tab-content"
                  activeTab={this.state.activeTab}
                >
                  <TabPane tabId="Propuesta" role="tabpanel">
                    <Card>
                      <CardBody>
                        <Form>
                          <Row>
                            <Col className="pr-1" md="5">
                              <FormGroup>
                                <label> Nombre </label>
                                <Input
                                  defaultValue={`${this.state.data.CandidatoRSNombre} ${this.state.data.CandidatoRSPaterno} ${this.state.data.CandidatoRSMaterno}`}
                                  disabled
                                  type="text"
                                />
                              </FormGroup>
                            </Col>
                            <Col className="pr-1" md="5">
                              <FormGroup>
                                <label> Propone </label>
                                <Input
                                  //defaultValue={`${dataCandidato.Propone.Nombre} ${dataCandidato.Propone.Paterno} ${dataCandidato.Propone.Materno}`}
                                  disabled
                                  type="text"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm="3">
                              <FormGroup>
                                <Label className="text-left"> Sexo </Label>
                                <Input
                                  defaultValue={
                                    this.state.data.CandidatoRSsexo.label
                                  }
                                  disabled
                                  type="text"
                                />
                              </FormGroup>
                            </Col>
                            <Col sm="3">
                              <FormGroup>
                                <Label className="text-left">
                                  Estado Civil
                                </Label>
                                <Input
                                  defaultValue={
                                    this.state.data.CandidatoRSestado_civil.label
                                  }
                                  disabled
                                  type="text"
                                />
                              </FormGroup>
                            </Col>
                            <Col sm="3">
                              <FormGroup>
                                <Label className="text-left"> INE </Label>
                                <Input
                                  defaultValue={this.state.data.CandidatoRSINE}
                                  disabled
                                  type="text"
                                />
                              </FormGroup>
                            </Col>
                            <Col sm="3">
                              <FormGroup>
                                <Label className="text-left"> CURP </Label>
                                <Input
                                  defaultValue={this.state.data.CandidatoRSCURP}
                                  disabled
                                  type="text"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm="3">
                              <FormGroup>
                                <Label className="text-left"> Municipio </Label>
                                <Input
                                  defaultValue={
                                    this.state.data.CandidatoRSmunicipios.label
                                  }
                                  disabled
                                  type="text"
                                />
                              </FormGroup>
                            </Col>
                            <Col sm="3">
                              <FormGroup>
                                <Label className="text-left">
                                  Último Empleo
                                </Label>
                                <Input
                                  defaultValue={
                                    this.state.data.CandidatoRSUltimoTrabajo
                                  }
                                  disabled
                                  type="text"
                                />
                              </FormGroup>
                            </Col>
                            <Col sm="3">
                              <FormGroup>
                                <Label className="text-left">
                                  Nivel de Estudios
                                </Label>
                                <Input
                                  defaultValue={
                                    this.state.data.CandidatoRSestudios.label
                                  }
                                  disabled
                                  type="text"
                                />
                              </FormGroup>
                            </Col>
                            <Col sm="3">
                              <FormGroup>
                                <Label className="text-left">
                                  Grado Obtenido
                                </Label>
                                <Input
                                  defaultValue={
                                    this.state.data.CandidatoRSGradoObtenido
                                  }
                                  disabled
                                  type="text"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm="4">
                              <FormGroup>
                                <Label className="text-left"> Celular </Label>
                                <Input
                                  defaultValue={
                                    this.state.data.CandidatoRSCelular1
                                  }
                                  disabled
                                  type="text"
                                />
                              </FormGroup>
                            </Col>
                            <Col sm="4">
                              <FormGroup>
                                <Label className="text-left"> Tel Casa </Label>
                                <Input
                                  defaultValue={this.state.data.CandidatoRSTelCasa}
                                  disabled
                                  type="text"
                                />
                              </FormGroup>
                            </Col>
                            <Col sm="4">
                              <FormGroup>
                                <Label className="text-left">
                                  Correo Electrónico
                                </Label>
                                <Input
                                  defaultValue={
                                    this.state.data.CandidatoRSCorreoElectronico
                                  }
                                  disabled
                                  type="text"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm="4">
                              <FormGroup>
                                <Label className="text-left"> Facebook </Label>
                                <Input
                                  defaultValue={
                                    this.state.data.CandidatoRSFacebook
                                  }
                                  disabled
                                  type="text"
                                />
                              </FormGroup>
                            </Col>
                            <Col sm="4">
                              <FormGroup>
                                <Label className="text-left"> Twitter </Label>
                                <Input
                                  defaultValue={this.state.data.CandidatoRSTwitter}
                                  disabled
                                  type="text"
                                />
                              </FormGroup>
                            </Col>
                            <Col sm="4">
                              <FormGroup>
                                <Label className="text-left"> Instagram </Label>
                                <Input
                                  defaultValue={
                                    this.state.data.CandidatoRSInstagram
                                  }
                                  disabled
                                  type="text"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </Form>
                      </CardBody>
                     
                        <CardFooter>
                          <div className="form-row  text-center">
                            <Col>
                              <Button
                                onClick={() =>
                                  this.props.autorizar(this.state.data)
                                }
                                className="ml-3"
                                color="info"
                              >
                                Autorizado
                              </Button>
                            </Col>
                            <Col>
                              <Button
                                onClick={() =>
                                  this.props.noautorizar(this.state.data)
                                }
                                className="ml-3"
                                color="danger"
                              >
                                Rechazado
                              </Button>
                            </Col>
                          </div>
                        </CardFooter>
                      
                    </Card>
                  </TabPane>
                  <TabPane tabId="Contrapropuesta" role="tabpanel">
                    <div className="content">
                      <Row>
                        <Col md="12">
                          <Card>
                            <CardBody>
                              <ReactTable
                                data={ContraPropuesta}
                                filterable
                                columns={[
                                  {
                                    Header: "Fecha Mov",
                                    minWidth: 80,
                                    id: "fecha_movimiento",
                                    accessor: d => d.FechaMovimiento,
                                    filterMethod: (filter, rows) =>
                                      matchSorter(rows, filter.value, {
                                        keys: ["FechaMovimiento"]
                                      }),
                                    filterAll: true
                                  },
                                  {
                                    Header: "Candidato",
                                    minWidth: 150,
                                    id: "candidato",
                                    accessor: d => d.btnCandidato,
                                    filterMethod: (filter, rows) =>
                                      matchSorter(rows, filter.value, {
                                        keys: ["Candidato"]
                                      }),
                                    filterAll: true
                                  },
                                  {
                                    Header: "Nivel Estudios",
                                    minWidth: 50,
                                    id: "NivelEstudios",
                                    accessor: d => d.NivelEstudios,
                                    filterMethod: (filter, rows) =>
                                      matchSorter(rows, filter.value, {
                                        keys: ["NivelEstudios"]
                                      }),
                                    filterAll: true
                                  },
                                  {
                                    Header: "Grado Obtenido",
                                    minWidth: 50,
                                    id: "GradoObtenido",
                                    accessor: d => d.GradoObtenido,
                                    filterMethod: (filter, rows) =>
                                      matchSorter(rows, filter.value, {
                                        keys: ["GradoObtenido"]
                                      }),
                                    filterAll: true
                                  },
                                  {
                                    Header: "Nivel",
                                    minWidth: 50,
                                    id: "nivel",
                                    accessor: d => d.Nivel,
                                    filterMethod: (filter, rows) =>
                                      matchSorter(rows, filter.value, {
                                        keys: ["Nivel"]
                                      }),
                                    filterAll: true
                                  },
                                  {
                                    Header: "Propone",
                                    minWidth: 50,
                                    id: "Propone",
                                    accessor: d => d.Propone,
                                    filterMethod: (filter, rows) =>
                                      matchSorter(rows, filter.value, {
                                        keys: ["Propone"]
                                      }),
                                    filterAll: true
                                  },
                                  {
                                    Header: "Estatus",
                                    minWidth: 50,
                                    id: "Estatus",
                                    accessor: d => d.Estatus,
                                    filterMethod: (filter, rows) =>
                                      matchSorter(rows, filter.value, {
                                        keys: ["Estatus"]
                                      }),
                                    filterAll: true
                                  }
                                ]}
                                defaultPageSize={10}
                                showPagination={true}
                                showPaginationTop={false}
                                showPaginationBottom={true}
                                pageSizeOptions={[10, 20, 25, 50, 100]}
                                manual
                                canNextFromData={true}
                                showTotalPages={true}
                                PaginationComponent={Pagination}
                                previousText="Anterior"
                                nextText="Siguiente"
                                ofText="de"
                                total={this.state.totalContra}
                                loading={this.state.loadingContra}
                                id="tbContraPropuesta"
                                onFetchData={(state, instance) => {
                                  console.log("==>state: ", state);

                                  const token = global.access_token;

                                  const Filtered = this.state.firstTimeFilter
                                    ? [
                                        {
                                          id: "idMovimiento",
                                          value: "1"
                                        },
                                        {
                                          id: "isJefatura",
                                          value: "1"
                                        },
                                        {
                                          id: "isActivo",
                                          value: "1"
                                        }
                                      ]
                                    : state.filtered;

                                  const Sorted = this.state.firstTimeFilter
                                    ? [
                                        {
                                          id: "FechaHora",
                                          value: true
                                        }
                                      ]
                                    : state.sorted;

                                  this.setState({
                                    loadingContra: true,
                                    firstTimeFilter: false
                                  });

                                  const data = {
                                    page: state.page,
                                    tipo: "and",
                                    pageSize: state.pageSize,
                                    sorted: Sorted,
                                    filtered: Filtered
                                  };

                                  console.log(
                                    "==>Data contraPropuesta: ",
                                    data
                                  );

                                  EjecutarApi(
                                    data,
                                    "getPlazaMovimientos",
                                    token
                                  )
                                    .then(function(res) {
                                      return res;
                                    })
                                    .then(items => {
                                      console.log(
                                        "===> Data contraPropuesta: ",
                                        items
                                      );

                                      if (items.results) {
                                        const dataTable = items.data;

                                        console.log(
                                          "==>ContraPropuesta RS: ",
                                          dataTable
                                        );

                                        this.setState({
                                          loadingContra: false,
                                          totalContra: items.total,
                                          ContraPropuesta: dataTable.map(
                                            (prop, key) => {
                                              return {
                                                id: prop.id,
                                                key: key,
                                                FechaMovimiento:
                                                  prop.FechaMovimiento || "",
                                                PuestoQuienPropone:
                                                  prop.Propone.Puesto || "",
                                                Movimiento:
                                                  prop.movimiento.label,
                                                Propone:
                                                  `${prop.Propone.Nombre} ${prop.Propone.Paterno}, ${prop.Propone.Puesto}` ||
                                                  "",
                                                Candidato: `${prop.candidato.Nombre} ${prop.candidato.Paterno} ${prop.candidato.Materno}`,
                                                NivelEstudios:
                                                  prop.candidato.NivelEstudios
                                                    .label,
                                                GradoObtenido:
                                                  prop.candidato.GradoObtenido,
                                                dataCandidato: prop,
                                                btnCandidato: (
                                                  <Button
                                                    className="btn-link"
                                                    color="primary"
                                                    onClick={() => {
                                                      let obj = this.state.ContraPropuesta.find(
                                                        o => o.key === key
                                                      );
                                                      this.setContraPropuesta(
                                                        obj
                                                      );
                                                    }}
                                                  >
                                                    {`${prop.candidato.Nombre} ${prop.candidato.Paterno} ${prop.candidato.Materno}`}
                                                  </Button>
                                                ),
                                                Plaza: prop.plaza.Puesto || "",
                                                Municipio: prop.plaza.Municipio,
                                                Nivel:
                                                  prop.candidato
                                                    .NivelPropuesto || "",
                                                Estatus:
                                                  prop.Movimiento_status.label,
                                                idMovimientoStatus:
                                                  prop.Movimiento_status
                                                    .label || ""
                                              };
                                            }
                                          )
                                        });
                                      }
                                    })
                                    .catch(e => {
                                      console.log(
                                        "error contraPropuesta: ",
                                        e.message
                                      );
                                      this.setState({
                                        loadingContra: false
                                      });
                                      Notify(
                                        3,
                                        "tc",
                                        `Error: al obtener contraPropuesta ${e.message}`,
                                        this.refs
                                      );
                                    });
                                }}
                                /*
                        You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none-which will make the pagination buttons gray
                        */
                                className="-striped -highlight primary-pagination"
                              />
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </div>
                  </TabPane>
                  <TabPane tabId="Historico" role="tabpanel">
                    <div className="content">
                      <Row>
                        <Col md="12">
                          <Card>
                            <CardBody>
                              <ReactTable
                                data={History}
                                filterable
                                columns={[
                                  {
                                    Header: "Fecha Mov",
                                    minWidth: 80,
                                    id: "fecha_movimiento",
                                    accessor: d => d.FechaMovimiento,
                                    filterMethod: (filter, rows) =>
                                      matchSorter(rows, filter.value, {
                                        keys: ["FechaMovimiento"]
                                      }),
                                    filterAll: true
                                  },
                                  {
                                    Header: "Tipo",
                                    minWidth: 80,
                                    id: "tipo",
                                    accessor: d => d.Movimiento,
                                    filterMethod: (filter, rows) =>
                                      matchSorter(rows, filter.value, {
                                        keys: ["Movimiento"]
                                      }),
                                    filterAll: true
                                  },
                                  {
                                    Header: "Dependencia",
                                    id: "dependencia",
                                    accessor: d => d.Dependencia,
                                    filterMethod: (filter, rows) =>
                                      matchSorter(rows, filter.value, {
                                        keys: ["Dependencia"]
                                      }),
                                    filterAll: true
                                  },
                                  {
                                    Header: "Propone",
                                    id: "propone",
                                    accessor: d => d.QuienPropone,
                                    filterMethod: (filter, rows) =>
                                      matchSorter(rows, filter.value, {
                                        keys: ["QuienPropone"]
                                      }),
                                    filterAll: true
                                  },

                                  {
                                    Header: "Plaza",
                                    id: "plaza",
                                    accessor: d => d.Plaza,
                                    filterMethod: (filter, rows) =>
                                      matchSorter(rows, filter.value, {
                                        keys: ["Plaza"]
                                      }),
                                    filterAll: true
                                  },
                                  {
                                    Header: "Nivel",
                                    minWidth: 50,
                                    id: "nivel",
                                    accessor: d => d.Nivel,
                                    filterMethod: (filter, rows) =>
                                      matchSorter(rows, filter.value, {
                                        keys: ["Nivel"]
                                      }),
                                    filterAll: true
                                  },
                                  {
                                    Header: "Estatus",
                                    minWidth: 50,
                                    id: "Estatus",
                                    accessor: d => d.Estatus,
                                    filterMethod: (filter, rows) =>
                                      matchSorter(rows, filter.value, {
                                        keys: ["Estatus"]
                                      }),
                                    filterAll: true
                                  }
                                ]}
                                defaultPageSize={10}
                                loading={this.state.loading}
                                id="tbHistory"
                                /*
                        You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none-which will make the pagination buttons gray
                        */
                                className="-striped -highlight primary-pagination"
                              />
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </div>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default MovimientoAutorizar;
