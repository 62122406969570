
import {URL_PUBLICA} from "../GlobalStore"; 
const EjecutarGetApi = (data, api, token) => {
    return new Promise( (resolve, reject) => {
    
        const requestInfo = {
            headers: new Headers({
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            })
        };

        var url = new URL(URL_PUBLICA+api);

        if(data.length)
        {
            data.array.forEach(item => {
                url.searchParams.append(item.id, item.value);
            })
        
        }
        console.log('==>url Get:', url);

        fetch(url, requestInfo)
        .then(response => {
            if(response.ok){
              return response.json();
            }
            throw new Error(`Ocurrio un error: ${api}: ${response.statusText}`);
        })
        .then(dataRS => {

            console.log('==>Datos RS: ', dataRS);

            if(!dataRS.success)
            {
                const rs={
                    results: false,
                    data: {},
                    error: dataRS.error
                }
                reject(rs);
            }else
            {
                resolve(dataRS);
            }
            
          
        })
        .catch(e => {
            //console.log('==>Error: '+ api +': ', e);
            const rs={
                results: false,
                data: {},
                message: e.message
            }
            reject(rs);
        });
    })
}

export default EjecutarGetApi;