import React from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Table,
  Row,
  Col,
} from "reactstrap";
import PerfectScrollbar from "perfect-scrollbar";
import NotificationAlert from "react-notification-alert";
import Select from "react-select";
import Stepper from "react-stepper-horizontal";
import ReactTable from "react-table";
import matchSorter from "match-sorter";
import moment from "moment";
import { PushSpinner } from "react-spinners-kit";
import SweetAlert from "react-bootstrap-sweetalert";
import EjecutarApi from "../../data/Consultas";
import Notify from "../../data/Notificacion";
import DashboardNavbar from "../../components/Navbars/DashboardNavbar.jsx";
import Footer from "../../components/Footer/Footer.jsx";
import Sidebar from "../../components/Sidebar/MenuSidebar.jsx";
import Pagination from "../components/Paginacion";
import getFile from "../../data/GetFile";

import ReLogin from "./ReLogin";

import UploadFile from "../components/Dropzone/index";
import { getVars, URL_PUBLICA } from "../../GlobalStore";
import { ExcelRenderer } from "react-excel-renderer";

var global = "";

var ps;

class CargarPlantilla extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "black",
      activeColor: "info",
      sidebarMini: false,
      showReload: false,
      loading: false,
      Archivos: [],
      Movimiento: "",
      tipo: {
        value: "",
        label: "",
      },
      Plantillatipo: [{ value: "", label: "" }],
      showload: true,
      showMatch: false,
      showconfirm: false,
      colExcel: [],
      colDataBase: [],
      totalColumnas: 0,
      colDataBase: [
        { value: "campo1", label: "Campo 1" },
        { value: "campo2", label: "Campo 2" },
        { value: "campo3", label: "Campo 3" },
        { value: "campo4", label: "Campo 4" },
        { value: "campo5", label: "Campo 5" },
        { value: "campo6", label: "Campo 6" },
        { value: "campo7", label: "Campo 7" },
        { value: "campo8", label: "Campo 8" },
        { value: "campo9", label: "Campo 9" },
        { value: "campo10", label: "Campo 10" },
        { value: "campo11", label: "Campo 11" },
        { value: "campo12", label: "Campo 12" },
        { value: "campo13", label: "Campo 13" },
        { value: "campo14", label: "Campo 14" },
        { value: "campo15", label: "Campo 15" },
        { value: "campo16", label: "Campo 16" },
        { value: "campo17", label: "Campo 17" },
        { value: "campo18", label: "Campo 18" },
        { value: "campo19", label: "Campo 19" },
        { value: "campo20", label: "Campo 20" },
      ],
    };
  }

  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(this.refs.mainPanel);
    }

    //getTipoPlantilla
    const token = global.access_token;
    EjecutarApi({}, "getTipoPlantilla", token)
      .then(function (res) {
        return res;
      })
      .then((items) => {
        if (items.results) {
          this.setState({
            loadingOcupadas: false,
            Plantillatipo: items.data.map((item) => {
              return { label: item.label, value: item.value };
            }),
          });
        }
      })
      .catch((e) => {
        Notify(
          3,
          "tc",
          `Error al cargar getTipoPlantilla: ${e.message}`,
          this.refs
        );
        this.setState({ loadingOcupadas: false });
      });
    // const data = {
    //   page: 0,
    //   pageSize: 10,
    //   sorted: [],
    //   tipo: 'and',
    //   filtered: [],
    // };

    // EjecutarApi(data, 'getPlantillaArchivos', getVars('Token').access_token)
    //   .then(function(res) {
    //     return res;
    //   })
    //   .then(res => {

    //   })
    //   .catch(e => {

    //     Notify(3, 'tc', `Error en getPlantillaArchivos: ${e.error}`, this.refs);
    //     this.setState({ loading: false });
    //   });
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
  }
  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }

  toggleDependencia = () => {
    this.setState((prevState) => ({
      modalDependencia: !prevState.modalDependencia,
    }));
  };

  handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      this.setState({ sidebarMini: false });
    } else {
      this.setState({ sidebarMini: true });
    }
    document.body.classList.toggle("sidebar-mini");
  };

  setQuincena = (e) => {
    this.setState({ Quincena: moment(e._d).format("YYYY-MM-DD") });
  };

  onFilesAdded = (files) => {
    this.setState({ files });
  };

  getBase64(file) {
    let document = "";
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      document = reader.result;
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };

    return document;
  }
  sendFile = () => {
    if (!this.state.hasOwnProperty("files")) {
      Notify(3, "tc", `No ha seleccionado ningún archivo`, this.refs);
      return;
    }
    if (this.state.files.length == 0) {
      Notify(3, "tc", `No ha seleccionado ningún archivo`, this.refs);
      return;
    }

    var formData = new FormData();
    formData.append("Archivo", this.state.files[0], this.state.files[0].name);

    const token = global.access_token;

    const requestInfo = {
      method: "POST",
      body: formData,
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    };

    this.setState({ loading: true });
    fetch(URL_PUBLICA + "loadFile", requestInfo)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Post");
      })
      .then((Results) => {
        console.log("==>Results SubirExcel: ", Results);
        if (Results.results) {
          let url =
            "data:application/vnd.ms-excel;base64," + Results.data.filedata;
          // application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;
          let a = document.createElement("a");
          a.href = url;
          a.download = `${Results.data.name}.${Results.data.ext}`;
          a.click();
          Notify(2, "tc", `Cruce generado con éxito!!!`, this.refs);

          this.setState({ files: [] });
        } else {
          Notify(
            3,
            "tc",
            `Error al realizar el cruce. ${Results.message}`,
            this.refs
          );
        }

        this.setState({ loading: false });

        return;
      })
      .catch((e) => {
        console.log("=>Error: ", e);
        Notify(3, "tc", `Error al cargar el archivo: ${e.message}`, this.refs);
        this.setState({ loading: false });
      });

    this.setState({ step: 2 });
  };

  bytesToSize = (bytes) => {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes == 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  };

  downloadFile = (item) => {
    console.log("=>Descargar: ", item);
    const token = global.access_token; //localstorage

    window.location.href =
      URL_PUBLICA + `getPlantillaArchivoExcel?id=${item.id}&token=${token}`;
  };

  closeReload = () => {
    this.setState({ showReload: false });
  };

  // readFirts = (fileObj) =>{
  //   const arr =  ['nombre', 'paterno', 'materno', 'nombres'];
  //   ExcelRenderer(fileObj, (err, resp) => {
  //       if(err){
  //       }
  //       else{
  //         if(resp.rows.length>0)
  //         {
  //             console.log("successfully",resp);
  //             //setMensaje('Compatible!!!')
  //             return true;
  //         }
  //         else{
  //           console.log('Error: El archivo no tiene un formato compatible!!!')
  //           //setLoading(false);
  //           return false;
  //         }
  //     }
  //     });
  // }
  render() {
    const { loading, Archivos, showReload } = this.state;

    console.log("=>Estados: ", this.state);

    global = getVars("Token");

    return (
      <div>
        {!showReload && (
          <div className="wrapper">
            <Sidebar
              {...this.props}
              bgColor={this.state.backgroundColor}
              activeColor={this.state.activeColor}
            />
            <div className="main-panel" ref="mainPanel">
              <DashboardNavbar
                {...this.props}
                handleMiniClick={this.handleMiniClick}
              />
              <NotificationAlert ref="notificationAlert" />
              <div className="content">
                <Row>
                  <Col md="12">
                    {this.state.showload && (
                      <Card>
                        <CardBody>
                          <Row>
                            <Col sm={3}>
                              <UploadFile
                                onFilesAdded={this.onFilesAdded}
                                Accept="xlsx"
                                title="Cargar excel"
                              />
                            </Col>
                            <Col sm={3}>
                              <FormGroup>
                                <br />
                                {loading && (
                                  <PushSpinner
                                    size={30}
                                    color="#686769"
                                    loading={loading}
                                  />
                                )}
                                {!loading && (
                                  <Button
                                    className="btn-round mb-3"
                                    color="warning"
                                    onClick={this.sendFile}
                                  >
                                    <span className="btn-label">
                                      <i className="nc-icon nc-zoom-split" />
                                    </span>
                                    Subir archivo
                                  </Button>
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    )}
                  </Col>
                </Row>
              </div>
              <Footer fluid />
            </div>
          </div>
        )}
        {showReload && (
          <ReLogin close={this.closeReload} message="Sesión expiró!" />
        )}
      </div>
    );
  }
}

export default CargarPlantilla;
