import React from 'react';
import {
	Button,
	Alert,
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Container,
	Col,
	Row,
} from 'reactstrap';
import Footer from '../../components/Footer/Footer.jsx';
import NotificationAlert from 'react-notification-alert';
import { CircleSpinner } from 'react-spinners-kit';

import { setVars, URL_PUBLICA} from '../../GlobalStore';

class ReLogin extends React.Component {
	constructor(props) {
		super(props);

		console.log(this.props);
		this.state = {
			isLoading: false,
			Username: '',
			Passwd: '',
			message: this.props.message || '',
		};
	}
	componentDidMount() {
		document.body.classList.toggle('login-page');
	}
	componentWillUnmount() {
		document.body.classList.toggle('login-page');
	}

	setUsername = input => {
		this.setState({ Username: input.target.value });
	};

	setPasswd = input => {
		this.setState({ Passwd: input.target.value });
	};

	notify = (color, place, message) => {
		var type;
		switch (color) {
			case 1:
				type = 'primary';
				break;
			case 2:
				type = 'success';
				break;
			case 3:
				type = 'danger';
				break;
			case 4:
				type = 'warning';
				break;
			case 5:
				type = 'info';
				break;
			default:
				break;
		}
		var options = {};
		options = {
			place: place,
			message: (
				<div>
					<div>{message}</div>
				</div>
			),
			type: type,
			icon: 'now-ui-icons ui-1_bell-53',
			autoDismiss: 4,
		};
		this.refs.notificationAlert.notificationAlert(options);
	};

	ReLogin = () => {
		this.setState({ isLoading: true });

		if (this.Username.length < 3) {
			this.notify(3, 'tc', 'Usuario requerido');
			return;
		}

		if (this.Passwd.length < 3) {
			this.notify(3, 'tc', 'Contraseña requerido');
			return;
		}

		const data = {
			email: this.Username,
			password: this.Passwd,
			message: '',
		};

		const requestInfo = {
			method: 'POST',
			body: JSON.stringify(data),
			headers: new Headers({
				'Content-Type': 'application/json',
			}),
		};

		console.log('=>Iniciar Sesion:');

		fetch(URL_PUBLICA+'login', requestInfo)
			.then(response => {
				console.log('==>Response: ', response);
				if (response.ok) {
					return response.json();
				}
				throw new Error('Login inválido');
			})
			.then(token => {
				this.setState({ isLoading: false });

				if (token.access_token) {
					console.log('==>ReLogin: ', token);

					setVars('Token', token);

					this.props.close();
				} else {
					console.log('==> Sin Información', token.message);
					this.notify(3, 'tc', token.message);
				}
				return;
			})
			.catch(e => {
				this.setState({ isLoading: false });
				this.notify(3, 'tc', `Erro de acceso: ${e.message}`);
			});
	};

	render() {
		const { isLoading } = this.state;

		return (
			<div className="wrapper wrapper-full-page" ref="fullPages">
				<div className="full-page section-image">
					<NotificationAlert ref="notificationAlert" />
					<div className="login-page">
						<Container>
							<Row>
								<Col className="ml-auto mr-auto" lg="4" md="6">
									<Form onSubmit={this.ReLogin}>
										<Card className="card-login">
											<CardHeader>
												<CardHeader>
													<h3 className="header text-center">Iniciar Sesión</h3>
													<h3>
														{this.state.message !== '' ? (
															<Alert color="danger" className="text-center">
																{this.state.message}
															</Alert>
														) : (
															''
														)}
													</h3>
												</CardHeader>
											</CardHeader>
											<CardBody>
												<InputGroup>
													<InputGroupAddon addonType="prepend">
														<InputGroupText>
															<i className="nc-icon nc-single-02" />
														</InputGroupText>
													</InputGroupAddon>
													<Input
														placeholder="Username"
														id="Username"
														type="text"
														onChange={e => (this.Username = e.target.value)}
													/>
												</InputGroup>
												<InputGroup>
													<InputGroupAddon addonType="prepend">
														<InputGroupText>
															<i className="nc-icon nc-key-25" />
														</InputGroupText>
													</InputGroupAddon>
													<Input
														placeholder="Password"
														id="Passwd"
														type="password"
														autoComplete="off"
														onChange={e => (this.Passwd = e.target.value)}
													/>
												</InputGroup>
												<br />
											</CardBody>
											<CardFooter>
												<div className="header text-center">
													{isLoading && (
														<CircleSpinner
															className="text-center"
															size={30}
															color="#686769"
															loading={isLoading}
														/>
													)}
													{!isLoading && (
														<Button
															type="submit"
															className="btn-round mb-3"
															color="warning"
															onClick={this.ReLogin}
														>
															Iniciar Sesión
														</Button>
													)}
												</div>
											</CardFooter>
										</Card>
									</Form>
								</Col>
							</Row>
						</Container>
						<div
							className="full-page-background"
							style={{
								backgroundImage: `url(${require('assets/img/bg/guanajuato-guanajuato-1.jpg')})`,
							}}
						/>
					</div>
					<Footer ver="1.3" fluid />
				</div>
			</div>
		);
	}
}

export default ReLogin;
