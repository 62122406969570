import React, { Component } from 'react';
import { Button, Card, CardHeader, CardBody, CardFooter, Row, Col } from 'reactstrap';
import Stepper from 'react-stepper-horizontal';
import ReactTable from 'react-table';
import matchSorter from 'match-sorter';
import { PushSpinner } from 'react-spinners-kit';
import NotificationAlert from 'react-notification-alert';

import EjecutarApi from '../../../data/Consultas';
import getFile from '../../../data/GetFile';
import Notify from '../../../data/Notificacion';

import Pagination from '../Paginacion/index';

import { getVars,URL_PUBLICA } from '../../../GlobalStore';

var global = '';

var infoRS = [];
infoRS[0] = 'Actores Políticos';
infoRS[1] = 'Electoral Abogados';
infoRS[2] = 'Electoral Casas Azules';
infoRS[3] = 'Electoral RCs';
infoRS[4] = 'Electoral RGs';
infoRS[5] = 'Est Promoción';
infoRS[6] = 'Morena';
infoRS[7] = 'PAN';
infoRS[8] = 'PRD';
infoRS[9] = 'PRI';
infoRS[10] = 'PVEM';
infoRS[11] = 'Vota';
infoRS[12] = 'Plantilla';
infoRS[13] = 'isEspecial';
infoRS[14] = 'osc';
infoRS[15] = 'RCRG_INE';
infoRS[16] = 'Movimientos';

var catRS = [];
catRS[0] = 'isMilitante';
catRS[1] = 'isElectoralA';
catRS[2] = 'isElectoral';
catRS[3] = 'isElectoralrc';
catRS[4] = 'isElectoralrg';
catRS[5] = 'isPromocion';
catRS[6] = 'isMorena';
catRS[7] = 'isPan';
catRS[8] = 'isPrd';
catRS[9] = 'isPri';
catRS[10] = 'isPvem';
catRS[11] = 'isSocial';
catRS[12] = 'isPlantilla';
catRS[13] = 'isEspecial';
catRS[14] = 'osc';
catRS[15] = 'RCRG_INE';
catRS[16] = 'Movimientos';

class Validaciones extends Component {
	constructor(props) {
		super(props);
		console.log('=> Validacion data: ', props.data);

		this.state = {
			data: props.data || {},
			step: 0,
			total: 0,
			showButtons: false,
			Current: {
				Current: '',
				id: 0,
			},
			MostrarEncontrado: false,
			loading: true,
			results: [],
			setValidaciones: {
				id: null,
				NombreCompleto: '',
				Tipo: 'Validaciones',
				Propone: '',
				actorespoliticos: '',
				electoralabogados: '',
				electoralCasasAzules: '',
				electoralrc: '',
				electoralrg: '',
				estpromocion: '',
				gto: '',
				morenagto: '',
				pangto: '',
				prdgto: '',
				prigto: '',
				pvemgto: '',
				vota: '',
				plantilla: '',
				isEspecial: '',
				osc: '',
				rcrgine: '',
				movimientos: '',
			},
		};
	}

	componentDidMount() {
		const data = {
			Nombre: this.state.data.dataCandidato.candidato.Nombre,
			Paterno: this.state.data.dataCandidato.candidato.Paterno,
			Materno: this.state.data.dataCandidato.candidato.Materno,
			NombreCompleto: this.state.data.dataCandidato.candidato.NombreCompleto,
		};

		console.log('=>>Buscar: ', data);

		const token = global.access_token;

		EjecutarApi(data, 'validaciones', token)
			.then(function(res) {
				return res;
			})
			.then(items => {
				console.log('===> Data Will Catalogos: ', items);

				const InfoData = [];
				InfoData.push(items.actorespoliticos);
				InfoData.push(items.electoralabogados);
				InfoData.push(items.electoralCasasAzules);
				InfoData.push(items.electoralrc);
				InfoData.push(items.electoralrg);
				InfoData.push(items.estpromocion);
				InfoData.push(items.morenagto);

				if (items.pangto.results) InfoData.push(items.pangto);
				else InfoData.push({ results: false, data: [] });
				InfoData.push(items.prdgto);
				InfoData.push(items.prigto);
				InfoData.push(items.pvemgto);
				InfoData.push(items.vota);
				InfoData.push(items.plantilla);
				InfoData.push(items.isEspecial);
				InfoData.push(items.osc);
				if (items.rcrgine.results) InfoData.push(items.rcrgine);
				else InfoData.push({ results: false, data: [] });
				InfoData.push(items.movimientos);
				

				const dataTable = items.actorespoliticos.data;

				console.log('==>dataTable: ', dataTable);
				console.log('==>InfoData: ', InfoData);

				this.setState({
					showButtons: true,
					results: dataTable.map((prop, key) => {
						return {
							id: key,
							key: key,
							btnNombre: (
								<Button
									className="btn-link"
									color="primary"
									onClick={() => {
										let obj = this.state.results.find(o => o.key === key);
										this.setEncontrado(obj);
									}}
								>
									{prop.nombreCompleto}
								</Button>
							),
							NombreCompleto: prop.nombreCompleto || '',
							Municipio: prop.municipio || '',
							Info: prop.Info || '',
							Fuente: 'Actores Políticos',
							data: prop,
						};
					}),
				});

				this.setState({
					validRS: items,
					InfoData,
					MostrarEncontrado: this.state.results.length > 0 ? true : false,
					Current: { Current: 'Actores Políticos', id: 0 },
					loading: false,
				});
			})
			.catch(e => {
				console.log('error MovValidaciones: ', e.message);
				Notify(2, 'tc', `Error al cargar validaciones: ${e.message}`, this.refs);
			});
	}

	setStep = e => {
		console.log('===>Click Step1: ', e);
	};

	updateValidaciones = () => {
		var data = this.state.setValidaciones;

		console.log('Download setValidaciones: ', data);

		const token = global.access_token;

		EjecutarApi(data, 'setCandidatoValidacion', token)
			.then(function(res) {
				return res;
			})
			.then(items => {
				console.log('===> Data setCandidatoValidacion: ', items);
				if (items.results) {
					Notify(2, 'tc', `Descargando archivo`, this.refs);
					//const token = global.access_token;
					getFile([{ id: 'uid', value: items.data.uid }], 'getCandidatosValidaciones', token);
				}
			})
			.catch(e => {
				console.log('error setCandidatoValidacion: ', e.message);
				Notify(2, 'tc', `Error al setCandidatoValidacion: ${e.message}`, this.refs);
			});
	};

	setEncontrado = item => {
		var DataValidacion = this.state.setValidaciones;

		/*
            id: this.state.data.dataCandidato.candidato.id,
            NombreCompleto: this.state.data.Candidato,
            Propone: this.state.data.QuienPropone,
        */

		console.log('=>item: ', item);

		switch (this.state.Current.id) {
			case 0: //Actores Políticos
				this.setState({
					setValidaciones: {
						...DataValidacion,
						Tipo: 'Validaciones',
						id: this.state.data.dataCandidato.candidato.id,
						NombreCompleto: this.state.data.Candidato,
						Propone: this.state.data.QuienPropone,
						actorespoliticos: `${item.NombreCompleto} ${item.Info} ${item.Municipio} ${item.Fuente}`,
					},
				});
				break;
			case 1: //electoralabogados
				this.setState({
					setValidaciones: {
						...DataValidacion,
						Tipo: 'Validaciones',
						id: this.state.data.dataCandidato.candidato.id,
						NombreCompleto: this.state.data.Candidato,
						Propone: this.state.data.QuienPropone,
						electoralabogados: `${item.NombreCompleto} ${item.Info} ${item.Municipio} ${item.Fuente}`,
					},
				});
				break;

			case 2: //electoralabogados
				this.setState({
					setValidaciones: {
						...DataValidacion,
						Tipo: 'Validaciones',
						id: this.state.data.dataCandidato.candidato.id,
						NombreCompleto: this.state.data.Candidato,
						Propone: this.state.data.QuienPropone,
						electoralCasasAzules: `${item.NombreCompleto} ${item.Info} ${item.Municipio} ${item.Fuente}`,
					},
				});
				break;
			case 3: //electoralabogados
				this.setState({
					setValidaciones: {
						...DataValidacion,
						Tipo: 'Validaciones',
						id: this.state.data.dataCandidato.candidato.id,
						NombreCompleto: this.state.data.Candidato,
						Propone: this.state.data.QuienPropone,
						electoralrc: `${item.NombreCompleto} ${item.Info} ${item.Municipio} ${item.Fuente}`,
					},
				});
				break;
			case 4: //electoralabogados
				this.setState({
					setValidaciones: {
						...DataValidacion,
						Tipo: 'Validaciones',
						id: this.state.data.dataCandidato.candidato.id,
						NombreCompleto: this.state.data.Candidato,
						Propone: this.state.data.QuienPropone,
						electoralrg: `${item.NombreCompleto} ${item.Info} ${item.Municipio} ${item.Fuente}`,
					},
				});
				break;
			case 5: //electoralabogados
				this.setState({
					setValidaciones: {
						...DataValidacion,
						Tipo: 'Validaciones',
						id: this.state.data.dataCandidato.candidato.id,
						NombreCompleto: this.state.data.Candidato,
						Propone: this.state.data.QuienPropone,
						estpromocion: `${item.NombreCompleto} ${item.Info} ${item.Municipio} ${item.Fuente}`,
					},
				});
				break;

			case 6: //electoralabogados
				this.setState({
					setValidaciones: {
						...DataValidacion,
						Tipo: 'Validaciones',
						id: this.state.data.dataCandidato.candidato.id,
						NombreCompleto: this.state.data.Candidato,
						Propone: this.state.data.QuienPropone,
						morenagto: `${item.NombreCompleto} ${item.Info} ${item.Municipio} ${item.Fuente}`,
					},
				});
				break;
			case 7: //electoralabogados
				this.setState({
					setValidaciones: {
						...DataValidacion,
						Tipo: 'Validaciones',
						id: this.state.data.dataCandidato.candidato.id,
						NombreCompleto: this.state.data.Candidato,
						Propone: this.state.data.QuienPropone,
						pangto: `${item.NombreCompleto} ${item.Info} ${item.Municipio} ${item.Fuente}`,
					},
				});
				break;
			case 8: //electoralabogados
				this.setState({
					setValidaciones: {
						...DataValidacion,
						Tipo: 'Validaciones',
						id: this.state.data.dataCandidato.candidato.id,
						NombreCompleto: this.state.data.Candidato,
						Propone: this.state.data.QuienPropone,
						prdgto: `${item.NombreCompleto} ${item.Info} ${item.Municipio} ${item.Fuente}`,
					},
				});
				break;
			case 9: //electoralabogados
				this.setState({
					setValidaciones: {
						...DataValidacion,
						Tipo: 'Validaciones',
						id: this.state.data.dataCandidato.candidato.id,
						NombreCompleto: this.state.data.Candidato,
						Propone: this.state.data.QuienPropone,
						prigto: `${item.NombreCompleto} ${item.Info} ${item.Municipio} ${item.Fuente}`,
					},
				});
				break;
			case 10: //electoralabogados
				this.setState({
					setValidaciones: {
						...DataValidacion,
						Tipo: 'Validaciones',
						id: this.state.data.dataCandidato.candidato.id,
						NombreCompleto: this.state.data.Candidato,
						Propone: this.state.data.QuienPropone,
						pvemgto: `${item.NombreCompleto} ${item.Info} ${item.Municipio} ${item.Fuente}`,
					},
				});
				break;
			case 11: //electoralabogados
				this.setState({
					setValidaciones: {
						...DataValidacion,
						Tipo: 'Validaciones',
						id: this.state.data.dataCandidato.candidato.id,
						NombreCompleto: this.state.data.Candidato,
						Propone: this.state.data.QuienPropone,
						vota: `${item.NombreCompleto} ${item.Info} ${item.Municipio} ${item.Fuente}`,
					},
				});
				break;
			case 12: //electoralabogados
				this.setState({
					setValidaciones: {
						...DataValidacion,
						Tipo: 'Validaciones',
						id: this.state.data.dataCandidato.candidato.id,
						NombreCompleto: this.state.data.Candidato,
						Propone: this.state.data.QuienPropone,
						plantilla: `${item.NombreCompleto} ${item.Info} ${item.Municipio} ${item.Fuente}`,
					},
				});
				break;
			case 13: //electoralabogados
				this.setState({
					setValidaciones: {
						...DataValidacion,
						Tipo: 'Validaciones',
						id: this.state.data.dataCandidato.candidato.id,
						NombreCompleto: this.state.data.Candidato,
						Propone: this.state.data.QuienPropone,
						isEspecial: `${item.NombreCompleto} ${item.Info} ${item.Municipio} ${item.Fuente}`,
					},
				});
				break;
			case 14: //electoralabogados
				this.setState({
					setValidaciones: {
						...DataValidacion,
						Tipo: 'Validaciones',
						id: this.state.data.dataCandidato.candidato.id,
						NombreCompleto: this.state.data.Candidato,
						Propone: this.state.data.QuienPropone,
						osc: `${item.NombreCompleto} ${item.Info} ${item.Municipio} ${item.Fuente}`,
					},
				});
				break;
			case 15: //electoralabogados
				this.setState({
					setValidaciones: {
						...DataValidacion,
						Tipo: 'Validaciones',
						id: this.state.data.dataCandidato.candidato.id,
						NombreCompleto: this.state.data.Candidato,
						Propone: this.state.data.QuienPropone,
						rcrgine: `${item.NombreCompleto} ${item.Info} ${item.Municipio} ${item.Fuente}`,
					},
				});
				break;
				case 16: //electoralabogados
				this.setState({
					setValidaciones: {
						...DataValidacion,
						Tipo: 'Validaciones',
						id: this.state.data.dataCandidato.candidato.id,
						NombreCompleto: this.state.data.Candidato,
						Propone: this.state.data.QuienPropone,
						movimientos: `${item.NombreCompleto} ${item.Info} ${item.Municipio} ${item.Fuente}`,
					},
				});
				break;

			default:
				break;
		}

		this.encontrado(this.state.Current);
	};

	updateMovimiento = (idMovimiento, idStatus) => {
		const token = global.access_token; //localstorage

		const formData = new FormData();
		formData.append('idMovimientoStatus', idStatus);
		formData.append('id', idMovimiento);

		fetch(URL_PUBLICA+`validarMovimiento`, {
			method: 'POST',
			headers: new Headers({ Authorization: `Bearer ${token}` }),
			body: formData,
		})
			.then(response => {
				switch (response.status) {
					case 200:
						console.log('==>Error Status: ', response.status);
						break;

					default:
						console.log('==>Unknow Status: ', response.status);
						/*
                    this.props.history.push({
                        pathname: '/logout',
                        state: {token},
                        message: 'Su cuenta ya expiro'
                    });
                    */
						return;
				}

				if (response.ok) {
					return response.json();
				}
				throw new Error('Ocurrio un error: obtenerDatosCandidatos: ', response.statusText);
			})
			.then(items => {
				console.log('Generar Archivo de PDF');
				this.updateValidaciones();
				return items;
			})
			.catch(e => {
				console.log('error: ', e.message);
				return e.message;
			});
	};

	encontrado = Current => {
		console.log('=>Current: ', Current);
		console.log('=>Current RS: ', catRS);
		console.log('=>Current No Auth: ', catRS[Current.id]);
		console.log('=>states: ', this.state);
		console.log('=>states id: ', this.state.data.dataCandidato.id);

		const formData = new FormData();
		formData.append('id', this.state.data.dataCandidato.candidato.id);
		formData.append(catRS[Current.id], 1);

		if (Current.id === 16) {
			formData.append('idValidado', 1);
		}

		for (var pair of formData.entries()) {
			console.log('===> ' + pair[0] + ', ' + pair[1]);
		}

		const token = global.access_token; //localstorage

		fetch(URL_PUBLICA+'validarCandidatos', {
			method: 'POST',
			headers: new Headers({ Authorization: `Bearer ${token}` }),
			body: formData,
		})
			.then(response => {
				console.log('==>Response: ', response);
				if (response.ok) {
					return response.json();
				}
				throw new Error('Login inválido');
			})
			.then(results => {
				console.log('==>R: ', results);
				const id = this.state.Current.id + 1;

				if (this.state.Current.id < 15) {
					this.setState({
						Current: { id, Current: infoRS[id] },
						results: this.state.InfoData[id].data.map((prop, key) => {
							console.log('==>Props: ', prop);
							return {
								id: key,
								key: key,
								btnNombre: (
									<Button
										className="btn-link"
										color="primary"
										onClick={() => {
											let obj = this.state.results.find(o => o.key === key);
											this.setEncontrado(obj);
										}}
									>
										{prop.nombreCompleto}
									</Button>
								),
								NombreCompleto: prop.nombreCompleto || '',
								Municipio: prop.municipio || '',
								Info: prop.Info || '',
								Fuente: infoRS[id],
								data: prop,
							};
						}),
						step: id,
					});
				} else {
					console.log('this.state.Current.id ==14: encontrado');
					const UpdateMovmiento = this.updateMovimiento(this.state.data.id, 3);
					console.log('==>UpdateMovmiento: ', UpdateMovmiento);
					this.setState({ step: id, Current: { id, Current: infoRS[id] } });
					Notify(2, 'tc', `Se concluyo las validaciones!`, this.refs);
				}
			})
			.catch(e => {
				this.setState({ message: e.message });
				Notify(3, 'tc', `Error: ${e.message}`, this.refs);
			});
	};

	noencontrado = Current => {
		console.log('=>No Encontrado: ', this.state.setValidaciones);

		this.setState({
			setValidaciones: {
				...this.state.setValidaciones,
				Tipo: 'Validaciones',
				id: this.state.data.dataCandidato.candidato.id,
				NombreCompleto: this.state.data.Candidato,
				Propone: this.state.data.QuienPropone,
			},
		});

		console.log('=>Current: ', Current);
		console.log('=>Current RS: ', catRS);
		console.log('=>Current No Auth: ', catRS[Current.id]);
		console.log('=>states: ', this.state);
		console.log('=>states id: ', this.state.data.dataCandidato.id);

		const formData = new FormData();
		formData.append('id', this.state.data.dataCandidato.candidato.id);
		formData.append(catRS[Current.id], 2);

		if (Current.id === 16) {
			formData.append('idValidado', 1);
		}

		for (var pair of formData.entries()) {
			console.log(`===>[${Current.id}] ' ${pair[0]} ', '  ${pair[1]}`);
		}

		const token = global.access_token; //localstorage

		fetch(URL_PUBLICA+'validarCandidatos', {
			method: 'POST',
			headers: new Headers({ Authorization: `Bearer ${token}` }),
			body: formData,
		})
			.then(response => {
				if (response.ok) {
					return response.json();
				}
				throw new Error('Login inválido');
			})
			.then(results => {
				console.log('==>R: ', results);
				const id = this.state.Current.id + 1;
				console.log('==>id: ', id);

				if (id < 16) {
					console.log('=> Entro Current.id<16', this.state.Current.id);

					this.setState({
						Current: { id, Current: infoRS[id] },
						results: this.state.InfoData[id].data.map((prop, key) => {
							console.log('==>Props: ', prop);
							return {
								id: key,
								key: key,
								btnNombre: (
									<Button
										className="btn-link"
										color="primary"
										onClick={() => {
											let obj = this.state.results.find(o => o.key === key);
											this.setEncontrado(obj);
										}}
									>
										{prop.nombreCompleto}
									</Button>
								),
								NombreCompleto: prop.nombreCompleto || '',
								Municipio: prop.municipio || '',
								Info: prop.Info || '',
								Fuente: infoRS[id],
								data: prop,
							};
						}),
						step: id,
					});
				} else {
					console.log('=> Else id = 16', this.state.Current.id);
					const UpdateMovmiento = this.updateMovimiento(this.state.data.id, 3);

					console.log('==>UpdateMovmiento: ', UpdateMovmiento);
					this.setState({ step: id, Current: { id, Current: infoRS[id] } });
					Notify(2, 'tc', `Se concluyo las validaciones!`, this.refs);
				}
			})
			.catch(e => {
				this.setState({ message: e.message });
				Notify(3, 'tc', `Error enviadno datos: ${e.message}`, this.refs);
			});
	};

	render() {
		console.log('==> State validaciones: ', this.state);
		global = getVars('Token');

		const Steps = [
			{
				title: '1',
				onClick: e => {
					e.preventDefault();
					console.log('onClick', 1);
					this.setStep(e);
				},
			},
			{
				title: '2',
				onClick: e => {
					e.preventDefault();
					console.log('onClick', 2);
					this.setStep(e);
				},
			},
			{
				title: '3',
				onClick: e => {
					e.preventDefault();
					console.log('onClick', 3);
					this.setStep(e);
				},
			},
			{
				title: '4',
				onClick: e => {
					e.preventDefault();
					console.log('onClick', 4);
					this.setStep(e);
				},
			},
			{
				title: '5',
				onClick: e => {
					e.preventDefault();
					console.log('onClick', 5);
					this.setStep(e);
				},
			},
			{ title: '6' },
			{ title: '7' },
			{ title: '8' },
			{ title: '9' },
			{ title: '10' },
			{ title: '11' },
			{ title: '12' },
			{ title: '13' },
			{ title: '14' },
			{ title: '15' },
			{ title: '16' },
			{ title: '17' },
		];

		console.log('==> Steps: ', Steps);

		const { dataCandidato } = this.state.data;
		const { loading, showButtons, results, step } = this.state;
		const disEnc = this.state.results.length > 0 ? false : true;

		return (
			<div>
				<NotificationAlert ref="notificationAlert" />
				<Row>
					<span className="text-ltr">
						<Button className="link" color="info" onClick={this.props.setBack}>
							<span className="btn-label">
								<i className="nc-icon nc-minimal-left"></i>
							</span>
							Regresar
						</Button>
					</span>
				</Row>
				
				<Row>
					<Col md="4">
						<Card>
							<CardHeader>
								<h5 className="title">Movimiento</h5>
								<h6 className="title">{dataCandidato.movimiento.label}</h6>
							</CardHeader>
							<CardBody>
								<ul className="list-unstyled team-members">
									<li>
										<Row>
											<Col md="12" xs="12">
												<span className="text-muted">
													<small>Nombre</small>
												</span>
												<br />
												{dataCandidato.movimiento.value !== 4
													? `${dataCandidato.candidato.Nombre} ${dataCandidato.candidato.Paterno} ${dataCandidato.candidato.Materno}`
													: `${dataCandidato.candidato.NombreCompleto}`}{' '}
												<br />
											</Col>
										</Row>
									</li>
									<li>
										<Row>
											<Col md="12" xs="12">
												<span className="text-muted">
													<small>Secretaria</small>
												</span>
												<br />
												{dataCandidato.plaza.Dependencia} <br />
											</Col>
										</Row>
									</li>
									<li>
										<Row>
											<Col md="12" xs="12">
												<span className="text-muted">
													<small>Tipo de Plaza</small>
												</span>
												<br />
												{dataCandidato.plaza.Tipo}
											</Col>
										</Row>
									</li>
									<li>
										<Row>
											<Col md="12" xs="12">
												<span className="text-muted">
													<small>Número Serie</small>
												</span>
												<br />
												{dataCandidato.plaza.NumSerie} <br />
											</Col>
										</Row>
									</li>
									<li>
										<Row>
											<Col md="12" xs="12">
												<span className="text-muted">
													<small>Puesto</small>
												</span>
												<br />
												{dataCandidato.plaza.PuestoFuncional} <br />
											</Col>
										</Row>
									</li>
									<li>
										<Row>
											<Col md="12" xs="12">
												<span className="text-muted">
													<small>Nivel</small>
												</span>
												<br />
												Nivel {dataCandidato.plaza.Nivel}
											</Col>
										</Row>
									</li>
									<li>
										<Row>
											<Col md="12" xs="12">
												<span className="text-muted">
													<small>Municipio</small>
												</span>
												<br />
												{dataCandidato.plaza.Municipio}
											</Col>
										</Row>
									</li>
									<li>
										<Row>
											<Col md="12" xs="12">
												<span className="text-muted">
													<small>Fecha para Movimiento</small>
												</span>
												<br />
												{dataCandidato.FechaMovimiento}
											</Col>
										</Row>
									</li>
								</ul>
							</CardBody>
						</Card>
					</Col>
					<Col md="8">
						<Card>
							<CardHeader>
								<h5 className="title">Propuesta</h5>
							</CardHeader>
							<CardBody>
								<Row>
									<Col>
										<Stepper
											steps={Steps}
											activeStep={this.state.step}
											onClick={e => this.setStep(e)}
										/>
									</Col>
								</Row>
								{!loading && (
									<div>
										<Row>
											<Col>
												{step < 17 && (
													<h4>
														Paso {this.state.Current.id + 1}. {this.state.Current.Current}{' '}
														mostrando {results.length} resultados
													</h4>
												)}
												{step > 15 && <h4>Registro validado!</h4>}
											</Col>
										</Row>
										<Row>
											<Col>
												<ReactTable
													data={results}
													filterable
													showPagination={true}
													showPageJump={false}
													previousText="Anterior"
													nextText="Siguiente"
													canNextFromData={true}
													defaultPageSize={10}
													total={this.state.total}
													pages={Math.floor(this.state.total / 10)}
													columns={[
														{
															Header: 'Nombre',
															id: 'NombreCompleto',
															accessor: d => d.btnNombre,
															filterMethod: (filter, rows) =>
																matchSorter(rows, filter.value, {
																	keys: ['NombreCompleto'],
																}),
															filterAll: true,
														},
														{
															Header: 'Municipio',
															id: 'Municipio',
															accessor: d => d.Municipio,
															filterMethod: (filter, rows) =>
																matchSorter(rows, filter.value, {
																	keys: ['Municipio'],
																}),
															filterAll: true,
														},
														{
															Header: 'Info',
															id: 'Info',
															accessor: d => d.Info,
															filterMethod: (filter, rows) =>
																matchSorter(rows, filter.value, { keys: ['Info'] }),
															filterAll: true,
														},
														{
															Header: 'Fuente',
															id: 'Fuente',
															accessor: d => d.Fuente,
															filterAll: false,
														},
													]}
													PaginationComponent={Pagination}
													/*
                                        You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                                        */
													className="-striped -highlight primary-pagination"
												/>
											</Col>
										</Row>
									</div>
								)}
								{loading && <PushSpinner size={30} color="#686769" loading={loading} />}
							</CardBody>
							{showButtons && step < 16 && (
								<CardFooter>
									<div className="form-row  text-center">
										<Col>
											<Button
												disabled={disEnc}
												onClick={() => this.encontrado(this.state.Current)}
												className="ml-3"
												color="info"
											>
												Encontrado
											</Button>
										</Col>
										<Col>
											<Button
												onClick={() => this.noencontrado(this.state.Current)}
												className="ml-3"
												color="danger"
											>
												No Encontrado
											</Button>
										</Col>
									</div>
								</CardFooter>
							)}
						</Card>
					</Col>
				</Row>
			
			</div>
		);
	}
}

export default Validaciones;
