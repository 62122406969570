import React from 'react';
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	CardTitle,
	FormGroup,
	Form,
	Input,
	Row,
	Col,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	TabContent,
	TabPane,
	NavItem,
	NavLink,
	Nav,
} from 'reactstrap';
import Select from 'react-select';
import NotificationAlert from 'react-notification-alert';
import ReactDatetime from 'react-datetime';
import { CircleSpinner } from 'react-spinners-kit';
import moment from 'moment';

import EjecutarApi from '../../data/Consultas';
import EjecutarGetApi from '../../data/ConsultasGET';
import Notify from '../../data/Notificacion';

import DashboardNavbar from '../../components/Navbars/DashboardNavbar.jsx';
import Footer from '../../components/Footer/Footer.jsx';
import Sidebar from '../../components/Sidebar/MenuSidebar.jsx';
// import UploadFile from '../components/Dropzone/index';
import UploadFoto from '../../components/CustomUpload/PictureUpload';

import { getVars,URL_PUBLICA } from '../../GlobalStore';

var global = '';
class UserProfile extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			backgroundColor: 'black',
			activeColor: 'info',
			sidebarMini: false,
			loading: false,
			saving: false,
			isSaving: false,
			showStatics: false,
			pageTabs: 'perfil',
			pageDireccion: 'vive',
			catMunicipios: [],
			Passwd: '',
			RePasswd: '',
			id: '',
			Foto: 'http://44.226.43.33/api/api/public/fotos/default.png',
			Nombre: '',
			Paterno: '',
			Materno: '',
			NombreCompleto: '',
			CPVive: '',
			CPVota: '',
			CalleVive: '',
			CalleVota: '',
			Celular: '',
			ColoniaVive: '',
			ColoniaVota: '',
			Email: '',
			FechaNacimiento: '',
			INE: '',
			MunicipioVive: {
				value: '',
				label: '',
			},
			MunicipioVota: {
				value: '',
				label: '',
			},
			Nivel: {
				value: '',
				label: '',
			},
			NumExtVive: '',
			NumExtVota: '',
			NumIntVive: '',
			NumIntVota: '',
			PinCode: '',
			SeccionVota: '',
			Sexo: {
				value: 1,
				label: 'No Definido',
			},
			TelMensajes: '',
			Telefono: '',
			idVoluntario: '',
		};
	}

	componentDidMount() {
		this.setState({ loading: true });

		const token = global.access_token;

		const data = {
			page: 0,
			tipo: 'and',
			pageSize: 10,
			sorted: [],
			filtered: [],
			id: global.user.id,
		};

		EjecutarApi(data, 'getProfile', token)
			.then(function(res) {
				return res;
			})
			.then(items => {
				console.log('===> Data Validaciones: ', items);

				this.setState({ ...items.data[0] });

				this.setState({ loading: false });
			})
			.catch(e => {
				console.log('=Error[getME]: ', e);
				Notify(3, 'tc', `Error en getME: ${e.error}`, this.refs);
				this.setState({ loading: false });
			});

		const Catalogo = [
			{
				id: 'option',
				value: 'municipios',
			},
		];

		this.setState({ loading: true });

		EjecutarGetApi(Catalogo, 'catalogos', token)
			.then(function(res) {
				return res;
			})
			.then(items => {
				console.log('===> Data Municipios: ', items.data);
				this.setState({ catMunicipios: items.data });
				this.setState({ loading: false });
			})
			.catch(e => {
				console.log('=Error[catalogos]: ', e);
				Notify(3, 'tc', `Error en catalogos: ${e.error}`, this.refs);
				this.setState({ loading: false });
			});
	}

	handleMiniClick = () => {
		if (document.body.classList.contains('sidebar-mini')) {
			this.setState({ sidebarMini: false });
		} else {
			this.setState({ sidebarMini: true });
		}
		document.body.classList.toggle('sidebar-mini');
	};

	toggle() {
		this.setState({ modal: !this.state.modal });
	}

	changePasswd = () => {
		const { Passwd, RePasswd } = this.state;

		if (Passwd.length < 8) {
			Notify(3, 'tc', 'La contraseña debera ser mayor a 7 digitos', this.refs);
			return true;
		}

		if (Passwd !== RePasswd) {
			Notify(3, 'tc', 'Las contraseñas no coinciden', this.refs);
			return true;
		}

		const token = global.access_token;

		const data = {
			password: this.state.Passwd,
			id: global.user.id,
		};

		this.setState({ saving: true });

		EjecutarApi(data, 'changePassword', token)
			.then(function(res) {
				return res;
			})
			.then(results => {
				console.log('===> Change Passwd: ', results);
				Notify(2, 'tc', `La contraseña se cambio correctamente!`, this.refs);
				this.setState({ saving: false });
			})
			.catch(e => {
				console.log('=Error[getME]: ', e);
				Notify(3, 'tc', `Error en getME: ${e.error}`, this.refs);
				this.setState({ saving: false });
			});
	};

	cambiarFoto = () => {
		this.setState({ modal: !this.state.modal });

		var formData = new FormData();
		formData.append('Foto', this.state.subirFoto[0], this.state.subirFoto[0].name);
		formData.append('id', global.user.id);

		for (var pair of formData.entries()) {
			console.log(pair[0] + ', ' + pair[1]);
		}

		const token = global.access_token;

		const requestInfo = {
			method: 'POST',
			body: formData,
			headers: new Headers({
				Authorization: `Bearer ${token}`,
			}),
		};

		fetch(URL_PUBLICA+'cargarFoto', requestInfo)
			.then(response => {
				if (response.ok) {
					return response.json();
				}
				throw new Error('Post');
			})
			.then(Results => {
				console.log('==>Results Foto: ', Results);

				Notify(2, 'tc', 'Se cargo el archivo correctamente!', this.refs);

				this.setState({ isloading: false });
			})
			.catch(e => {
				console.log('=>Error: ', e.message);
				Notify(3, 'tc', `Error al cargar el archivo: ${e.message}`, this.refs);
				this.setState({ isloading: false });
			});
	};

	setFoto = Foto => {
		console.log('=>Foto: ', Foto);
		this.setState({ subirFoto: Foto });
	};

	setFechaCumple = e => {
		this.setState({ FechaNacimiento: moment(e._d).format('YYYY-MM-DD') });
	};

	setSexo = e => {
		console.log('=>EstadoCivil: ', e);

		this.setState({
			Sexo: {
				value: e.value,
				label: e.label,
			},
		});
	};

	setProfile = () => {
		if (this.state.Nombre.length < 1) {
			Notify(3, 'tc', `Error Nombre es obligatorio`, this.refs);
			return true;
		}

		if (this.state.Paterno.length < 1) {
			Notify(3, 'tc', `Error Paterno es obligatorio`, this.refs);
			return true;
		}

		this.setState({ isSaving: true });

		const {
			id,
			Nombre,
			Paterno,
			Materno,
			CPVive,
			CPVota,
			CalleVive,
			CalleVota,
			Celular,
			ColoniaVive,
			ColoniaVota,
			Email,
			FechaNacimiento,
			MunicipioVive,
			MunicipioVota,
			Nivel,
			NumExtVive,
			NumExtVota,
			NumIntVive,
			NumIntVota,
			SeccionVota,
			Sexo,
			TelMensajes,
		} = this.state;

		const data = {
			id,
			Nombre,
			Paterno,
			Materno,
			CPVive,
			CPVota,
			CalleVive,
			CalleVota,
			Celular,
			ColoniaVive,
			ColoniaVota,
			Email,
			FechaNacimiento,
			MunicipioVive: MunicipioVive.value,
			MunicipioVota: MunicipioVota.value,
			Nivel: Nivel.value,
			NumExtVive,
			NumExtVota,
			NumIntVive,
			NumIntVota,
			SeccionVota,
			Sexo: Sexo.value,
			TelMensajes,
		};

		const token = global.access_token;

		EjecutarApi(data, 'setProfile', token)
			.then(function(res) {
				return res;
			})
			.then(items => {
				console.log('===> Update Profile: ', items);
				this.setState({ isSaving: false });
			})
			.catch(e => {
				console.log('=Error[getME]: ', e);
				Notify(3, 'tc', `Error en getME: ${e.error}`, this.refs);
				this.setState({ isSaving: false });
			});
	};

	render() {
		global = getVars('Token');

		const {
			Foto,
			Nombre,
			Paterno,
			Materno,
			CPVive,
			CPVota,
			CalleVive,
			CalleVota,
			Celular,
			ColoniaVive,
			ColoniaVota,
			Email,
			MunicipioVive,
			MunicipioVota,
			Nivel,
			NumExtVive,
			NumExtVota,
			NumIntVive,
			NumIntVota,
			SeccionVota,
			Sexo,
			TelMensajes,
			catMunicipios,
			saving,
			isSaving,
		} = this.state;
		console.log('Mis Props: ', this.props);
		console.log('=>Mis States: ', this.state);

		return (
			<div className="wrapper">
				<Sidebar {...this.props} bgColor={this.state.backgroundColor} activeColor={this.state.activeColor} />
				<div className="main-panel" ref="mainPanel">
					<DashboardNavbar {...this.props} handleMiniClick={this.handleMiniClick} />
					<NotificationAlert ref="notificationAlert" />
					<div className="content">
						<Modal isOpen={this.state.modal} toggle={() => this.setState({ modal: !this.state.modal })}>
							<ModalHeader toggle={() => this.setState({ modal: !this.state.modal })}>
								Cambiar foto de perfil
							</ModalHeader>
							<ModalBody>
								<UploadFoto setFoto={this.setFoto} title="Cargar foto" />
							</ModalBody>
							<ModalFooter>
								<Button color="primary" onClick={this.cambiarFoto}>
									Cargar
								</Button>{' '}
								<Button color="secondary" onClick={() => this.setState({ modal: !this.state.modal })}>
									Cancelar
								</Button>
							</ModalFooter>
						</Modal>
						<Row>
							<Col md="4">
								<Card className="card-user">
									<div className="image">
										<img alt="..." src={require('assets/img/bg/damir-bosnjak.jpg')} />
									</div>
									<CardBody>
										<div className="author">
											<a
												href="#chris"
												onClick={() => this.setState({ modal: !this.state.modal })}
											>
												<img alt="..." className="avatar border-gray" src={`http://${Foto}`} />
												<h5 className="title">{`${Nombre} ${Paterno} ${Materno}`}</h5>
											</a>
											<p className="description">{Nivel.label}</p>
										</div>
									</CardBody>
									<CardFooter>
										<hr />
										<div className="button-container">
											<Row>
												{SeccionVota && (
													<Col className="ml-auto" lg="6" md="6" xs="6">
														<h5>
															Sección <br />
															<small>{SeccionVota}</small>
														</h5>
													</Col>
												)}

												{MunicipioVive.label && (
													<Col className="ml-auto" lg="6" md="6" xs="6">
														<h5>
															Municipio <br />
															<small>{MunicipioVive.label}</small>
														</h5>
													</Col>
												)}
											</Row>
										</div>
									</CardFooter>
								</Card>
								{this.state.showStatics && (
									<Card>
										<CardHeader>
											<CardTitle tag="h4">Estadística</CardTitle>
										</CardHeader>
										<CardBody>
											<ul className="list-unstyled team-members">
												<li>
													<Row>
														<Col md="7" xs="7">
															Ingresos <br />
															<span className="text-primary">
																<small>0</small>
															</span>
														</Col>
														<Col className="text-right" md="5" xs="5">
															<Button
																className="btn-round btn-icon"
																color="success"
																outline
																size="sm"
															>
																<i className="fa fa-envelope" />
															</Button>
														</Col>
													</Row>
												</li>
												<li>
													<Row>
														<Col md="7" xs="7">
															Promoción <br />
															<span className="text-success">
																<small>0</small>
															</span>
														</Col>
														<Col className="text-right" md="5" xs="5">
															<Button
																className="btn-round btn-icon"
																color="success"
																outline
																size="sm"
															>
																<i className="fa fa-envelope" />
															</Button>
														</Col>
													</Row>
												</li>
												<li>
													<Row>
														<Col className="col-ms-7" xs="7">
															Bajas <br />
															<span className="text-danger">
																<small>0</small>
															</span>
														</Col>
														<Col className="text-right" md="5" xs="5">
															<Button
																className="btn-round btn-icon"
																color="success"
																outline
																size="sm"
															>
																<i className="fa fa-envelope" />
															</Button>
														</Col>
													</Row>
												</li>
											</ul>
										</CardBody>
									</Card>
								)}
							</Col>
							<Col md="8">
								<Card>
									<CardHeader>
										<h5 className="title">Editar Perfil</h5>
									</CardHeader>
									<CardBody>
										<Nav className="nav-pills-primary nav-pills-icons" role="tablist">
											<NavItem>
												<NavLink
													data-toggle="tab"
													href="#chris"
													role="tablist"
													className={this.state.pageTabs === 'perfil' ? 'active' : ''}
													onClick={() => this.setState({ pageTabs: 'perfil' })}
												>
													<i className="now-ui-icons objects_umbrella-13" />
													Datos Personales
												</NavLink>
											</NavItem>
											<NavItem>
												<NavLink
													data-toggle="tab"
													href="#chris"
													role="tablist"
													className={this.state.pageTabs === 'contraseña' ? 'active' : ''}
													onClick={() => this.setState({ pageTabs: 'contraseña' })}
												>
													<i className="now-ui-icons shopping_shop" />
													Contraseña
												</NavLink>
											</NavItem>
										</Nav>
										<br></br>
										<TabContent
											className="text-center"
											id="my-tab-content"
											activeTab={this.state.pageTabs}
										>
											<TabPane tabId="perfil" role="tabpanel">
												<Form>
													<Row>
														<Col className="pr-1" md="3">
															<FormGroup>
																<label>Usuario</label>
																<Input
																	defaultValue={Email}
																	disabled
																	placeholder="Usuario"
																	type="text"
																/>
															</FormGroup>
														</Col>
														<Col className="px-1" md="3">
															<FormGroup>
																<label>Nombre</label>
																<Input
																	defaultValue={Nombre}
																	placeholder="Nombre"
																	type="text"
																	onChange={e =>
																		this.setState({ Nombre: e.target.value })
																	}
																/>
															</FormGroup>
														</Col>
														<Col className="px-1" md="2">
															<FormGroup>
																<label>Paterno</label>
																<Input
																	defaultValue={Paterno}
																	placeholder="Paterno"
																	type="text"
																	onChange={e =>
																		this.setState({ Paterno: e.target.value })
																	}
																/>
															</FormGroup>
														</Col>
														<Col className="px-1" md="2">
															<FormGroup>
																<label>Materno</label>
																<Input
																	defaultValue={Materno}
																	placeholder="Materno"
																	type="text"
																	onChange={e =>
																		this.setState({ Materno: e.target.value })
																	}
																/>
															</FormGroup>
														</Col>
													</Row>
													<Row>
														<Col className="px-1" md="3">
															<FormGroup>
																<label>Sexo</label>
																<Select
																	className="react-select primary"
																	classNamePrefix="react-select"
																	name="Sexo"
																	value={Sexo}
																	onChange={this.setSexo}
																	options={[
																		{ value: 1, label: 'No Definido' },
																		{ value: 2, label: 'Hombre' },
																		{ value: 3, label: 'Mujer' },
																	]}
																	placeholder="Seleccione Sexo"
																/>
															</FormGroup>
														</Col>
														<Col className="pr-1" md="3">
															<FormGroup>
																<label>FechaNacimiento</label>
																<ReactDatetime
																	dateFormat="YYYY-MM-DD"
																	timeFormat={false}
																	closeOnSelect
																	onChange={this.setFechaCumple}
																	inputProps={{
																		className: 'form-control',
																		placeholder: 'Fecha Nacimiento',
																	}}
																/>
															</FormGroup>
														</Col>

														<Col className="px-1" md="2">
															<FormGroup>
																<label>Celular</label>
																<Input
																	defaultValue={Celular}
																	placeholder="Celular"
																	type="text"
																	onChange={e =>
																		this.setState({ Celular: e.target.value })
																	}
																/>
															</FormGroup>
														</Col>
														<Col className="px-1" md="2">
															<FormGroup>
																<label>Tel Mensajes</label>
																<Input
																	defaultValue={TelMensajes}
																	placeholder="Tel"
																	type="text"
																	onChange={e =>
																		this.setState({ TelMensajes: e.target.value })
																	}
																/>
															</FormGroup>
														</Col>
													</Row>

													<Row>
														<Col className="text-center">
															<br></br>
															<Nav
																className="nav-pills-primary nav-pills-icons justify-content-center"
																pills
																role="tablist"
															>
																<NavItem>
																	<NavLink
																		data-toggle="tab"
																		href="#chris"
																		role="tablist"
																		className={
																			this.state.pageDireccion === 'vive'
																				? 'active'
																				: ''
																		}
																		onClick={() =>
																			this.setState({ pageDireccion: 'vive' })
																		}
																	>
																		<i className="now-ui-icons objects_umbrella-13" />
																		Dirección Vive
																	</NavLink>
																</NavItem>
																<NavItem>
																	<NavLink
																		data-toggle="tab"
																		href="#chris"
																		role="tablist"
																		className={
																			this.state.pageDireccion === 'vota'
																				? 'active'
																				: ''
																		}
																		onClick={() =>
																			this.setState({ pageDireccion: 'vota' })
																		}
																	>
																		<i className="now-ui-icons shopping_shop" />
																		Dirección Vota
																	</NavLink>
																</NavItem>
															</Nav>
															<TabContent
																className="text-center"
																id="my-tab-direccion"
																activeTab={this.state.pageDireccion}
															>
																<TabPane
																	tabId="vive"
																	role="tabpanel"
																	className="justify-content-center"
																>
																	<Card>
																		<Row>
																			<Col md="7">
																				<FormGroup>
																					<label>Dirección</label>
																					<Input
																						defaultValue={CalleVive}
																						placeholder="Calle"
																						type="text"
																						onChange={e =>
																							this.setState({
																								CalleVive:
																									e.target.value,
																							})
																						}
																					/>
																				</FormGroup>
																			</Col>
																			<Col md="2">
																				<FormGroup>
																					<label>Num Exterior</label>
																					<Input
																						defaultValue={NumExtVive}
																						placeholder="Num Ext"
																						type="text"
																						onChange={e =>
																							this.setState({
																								NumExtVive:
																									e.target.value,
																							})
																						}
																					/>
																				</FormGroup>
																			</Col>
																			<Col md="2">
																				<FormGroup>
																					<label>Num Interior</label>
																					<Input
																						defaultValue={NumIntVive}
																						placeholder="Num Int"
																						type="text"
																						onChange={e =>
																							this.setState({
																								NumIntVive:
																									e.target.value,
																							})
																						}
																					/>
																				</FormGroup>
																			</Col>
																		</Row>
																		<Row>
																			<Col className="pr-1" md="4">
																				<FormGroup>
																					<label>Colonia</label>
																					<Input
																						defaultValue={ColoniaVive}
																						placeholder="Colonia"
																						type="text"
																						onChange={e =>
																							this.setState({
																								ColoniaVive:
																									e.target.value,
																							})
																						}
																					/>
																				</FormGroup>
																			</Col>
																			<Col className="px-1" md="4">
																				<FormGroup>
																					<label>Municipio</label>
																					<Select
																						className="react-select primary"
																						classNamePrefix="react-select"
																						name="Municipio"
																						value={this.state.MunicipioVive}
																						onChange={value =>
																							this.setState({
																								MunicipioVive: value,
																							})
																						}
																						options={
																							catMunicipios &&
																							catMunicipios.constructor ===
																								Array
																								? catMunicipios
																								: []
																						}
																						placeholder="Seleccione municipio"
																					/>
																				</FormGroup>
																			</Col>
																			<Col className="pl-1" md="2">
																				<FormGroup>
																					<label>CP</label>
																					<Input
																						defaultValue={CPVive}
																						placeholder="CP"
																						type="number"
																						onChange={e =>
																							this.setState({
																								CPVive: e.target.value,
																							})
																						}
																					/>
																				</FormGroup>
																			</Col>
																		</Row>
																		<CardFooter></CardFooter>
																	</Card>
																</TabPane>
																<TabPane tabId="vota" role="tabpanel">
																	<Card>
																		<Row>
																			<Col md="7">
																				<FormGroup>
																					<label>Dirección</label>
																					<Input
																						defaultValue={CalleVota}
																						placeholder="Calle"
																						type="text"
																						onChange={e =>
																							this.setState({
																								CalleVota:
																									e.target.value,
																							})
																						}
																					/>
																				</FormGroup>
																			</Col>
																			<Col md="2">
																				<FormGroup>
																					<label>Num Exterior</label>
																					<Input
																						defaultValue={NumExtVota}
																						placeholder="Num Ext"
																						type="text"
																						onChange={e =>
																							this.setState({
																								NumExtVota:
																									e.target.value,
																							})
																						}
																					/>
																				</FormGroup>
																			</Col>
																			<Col md="2">
																				<FormGroup>
																					<label>Num Interior</label>
																					<Input
																						defaultValue={NumIntVota}
																						placeholder="Num Int"
																						type="text"
																						onChange={e =>
																							this.setState({
																								NumIntVota:
																									e.target.value,
																							})
																						}
																					/>
																				</FormGroup>
																			</Col>
																		</Row>
																		<Row>
																			<Col className="pr-1" md="4">
																				<FormGroup>
																					<label>Colonia</label>
																					<Input
																						defaultValue={ColoniaVota}
																						placeholder="Colonia"
																						type="text"
																						onChange={e =>
																							this.setState({
																								ColoniaVota:
																									e.target.value,
																							})
																						}
																					/>
																				</FormGroup>
																			</Col>
																			<Col className="px-1" md="4">
																				<FormGroup>
																					<label>Municipio</label>
																					<Select
																						className="react-select primary"
																						classNamePrefix="react-select"
																						name="Municipio"
																						value={MunicipioVota}
																						onChange={value =>
																							this.setState({
																								MunicipioVota: value,
																							})
																						}
																						options={
																							catMunicipios &&
																							catMunicipios.constructor ===
																								Array
																								? catMunicipios
																								: []
																						}
																						placeholder="Seleccione municipio"
																					/>
																				</FormGroup>
																			</Col>
																			<Col className="pl-1" md="2">
																				<FormGroup>
																					<label>CP</label>
																					<Input
																						onChange={e =>
																							this.setState({
																								CPVota: e.target.value,
																							})
																						}
																						defaultValue={CPVota}
																						placeholder="CP"
																						type="number"
																					/>
																				</FormGroup>
																			</Col>
																		</Row>
																		<Row>
																			<Col className="pr-1" md="4">
																				<FormGroup>
																					<label>Sección</label>
																					<Input
																						defaultValue={SeccionVota}
																						placeholder="Seccion"
																						type="text"
																						pattern="[0-9]*"
																						maxLength={5}
																						onChange={e =>
																							this.setState({
																								SeccionVota:
																									e.target.value,
																							})
																						}
																					/>
																				</FormGroup>
																			</Col>
																		</Row>
																		<CardFooter></CardFooter>
																	</Card>
																</TabPane>
															</TabContent>
														</Col>
													</Row>
												</Form>
											</TabPane>
											<TabPane tabId="contraseña" role="tabpanel">
												<Form>
													<Row>
														<Col className="pr-1" md="3">
															<FormGroup>
																<label>Contraseña</label>
																<Input
																	placeholder="Password"
																	id="Passwd"
																	type="password"
																	autoComplete="off"
																	onChange={e =>
																		this.setState({ Passwd: e.target.value })
																	}
																/>
															</FormGroup>
														</Col>
													</Row>
													<Row>
														<Col className="pr-1" md="3">
															<FormGroup>
																<label>Repetir Contraseña</label>
																<Input
																	placeholder="Repetir Password"
																	id="Passwd"
																	type="password"
																	autoComplete="off"
																	onChange={e =>
																		this.setState({ RePasswd: e.target.value })
																	}
																/>
															</FormGroup>
														</Col>
													</Row>
													<Row>
														<Col className="pr-1" md="3">
															{saving && (
																<CircleSpinner
																	className="justify-content-center"
																	size={30}
																	color="#686769"
																	loading={saving}
																/>
															)}
															{!saving && (
																<Button
																	block
																	className="btn-round mb-3"
																	color="warning"
																	onClick={this.changePasswd}
																>
																	Guardar
																</Button>
															)}
														</Col>
													</Row>
												</Form>
											</TabPane>
										</TabContent>
									</CardBody>
									<CardFooter className="justify-content-center">
										{isSaving && (
											<CircleSpinner
												className="text-center"
												size={30}
												color="#686769"
												loading={isSaving}
											/>
										)}
										{!isSaving && (
											<Button
												type="submit"
												className="btn-round mb-3"
												color="primary"
												onClick={this.setProfile}
											>
												Actualizar Perfil
											</Button>
										)}
									</CardFooter>
								</Card>
							</Col>
						</Row>
					</div>
					<Footer fluid />
				</div>
			</div>
		);
	}
}

export default UserProfile;
