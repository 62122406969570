import React, { Component } from "react";

import NotificationAlert from "react-notification-alert";
import { CircleSpinner } from "react-spinners-kit";
// reactstrap components
import {
  Row,
  Col
} from "reactstrap";

import EjecutarApi from "../../data/Consultas";
import { getVars } from "../../GlobalStore";

import DashboardNavbar from "../../components/Navbars/DashboardNavbar.jsx";
import Footer from "../../components/Footer/Footer.jsx";
import Sidebar from "../../components/Sidebar/MenuSidebar.jsx";
import SingleLines from "components/ChartDashboard/SingleLines"; 
import ScatterCharts from "components/ChartDashboard/ScatterCharts"; 
import BarChart from "components/ChartDashboard/BarChart"; 
var global = "";

class Dashboard_Movimientos extends Component {
  constructor(props) {
    super(props);
    this.state ={  loadingBar: false,
      seriebarra:{}};
  }

  componentDidMount() {

    let data = {
      filtered: [
        { id: "idMovimientoStatus", value: "1" },
        { id: "isActivo", value: "1" }
      ]
    };

    const token = global.access_token; //localstorage

    EjecutarApi(data, "getPMTotal", token, "POST")
      .then(function(res) {
        return res;
      })
      .then(items => {
        if (items.results) {
          console.log('getPMTotal: ',items)
          let value= {Title:'Dashboard 1',
          Subtitle:'Cruces',
          yAxis:'Total Cruces',
          Series:items.serie};
          console.log('value--> ',value);
          this.setState({ 
            loadingBar: true,
            seriebarra:value
          });
        }
      })
      .catch(e => {
        console.log("error Por Autorizar getPlazaMovimientos: ", e.message);
        this.setState({
          loadingPorAutorizar: false
        });
      });

    
  }
  
  handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      this.setState({ sidebarMini: false });
    } else {
      this.setState({ sidebarMini: true });
    }
    document.body.classList.toggle("sidebar-mini");
  };

  render() {
    global = getVars("Token");
    const{
      loadingBar,
      seriebarra
    }=this.state;
    return (
      <div className="wrapper">
        <Sidebar
          {...this.props}
          bgColor={this.state.backgroundColor}
          activeColor={this.state.activeColor}
        />
        <div className="main-panel" ref="mainPanel">
          <DashboardNavbar
            {...this.props}
            handleMiniClick={this.handleMiniClick}
          />
          <NotificationAlert ref="notificationAlert" />
          <div className="content">
            
            <Row>
            {!loadingBar && (
                              <CircleSpinner
                                className="text-center"
                                size={30}
                                color="#686769"
                                loading={!loadingBar}
                              />
                            )}
              {loadingBar&&(
                  <Col sm={12} md={8} lg={12} >
                    <BarChart 
                      props={this.props}
                      seriebarra={seriebarra}

                       />
                  </Col>)
              }
              
            </Row>
            <Row>
              <Col sm={12} md={8} lg={12} >
                  <SingleLines></SingleLines>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={8} lg={12} >
                <ScatterCharts></ScatterCharts>
              </Col>
            </Row>
          </div>
          <Footer fluid />
        </div>
      </div>
    );
  }
}

export default Dashboard_Movimientos;
