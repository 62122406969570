import React from 'react';
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Row, CardFooter } from 'reactstrap';
import NotificationAlert from 'react-notification-alert';
import ReactTable from 'react-table';
import matchSorter from 'match-sorter';

import EjecutarApi from '../../data/Consultas';
import Notify from '../../data/Notificacion';

import DashboardNavbar from '../../components/Navbars/DashboardNavbar.jsx';
import Footer from '../../components/Footer/Footer.jsx';
import Sidebar from '../../components/Sidebar/MenuSidebar.jsx';

import Pagination from '../components/Paginacion/index2';

import { getVars,URL_PUBLICA } from '../../GlobalStore';

var global = '';

class Oficios extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			backgroundColor: 'black',
			activeColor: 'info',
			sidebarMini: false,
			showData: true,
			PorGenerar: 0,
			total: 0,
			Totales: [],
			Oficios: [],
			data: {},
			dataRS: [],
			dataRsPlus: [],
		};
	}

	componentDidMount() {
		console.log('==> Inicia Oficios');
		const token = global.access_token; //localstorage

		fetch(URL_PUBLICA+'totales?filter=1', {
			headers: new Headers({ Authorization: `Bearer ${token}` }),
		})
			.then(response => {
				switch (response.status) {
					case 200:
						console.log('==>Error Status: ', response.status);
						break;

					default:
						console.log('==>Unknow Status: ', response.status);

						return;
				}

				if (response.ok) {
					return response.json();
				}
				throw new Error('Ocurrio un error: obtenerDatosCandidatos: ', response.statusText);
			})
			.then(items => {
				console.log('===> Data Totales: ', items);

				if (items.results) {
					this.setState({ Totales: items.data });
				}
			})
			.catch(e => {
				console.log('error AutorizarMovimiento: ', e.message);
				Notify(2, 'tc', 'Error al invocar la api: ', e.message);
			});

		const data = {
			page: 0,
			pageSize: 8,
			sorted: {},
			tipo: 'and',
			filtered: {},
		};

		EjecutarApi(data, 'obtenerOficios', token)
			.then(function(res) {
				return res;
			})
			.then(items => {
				console.log('===> Mis Oficios: ', items);
				if (items.results) {
					const dataTable = items.data;
					this.setState({
						Oficios: dataTable.map((prop, key) => {
							return {
								id: prop.id,
								key: key,
								Dependencia: prop.Dependencia || '',
								FechaHora: prop.FechaHora || '',
								Folio: prop.Folio || '',
								NumMovimientos: prop.NumMovimientos || '',
								UserCreate: prop.UserCreate || '',
								btnOficio: (
									<Button
										className="btn-link"
										color="primary"
										onClick={() => {
											let obj = this.state.Oficios.find(o => o.key === key);
											this.getOficio(obj);
										}}
									>
										Descargar
									</Button>
								),
							};
						}),
					});
				}
			})
			.catch(e => {
				console.log('=Error[getME]: ', e);
				Notify(3, 'tc', `Error en getME: ${e.error}`, this.refs);
				this.setState({ loading: false });
			});

		/*
        fetch(URL_PUBLICA+'obtenerOficios', { headers: new Headers({ 'Authorization': `Bearer ${token}`})})
        .then(response => {

            switch(response.status)
            {
                case 200:
                    console.log('==>Error Status: ', response.status);
                break;

                default:
                    
                    console.log('==>Unknow Status: ', response.status);
                   
                return;
            }

          if(response.ok){
            return response.json();
          }
          throw new Error("Ocurrio un error: obtenerDatosCandidatos: ", response.statusText);

        })
        .then(items => {
            console.log('===> Mis Oficios: ', items);
            if(items.results){
              const dataTable=items.data;
              this.setState({ Oficios : dataTable.map((prop, key) => {
                return {
                  id: prop.id,
                  key:key,
                  Dependencia: prop.Dependencia || '',
                  FechaHora: prop.FechaHora || '',
                  Folio: prop.Folio || '',
                  NumMovimientos: prop.NumMovimientos || '',
                  UserCreate: prop.UserCreate || '',
                  btnOficio: (<Button className="btn-link" color="primary" onClick={() => {
                          let obj = this.state.Oficios.find(o => o.key === key);
                          this.getOficio(obj);
                        }}>Descargar</Button>)
                };
              })
              });
            }
           
        })
        .catch(e => {
            console.log('error AutorizarMovimiento: ', e.message);
            Notify(2,"tc","Error al invocar la api: ", e.message);
        });
        */
	}

	getOficio = Item => {
		console.log('=>Descargar Oficio: ', Item);

		const token = global.access_token; //localstorage

		window.location.href = URL_PUBLICA+`descargaOficio?idOficio=${Item.id}&token=${token}`;
	};

	generarOficios = () => {
		const token = global.access_token; //localstorage

		EjecutarApi({}, 'createOficios', token)
			.then(function(res) {
				return res;
			})
			.then(res => {
				console.log('createOficios: ', res);
			})
			.catch(e => {
				console.log('=Error[createOficios]: ', e);
				Notify(3, 'tc', `Error en createOficios: ${e.error}`, this.refs);
				this.setState({ loading: false });
			});
	};

	render() {
		console.log('=>Mis Estados XXXX: ', this.state);

		global = getVars('Token');

		const { Totales, total } = this.state;
		var PorGenerar = 0;
		if (this.state.Totales[3]) PorGenerar = this.state.Totales[3].total || 0;

		console.log('==T: ', Totales);
		console.log('==T: ', Totales[0]);
		return (
			<div className="wrapper">
				<Sidebar {...this.props} bgColor={this.state.backgroundColor} activeColor={this.state.activeColor} />
				<div className="main-panel" ref="mainPanel">
					<DashboardNavbar {...this.props} handleMiniClick={this.handleMiniClick} />
					<NotificationAlert ref="notificationAlert" />
					<div className="content">
						<Row>
							<Col lg="3" md="6" sm="6">
								<Card className="card-stats">
									<CardBody>
										<Row>
											<Col md="4" xs="5">
												<div className="icon-big text-center icon-warning">
													<i className="fa fa-print text-success" />
												</div>
											</Col>
											<Col md="8" xs="7">
												<div className="numbers">
													<p className="card-category">Movimientos</p>
													<CardTitle tag="p">{PorGenerar}</CardTitle>
													<p />
												</div>
											</Col>
										</Row>
									</CardBody>
									<CardFooter>
										<hr />
										<div className="stats">
											<i className="fa fa-calendar-o" />
											{PorGenerar > 0 && (
												<Button onClick={this.generarOficios} className="btn-link btn-info">
													Generar
												</Button>
											)}
										</div>
									</CardFooter>
								</Card>
							</Col>
						</Row>
						<Card>
							<CardHeader>
								<CardTitle tag="h4">Seguimiento de Oficios</CardTitle>
							</CardHeader>
							<CardBody>
								<ReactTable
									data={this.state.Oficios}
									filterable
									columns={[
										{
											Header: 'Folio',
											minWidth: 80,
											id: 'Folio',
											accessor: d => d.Folio,
											filterMethod: (filter, rows) =>
												matchSorter(rows, filter.value, { keys: ['Folio'] }),
											filterAll: true,
										},
										{
											Header: 'Fecha',
											minWidth: 80,
											id: 'FechaHora',
											accessor: d => d.FechaHora,
											filterMethod: (filter, rows) =>
												matchSorter(rows, filter.value, { keys: ['FechaHora'] }),
											filterAll: true,
										},
										{
											Header: 'Movimientos',
											minWidth: 50,
											id: 'NumMovimientos',
											accessor: d => d.NumMovimientos,
											filterMethod: (filter, rows) =>
												matchSorter(rows, filter.value, { keys: ['NumMovimientos'] }),
											filterAll: true,
										},
										{
											Header: 'Dependencia',
											id: 'Dependencia',
											accessor: d => d.Dependencia,
											filterMethod: (filter, rows) =>
												matchSorter(rows, filter.value, { keys: ['Dependencia'] }),
											filterAll: true,
										},
										{
											Header: 'Descargar',
											id: 'btnOficio',
											accessor: d => d.btnOficio,
											filterAll: false,
										},
									]}
									loading={this.state.loading}
									defaultPageSize={10}
									showPagination={true}
									showPaginationTop={false}
									showPaginationBottom={true}
									pageSizeOptions={[5, 10, 20, 25, 50, 100]}
									total={total}
									manual
									canNextFromData={true}
									showTotalPages={true}
									PaginationComponent={Pagination}
									previousText="Anterior"
									nextText="Siguiente"
									ofText="de"
									onFetchData={(statePropuesta, instance) => {
										this.setState({ loading: true });

										console.log('==>TableRS: ', statePropuesta);

										const token = global.access_token;

										const data = {
											page: statePropuesta.page,
											pageSize: statePropuesta.pageSize,
											sorted: statePropuesta.sorted,
											tipo: 'and',
											filtered: statePropuesta.filtered,
										};

										EjecutarApi(data, 'obtenerOficios', token)
											.then(function(res) {
												return res;
											})
											.then(items => {
												console.log('===> Mis Oficios: ', items);
												if (items.results) {
													const dataTable = items.data;
													this.setState({
														total: items.total,
														Oficios: dataTable.map((prop, key) => {
															return {
																id: prop.id,
																key: key,
																Dependencia: prop.Dependencia || '',
																FechaHora: prop.FechaHora || '',
																Folio: prop.Folio || '',
																NumMovimientos: prop.NumMovimientos || '',
																UserCreate: prop.UserCreate || '',
																btnOficio: (
																	<Button
																		className="btn-link"
																		color="primary"
																		onClick={() => {
																			let obj = this.state.Oficios.find(
																				o => o.key === key
																			);
																			this.getOficio(obj);
																		}}
																	>
																		Descargar
																	</Button>
																),
															};
														}),
													});
												}
												this.setState({ loading: false });
											})
											.catch(e => {
												console.log('=Error[getME]: ', e);
												Notify(3, 'tc', `Error en getME: ${e.error}`, this.refs);
												this.setState({ loading: false });
											});
									}}
									/*
                      You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                    */
									className="-striped -highlight primary-pagination"
								/>
							</CardBody>
						</Card>
					</div>
					<Footer fluid />
				</div>
			</div>
		);
	}
}

export default Oficios;
