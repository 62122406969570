var CryptoJS = require("crypto-js");

//const baseURL = "http://127.0.0.1:8000";
// const baseURL = "https://api.tableroestrategico.com";
const baseURL = "https://api.somospuerta.com";

export const URL_PUBLICA = `${baseURL}/api/`;

export const setVars = (id, Value) => {
  //const toSave = JSON.stringify(Value);

  var ciphertext = CryptoJS.AES.encrypt(
    JSON.stringify(Value),
    "c273ncriPt0*-#"
  ).toString();

  sessionStorage.setItem(id, ciphertext);

  var bytes = CryptoJS.AES.decrypt(ciphertext, "c273ncriPt0*-#");
  var originalText = bytes.toString(CryptoJS.enc.Utf8);

  return originalText;

  //console.log(`Decode ID: ${id} => `, JSON.parse(originalText));
};

export const getVars = (id, defaultvar = {}) => {
  var data = sessionStorage.getItem(id);

  var originalText = null;
  if (data !== null) {
    var bytes = CryptoJS.AES.decrypt(data, "c273ncriPt0*-#");
    originalText = bytes.toString(CryptoJS.enc.Utf8);
  }

  return originalText !== null ? JSON.parse(originalText) : defaultvar;

  //console.log(`Decode ID: ${id} => `, JSON.parse(originalText));
};
