import React from 'react'
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    CardFooter,
    Row,
    Col, Input, FormGroup
} from 'reactstrap'
import NotificationAlert from 'react-notification-alert'
import ReactTable from 'react-table'
import { CircleSpinner } from 'react-spinners-kit'
import Select from 'react-select'
import ReactDatetime from 'react-datetime'
import moment from 'moment'
//import history from 'history';

import DashboardNavbar from '../../components/Navbars/DashboardNavbar.jsx'
import Footer from '../../components/Footer/Footer.jsx'
import Sidebar from '../../components/Sidebar/MenuSidebar.jsx'

import EjecutarApi from '../../data/Consultas'
import Notify from '../../data/Notificacion'

import Pagination from '../components/Paginacion/index'
import OficialiaAdd from './oficialiaAdd'
import ModalProponeSolicitud from '../components/Modal/ModalPropone'

import { getVars, URL_PUBLICA } from '../../GlobalStore'
import CardSubtitle from 'reactstrap/lib/CardSubtitle'

var global = ''
var ps = ''
class oficialiaListado extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            backgroundColor: 'black',
            activeColor: 'info',
            loading: false,
            sidebarMini: false,
            showData: true,
            total: 0,
            data: {},
            dataRS: [],
            dataRsPlus: [],
            showAdd: false,
            TotalPendiente: 0,
            TotalOperado: 0,
            TotalConcluido: 0,
            TotalSolicitudes: 0,
            catalogos: {},
            SolicitudDependencia: { label: '', value: 0 },
            SolicitudFolioSolicitud: '',
            SolicitudFechaOficio: '',
            SolicitudFechaRecepcion: '',
            SolicitudFechaCreate: '',
            SolicitudNombreCandidato: '',
            SolicitudEstatus: { label: '', value: 0 },
            SolicitudSecuencial: '',
            Filtro: []
        }
    }

    componentDidMount() {
        this.setState({ loading: true })

        const token = getVars('Token').access_token
        fetch(URL_PUBLICA + 'getDashboard', {
            headers: new Headers({ Authorization: `Bearer ${token}` })
        })
            .then((response) => {

                if (response.ok) {
                    return response.json()
                }
                throw new Error(
                    'Ocurrio un error: getDashboard: ',
                    response.statusText
                )
            })
            .then((response) => {

                if (response.results) {

                    this.setState({
                        loading: false,
                        TotalPendiente: response.data[0].Pendiente,
                        TotalOperado: response.data[0].Operado,
                        TotalConcluido: response.data[0].Concluido,
                        TotalSolicitudes: response.data[0].TotalSolicitudes,
                    })
                }
            })
            .catch((e) => {
                this.setState({ loading: false })


            })

        fetch(URL_PUBLICA + 'getDependenciasSolicitud', {
            headers: new Headers({ Authorization: `Bearer ${token}` })
        })
            .then(function (res) {
                return res.json();
            })
            .then((res) => {

                if (res.results) {
                    this.setState({ loading: false })

                    var tbl = res.data;//{};
                    tbl = res.data.map((item, key) => {
                        return {
                            value: item.value,
                            label: item.label
                        }
                    });


                    this.setState({ catdependencias: tbl });
                }

            })
            .catch((e) => {

                this.setState({ loading: false })
            })
        fetch(URL_PUBLICA + 'getCatalogosSolicitud', {
            headers: new Headers({ Authorization: `Bearer ${token}` }),
        })
            .then((response) => {
                switch (response.status) {
                    case 200:

                        break

                    default:
                        if (navigator.platform.indexOf('Win') > -1) {
                            ps.destroy()
                            document.documentElement.className +=
                                ' perfect-scrollbar-off'
                            document.documentElement.classList.remove(
                                'perfect-scrollbar-on'
                            )
                        }

                        this.props.history.push({
                            pathname: '/logout',
                            state: { token },
                            message: 'Su cuenta ya expiro',
                        })
                        break
                }

                if (response.ok) {
                    return response.json()
                }
                throw new Error(
                    'Ocurrio un error: obtenerDatosCandidatos: ',
                    response.statusText
                )
            })
            .then((items) => {


                this.setState({
                    catEstatus: items.SeguimientoEstatus
                })
            })
            .catch((e) => {
                this.setState({ loading: false })

                this.props.history.push({
                    pathname: '/logout',
                    state: { token },
                    message: e.message,
                })
            })

        this.setState({ loading: false })
    }


    exportar = (e) => {

        this.setState({ loading: true })
        const token = getVars('Token').access_token
        const myFiltro = [];

        if (e === 'exportar') {
            if (this.state.SolicitudDependencia.label.length > 1) myFiltro.push({ id: 's.idDependencia', value: this.state.SolicitudDependencia.value });
            if (this.state.SolicitudFolioSolicitud.length > 1) myFiltro.push({ id: 's.FolioOficio', value: this.state.SolicitudFolioSolicitud });
            if (this.state.SolicitudFechaRecepcion.length > 1) myFiltro.push({ id: 's.FechaRecepcion', value: this.state.SolicitudFechaRecepcion });
            if (this.state.SolicitudFechaCreate.length > 1) myFiltro.push({ id: 's.FechaCreate', value: this.state.SolicitudFechaCreate });
            if (this.state.SolicitudFechaOficio.length > 1) myFiltro.push({ id: 's.FechaSolicitud', value: this.state.SolicitudFechaOficio });
            if (this.state.SolicitudEstatus.label.length > 1) myFiltro.push({ id: 's.idStatus', value: this.state.SolicitudEstatus.value });
            if (this.state.SolicitudNombreCandidato.length > 1) myFiltro.push({ id: 'candidato', value: this.state.SolicitudNombreCandidato });
            if (this.state.SolicitudSecuencial.length > 1) myFiltro.push({ id: 'sm.PLAZA', value: this.state.SolicitudSecuencial });
        }

        var url = new URL(
            URL_PUBLICA + `getSolicitudesExcel?token=${token}`
        );

        if (myFiltro.length) {
            myFiltro.forEach(item => {
                url.searchParams.append(`${item.id}`, item.value);
            });
        }


        window.open(url, "_blank");
        this.setState({ loading: false });
    };

    setValidate = (obj) => {

        this.setState({ data: obj, showData: false })
    }

    setBack = () => {
        this.setState({ showData: true })
    }

    handleAddSolicitud = () => {
        this.setState({ showAdd: true })
    }

    handleEliminar = (ID) => {
        this.setState({ loading: true })

        const token = getVars('Token').access_token

        EjecutarApi({ id: ID }, 'deleteSolicitudes', token)
            .then(function (res) {
                return res;
            })
            .then((res) => {

                if (res.results) {
                    var data = res.data;
                    this.setState({ loading: false })
                    Notify(
                        2,
                        'tc',
                        `Registro Eliminado: `,
                        this.refs
                    )
                }

            })
            .catch(e => {

                this.setState({ loading: false });
                Notify(
                    3,
                    'tc',
                    `Error al eliminar la propuesta: ${e.error}`,
                    this.refs
                )
            })

        //grid
        this.setState({ loading: true })

        var parametros = {
            filtered: [],
            tipo: 'and',
            page: 0,
            pageSize: 99999
        }
        EjecutarApi(
            parametros,
            'getSolicitudes',
            token
        )
            .then(function (res) {
                return res
            })
            .then((res) => {
                this.setState({
                    total: res.data.length,
                    InfoVIP: res.data.map(
                        (item, key) => {
                            return {
                                id: item.id,
                                Eliminar: (<Button
                                    onClick={() => { this.handleEliminar(item.id) }}
                                    color="danger"
                                    size="sm"
                                    className="btn-icon btn-link remove"
                                >x</Button>),
                                FechaSolicitud:
                                    item.FechaSolicitud,
                                FechaRecepcion:
                                    item.FechaRecepcion,
                                FechaCreate:
                                    item.FechaCreate,
                                Propone:
                                    item
                                        .Propone
                                        .label,
                                idPropone:
                                    item
                                        .Propone
                                        .id,
                                Dependencia:
                                    item
                                        .Dependencia
                                        .label,
                                idDependencia:
                                    item
                                        .Dependencia
                                        .id,
                                FolioOficio:
                                    item.FolioOficio,
                                Estatus: item.Estatus.label,
                                data: item,
                            }
                        }
                    ),
                    loading: false,
                })
            })
            .catch((e) => {
                Notify(
                    3,
                    'tc',
                    `Error en getSolicitudes: ${e.error}`,
                    this.refs
                )
                this.setState({
                    loading: false,
                })
            })


    }

    handleEditarSolicitud = (id) => {
        this.props.history.push({
            pathname: '/gto/admin/oficialiaEdit',
            state: { id }
        })
    }
    btnGetListado = (e) => {
        const myFiltro = [];

        if (e === 'filtra') {
            if (this.state.SolicitudDependencia.label.length > 1) myFiltro.push({ id: 's.idDependencia', value: this.state.SolicitudDependencia.value });
            if (this.state.SolicitudFolioSolicitud.length > 1) myFiltro.push({ id: 's.FolioOficio', value: this.state.SolicitudFolioSolicitud });
            if (this.state.SolicitudFechaRecepcion.length > 1) myFiltro.push({ id: 's.FechaRecepcion', value: this.state.SolicitudFechaRecepcion });
            if (this.state.SolicitudFechaCreate.length > 1) myFiltro.push({ id: 's.FechaCreate', value: this.state.SolicitudFechaCreate });
            if (this.state.SolicitudFechaOficio.length > 1) myFiltro.push({ id: 's.FechaSolicitud', value: this.state.SolicitudFechaOficio });
            if (this.state.SolicitudEstatus.label.length > 1) myFiltro.push({ id: 's.idStatus', value: this.state.SolicitudEstatus.value });
            if (this.state.SolicitudNombreCandidato.length > 1) myFiltro.push({ id: 'candidato', value: this.state.SolicitudNombreCandidato });
            if (this.state.SolicitudSecuencial.length > 1) myFiltro.push({ id: 'sm.PLAZA', value: this.state.SolicitudSecuencial });
        } else {
            this.setState({
                SolicitudDependencia: { value: 1, label: "No definido" },
                SolicitudFolioSolicitud: '',
                SolicitudFechaRecepcion: '',
                SolicitudFechaOficio: '',
                SolicitudFechaCreate: '',
                SolicitudEstatus: { label: "", value: 0 },
                SolicitudNombreCandidato: '',
                SolicitudSecuencial: ''

            })
        }

        this.setState({ Filtro: myFiltro })
        //Ejecutando API
        const token = getVars('Token').access_token;
        var parametros = {
            filtered: myFiltro,
            tipo: 'and',
            page: this.state.state.page,
            pageSize: this.state.state.pageSize,
            sorted: [{ "id": "s.FechaLastUpdated", "desc": true }]
        }
        EjecutarApi(parametros, 'getSolicitudes', token)
            .then(function (res) {
                return res
            })
            .then((res) => {
                if (res.results) {

                    this.setState({
                        total: res.total,
                        InfoVIP: res.data.map(
                            (item, key) => {
                                return {
                                    id: item.id,
                                    Eliminar: (<Button
                                        onClick={() => { this.handleEliminar(item.id) }}
                                        color="danger"
                                        size="sm"
                                        className="btn-icon btn-link remove"
                                    >x</Button>),
                                    FechaSolicitud:

                                        item.FechaSolicitud,
                                    FechaRecepcion:
                                        item.FechaRecepcion,
                                    FechaCreate:
                                        item.FechaCreate,
                                    Propone:
                                        item
                                            .Propone
                                            .label,
                                    idPropone:
                                        item
                                            .Propone
                                            .id,
                                    Dependencia:
                                        item
                                            .Dependencia
                                            .label,
                                    idDependencia:
                                        item
                                            .Dependencia
                                            .id,
                                    FolioOficio:
                                        (<Button
                                            onClick={() => { this.handleEditarSolicitud(item) }}
                                            color="info"
                                            size="sm"
                                            className="btn-link"
                                        > {" "}{item.FolioOficio} {" "}</Button>),
                                    Estatus: item.Estatus.label,
                                    data: item,
                                }
                            }
                        ),
                        loading: false,
                    })
                }
                else {
                    this.setState({ loading: false });
                    Notify(
                        2,
                        'tc',
                        `No se encontraron coincidencias en la búsqueda!!`,
                        this.refs
                    )
                }

            })
            .catch((e) => {
                Notify(
                    3,
                    'tc',
                    `Error en getSolicitudes: ${e.error}`,
                    this.refs
                )
                this.setState({
                    loading: false,
                })
            });

    }

    setSolicitudDependencia = (e) => {
        this.setState({ SolicitudDependencia: e })
    }
    setFolioSolicitud = (e) => {

        this.setState({ SolicitudFolioSolicitud: e.target.value })
    }
    setFechaOficioSolicitud = (e) => {
        this.setState({ SolicitudFechaOficio: moment(e._d).format('YYYY-MM-DD') })
    }
    setFechaRecepcionSolicitud = (e) => {

        this.setState({
            SolicitudFechaRecepcion: moment(e._d).format('YYYY-MM-DD'),
        })
    }
    //Para guardar valor en SolicitudFechaCreate
    setFechaCreate = (e) => {

        this.setState({
            SolicitudFechaCreate: moment(e._d).format('YYYY-MM-DD'),
        })
    }

    render() {

        global = getVars('Token')
        // const { total, Totales, loading } = this.state
        const { Solicitud } = this.state;
        var ItemsDependencia = this.state.catdependencias || [];
        var ItemsEstatus = this.state.catEstatus || [];

        return (
            <div className="wrapper">
                <Sidebar
                    {...this.props}
                    bgColor={this.state.backgroundColor}
                    activeColor={this.state.activeColor}
                />
                <div className="main-panel" ref="mainPanel">
                    <DashboardNavbar
                        {...this.props}
                        handleMiniClick={this.handleMiniClick}
                    />
                    <NotificationAlert ref="notificationAlert" />
                    <div className="content">
                        <Row>
                            <Col lg="2" md="6" sm="6">
                                <Card className="card-stats">
                                    <CardBody>
                                        <Row>
                                            <Col md="4" xs="5">
                                                <div className="icon-big text-center icon-warning">
                                                    <i className="nc-icon nc-globe text-warning" />
                                                </div>
                                            </Col>
                                            <Col md="8" xs="7">
                                                <div className="numbers">
                                                    <p className="card-category">
                                                        Total Solicitudes
                                                    </p>
                                                    {this.state.loading && (
                                                        <CircleSpinner
                                                            className="text-center"
                                                            size={30}
                                                            color="#686769"
                                                            loading={this.state.loading}
                                                        />
                                                    )}
                                                    <CardTitle tag="p">
                                                        {this.state.TotalSolicitudes}
                                                    </CardTitle>
                                                    <p />
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter>
                                        <hr />
                                        <div className="stats">
                                            <i className="fa fa-calendar-o" />
                                            {this.state.loading
                                                ? 'Actualizando...'
                                                : 'Actualizado'}
                                        </div>
                                    </CardFooter>
                                </Card>
                            </Col>

                            <Col lg="2" md="6" sm="6">
                                <Card className="card-stats">
                                    <CardBody>
                                        <Row>
                                            <Col md="4" xs="5">
                                                <div className="icon-big text-center icon-warning">
                                                    <i className="nc-icon nc-favourite-28 text-primary" />
                                                </div>
                                            </Col>
                                            <Col md="8" xs="7">
                                                <div className="numbers">
                                                    <p className="card-category">
                                                        Total Pendiente
                                                    </p>
                                                    {this.state.loading && (
                                                        <CircleSpinner
                                                            className="text-center"
                                                            size={30}
                                                            color="#686769"
                                                            loading={this.state.loading}
                                                        />
                                                    )}
                                                    <CardTitle tag="p">
                                                        {this.state.TotalPendiente}
                                                    </CardTitle>
                                                    <p />
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter>
                                        <hr />
                                        <div className="stats">
                                            <i className="fa fa-calendar-o" />
                                            {this.state.loading
                                                ? 'Actualizando...'
                                                : 'Actualizado'}
                                        </div>
                                    </CardFooter>
                                </Card>
                            </Col>
                            <Col lg="2" md="6" sm="6">
                                <Card className="card-stats">
                                    <CardBody>
                                        <Row>
                                            <Col md="4" xs="5">
                                                <div className="icon-big text-center icon-warning">
                                                    <i className="nc-icon nc-money-coins text-success" />
                                                </div>
                                            </Col>
                                            <Col md="8" xs="7">
                                                <div className="numbers">
                                                    <p className="card-category">
                                                        Total Operado
                                                    </p>
                                                    <CardTitle tag="p">
                                                        {this.state.loading && (
                                                            <CircleSpinner
                                                                className="text-center"
                                                                size={30}
                                                                color="#686769"
                                                                loading={
                                                                    this.state.loading
                                                                }
                                                            />
                                                        )}
                                                        {this.state.TotalOperado}
                                                    </CardTitle>
                                                    <p />
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter>
                                        <hr />
                                        <div className="stats">
                                            <i className="fa fa-calendar-o" />
                                            {this.state.loading
                                                ? 'Actualizando...'
                                                : 'Actualizado'}
                                        </div>
                                    </CardFooter>
                                </Card>
                            </Col>
                            <Col lg="2" md="6" sm="6">
                                <Card className="card-stats">
                                    <CardBody>
                                        <Row>
                                            <Col md="4" xs="5">
                                                <div className="icon-big text-center icon-warning">
                                                    <i className="nc-icon nc-vector text-danger" />
                                                </div>
                                            </Col>
                                            <Col md="8" xs="7">
                                                <div className="numbers">
                                                    <p className="card-category">
                                                        Total Concluido
                                                    </p>
                                                    <CardTitle tag="p">
                                                        {this.state.loading && (
                                                            <CircleSpinner
                                                                className="text-center"
                                                                size={30}
                                                                color="#686769"
                                                                loading={
                                                                    this.state.loading
                                                                }
                                                            />
                                                        )}
                                                        {this.state.TotalConcluido}
                                                    </CardTitle>
                                                    <p />
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter>
                                        <hr />
                                        <div className="stats">
                                            <i className="fa fa-clock-o" />
                                            {this.state.loading
                                                ? 'Actualizando...'
                                                : 'Actualizado'}
                                        </div>
                                    </CardFooter>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h6">
                                            Datos de Solicitud
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>

                                            <Col sm="4">
                                                Fecha Solicitud:

                                                <ReactDatetime
                                                    name='FechaOficio'
                                                    dateFormat="YYYY-MM-DD"
                                                    timeFormat={false}
                                                    closeOnSelect
                                                    onChange={
                                                        this
                                                            .setFechaOficioSolicitud
                                                    }
                                                    inputProps={{
                                                        className:
                                                            'form-control',
                                                        placeholder:
                                                            'Fecha Oficio',
                                                    }}
                                                    value={this.state.SolicitudFechaOficio}
                                                />
                                            </Col>
                                            <Col sm="4">
                                                Fecha Recepción:
                                                <ReactDatetime
                                                    name='FechaRecepcion'
                                                    dateFormat="YYYY-MM-DD"
                                                    //timeFormat={false}
                                                    closeOnSelect
                                                    onChange={
                                                        this
                                                            .setFechaRecepcionSolicitud
                                                    }
                                                    inputProps={{
                                                        className:
                                                            'form-control',
                                                        placeholder:
                                                            'Fecha Oficio',
                                                    }}
                                                    value={this.state.SolicitudFechaRecepcion}
                                                />
                                            </Col>
                                            <Col sm="4">
                                                Fecha Captura:

                                                <ReactDatetime
                                                    name='FechaCreate'
                                                    dateFormat="YYYY-MM-DD"
                                                    timeFormat={false}
                                                    closeOnSelect
                                                    onChange={
                                                        this
                                                            .setFechaCreate
                                                    }
                                                    inputProps={{
                                                        className:
                                                            'form-control',
                                                        placeholder:
                                                            'Fecha Creación',
                                                    }}
                                                    value={this.state.SolicitudFechaCreate}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm="4">
                                                Folio de Solicitud:
                                                <Input
                                                    name='FolioSolicitud'
                                                    value={this.state.SolicitudFolioSolicitud}
                                                    onChange={this.setFolioSolicitud}
                                                    type="text"
                                                ></Input>
                                            </Col>
                                            <Col sm="4">

                                                <FormGroup>
                                                    Secuencial:
                                                    <Input
                                                        id="solPropones"
                                                        value={this.state.SolicitudSecuencial}
                                                        onChange={(e) => this.setState({ SolicitudSecuencial: e.target.value })}
                                                        type="text"
                                                    />
                                                </FormGroup>

                                            </Col>
                                            <Col sm="4">
                                                Estatus:
                                                <Select
                                                    className="react-select primary"
                                                    classNamePrefix="react-select"
                                                    name="EstatusSolicitud"
                                                    onChange={(e) => this.setState({ SolicitudEstatus: e })}
                                                    options={ItemsEstatus}
                                                    placeholder="Seleccione Estatus"
                                                    value={this.state.SolicitudEstatus}
                                                />
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col sm="6">
                                                {this.state.loading && (
                                                    <CircleSpinner
                                                        className="text-center"
                                                        size={30}
                                                        color="#686769"
                                                        loading={
                                                            this.state
                                                                .loading
                                                        }
                                                    />
                                                )}
                                                Dependencia:
                                                <Select
                                                    className="react-select primary"
                                                    classNamePrefix="react-select"
                                                    name="DepSolicitud"
                                                    onChange={(e) => this.setSolicitudDependencia(e)}
                                                    options={
                                                        ItemsDependencia &&
                                                            ItemsDependencia.constructor ===
                                                            Array
                                                            ? ItemsDependencia
                                                            : []
                                                    }
                                                    placeholder="Seleccione Dependencia"
                                                    value={this.state.SolicitudDependencia}
                                                />
                                            </Col>
                                            <Col sm="6">

                                                <FormGroup>
                                                    Nombre Candidato
                                                    <Input
                                                        id="solPropones"
                                                        value={this.state.SolicitudNombreCandidato}
                                                        onChange={(e) => this.setState({ SolicitudNombreCandidato: e.target.value })}
                                                        type="text"
                                                    />
                                                </FormGroup>

                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter>
                                        <Button className='primary' color='primary' onClick={() => this.btnGetListado('filtra')}>Buscar</Button>
                                        {' '}
                                        <Button className='danger' color='danger' onClick={() => this.btnGetListado('clear')}>Limpiar</Button>
                                        <ModalProponeSolicitud
                                            isOpen={
                                                this.state
                                                    .modalProponeSolicitud
                                            }
                                            newUser={
                                                this.setNewProponeSolicitud
                                            }
                                            toggle={
                                                this
                                                    .togglemodalProponeSolicitud
                                            }
                                            setPropone={
                                                this.setProponeSolicitud
                                            }
                                            onError={this.onErrorRedirect}
                                            ItemsDependencia={
                                                ItemsDependencia
                                            }
                                        />
                                    </CardFooter>
                                </Card>
                            </Col>
                        </Row>
                        <Card>{this.state.showAdd ? (
                            <OficialiaAdd
                                props={this.props}
                            ></OficialiaAdd>
                        ) : (<>
                            <CardHeader>
                                <CardTitle tag="h6">
                                    <Row>
                                        <Col sm="12" md="4" lg="4" className="text-align-center-left" >
                                            <Button size="sm" color="success" onClick={() => this.exportar('exportar')}>
                                                <i class="fa fa-file-excel-o fa-lg" aria-hidden="true"></i>
                                            </Button>
                                        </Col>
                                        <Col sm="12" md="6" lg="8" className="text-align-center-left align-content-center">Listado Solicitudes{' '}</Col>
                                    </Row>

                                </CardTitle>
                                <CardSubtitle>

                                </CardSubtitle>
                            </CardHeader>
                            <CardBody>
                                <ReactTable size="sm"
                                    data={this.state.InfoVIP}
                                    columns={[
                                        {
                                            Header: '',
                                            minWidth: 25,
                                            id: 'Eliminar',
                                            accessor: (d) => d.Eliminar,
                                        },
                                        {
                                            Header: 'Folio Oficio',
                                            minWidth: 100,
                                            id: 'FolioOficio',
                                            accessor: (d) => d.FolioOficio,
                                        },
                                        {
                                            Header: 'Fecha Solicitud',
                                            minWidth: 80,
                                            id: 'FechaSolicitud',
                                            accessor: (d) =>
                                                d.FechaSolicitud,
                                        },
                                        {
                                            Header: 'FechaRecepcion',
                                            minWidth: 80,
                                            id: 'FechaRecepcion',
                                            accessor: (d) =>
                                                d.FechaRecepcion,
                                        },
                                        {
                                            Header: 'FechaCreacion',
                                            minWidth: 80,
                                            id: 'FechaCreate',
                                            accessor: (d) =>
                                                d.FechaCreate,
                                        },
                                        {
                                            Header: 'Propone',
                                            minWidth: 80,
                                            id: 'Propone',
                                            accessor: (d) => d.Propone,
                                        },
                                        {
                                            Header: 'Dependencia',
                                            minWidth: 150,
                                            id: 'Dependencia',
                                            accessor: (d) => d.Dependencia,
                                        },

                                        {
                                            Header: 'Estatus',
                                            minWidth: 50,
                                            id: 'Estatus',
                                            accessor: (d) => d.Estatus,
                                        },
                                    ]}
                                    loading={this.state.loading}
                                    defaultPageSize={10}
                                    showPagination={true}
                                    showPaginationTop={false}
                                    showPaginationBottom={true}
                                    showPageJump={false}
                                    pageSizeOptions={[
                                        5,
                                        10,
                                        20,
                                        25,
                                        50,
                                        100,
                                    ]}
                                    total={this.state.total}
                                    pages={Math.ceil(this.state.total / 10)}
                                    manual
                                    canNextFromData={true}
                                    showTotalPages={true}
                                    PaginationComponent={Pagination}
                                    previousText="Anterior"
                                    nextText="Siguiente"
                                    ofText="de"
                                    onFetchData={(state, instance) => {
                                        this.setState({ loading: true, state: state })

                                        const token = getVars('Token').access_token;
                                        var parametros = {
                                            filtered: this.state.Filtro ? this.state.Filtro : [],
                                            tipo: 'and',
                                            page: state.page,
                                            pageSize: state.pageSize,
                                            sorted: [{ "id": "s.FechaLastUpdated", "desc": true }]
                                        }
                                        EjecutarApi(parametros, 'getSolicitudes', token)
                                            .then(function (res) {
                                                return res
                                            })
                                            .then((res) => {
                                                this.setState({
                                                    total: res.total,
                                                    InfoVIP: res.data.map(
                                                        (item, key) => {
                                                            return {
                                                                id: item.id,
                                                                Eliminar: (<Button
                                                                    onClick={() => { this.handleEliminar(item.id) }}
                                                                    color="danger"
                                                                    size="sm"
                                                                    className="btn-icon btn-link remove"
                                                                >x</Button>),
                                                                FechaSolicitud:

                                                                    item.FechaSolicitud,
                                                                FechaRecepcion:
                                                                    item.FechaRecepcion,
                                                                FechaCreate:
                                                                    item.FechaCreate,
                                                                Propone:
                                                                    item
                                                                        .Propone
                                                                        .label,
                                                                idPropone:
                                                                    item
                                                                        .Propone
                                                                        .id,
                                                                Dependencia:
                                                                    item
                                                                        .Dependencia
                                                                        .label,
                                                                idDependencia:
                                                                    item
                                                                        .Dependencia
                                                                        .id,
                                                                FolioOficio:
                                                                    (<Button
                                                                        onClick={() => { this.handleEditarSolicitud(item) }}
                                                                        color="info"
                                                                        size="sm"
                                                                        className="btn-link"
                                                                    > {" "}{item.FolioOficio} {" "}</Button>),
                                                                Estatus: item.Estatus.label,
                                                                data: item,
                                                            }
                                                        }
                                                    ),
                                                    loading: false,
                                                })
                                            })
                                            .catch((e) => {
                                                Notify(
                                                    3,
                                                    'tc',
                                                    `Error en getSolicitudes: ${e.error}`,
                                                    this.refs
                                                )
                                                this.setState({
                                                    loading: false,
                                                })
                                            })
                                    }}
                                    className="-striped -highlight primary-pagination"
                                />
                            </CardBody>
                        </>)}
                        </Card>
                    </div>
                    <Footer fluid />
                </div>
            </div>
        )
    }
}

export default oficialiaListado
