import React from 'react'
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    CardFooter,
    Label,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
} from 'reactstrap'
import Select from 'react-select'
import PerfectScrollbar from 'perfect-scrollbar'
import NotificationAlert from 'react-notification-alert'
import ReactTable from 'react-table'
import matchSorter from 'match-sorter'
import ReactDatetime from 'react-datetime'
import moment from 'moment'
import { CircleSpinner } from 'react-spinners-kit'

import EjecutarApi from '../../data/Consultas'
import Notify from '../../data/Notificacion'
import { getVars,URL_PUBLICA } from '../../GlobalStore'

import DashboardNavbar from '../../components/Navbars/DashboardNavbar.jsx'
import Footer from '../../components/Footer/Footer.jsx'
import Sidebar from '../../components/Sidebar/MenuSidebar.jsx'

import Pagination from '../components/Paginacion/index'

import ModalSearchUser from '../components/ModalSearchUser'
import ModalPropone from '../components/Modal/ModalPropone'
import ModalProponeSolicitud from '../components/Modal/ModalPropone'
import ModalNuevaPlaza from '../components/ModalNuevaPlaza'
import UploadFile from '../components/UploadFiles/ImageUpload'


var ps
var global = ''

class oficialiaAddMovimiento extends React.Component {
    constructor(props) {
        super(props)
        console.log('Oficialia Add idSolicitud: ',props.location);
        this.state = {
            IDSolicitud:props.location.IDSolicitud,
            isVisible: false,
            backgroundColor: 'black',
            activeColor: 'info',
            sidebarMini: false,
            loadingPlazas: false,
            loadingOcupadas: false,
            loadingVacantes: false,
            modalPlaza: false,
            modalCandidato: false,
            modalPropone: false,
            modalNotice: false,
            FolioDependencia: '',
            isRecomendado: false,
            PlazasOcupadas: [],
            PlazasVacantes: [],
            totalOcupadas: 0,
            firstOcupada: true,
            Baja: {
                uid: 'undefined',
            },
            Promocion: {
                uid: 'undefined',
            },
            catPlazas: {
                value: '',
                label: 'Seleccione plaza',
            },
            idDependencia: {
                value: 'undefined',
                label: 'Seleccione',
            },
            Movimiento: {
                value: 'undefined',
                label: 'Seleccione',
            },
            Plaza: {
                id: 'undefined',
                label: '',
            },
            NuevaPlaza: {
                NumSerie: '',
                Puesto: '',
                Municipio: {
                    value: 0,
                    label: '',
                },
                Nivel: {
                    value: 0,
                    label: '',
                },
                TipoPlaza: {
                    value: 0,
                    lanel: '',
                },
            },
            idEstadoCivil: {
                value: 'undefined',
                label: 'Seleccione',
            },
            idNivelEstudio: {
                value: 'undefined',
                label: 'Seleccione',
            },
            idPerfilCarrera: {
                value: 1,
                label: 'No definido',
            },
            NivelPropuesto: {
                value: 'undefined',
                label: 'Seleccione',
            },
            modalDependencia: false,
            PersonaPropone: {
                idQuienPropone: null,
                Nombre: '',
                Paterno: '',
                Materno: '',
                Puesto: '',
                Cargo: '',
                Dependencia: {
                    value: 'undefined',
                    label: '',
                },
            },
            INE: '',
            CURP: '',
            id: null,
            Nombre: '',
            Paterno: '',
            Materno: '',
            TelCasa: '',
            Celular: '',
            CorreoElectronico: '',
            Facebook: '',
            Twitter: '',
            Instagram: '',
            GradoObtenido: '',
            GradoMaestria: '',
            UltimoTrabajo: '',
            Municipio: {
                value: 61,
                label: 'No Definido',
            },
            Sexo: {
                value: 1,
                label: 'No Definido',
            },
            idStatus: {
                value: 'undefined',
                label: 'undefined',
            },
            Comentarios: '',
            FechaRecepcion: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            FechaMovimiento: '',
            showDatosPersonales: false,
            readPersonales: false,
            SolicitudidDependencia: 0,
            SolicitudDependencia: '',
            SolicitudFechaOficio: '',
            SolicitudFechaRecepcion: '',
            SolicitudFolioSolicitud: '',
            SolicitudEstatus:'',
            modalProponeSolicitud: false,
            PersonaProponeSolicitudFullName:'',
            PersonaProponeSolicitud: {
                idQuienPropone: null,
                Nombre: '',
                Paterno: '',
                Materno: '',
                Puesto: '',
                Cargo: '',
                Dependencia: {
                    value: 'undefined',
                    label: '',
                },
                
            },
            areaPropuesta: false,
            totalListadoPropuesta: 0,
            ListadoPropuestas: [],
            dataListadoPropuesta: {
                id: 0,
                Movimiento: '',
                Dependencia: '',
                Candidato: '',
                Secuencial: '',
                Estatus: '',
                Denominacion_funcional: '',
                Tipo: '',
                Nivel: '',
                data: '',
            },
            idSolicitud: 0,
            loading: false,
            file: null,
            catdependencias:[],
            catalogos:{
                EstadoCivil:[],
                PerfilCarrera:[],
                SeguimientoEstatus:[],
                municipios:[],
                nivel:[]
                
            }
        }
    }

    componentDidMount() {
        this.setState({ loading: true })
        
        if (navigator.platform.indexOf('Win') > -1) {
            document.documentElement.className += ' perfect-scrollbar-on'
            document.documentElement.classList.remove('perfect-scrollbar-off')
            ps = new PerfectScrollbar(this.refs.mainPanel)
        }
        const token = global.access_token //localstorage


        fetch(URL_PUBLICA+'getCatalogosSolicitud', {
            headers: new Headers({ Authorization: `Bearer ${token}` }),
        })
            .then((response) => {
                switch (response.status) {
                    case 200:
                        console.log('==>Error Status: ', response.status)
                        break

                    default:
                        if (navigator.platform.indexOf('Win') > -1) {
                            ps.destroy()
                            document.documentElement.className +=
                                ' perfect-scrollbar-off'
                            document.documentElement.classList.remove(
                                'perfect-scrollbar-on'
                            )
                        }
                        console.log('==>Unknow Status: ', response.status)
                        this.props.history.push({
                            pathname: '/logout',
                            state: { token },
                            message: 'Su cuenta ya expiro',
                        })
                        break
                }

                if (response.ok) {
                    return response.json()
                }
                throw new Error(
                    'Ocurrio un error: obtenerDatosCandidatos: ',
                    response.statusText
                )
            })
            .then((items) => {
                console.log('===> Data Catalogos: ', items)
                
                this.setState({
                    catalogos: {
                        EstadoCivil: items.EstadoCivil,
                        municipios: items.municipios,
                        perfilcarrera: items.PerfilCarrera || {
                            value: 1,
                            label: 'No definido',
                        },
                        nivel: items.nivel,
                    },
                })
            })
            .catch((e) => {
                this.setState({ loading: false })
                console.log('error: ', e.message)
                this.props.history.push({
                    pathname: '/logout',
                    state: { token },
                    message: e.message,
                })
            })
        
        console.log('AddMovi-ComponenDidMount:',this.state);
        fetch(URL_PUBLICA+'getDependenciasSolicitud', {
            headers: new Headers({ Authorization: `Bearer ${token}` })
            })
            .then(function (res) {
                return res.json();
            })
            .then((res)=>{
                console.log('EjecturaAPI->getDependenciasSolicitud',res);
                if(res.results){
                    this.setState({ loading: false })
                    console.log('EjecturaAPI->getDependenciasSolicitud',res);
                    var tbl=res.data;//{};
                    tbl = res.data.map((item,key)=>{
                        return{
                            value:item.value,
                            label: item.label
                        }
                    });

                    console.log('tbl',tbl);
                    this.setState({catdependencias:tbl});
                }
                
            })
            .catch((e)   =>{
                console.log('Error en API',e);
                this.setState({ loading: false })
            })
            console.log('Api Termino');
            this.setState({ loading: false })
        //obteniendo los datos de la solicitud...
        if (this.state.IDSolicitud)
        {
            this.setState({areaPropuesta:true, isVisible:true,idSolicitud:this.state.IDSolicitud});
            var parametros = {
                filtered: [{"id":"s.id","value":this.state.IDSolicitud}],
                tipo: 'and',
                page: 0,
                pageSize: 1,
                sorted :[{"id":"s.FechaLastUpdated","desc":true}]
            }
            console.log(
                'Oficialia ADD getSolicitudes:',
                JSON.stringify(parametros)
            )
            EjecutarApi( parametros,'getSolicitudes', token )
                .then(function (res) {
                    return res
                })
                .then((res) => {
                    console.log('getSolicitudes: ', res)

                    this.setState({
                        total: res.total,
                        PersonaProponeSolicitud: res.data.map(
                            (item, key) => {
                                console.log( '==>item: ',item  )
                                return {
                                    
                                    idQuienPropone: item.Propone.value,
                                    Dependencia: item.Dependencia,
                                    FullName:item.Propone.label,         
                                    
                                    
                                }
                            }
                        ),
                        SolicitudFolioSolicitud:res.data[0].FolioOficio,
                        SolicitudFechaRecepcion:res.data[0].FechaRecepcion,
                        SolicitudFechaOficio:res.data[0].FechaSolicitud,
                        SolicitudEstatus:res.data[0].Estatus,   
                        SolicitudidDependencia:res.data[0].Dependencia.value,
                        SolicitudDependencia:res.data[0].Dependencia.label,
                        PersonaProponeSolicitudFullName:res.data[0].Propone.label,
                        idDependencia:res.data[0].Dependencia.value,
                        loading: false,
                    })
                })
                .catch((e) => {
                    console.log( 'Error[getSolicitudes]:$$$$$$$$$$',e )
                    Notify(
                        3,
                        'tc',
                        `Error en getSolicitudes: ${e.error}`,
                        this.refs
                    )
                    this.setState({
                        loading: false,
                    })
                })
        }
    }

    componentDidUpdate(e) {
        if (e.history.action === 'PUSH') {
            document.documentElement.scrollTop = 0
            document.scrollingElement.scrollTop = 0
            this.refs.mainPanel.scrollTop = 0
        }
    }

    togglemodalCandidato = () => {
        this.setState({
            modalCandidato: !this.state.modalCandidato,
        })
    }

    togglemodalPropone = () => {
        console.log('==>Presiono modal: Propone')
        this.setState({
            modalPropone: !this.state.modalPropone,
        })
    }
    togglemodalProponeSolicitud = () => {
        console.log('==>Presiono modal: Propone')
        this.setState({
            modalProponeSolicitud: !this.state.modalProponeSolicitud,
        })
    }
    toggleNuevaPlaza = () => {
        this.setState({
            modalPlaza: !this.state.modalPlaza,
        })
    }

    searchUser = () => {
        console.log('=> Buscar usuario')
        this.togglemodalCandidato()
    }

    handleMiniClick = () => {
        if (document.body.classList.contains('sidebar-mini')) {
            this.setState({ sidebarMini: false })
        } else {
            this.setState({ sidebarMini: true })
        }
        document.body.classList.toggle('sidebar-mini')
    }

    btnSaveValidate = () => {
        console.log('Guardar y validar')
    }

    setFechaRecepcion = (e) => {
        this.setState({
            FechaRecepcion: moment(e._d).format('YYYY-MM-DD HH:ii:ss'),
        })
    }

    setFechaMovimiento = (e) => {
        this.setState({ FechaMovimiento: moment(e._d).format('YYYY-MM-DD') })
    }

    handleSubmit = (e) => {
        e.preventDefault()
        this.setState({ loading: true })
        console.log('=>Mis esttes: ', this.state)
       
        /*if (this.state.Movimiento.value < 3) {
            if (this.state.PersonaProponeSolicitud.idQuienPropone < 0) {
                Notify(
                    3,
                    'tc',
                    'Error: Debe seleccionar quien propone',
                    this.refs
                )
                return
            }

            if (this.state.Nombre.length < 3) {
                console.log('Error: Escriba un nombre', this.state.Nombre)

                Notify(3, 'tc', 'Error: Escriba un nombre', this.refs)
                return
            }

            if (this.state.Paterno.length < 3) {
                console.log('Error: Escriba un apellido Paterno')
                Notify(3, 'tc', 'Error: Escriba un apellido Paterno', this.refs)
                return
            }

            if (this.state.Materno.length < 1) {
                console.log('Error: Escrina un apellido Materno')

                this.notify(
                    3,
                    'tc',
                    'Error: Escriba un apellido Materno',
                    this.refs
                )
                return
            }

            if (this.state.idEstadoCivil.value === 'undefined') {
                this.notify(3, 'tc', 'Error: Escriba estado civil', this.refs)
                return
            }

            if (this.state.idNivelEstudio.value === 'undefined') {
                this.notify(
                    3,
                    'tc',
                    'Error: Escriba nivel de estudios',
                    this.refs
                )
                return
            }
        }*/

        const token = global.access_token

        let Candidato = {}

        let Propone = {}

        if (this.state.PersonaProponeSolicitud.idQuienPropone === null) {
            Propone = this.state.PersonaPropone
        } else {
            Propone = {
                idQuienPropone: this.state.PersonaProponeSolicitud.idQuienPropone,
                Dependencia: this.state.PersonaProponeSolicitud.Dependencia,
                OtraDependencia: this.state.PersonaProponeSolicitud.OtraDependencia,
            }
        }

        let idPlantilla = null
        let PLAZA = ''
        let DENOMINACION_TABULAR = ''
        let NIVEL = ''
        let MUNICIPIO = ''
        let TIPO = ''
        let DEPENDENCIA_ORGANISMO = ''

        if (this.state.Movimiento.value > 1) {
            if (this.state.Plaza.id === '999999') {
                idPlantilla = null
                PLAZA = this.state.NuevaPlaza.NumSerie
                DENOMINACION_TABULAR = this.state.NuevaPlaza.Puesto
                NIVEL = this.state.NuevaPlaza.Nivel.value
                MUNICIPIO = this.state.NuevaPlaza.Municipio.value
                TIPO = this.state.NuevaPlaza.TipoPlaza.value
                DEPENDENCIA_ORGANISMO = this.state.idDependencia.value
            } else {
                idPlantilla = this.state.Plaza.id
            }
        } else {
            idPlantilla = 1
        }

        if (this.state.id === null) {
            Candidato = {
                idCandidato: null,
                INE: this.state.INE,
                CURP: this.state.CURP,
                Nombre: this.state.Nombre,
                Paterno: this.state.Paterno,
                Materno: this.state.Materno,
                idNivelEstudios: this.state.idNivelEstudio.value,
                idPerfilCarrera: this.state.idPerfilCarrera.value,
                idEstadoCivil: this.state.idEstadoCivil.value,
                GradoObtenido: this.state.GradoObtenido,
                GradoMaestria: this.state.GradoMaestria,
                Celular1: this.state.Celular,
                TelCasa: this.state.TelCasa,
                CorreoElectronico: this.state.CorreoElectronico,
                UltimoTrabajo: this.state.UltimoTrabajo,
                idMunicipio: this.state.Municipio.value,
                Facebook: this.state.Facebook,
                Twitter: this.state.Twitter,
                Instagram: this.state.Instagram,
                idSexo: this.state.Sexo.value,
                Comentarios: this.state.Comentarios,
                IdValidado: 0,
                isJefatura: this.state.Movimiento.value > 1 ? 0 : 1,
                Propone:
                    this.state.PersonaPropone.Nombre +
                    '' +
                    this.state.PersonaPropone.Paterno +
                    ' ' +
                    this.state.PersonaPropone.Materno,
                PuestoPropone: this.state.PersonaPropone.Puesto,
                DependenciaPropone: this.state.PersonaPropone.Dependencia,
                isRecomendado: this.state.isRecomendado ? 1 : 0,
                NivelPropuesto:
                    this.state.Movimiento.value === 1
                        ? this.state.NivelPropuesto.value
                        : this.state.Plaza.value === 999999
                        ? this.state.NuevaPlaza.Nivel.value
                        : this.state.Plaza.Nivel,
            }
        } else {
            Candidato = {
                idCandidato: this.state.id,
                isRecomendado: this.state.isRecomendado ? 1 : 0,
            }
        }

        const DataSend =
            this.state.Movimiento.value < 3
                ? {
                      idSolicitud: this.state.idSolicitud,
                      idPlantilla,
                      PLAZA,
                      DENOMINACION_TABULAR,
                      NIVEL,
                      MUNICIPIO,
                      TIPO,
                      DEPENDENCIA_ORGANISMO,
                      Candidato,
                      idMovimiento: this.state.Movimiento.value,
                      FechaRecepcion: this.state.FechaRecepcion,
                      FechaMovimiento: this.state.FechaMovimiento,
                      FolioDependencia: this.state.FolioDependencia,
                      idMovimientoStatus:
                          this.state.Movimiento.value === 1 ? 1 : 2,
                      Propone,
                  }
                : this.state.Movimiento.value === 3
                ? {
                      Baja: this.state.Baja,
                      idSolicitud: this.state.idSolicitud,
                      Promocion: this.state.Promocion,
                      idMovimiento: this.state.Movimiento.value,
                      FechaRecepcion: this.state.FechaRecepcion,
                      FechaMovimiento: this.state.FechaMovimiento,
                      FolioDependencia: this.state.FolioDependencia,
                      idMovimientoStatus:
                          this.state.Movimiento.value === 1 ? 1 : 2,
                      Propone,
                  }
                : {
                      Baja: this.state.Baja,
                      idSolicitud: this.state.idSolicitud,
                      idMovimiento: this.state.Movimiento.value,
                      FechaRecepcion: this.state.FechaRecepcion,
                      FechaMovimiento: this.state.FechaMovimiento,
                      FolioDependencia: this.state.FolioDependencia,
                      idMovimientoStatus:
                          this.state.Movimiento.value === 1 ? 1 : 2,
                      Propone,
                  }

        console.log('==>Datos a enviar: ', JSON.stringify(DataSend))
        EjecutarApi(DataSend, 'setPropuesta', token)
            .then(function (res) {
                return res
            })
            .then((res) => {
                console.log('EjecutarApi->setPropuesta:', res)
                if (res.success) {
                    console.log('==>Insert Response: ', res)
                    this.setState({ loading: false, isVisible: true })
                    Notify(
                        2,
                        'tc',
                        'La porpuesta ha sido capturada con exito!',
                        this.refs
                    )
                    console.log('Inicia el limpiado de Form')
                    this.clearForm()
                }
            })
            .catch((e) => {
                console.log('error: ', e.message)
                this.setState({ isVisible: false, loading: false })
                Notify(3, 'tc', e.message, this.refs)
                return
            })

        var parametros = {
            filtered: [{ id: 'sm.idSolicitud', value: this.state.idSolicitud }],
            tipo: 'and',
            page: 0,
            pageSize: 10,
        }
        console.log('tblPropuestas->parametros: ', JSON.stringify(parametros))
        EjecutarApi(parametros, 'getPropuestas', token)
            .then(function (res) {
                return res
            })
            .then((res) => {
                console.log('EjecutarAPI->getPropuestas->res:', res)
                if (res.results) {
                    var data = res.data
                    this.setState({
                        totalListadoPropuesta: res.total,
                        ListadoPropuestas: data.map((item, key) => {
                            return {
                                id: item.id,
                                Eliminar: (
                                    <Button
                                        onClick={() => {
                                            this.handleEliminar(item.id)
                                        }}
                                        color="danger"
                                        size="sm"
                                        className="btn-icon btn-link remove"
                                    >
                                        x
                                    </Button>
                                ),
                                Movimiento: item.Movimiento.label,
                                Dependencia: item.Dependencia_organismo,
                                Candidato: item.Candidato.label,
                                Secuencial: item.Plaza,
                                Estatus: item.MovimientoStatus.label,
                                Denominacion_funcional:
                                    item.Denominacion_funcional,
                                Tipo: item.Tipo,
                                Nivel: item.Nivel,
                                data: item,
                            }
                        }),
                    })
                }
            })
            .catch((e) => {
                console.log('Error->tblPropuestas: ', e)
                this.setState({ loading: false })
            })
    }

    clearForm = () => {
        console.log('Inicia el limpiado de Form')
        this.setState({
            FolioDependencia: '',
            isRecomendado: false,
            catPlazas: {
                value: '',
                label: 'Seleccione plaza',
            },
            Movimiento: {
                value: 'undefined',
                label: 'Seleccione',
            },
            Plaza: {
                value: 'undefined',
                label: '',
            },
            NuevaPlaza: {
                NumSerie: '',
                Puesto: '',
                Municipio: {
                    value: 0,
                    label: '',
                },
                Nivel: {
                    value: 0,
                    label: '',
                },
                TipoPlaza: {
                    value: 0,
                    lanel: '',
                },
            },
            idEstadoCivil: {
                value: 'undefined',
                label: 'Seleccione',
            },
            idPerfilCarrera: {
                value: 1,
                label: 'No definido',
            },
            NivelPropuesto: {
                value: 'undefined',
                label: 'Seleccione',
            },
            PersonaPropone: {
                id: null,
                Nombre: '',
                Paterno: '',
                Materno: '',
                Puesto: '',
                Cargo: '',
                Dependencia: {
                    value: null,
                    label: '',
                },
            },
            INE: '',
            CURP: '',
            id: null,
            Nombre: '',
            Paterno: '',
            Materno: '',
            TelCasa: '',
            Celular: '',
            CorreoElectronico: '',
            Facebook: '',
            Twitter: '',
            Instagram: '',
            GradoObtenido: '',
            GradoMaestria: '',
            UltimoTrabajo: '',
            Municipio: {
                value: 61,
                label: 'No Definido',
            },
            Sexo: {
                value: 1,
                label: 'No Definido',
            },
            showDatosPersonales: false,
            readPersonales: false,
        })
    }

    setMovimiento = (e) => {
        console.log('===> Movimiento', e)

        this.setState({ Movimiento: e })
      
        
        if (this.state.idDependencia.value !== '') {
            //this.
            this.getPlazas(e.value, this.state.SolicitudDependencia)
        }
    }

    getPlazasOcupadas = (Dependencia) => {
        const token = global.access_token

        var data = {}

        this.setState({ loadingOcupadas: true, firstOcupada: false })

        data = {
            page: 0,
            tipo: 'and',
            pageSize: 10,
            sorted: [],
            filtered: [
                {
                    id: 'Dependencia_organismo',
                    value: Dependencia,
                },
                {
                    id: 'Estatus',
                    value: 'OCUPADA',
                },
            ],
        }

        console.log('==> Buscar getPlazas Ocupadas: ', data)
        EjecutarApi(data, 'getPlantillas', token)
            .then(function (res) {
                return res
            })
            .then((items) => {
                console.log('===> Data Plazas Ocupadas: ', items)

                if (items.results) {
                    const dataTable = items.data

                    this.setState({
                        loadingOcupadas: false,
                        loading: false,
                        totalOcupadas: items.total,
                        PlazasOcupadas: dataTable.map((prop, key) => {
                            return {
                                id: prop.uid,
                                key: prop.uid,
                                label:
                                    prop.Plaza +
                                    ' - ' +
                                    prop.Denominacion_funcional,
                                Dependencia_organismo: prop.Dependencia,
                                Nivel: prop.Nivel,
                                Tipo: prop.Tipo,
                                Nombre: prop.Nombre,
                                Denominacion_tabular: prop.Denominacion_tabular,
                                Denominacion_funcional:
                                    prop.Denominacion_funcional,
                                Estatus: prop.Estatus,
                                Municipio: prop.Municipio,
                                FechaVacante: prop.Fecha_vacante,
                                NombreBtn: (
                                    <Button
                                        className="btn-link"
                                        color="primary"
                                        onClick={() => {
                                            this.setBaja(prop)
                                        }}
                                    >{`${prop.Nombre}`}</Button>
                                ),
                                data: prop,
                            }
                        }),
                    })
                }
            })
            .catch((e) => {
                console.log('error getPlazas: ', e.message)
                Notify(
                    3,
                    'tc',
                    `Error al cargar plazas: ${e.message}`,
                    this.refs
                )
                this.setState({ loadingOcupadas: false })
            })
    }
    getPlazasVacantes = (Dependencia) => {
        const token = global.access_token

        var data = {}

        this.setState({ loadingVacantes: true, firstVacante: false })

        data = {
            page: 0,
            tipo: 'and',
            pageSize: 10,
            sorted: [],
            filtered: [
                {
                    id: 'Dependencia_organismo',
                    value: Dependencia,
                }/*,
                {
                    id: 'Estatus',
                    value: 'VACANTE',
                },*/
            ],
        }

        console.log('==> Buscar getPlazas Vacantes: ', data)
        EjecutarApi(data, 'getPlantillas', token)
            .then(function (res) {
                return res
            })
            .then((items) => {
                console.log('===> Results Data Plazas Vacante: ', items)

                if (items.results) {
                    const dataTable = items.data

                    this.setState({
                        loadingVacantes: false,
                        loading: false,
                        totalVacantes: items.total,
                        PlazasVacantes: dataTable.map((prop, key) => {
                            return {
                                id: prop.uid,
                                key: prop.uid,
                                label:
                                    prop.Plaza +
                                    ' - ' +
                                    prop.Denominacion_funcional,
                                Dependencia_organismo: prop.Dependencia,
                                Nivel: prop.Nivel,
                                Tipo: prop.Tipo,
                                Nombre: prop.Nombre,
                                Denominacion_tabular: prop.Denominacion_tabular,
                                Denominacion_funcional:
                                    prop.Denominacion_funcional,
                                Estatus: prop.Estatus,
                                Municipio: prop.Municipio,
                                FechaVacante: prop.Fecha_vacante,
                                NombreBtn: (
                                    <Button
                                        className="btn-link"
                                        color="primary"
                                        onClick={() => {
                                            this.setBaja(prop)
                                        }}
                                    >{`${prop.Nombre}`}</Button>
                                ),
                                data: prop,
                            }
                        }),
                    })
                }
            })
            .catch((e) => {
                console.log('error getPlazas Vacantes: ', e.message)
                Notify(
                    3,
                    'tc',
                    `Error al cargar plazas: ${e.message}`,
                    this.refs
                )
                this.setState({ loadingVacantes: false })
            })
    }

    getPlazas = (Movimiento, Dependencia) => {
        const token = global.access_token

        var data = {}

        this.setState({ loadingPlazas: true })

        console.log('==> Checar Movmiento: ', Movimiento)

        if (Movimiento === 2 || Movimiento === 3) {
            data = {
                page: 0,
                tipo: 'and',
                pageSize: 99999,
                sorted: [],
                filtered: [
                    {
                        id: 'Dependencia_organismo',
                        value: Dependencia,
                    }
                ],
            }
        } else {
            data = {
                page: 0,
                tipo: 'and',
                pageSize: 99999,
                sorted: [],
                filtered: [
                    {
                        id: 'Dependencia_organismo',
                        value: Dependencia,
                    },
                    {
                        id: 'Estatus',
                        value: 'OCUPADA',
                    },
                ],
            }
        }

        console.log('==> Buscar getPlazas: ', JSON.stringify(data))
        EjecutarApi(data, 'getPlantillas', token)
            .then(function (res) {
                return res
            })
            .then((items) => {
                console.log('===> Data Plazas: ', items)

                if (items.results) {
                    const dataTable = items.data

                    this.setState(
                        {
                            loadingPlazas: false,
                            loading: false,
                            total: items.total,
                            catPlazas: dataTable.map((prop, key) => {
                                return {
                                    id: prop.uid,
                                    key: prop.uid,
                                    label:
                                        prop.Plaza +
                                        ' - ' +
                                        prop.Denominacion_funcional,
                                    Dependencia_organismo: prop.Dependencia,
                                    Nivel: prop.Nivel,
                                    Tipo: prop.Tipo,
                                    Denominacion_tabular:
                                        prop.Denominacion_tabular,
                                    Denominacion_funcional:
                                        prop.Denominacion_funcional,
                                    Estatus: prop.Estatus,
                                    Municipio: prop.Municipio,
                                    FechaVacante: prop.Fecha_vacante,
                                    data: prop,
                                }
                            }),
                        },
                        () => {
                            const Data = this.state.catPlazas
                            Data.push({ id: '999999', label: 'Nueva Plaza' })
                            this.setState({ catPlazas: Data })

                            console.log('==>Plazas: ', Data)
                        }
                    )
                }
            })
            .catch((e) => {
                console.log('error getPlazas: ', e.message)
                Notify(
                    3,
                    'tc',
                    `Error al cargar plazas: ${e.message}`,
                    this.refs
                )
                this.setState({ loadingPlazas: false })
            })
    }

    setDependencia = (e) => {
        console.log(e)

        this.setState({
            Plaza: { value: 'undefined', label: '' },
            idDependencia: {
                value: e.id,
                label: e.label,
            },
        })

        if (this.state.Movimiento.value >= 3) {
            console.log('=> Busca plaza ocupadas')
            this.getPlazasOcupadas(e.label)
        } else {
            this.setState({
                loadingPlazas: true,
                Plaza: { value: 'undefined', label: '' },
                idDependencia: {
                    value: e.id,
                    label: e.label,
                },
            })
            this.getPlazas(this.state.Movimiento.value, e.label)
        }
    }

    setSexo = (e) => {
        console.log('=>EstadoCivil: ', e)

        this.setState({
            Sexo: {
                value: e.value,
                label: e.label,
            },
        })
    }

    setEstadoCivil = (e) => {
        console.log('=>EstadoCivil: ', e)

        this.setState({
            idEstadoCivil: {
                value: e.value,
                label: e.label,
            },
        })
    }
    setNivelEstudios = (e) => {
        console.log('=>NivelEstudios: ', e)

        this.setState({
            idNivelEstudio: {
                value: e.value,
                label: e.label,
            },
        })
    }

    setPerfilCarrera = (e) => {
        console.log('=>PerfilCarrera: ', e)

        this.setState({
            idPerfilCarrera: {
                value: e.value,
                label: e.label,
            },
        })
    }

    onErrorRedirect = (error) => {
        console.log('Hubo un error al buscar personas', error)
    }

    setPlaza = (Plaza) => {
        console.log('==>Seleccoona Plaza: ', Plaza)

        if (Plaza.id === '999999') {
            console.log('=> Capturar nueva Plaza')
            this.toggleNuevaPlaza()
        }

        this.setState({ Plaza })
    }

    setNuevaPlaza = (Plaza) => {
        this.setState({ NuevaPlaza: Plaza })
        this.toggleNuevaPlaza()
    }

    setNewProponeSolicitud = (Persona) => {
        console.log('==> Se guarda, quien propone en Solicitud ', Persona)
        this.setState({
            PersonaProponeSolicitud: Persona,
            PersonaPropone: Persona,
        })
    }

    setNewPerson = (Persona) => {
        this.setState({
            INE: '',
            CURP: '',
            id: null,
            Nombre: Persona.Nombre,
            Paterno: Persona.Paterno,
            Materno: Persona.Materno,
            TelCasa: '',
            Celular: '',
            CorreoElectronico: '',
            Facebook: '',
            Twitter: '',
            Instagram: '',
            GradoObtenido: '',
            GradoMaestria: '',
            UltimoTrabajo: '',
            idEstadoCivil: {
                value: 'undefined',
                label: 'Seleccione',
            },
            idNivelEstudio: {
                value: 'undefined',
                label: 'Seleccione',
            },
            Municipio: {
                value: 61,
                label: 'No Definido',
            },
            Sexo: {
                value: 1,
                label: 'No Definido',
            },
            idStatus: {
                value: 'undefined',
                label: 'undefined',
            },
            showDatosPersonales: true,
            readPersonales: false,
        })
    }

    setPropone = (PersonaPropone) => {
        console.log('==>Set Propone X', PersonaPropone)
        this.setState({ PersonaPropone })
    }
    setProponeSolicitud = (PersonaPropone) => {
        console.log('==>Set Propone X', PersonaPropone)
        this.setState({
            dataSolicitud: { dataSolicitud: PersonaPropone },
            PersonaPropone: PersonaPropone,
        })
        // this.setState({ PersonaPropone : PersonaPropone });
    }

    setPersona = (perfil) => {
        console.log('==>Set ', perfil)
        this.setState({
            id: perfil.id,
            Nombre: perfil.Nombre,
            Paterno: perfil.Paterno,
            Materno: perfil.Materno,
            INE: perfil.INE,
            CURP: perfil.CURP,
            Celular1: perfil.Celular1,
            Celular2: perfil.Celular2,
            CorreoElectronico: perfil.CorreoElectronico,
            Facebook: perfil.Facebook,
            Instagram: perfil.Instagram,
            Twitter: perfil.Twitter,
            GradoObtenido: perfil.GradoObtenido,
            GradoMaestria: perfil.GradoMaestria || '',
            Telcasa: perfil.TelCasa,
            UltimoTrabajo: perfil.UltimoTrabajo,
            idStatus: perfil.estatus,
            idEstadoCivil: perfil.estado_civil,
            idNivelEstudio: perfil.estudios,
            Municipio: perfil.municipios,
            Sexo: perfil.sexo,
            showDatosPersonales: true,
            readPersonales: true,
        })
    }

    setBaja = (Baja) => {
        this.setState({ Baja })
    }

    setPromocion = (Promocion) => {
        this.setState({ Promocion })
    }
    setSolicitudDependencia = (e) => {
        console.log('setSolicitudDependencia', e)

        this.setState({
            SolicitudidDependencia: e.value,
            SolicitudDependencia: e.label,
            Plaza: { value: 'undefined', label: '' },
            idDependencia: {
                value: e.value,
                label: e.label,
            },
        })
    }
    setFolioSolicitud = (e) => {
        console.log('FolioSolicitud', e)
        this.setState({ SolicitudFolioSolicitud: e.target.value })
    }
    setFechaOficioSolicitud = (e) => {
        console.log('FechaOficio', e)
        this.setState({
            SolicitudFechaOficio: moment(e._d).format('YYYY-MM-DD'),
        })
    }
    setFechaRecepcionSolicitud = (e) => {
        console.log('FechaRecepcion', e)
        this.setState({
            SolicitudFechaRecepcion: moment(e._d).format('YYYY-MM-DD'),
        })
    }
    saveSolicitud = () => {
        if (this.state.idDependencia.value === 'undefined') {
            Notify(
                3,
                'tc',
                'Error: Debe especificar la dependencia',
                this.refs
            )
            return
        }
        if (this.state.SolicitudFolioSolicitud.length < 3) {
            Notify(3, 'tc', 'Error: Falta Folio de Solicitud', this.refs)
            return
        }
        if (this.state.SolicitudFechaOficio.length < 3) {
            Notify(3, 'tc', 'Error: Falta Fecha Oficio', this.refs)
            return
        }
        if (this.state.SolicitudFechaRecepcion.length < 3) {
            Notify(3, 'tc', 'Error: Falta Fecha Recepción', this.refs)
            return
        }
        if (this.state.PersonaProponeSolicitud.Nombre.length<1) {
            Notify(3, 'tc', 'Error: Falta seleccionar Persona Propone', this.refs)
            return
        }

        if (!this.state.file) {
            Notify(
                3,
                'tc',
                'Error: Debe agregar un documento',
                this.refs
            )
            return
        }
        
        
        this.setState({
            loading: true,
        })
        const token = global.access_token

        var parametros = {}
        if (this.state.PersonaProponeSolicitud.idQuienPropone) {
            parametros = {
                Archivo: formData,
                idQuienPropone: this.state.PersonaProponeSolicitud
                    .idQuienPropone,
                FechaSolicitud: this.state.SolicitudFechaOficio,
                FechaRecepcion: this.state.SolicitudFechaRecepcion,
                FolioOficio: this.state.SolicitudFolioSolicitud,
                idDependencia: this.state.SolicitudidDependencia,
                propone: {
                    Nombre: this.state.PersonaProponeSolicitud.Nombre,
                    Paterno: this.state.PersonaProponeSolicitud.Paterno,
                    Materno: this.state.PersonaProponeSolicitud.Materno,
                    Puesto: this.state.PersonaProponeSolicitud.Puesto,
                    idDependencia: this.state.PersonaProponeSolicitud
                        .Dependencia.value,
                },
            }
        } else {
            parametros = {
                Archivo: formData,
                FechaSolicitud: this.state.SolicitudFechaOficio,
                FechaRecepcion: this.state.SolicitudFechaRecepcion,
                FolioOficio: this.state.SolicitudFolioSolicitud,
                idDependencia: this.state.SolicitudidDependencia,
                propone: {
                    Nombre: this.state.PersonaProponeSolicitud.Nombre,
                    Paterno: this.state.PersonaProponeSolicitud.Paterno,
                    Materno: this.state.PersonaProponeSolicitud.Materno,
                    Puesto: this.state.PersonaProponeSolicitud.Puesto,
                    idDependencia: this.state.PersonaProponeSolicitud
                        .Dependencia.value,
                },
            }
        }
        var propone = {
            propone: {
                Nombre: this.state.PersonaProponeSolicitud.Nombre,
                Paterno: this.state.PersonaProponeSolicitud.Paterno,
                Materno: this.state.PersonaProponeSolicitud.Materno,
                Puesto: this.state.PersonaProponeSolicitud.Puesto,
                idDependenciaPropone: this.state.PersonaProponeSolicitud
                    .Dependencia.value,
            },
        }

        var formData = new FormData()
        formData.append(
            'idQuienPropone',
            this.state.PersonaProponeSolicitud.idQuienPropone
        )
        formData.append('FechaSolicitud', this.state.SolicitudFechaOficio)
        formData.append('FechaRecepcion', this.state.SolicitudFechaRecepcion)
        formData.append('FolioOficio', this.state.SolicitudFolioSolicitud)
        formData.append('idDependencia', this.state.SolicitudidDependencia)
        formData.append('Archivo', this.state.file[0], this.state.file[0].name)
        formData.append('Nombre', this.state.PersonaProponeSolicitud.Nombre)
        formData.append('Paterno', this.state.PersonaProponeSolicitud.Paterno)
        formData.append('Materno', this.state.PersonaProponeSolicitud.Materno)
        formData.append('Puesto', this.state.PersonaProponeSolicitud.Puesto)
        formData.append(
            'idDependenciaPropone',
            this.state.PersonaProponeSolicitud.Dependencia.value
        )
        console.log('Parametros a enviar: ', JSON.stringify(parametros))
        const requestInfo = {
            method: 'POST',
            body: formData,
            headers: new Headers({
                Authorization: `Bearer ${token}`,
            }),
        }
        
        fetch(URL_PUBLICA+'setSolicitudes', requestInfo)
            .then((res) => {
                if (res.ok) {
                    return res.json()
                }
            })
            .then((res) => {
                console.log('Res', res)
                if (res.success) {
                    this.setState({
                        loading: false,
                        areaPropuesta: true,
                        idSolicitud: res.idSolicitud,
                    })

                    Notify(
                        2,
                        'tc',
                        `!! El Registro fue guardado exitosamente!!`,
                        this.refs
                    )
                }
                else if(res.message==='El Folio de la Solicitud ya fue registrada'){
                    Notify(
                        3,
                        'tc',
                        `Error: ${res.message}`,
                        this.refs
                    )
                }
                if (!res.messageError){
                    
                }
            })
            .catch((e) => {
                console.log('=Error[Agregar Solicitud]: ', e)
                Notify(
                    3,
                    'tc',
                    `Error en Agregar Solicitud: ${e.error}`,
                    this.refs
                )
                this.setState({
                    loading: false,
                })
            })
    }
  
    handleVerificaCURP = (e) => {
        console.log('Curp', e)
    }
    handleVerificaINE = (e) => {
        console.log('Ine', e)
    }
    handleImageChange(e) {
        e.preventDefault()
        let reader = new FileReader()
        let file = e.target.files[0]
        console.log('Archivo:', file)
        reader.onloadend = () => {
            this.setState({ file: file })
        }
        reader.readAsDataURL(file)
    }
    handleClick() {
        this.refs.fileInput.click()
    }
    onFilesAdded = (file) => {
        console.log('==>Archivos: ', file)
        this.setState({ file })
    }
    handleEliminar = (ID) => {
        this.setState({ loading: true })
        console.log('vamos a eliminar', ID)
        const token = getVars('Token').access_token

        EjecutarApi({ id: ID }, 'deletePropuesta', token)
            .then(function (res) {
                return res
            })
            .then((res) => {
                console.log('EjecutarAPI->deleteSolicitudes->res:', res)
                if (res.results) {
                    var data = res.data
                    this.setState({ loading: false })
                    Notify(2, 'tc', `Registro Eliminado: `, this.refs)
                }
            })
            .catch((e) => {
                console.log('Error->tblPropuestas: ', e)
                this.setState({ loading: false })
                Notify(
                    3,
                    'tc',
                    `Error al eliminar la propuesta: ${e.error}`,
                    this.refs
                )
            })

        var parametros = {
            filtered: [{ id: 'sm.idSolicitud', value: this.state.idSolicitud }],
            tipo: 'and',
            page: 0,
            pageSize: 10,
        }
        console.log('tblPropuestas->parametros: ', JSON.stringify(parametros))
        EjecutarApi(parametros, 'getPropuestas', token)
            .then(function (res) {
                return res
            })
            .then((res) => {
                console.log('EjecutarAPI->getPropuestas->res:', res)
                if (res.results) {
                    var data = res.data
                    this.setState({
                        totalListadoPropuesta: res.total,
                        ListadoPropuestas: data.map((item, key) => {
                            return {
                                id: item.id,
                                Eliminar: (
                                    <Button
                                        onClick={() => {
                                            this.handleEliminar(item.id)
                                        }}
                                        color="danger"
                                        size="sm"
                                        className="btn-icon btn-link remove"
                                    >
                                        x
                                    </Button>
                                ),
                                Movimiento: item.Movimiento.label,
                                Dependencia: item.Dependencia_organismo,
                                Candidato: item.Candidato.label,
                                Secuencial: item.Plaza,
                                Estatus: item.MovimientoStatus.label,
                                Denominacion_funcional:
                                    item.Denominacion_funcional,
                                Tipo: item.Tipo,
                                Nivel: item.Nivel,
                                data: item,
                            }
                        }),
                    })
                }
            })
            .catch((e) => {
                console.log('Error->tblPropuestas: ', e)
                this.setState({ loading: false })
            })
    }
    getCURP = () => {
        this.setState({loading:true});
        console.log('=> Buscar CURP')
        if (!this.state.CURP) {
            this.setState({loading:false});
            Notify(
                3,
                'tc',
                `Error: CURP inválida!`,
                this.refs
            );
            return true
        }

        if (this.state.CURP.length != 18) {
            this.setState({loading:false});
            Notify(
                3,
                'tc',
                `Error: CURP inválida!`,
                this.refs
            );
            
            return true
        }

        const CURP = this.state.CURP.toUpperCase();
        var sexo_obtenido
        console.log('=> Buscar CURP: ', CURP)
        fetch(
            `https://seguimiento.guanajuato.gob.mx/apiinformacionsocial/api/renapo/porcurp/pL@t_1n|Run$28/${CURP}`
        )
            .then((res) => res.json())
            .then((dataRS) => {
                this.setState({loading:false});
                console.log('=> Resultados CURP', dataRS)
                if (dataRS.Mensaje == 'OK') {
                    //aqui
                    var FN = dataRS.Resultado.fechNac.split('/');
                    console.log('=FNAC: ', FN); //año-mes-dia
                    sexo_obtenido = dataRS.Resultado.sexo;
                    this.setState({
                        Nombre: dataRS.Resultado.nombres,
                        Paterno: dataRS.Resultado.apellido1,
                        Materno: dataRS.Resultado.apellido2,
                        Sexo: dataRS.Resultado.sexo === 'H' ? '2' : '1', // { value: "M", label:"MASCULINO"} : { value: "F", label:"FEMENINO"} , //{ label: dataRS.Resultado.sexo, value: dataRS.Resultado.sexo === 'H' ? 'M' : 'F' },//"M",//dataRS.Resultado.sexo === 'H' ? 'M' : 'F',
                        FechaNacimiento: FN[2] + '-' + FN[1] + '-' + FN[0]
                    });

                    /*setVale({
                        ...vale,
                        Nombre: dataRS.Resultado.nombres,
                        Paterno: dataRS.Resultado.apellido1,
                        Materno: dataRS.Resultado.apellido2,
                        Sexo: dataRS.Resultado.sexo === 'H' ? 'M' : 'F', // { value: "M", label:"MASCULINO"} : { value: "F", label:"FEMENINO"} , //{ label: dataRS.Resultado.sexo, value: dataRS.Resultado.sexo === 'H' ? 'M' : 'F' },//"M",//dataRS.Resultado.sexo === 'H' ? 'M' : 'F',
                        FechaNacimiento: FN[2] + '-' + FN[1] + '-' + FN[0],
                    })*/
                    
                } else {
                    this.setState({loading:false});
                    if (
                        dataRS.Mensaje ===
                        'La CURP no se encuentra en la base de datos'
                    ) {
                        console.log(
                            'La CURP no se encuentra en la base de datos'
                        )
                        Notify(
                            3,
                            'tc',
                            `Error en CURP...! ${dataRS.Mensaje}`,
                            this.refs
                        );
                        
                    } else if (
                        dataRS.Mensaje ===
                        'El campo CURP: No cumple con el formato especificado.'
                    ) {
                        console.log(
                            'El campo CURP: No cumple con el formato especificado.'
                        )
                        Notify(
                            3,
                            'tc',
                            `Error en CURP...! ${dataRS.Mensaje}`,
                            this.refs
                        );
                    } else {
                        console.log('El servicio no esta disponible.')
                    }
                    return true
                }
                console.log('=> Resultados CURP', dataRS)
            })
            .catch((err) => {
                this.setState({loading:false});
                console.log('=>Error en CURO: ', err)
            })
        //
    }
    render() {
        console.log('==> Oficialia Add: ', this.state)

        global = getVars('Token')
       // var dataPropuestas2 = dataPropuestas
        const {
            catalogos,
            catdependencias,
            catPlazas,
            Movimiento,
            loadingPlazas,
            PlazasOcupadas,
            totalOcupadas,
            PlazasVacantes,
            totalVacantes,
            Baja,
            Promocion,
            isVisible,
        } = this.state
        //const { municipios, EstadoCivil, nivel, dependencias } = this.state.catalogos;
        var ItemsDependencia = []
        var ItemsEstadoCivil = []
        var ItemsNivelEstudios = []
        var ItemsPerfilCarrera = []
        var ItemsMunicipio = []

        if (catalogos) {
            if(catdependencias){
                ItemsDependencia=catdependencias;
            }
            ItemsEstadoCivil = catalogos.EstadoCivil
            ItemsNivelEstudios = catalogos.nivel
            ItemsPerfilCarrera = catalogos.perfilcarrera
            ItemsMunicipio = catalogos.municipios
        }
        console.log('==>MovimientoAdd-Edit: ', this.state)

        return (
            <div className="wrapper">
                <Sidebar
                    {...this.props}
                    bgColor={this.state.backgroundColor}
                    activeColor={this.state.activeColor}
                />
                <div className="main-panel" ref="mainPanel">
                    <DashboardNavbar
                        {...this.props}
                        handleMiniClick={this.handleMiniClick}
                    />
                    <NotificationAlert ref="notificationAlert" />
                    <div className="content">
                        <Form
                            className="form-horizontal"
                            name="form1"
                            id="form1"
                            onSubmit={this.handleSubmit}
                            noValidate
                        >
                            {!this.state.IDSolicitud?<>
                                <Row>
                                <Col sm="12">
                                    <Card>
                                        <CardHeader>
                                            <CardTitle tag="h6">
                                                Captura de Solicitud
                                            </CardTitle>
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Col sm="3">
                                                    {this.state.loading && (
                                                        <CircleSpinner
                                                            className="text-center"
                                                            size={30}
                                                            color="#686769"
                                                            loading={
                                                                this.state
                                                                    .loading
                                                            }
                                                        />
                                                    )}
                                                    Dependencia:
                                                    <Select
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        name="DepSolicitud"
                                                        onChange={this.setSolicitudDependencia}
                                                        options={
                                                            ItemsDependencia &&
                                                            ItemsDependencia.constructor ===
                                                                Array
                                                                ? ItemsDependencia
                                                                : []
                                                        }
                                                        placeholder="Seleccione Dependencia"
                                                    />
                                                </Col>
                                                <Col sm="3">
                                                    Folio de Solicitud:
                                                    <Input
                                                        name='FolioSolicitud'
                                                        value={this.state.SolicitudFolioSolicitud.toUpperCase()}
                                                        onChange={
                                                            this
                                                                .setFolioSolicitud
                                                        }
                                                    ></Input>
                                                </Col>
                                                <Col sm="3">
                                                    Fecha Oficio:
                                                    <ReactDatetime
                                                        name='FechaOficio'
                                                        dateFormat="YYYY-MM-DD"
                                                        timeFormat={false}
                                                        closeOnSelect
                                                        onChange={
                                                            this
                                                                .setFechaOficioSolicitud
                                                        }
                                                        inputProps={{
                                                            className:
                                                                'form-control',
                                                            placeholder:
                                                                'Fecha Oficio',
                                                        }}
                                                    />
                                                </Col>
                                                <Col sm="3">
                                                    Fecha Recepción:
                                                    <ReactDatetime
                                                        name='FechaRecepcion'
                                                        dateFormat="YYYY-MM-DD"
                                                        //timeFormat={false}
                                                        closeOnSelect
                                                        onChange={
                                                            this
                                                                .setFechaRecepcionSolicitud
                                                        }
                                                        inputProps={{
                                                            className:
                                                                'form-control',
                                                            placeholder:
                                                                'Fecha Oficio',
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm="4">
                                                    <div className="form-row align-items-center">
                                                        <FormGroup className="col-md-6 pr-0">
                                                            <Label className="text-left">
                                                                Persona Propone {this.state.PersonaProponeSolicitudFullName}
                                                            </Label>
                                                            <Input
                                                                disabled
                                                                id="solPropones"
                                                                value={
                                                                    this.state.PersonaProponeSolicitudFullName
                                                                }
                                                                type="text"
                                                            />
                                                        </FormGroup>

                                                        <FormGroup className="col-md-2 pl-0">
                                                            <br></br>
                                                            <Button className='btn-round'
                                                                onClick={
                                                                    this
                                                                        .togglemodalProponeSolicitud
                                                                }
                                                                color="warning"
                                                            >
                                                                <i className="nc-icon nc-zoom-split lg" />
                                                            </Button>
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                                <Col sm="4">
                                                    <UploadFile
                                                        onFilesAdded={
                                                            this.onFilesAdded
                                                        }
                                                        Accept="pdf"
                                                        title="Cargar excel"
                                                    ></UploadFile>
                                                </Col>

                                                <Col sm="3">
                                                    Estatus:
                                                    <Input
                                                        name="EstatusSolicitud"
                                                        placeholder='Pendiente'
                                                        readOnly={true}
                                                    />
                                                </Col>
                                                <Col>
                                                    {!this.state
                                                        .areaPropuesta ? (
                                                        <Button className ='btn-round'
                                                            color="primary"
                                                            onClick={() =>
                                                                this.saveSolicitud()
                                                            }
                                                        >
                                                            Guardar
                                                        </Button>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </Col>
                                            </Row>
                                            <Row></Row>
                                        </CardBody>
                                        <CardFooter>
                                            <ModalProponeSolicitud
                                                isOpen={
                                                    this.state
                                                        .modalProponeSolicitud
                                                }
                                                newUser={
                                                    this.setNewProponeSolicitud
                                                }
                                                toggle={
                                                    this
                                                        .togglemodalProponeSolicitud
                                                }
                                                setPropone={
                                                    this.setProponeSolicitud
                                                }
                                                onError={this.onErrorRedirect}
                                                ItemsDependencia={
                                                    ItemsDependencia
                                                }
                                            />
                                        </CardFooter>
                                    </Card>
                                </Col>
                            </Row>
                            </>:<>
                                <Row>
                                <Col sm="12">
                                    <Card>
                                        <CardHeader>
                                            <CardTitle tag="h6">
                                                Datos de Solicitud
                                            </CardTitle>
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Col sm="3">
                                                    {this.state.loading && (
                                                        <CircleSpinner
                                                            className="text-center"
                                                            size={30}
                                                            color="#686769"
                                                            loading={
                                                                this.state
                                                                    .loading
                                                            }
                                                        />
                                                    )}
                                                    Dependencia:
                                                    <Input
                                                        name='FolioDependencia'
                                                        value={this.state.SolicitudDependencia}
                                                        disabled
                                                        type="text"
                                                    ></Input>
                                                   
                                                </Col>
                                                <Col sm="3">
                                                    Folio de Solicitud:
                                                    <Input
                                                        name='FolioSolicitud'
                                                        value={this.state.SolicitudFolioSolicitud}
                                                        disabled
                                                        type="text"
                                                    ></Input>
                                                </Col>
                                                <Col sm="3">
                                                    Fecha Oficio:
                                                    <Input
                                                        name='FechaOficio'
                                                        value={this.state.SolicitudFechaOficio}
                                                        disabled
                                                        type="text"
                                                    />
                                                </Col>
                                                <Col sm="3">
                                                    Fecha Recepción:
                                                    <Input
                                                        name='FechaRecepcion'
                                                        value={this.state.SolicitudFechaRecepcion}
                                                        disabled
                                                        type="text"
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm="4">
                                                    <div className="form-row align-items-center">
                                                        <FormGroup className="col-md-6 pr-0">
                                                               Persona Propone
                                                            <Input
                                                                disabled
                                                                id="solPropones"
                                                                defaultValue={console.log('fullname',this.state.PersonaProponeSolicitud.FullName) }
                                                                type="text"
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                               

                                                <Col sm="3">
                                                    Estatus:
                                                    <Input
                                                        name="EstatusSolicitud"
                                                        value={this.state.SolicitudEstatus.label}
                                                        readOnly={true}
                                                    />
                                                </Col>
                                                <Col sm="2">
                                                    Archivo:
                                                    
                                                    <Button className="btn-link" color="primary">
                                                    <i className="nc-icon nc-cloud-download-93" />
                                                    </Button>
                                                </Col>
                                            </Row>
                                            <Row></Row>
                                        </CardBody>
                                        <CardFooter>
                                            <ModalProponeSolicitud
                                                isOpen={
                                                    this.state
                                                        .modalProponeSolicitud
                                                }
                                                newUser={
                                                    this.setNewProponeSolicitud
                                                }
                                                toggle={
                                                    this
                                                        .togglemodalProponeSolicitud
                                                }
                                                setPropone={
                                                    this.setProponeSolicitud
                                                }
                                                onError={this.onErrorRedirect}
                                                ItemsDependencia={
                                                    ItemsDependencia
                                                }
                                            />
                                        </CardFooter>
                                    </Card>
                                </Col>
                            </Row>
                            </>}

                            {this.state.areaPropuesta ? (
                                <>
                                    <Row>
                                        <Col md="12">
                                            <Card>
                                                <CardHeader>
                                                    <CardTitle tag="h6">
                                                        Datos de la Plaza
                                                    </CardTitle>
                                                </CardHeader>
                                                <CardBody>
                                                    <Row>
                                                        <Col sm="3">
                                                            <FormGroup>
                                                                <Label className="text-left">
                                                                    Movimiento
                                                                </Label>
                                                                <Select
                                                                    className="react-select primary"
                                                                    classNamePrefix="react-select"
                                                                    name="Movimiento"
                                                                    value={
                                                                        this
                                                                            .state
                                                                            .Movimiento
                                                                    }
                                                                    onChange={
                                                                        this
                                                                            .setMovimiento
                                                                    }
                                                                    options={[
                                                                        {
                                                                            value:
                                                                                '',
                                                                            label:
                                                                                'Seleccione',
                                                                            isDisabled: true,
                                                                        },

                                                                        {
                                                                            value: 2,
                                                                            label:
                                                                                'Ingreso',
                                                                        },
                                                                        {
                                                                            value: 3,
                                                                            label:
                                                                                'Promoción',
                                                                        },
                                                                        {
                                                                            value: 4,
                                                                            label:
                                                                                'Baja',
                                                                        },
                                                                    ]}
                                                                    placeholder="Seleccione Movimiento"
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm="4">
                                                            <FormGroup>
                                                                <Label className="text-left">
                                                                    Fecha
                                                                    Movimiento
                                                                    (Iniciar el
                                                                    Cargo)
                                                                </Label>
                                                                <ReactDatetime
                                                                    dateFormat="YYYY-MM-DD"
                                                                    timeFormat={
                                                                        false
                                                                    }
                                                                    closeOnSelect
                                                                    onChange={
                                                                        this
                                                                            .setFechaMovimiento
                                                                    }
                                                                    inputProps={{
                                                                        className:
                                                                            'form-control',
                                                                        placeholder:
                                                                            'Fecha Iniciar Cargo',
                                                                    }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        
                                                        
                                                        {Movimiento.value > 1 &&
                                                            Movimiento.value <
                                                                3 && (
                                                                <Col sm="5">
                                                                    <FormGroup>
                                                                        <Label className="text-left">
                                                                            Plaza
                                                                        </Label>
                                                                        {loadingPlazas && (
                                                                            <CircleSpinner
                                                                                size={
                                                                                    30
                                                                                }
                                                                                color="#686769"
                                                                                loading={
                                                                                    loadingPlazas
                                                                                }
                                                                            />
                                                                        )}
                                                                        {!loadingPlazas && (
                                                                            <Select
                                                                                className="react-select primary"
                                                                                classNamePrefix="react-select"
                                                                                name="Plaza"
                                                                                value={
                                                                                    this
                                                                                        .state
                                                                                        .Plaza
                                                                                }
                                                                                onChange={(
                                                                                    value
                                                                                ) =>
                                                                                    this.setPlaza(
                                                                                        value
                                                                                    )
                                                                                }
                                                                                options={
                                                                                    catPlazas &&
                                                                                    catPlazas.constructor ===
                                                                                        Array
                                                                                        ? catPlazas
                                                                                        : []
                                                                                }
                                                                                placeholder="Seleccione Plaza"
                                                                            />
                                                                        )}
                                                                    </FormGroup>
                                                                </Col>
                                                            )}
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        {Movimiento.value !== 'undefined' && (
                                            <Col md="12">
                                                <Card>
                                                    <CardHeader>
                                                        <CardTitle tag="h4">
                                                            {Movimiento.value ===
                                                            4
                                                                ? 'Seleccione plaza a eliminar'
                                                                : Movimiento.value ===
                                                                  3
                                                                ? 'Persona a Promover'
                                                                : 'Persona Propuesta'}
                                                        </CardTitle>
                                                    </CardHeader>
                                                    <CardBody>
                                                        {Movimiento.value <
                                                            3 && (
                                                            <Row>
                                                                <Col sm="3">
                                                                    <FormGroup>
                                                                        <Label className="text-left">
                                                                            Nombre
                                                                        </Label>
                                                                        <Input
                                                                            readOnly={
                                                                                this
                                                                                    .state
                                                                                    .readPersonales
                                                                            }
                                                                            placeholder="Nombre"
                                                                            id="Nombre"
                                                                            value={
                                                                                this
                                                                                    .state
                                                                                    .Nombre.toUpperCase()
                                                                            }
                                                                            type="text"
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                this.setState(
                                                                                    {
                                                                                        Nombre:
                                                                                            e
                                                                                                .target
                                                                                                .value.toUpperCase(),
                                                                                    }
                                                                                )
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col sm="3">
                                                                    <FormGroup>
                                                                        <Label className="text-left">
                                                                            Paterno
                                                                        </Label>
                                                                        <Input
                                                                            readOnly={
                                                                                this
                                                                                    .state
                                                                                    .readPersonales
                                                                            }
                                                                            placeholder="Paterno"
                                                                            id="Paterno"
                                                                            value={
                                                                                this
                                                                                    .state
                                                                                    .Paterno.toUpperCase()
                                                                            }
                                                                            type="text"
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                this.setState(
                                                                                    {
                                                                                        Paterno:
                                                                                            e
                                                                                                .target
                                                                                                .value.toUpperCase(),
                                                                                    }
                                                                                )
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col sm="3">
                                                                    <FormGroup>
                                                                        <Label className="text-left">
                                                                            Materno
                                                                        </Label>

                                                                        <Input
                                                                            readOnly={
                                                                                this
                                                                                    .state
                                                                                    .readPersonales
                                                                            }
                                                                            placeholder="Materno"
                                                                            id="Materno"
                                                                            value={
                                                                                this
                                                                                    .state
                                                                                    .Materno.toUpperCase()
                                                                            }
                                                                            type="text"
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                this.setState(
                                                                                    {
                                                                                        Materno:
                                                                                            e
                                                                                                .target
                                                                                                .value.toUpperCase(),
                                                                                    }
                                                                                )
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col sm="3">
                                                                    <FormGroup>
                                                                        <Button
                                                                            className="btn-round mb-3"
                                                                            color="warning"
                                                                            onClick={
                                                                                this
                                                                                    .searchUser
                                                                            }
                                                                        >
                                                                            <span className="btn-label">
                                                                                <i className="nc-icon nc-zoom-split" />
                                                                            </span>
                                                                            Buscar
                                                                        </Button>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        )}

                                                        <ModalSearchUser
                                                            isOpen={
                                                                this.state
                                                                    .modalCandidato
                                                            }
                                                            Nombre={{
                                                                Nombre: this
                                                                    .state
                                                                    .Nombre,
                                                                Paterno: this
                                                                    .state
                                                                    .Paterno,
                                                                Materno: this
                                                                    .state
                                                                    .Materno,
                                                            }}
                                                            newUser={
                                                                this
                                                                    .setNewPerson
                                                            }
                                                            toggle={
                                                                this
                                                                    .togglemodalCandidato
                                                            }
                                                            setPersona={
                                                                this.setPersona
                                                            }
                                                            onError={
                                                                this
                                                                    .onErrorRedirect
                                                            }
                                                        />

                                                        <ModalPropone
                                                            isOpen={
                                                                this.state
                                                                    .modalPropone
                                                            }
                                                            newUser={
                                                                this
                                                                    .setNewPropone
                                                            }
                                                            toggle={
                                                                this
                                                                    .togglemodalPropone
                                                            }
                                                            setPropone={
                                                                this.setPropone
                                                            }
                                                            onError={
                                                                this
                                                                    .onErrorRedirect
                                                            }
                                                            ItemsDependencia={
                                                                ItemsDependencia
                                                            }
                                                        />

                                                        <ModalNuevaPlaza
                                                            isOpen={
                                                                this.state
                                                                    .modalPlaza
                                                            }
                                                            Dependencia={
                                                                this.state
                                                                    .idDependencia
                                                            }
                                                            municipio={
                                                                ItemsMunicipio
                                                            }
                                                            toggle={
                                                                this
                                                                    .toggleNuevaPlaza
                                                            }
                                                            setNuevaPlaza={
                                                                this
                                                                    .setNuevaPlaza
                                                            }
                                                            onError={
                                                                this
                                                                    .onErrorRedirect
                                                            }
                                                        />

                                                        {this.state
                                                            .showDatosPersonales &&
                                                            Movimiento.value <
                                                                3 && (
                                                                <div>
                                                                    <Row>
                                                                        <Col sm="3">
                                                                            <FormGroup>
                                                                                <Label className="text-left">
                                                                                    Sexo
                                                                                </Label>
                                                                                <Select
                                                                                    className="react-select primary"
                                                                                    classNamePrefix="react-select"
                                                                                    name="Sexo"
                                                                                    value={
                                                                                        this
                                                                                            .state
                                                                                            .Sexo
                                                                                    }
                                                                                    onChange={
                                                                                        this
                                                                                            .setSexo
                                                                                    }
                                                                                    options={[
                                                                                        {
                                                                                            value: 1,
                                                                                            label:
                                                                                                'No Definido',
                                                                                        },
                                                                                        {
                                                                                            value: 2,
                                                                                            label:
                                                                                                'Hombre',
                                                                                        },
                                                                                        {
                                                                                            value: 3,
                                                                                            label:
                                                                                                'Mujer',
                                                                                        },
                                                                                    ]}
                                                                                    placeholder="Seleccione Sexo"
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>

                                                                        <Col sm="3">
                                                                            <FormGroup>
                                                                                <Label className="text-left">
                                                                                    Estado
                                                                                    Civil
                                                                                </Label>
                                                                                <Select
                                                                                    className="react-select primary"
                                                                                    classNamePrefix="react-select"
                                                                                    name="idEstadoCivil"
                                                                                    value={
                                                                                        this
                                                                                            .state
                                                                                            .idEstadoCivil
                                                                                    }
                                                                                    onChange={
                                                                                        this
                                                                                            .setEstadoCivil
                                                                                    }
                                                                                    options={
                                                                                        ItemsEstadoCivil &&
                                                                                        ItemsEstadoCivil.constructor ===
                                                                                            Array
                                                                                            ? ItemsEstadoCivil
                                                                                            : []
                                                                                    }
                                                                                    placeholder="Seleccione Estado Civil"
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>

                                                                        <Col sm="3">
                                                                            <FormGroup>
                                                                                <Label className="text-left">
                                                                                    INE
                                                                                </Label>
                                                                                <Input
                                                                                    placeholder="INE"
                                                                                    id="INE"
                                                                                    type="text"
                                                                                    value={this.state.INE?
                                                                                        this
                                                                                            .state
                                                                                            .INE.toUpperCase():''
                                                                                    }
                                                                                    onChange={(
                                                                                        e
                                                                                    ) =>
                                                                                        this.setState(
                                                                                            {
                                                                                                INE:
                                                                                                    e
                                                                                                        .target
                                                                                                        .value.toUpperCase(),
                                                                                            }
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                        
                                                                    </Row>
                                                                    <Row>
                                                                        <Col sm="4">
                                                                            <FormGroup>
                                                                                <Label className="text-left">
                                                                                    CURP
                                                                                </Label>
                                                                                <Input
                                                                                    placeholder="CURP"
                                                                                    id="CURP"
                                                                                    value={this.state.CURP?
                                                                                        this
                                                                                            .state
                                                                                            .CURP.toUpperCase():''
                                                                                    }
                                                                                    type="text"
                                                                                    onChange={(
                                                                                        e
                                                                                    ) =>
                                                                                        this.setState(
                                                                                            {
                                                                                                CURP:
                                                                                                    e
                                                                                                        .target
                                                                                                        .value.toUpperCase(),
                                                                                            }
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col
                                                                            sm="2"
                                                                            className=" absolute-center"
                                                                        >
                                                                            <Button
                                                                                name="btnCurp"
                                                                                color="warning"
                                                                                className='btn-round'
                                                                                onClick={() => this.getCURP }>
                                                                                <span className="btn-label">
                                                                                    <i className="nc-icon nc-zoom-split" />
                                                                                </span>
                                                                                Validar
                                                                            </Button>
                                                                        </Col>
                                                                        <Col
                                                                            sm="5"
                                                                            className=" absolute-center"
                                                                        >
                                                                            <UploadFile
                                                                                onFilesAdded={
                                                                                    this.onFilesAdded
                                                                                }
                                                                                Accept="pdf"
                                                                                title="adjuntaCV"
                                                                            ></UploadFile>
                                                                        </Col>
                                                                        </Row>
                                                                    <Row>
                                                                        <Col sm="3">
                                                                            <FormGroup>
                                                                                <Label className="text-left">
                                                                                    Municipio{' '}
                                                                                </Label>
                                                                                <Select
                                                                                    className="react-select primary"
                                                                                    classNamePrefix="react-select"
                                                                                    name="Municipio"
                                                                                    value={
                                                                                        this
                                                                                            .state
                                                                                            .Municipio
                                                                                    }
                                                                                    onChange={(
                                                                                        value
                                                                                    ) =>
                                                                                        this.setState(
                                                                                            {
                                                                                                Municipio: value,
                                                                                            }
                                                                                        )
                                                                                    }
                                                                                    options={
                                                                                        ItemsMunicipio &&
                                                                                        ItemsMunicipio.constructor ===
                                                                                            Array
                                                                                            ? ItemsMunicipio
                                                                                            : []
                                                                                    }
                                                                                    placeholder="Seleccione municipio"
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                        
                                                                        <Col sm="3">
                                                                            <FormGroup>
                                                                                <Label className="text-left">
                                                                                    Último
                                                                                    Empleo
                                                                                </Label>
                                                                                <Input
                                                                                    placeholder="último Trabajo"
                                                                                    id="UltimoTrabajo"
                                                                                    value={
                                                                                        this
                                                                                            .state
                                                                                            .UltimoTrabajo
                                                                                    }
                                                                                    type="text"
                                                                                    onChange={(
                                                                                        e
                                                                                    ) =>
                                                                                        this.setState(
                                                                                            {
                                                                                                UltimoTrabajo:
                                                                                                    e
                                                                                                        .target
                                                                                                        .value,
                                                                                            }
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col sm="3">
                                                                            <FormGroup>
                                                                                <Label className="text-left">
                                                                                    Nivel
                                                                                    de
                                                                                    Estudios
                                                                                </Label>
                                                                                <Select
                                                                                    className="react-select primary"
                                                                                    classNamePrefix="react-select"
                                                                                    name="idNivelEstudio"
                                                                                    value={
                                                                                        this
                                                                                            .state
                                                                                            .idNivelEstudio
                                                                                    }
                                                                                    onChange={
                                                                                        this
                                                                                            .setNivelEstudios
                                                                                    }
                                                                                    options={
                                                                                        ItemsNivelEstudios &&
                                                                                        ItemsNivelEstudios.constructor ===
                                                                                            Array
                                                                                            ? ItemsNivelEstudios
                                                                                            : []
                                                                                    }
                                                                                    placeholder="Seleccione Estudios"
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                        </Row>
                                                                    <Row>
                                                                        
                                                                        {this
                                                                            .state
                                                                            .idNivelEstudio
                                                                            .value >
                                                                            8 && (
                                                                            <Col sm="3">
                                                                                <FormGroup>
                                                                                    <Label className="text-left">
                                                                                        Grado
                                                                                        Obtenido
                                                                                    </Label>
                                                                                    <Input
                                                                                        placeholder="GradoObtenido"
                                                                                        id="GradoObtenido"
                                                                                        value={
                                                                                            this
                                                                                                .state
                                                                                                .GradoObtenido
                                                                                        }
                                                                                        type="text"
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            this.setState(
                                                                                                {
                                                                                                    GradoObtenido:
                                                                                                        e
                                                                                                            .target
                                                                                                            .value,
                                                                                                }
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                        )}
                                                                        {(this
                                                                            .state
                                                                            .idNivelEstudio
                                                                            .value >
                                                                            11 ||
                                                                            this
                                                                                .state
                                                                                .idNivelEstudio
                                                                                .value <
                                                                                14) && (
                                                                            <Col sm="3">
                                                                                <FormGroup>
                                                                                    <Label className="text-left">
                                                                                        Grado
                                                                                        Obtenido
                                                                                        Maestría
                                                                                    </Label>
                                                                                    <Input
                                                                                        placeholder="GradoMaestria"
                                                                                        id="GradoMaestria"
                                                                                        value={
                                                                                            this
                                                                                                .state
                                                                                                .GradoMaestria
                                                                                        }
                                                                                        type="text"
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            this.setState(
                                                                                                {
                                                                                                    GradoMaestria:
                                                                                                        e
                                                                                                            .target
                                                                                                            .value,
                                                                                                }
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                        )}
                                                                        <Col sm="3">
                                                                            <FormGroup>
                                                                                <Label className="text-left">
                                                                                    Perfil
                                                                                    Carrera
                                                                                </Label>
                                                                                <Select
                                                                                    className="react-select primary"
                                                                                    classNamePrefix="react-select"
                                                                                    name="idPerfilCarrera"
                                                                                    value={
                                                                                        this
                                                                                            .state
                                                                                            .idPerfilCarrera
                                                                                    }
                                                                                    onChange={
                                                                                        this
                                                                                            .setPerfilCarrera
                                                                                    }
                                                                                    options={
                                                                                        ItemsPerfilCarrera &&
                                                                                        ItemsPerfilCarrera.constructor ===
                                                                                            Array
                                                                                            ? ItemsPerfilCarrera
                                                                                            : []
                                                                                    }
                                                                                    placeholder="Perfil Carrera"
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                    
                                                                        <Col sm="4">
                                                                            <FormGroup>
                                                                                <Label className="text-left">
                                                                                    Celular
                                                                                </Label>
                                                                                <Input
                                                                                    placeholder="Celular"
                                                                                    id="Celular"
                                                                                    value={`${this.state.Celular}`}
                                                                                    type="text"
                                                                                    onChange={(
                                                                                        e
                                                                                    ) =>
                                                                                        this.setState(
                                                                                            {
                                                                                                Celular:
                                                                                                    e
                                                                                                        .target
                                                                                                        .value,
                                                                                            }
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col sm="4">
                                                                            <FormGroup>
                                                                                <Label className="text-left">
                                                                                    Tel
                                                                                    Casa
                                                                                </Label>
                                                                                <Input
                                                                                    placeholder="TelCasa"
                                                                                    id="TelCasa"
                                                                                    value={
                                                                                        this
                                                                                            .state
                                                                                            .TelCasa
                                                                                    }
                                                                                    type="text"
                                                                                    onChange={(
                                                                                        e
                                                                                    ) =>
                                                                                        this.setState(
                                                                                            {
                                                                                                TelCasa:
                                                                                                    e
                                                                                                        .target
                                                                                                        .value,
                                                                                            }
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col sm="4">
                                                                            <FormGroup>
                                                                                <Label className="text-left">
                                                                                    Correo
                                                                                    Electrónico
                                                                                </Label>
                                                                                <Input
                                                                                    placeholder="Correo"
                                                                                    id="Correo"
                                                                                    value={
                                                                                        this
                                                                                            .state
                                                                                            .CorreoElectronico
                                                                                    }
                                                                                    type="text"
                                                                                    onChange={(
                                                                                        e
                                                                                    ) =>
                                                                                        this.setState(
                                                                                            {
                                                                                                CorreoElectronico:
                                                                                                    e
                                                                                                        .target
                                                                                                        .value,
                                                                                            }
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col sm="4">
                                                                            <FormGroup>
                                                                                <Label className="text-left">
                                                                                    Facebook
                                                                                </Label>
                                                                                <Input
                                                                                    placeholder="Facebook"
                                                                                    id="Facebook"
                                                                                    value={
                                                                                        this
                                                                                            .state
                                                                                            .Facebook
                                                                                    }
                                                                                    type="text"
                                                                                    onChange={(
                                                                                        e
                                                                                    ) =>
                                                                                        this.setState(
                                                                                            {
                                                                                                Facebook:
                                                                                                    e
                                                                                                        .target
                                                                                                        .value,
                                                                                            }
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col sm="4">
                                                                            <FormGroup>
                                                                                <Label className="text-left">
                                                                                    Twitter
                                                                                </Label>
                                                                                <Input
                                                                                    placeholder="Twitter"
                                                                                    id="Twitter"
                                                                                    value={
                                                                                        this
                                                                                            .state
                                                                                            .Twitter
                                                                                    }
                                                                                    type="text"
                                                                                    onChange={(
                                                                                        e
                                                                                    ) =>
                                                                                        this.setState(
                                                                                            {
                                                                                                Twitter:
                                                                                                    e
                                                                                                        .target
                                                                                                        .value,
                                                                                            }
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col sm="4">
                                                                            <FormGroup>
                                                                                <Label className="text-left">
                                                                                    Instagram
                                                                                </Label>
                                                                                <Input
                                                                                    placeholder="Instagram"
                                                                                    id="Instagram"
                                                                                    value={
                                                                                        this
                                                                                            .state
                                                                                            .Instagram
                                                                                    }
                                                                                    type="text"
                                                                                    onChange={(
                                                                                        e
                                                                                    ) =>
                                                                                        this.setState(
                                                                                            {
                                                                                                Instagram:
                                                                                                    e
                                                                                                        .target
                                                                                                        .value,
                                                                                            }
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col sm="8">
                                                                            <FormGroup>
                                                                                <Label className="text-left">
                                                                                    Comentarios
                                                                                </Label>
                                                                                <Input
                                                                                    placeholder="Comentarios"
                                                                                    id="Comentarios"
                                                                                    value={
                                                                                        this
                                                                                            .state
                                                                                            .Comentarios
                                                                                    }
                                                                                    type="textarea"
                                                                                    onChange={(
                                                                                        e
                                                                                    ) =>
                                                                                        this.setState(
                                                                                            {
                                                                                                Comentarios:
                                                                                                    e
                                                                                                        .target
                                                                                                        .value,
                                                                                            }
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            )}
                                                        {Movimiento.value >=
                                                            3 &&
                                                            Baja.uid ===
                                                                'undefined' && (
                                                                <ReactTable
                                                                    data={
                                                                        PlazasOcupadas
                                                                    }
                                                                    filterable
                                                                    columns={[
                                                                        {
                                                                            Header:
                                                                                'Nombre',
                                                                            minWidth: 80,
                                                                            id:
                                                                                'Nombre',
                                                                            accessor: (
                                                                                d
                                                                            ) =>
                                                                                d.NombreBtn,
                                                                            filterMethod: (
                                                                                filter,
                                                                                rows
                                                                            ) =>
                                                                                matchSorter(
                                                                                    rows,
                                                                                    filter.value,
                                                                                    {
                                                                                        keys: [
                                                                                            'Nombre',
                                                                                        ],
                                                                                    }
                                                                                ),
                                                                            filterAll: true,
                                                                        },
                                                                        {
                                                                            Header:
                                                                                'Dependencia',
                                                                            minWidth: 80,
                                                                            id:
                                                                                'Dependencia_organismo',
                                                                            accessor: (
                                                                                d
                                                                            ) =>
                                                                                d.Dependencia_organismo,
                                                                            filterMethod: (
                                                                                filter,
                                                                                rows
                                                                            ) =>
                                                                                matchSorter(
                                                                                    rows,
                                                                                    filter.value,
                                                                                    {
                                                                                        keys: [
                                                                                            'Dependencia_organismo',
                                                                                        ],
                                                                                    }
                                                                                ),
                                                                            filterAll: true,
                                                                        },
                                                                        {
                                                                            Header:
                                                                                'Funcional',
                                                                            minWidth: 150,
                                                                            id:
                                                                                'Denominacion_funcional',
                                                                            accessor: (
                                                                                d
                                                                            ) =>
                                                                                d.Denominacion_funcional,
                                                                            filterMethod: (
                                                                                filter,
                                                                                rows
                                                                            ) =>
                                                                                matchSorter(
                                                                                    rows,
                                                                                    filter.value,
                                                                                    {
                                                                                        keys: [
                                                                                            'Denominacion_funcional',
                                                                                        ],
                                                                                    }
                                                                                ),
                                                                            filterAll: true,
                                                                        },
                                                                        {
                                                                            Header:
                                                                                'Tabular',
                                                                            minWidth: 50,
                                                                            id:
                                                                                'Denominacion_tabular',
                                                                            accessor: (
                                                                                d
                                                                            ) =>
                                                                                d.Denominacion_tabular,
                                                                            filterMethod: (
                                                                                filter,
                                                                                rows
                                                                            ) =>
                                                                                matchSorter(
                                                                                    rows,
                                                                                    filter.value,
                                                                                    {
                                                                                        keys: [
                                                                                            'Denominacion_tabular',
                                                                                        ],
                                                                                    }
                                                                                ),
                                                                            filterAll: true,
                                                                        },
                                                                        {
                                                                            Header:
                                                                                'Nivel',
                                                                            minWidth: 50,
                                                                            id:
                                                                                'Nivel',
                                                                            accessor: (
                                                                                d
                                                                            ) =>
                                                                                d.Nivel,
                                                                            filterMethod: (
                                                                                filter,
                                                                                rows
                                                                            ) =>
                                                                                matchSorter(
                                                                                    rows,
                                                                                    filter.value,
                                                                                    {
                                                                                        keys: [
                                                                                            'Nivel',
                                                                                        ],
                                                                                    }
                                                                                ),
                                                                            filterAll: true,
                                                                        },
                                                                        {
                                                                            Header:
                                                                                'Secuencial',
                                                                            minWidth: 50,
                                                                            id:
                                                                                'Plaza',
                                                                            accessor: (
                                                                                d
                                                                            ) =>
                                                                                d.Plaza,
                                                                            filterMethod: (
                                                                                filter,
                                                                                rows
                                                                            ) =>
                                                                                matchSorter(
                                                                                    rows,
                                                                                    filter.value,
                                                                                    {
                                                                                        keys: [
                                                                                            'Plaza',
                                                                                        ],
                                                                                    }
                                                                                ),
                                                                            filterAll: true,
                                                                        },
                                                                        {
                                                                            Header:
                                                                                'Municipio',
                                                                            minWidth: 50,
                                                                            id:
                                                                                'Municipio',
                                                                            accessor: (
                                                                                d
                                                                            ) =>
                                                                                d.Municipio,
                                                                            filterMethod: (
                                                                                filter,
                                                                                rows
                                                                            ) =>
                                                                                matchSorter(
                                                                                    rows,
                                                                                    filter.value,
                                                                                    {
                                                                                        keys: [
                                                                                            'Municipio',
                                                                                        ],
                                                                                    }
                                                                                ),
                                                                            filterAll: true,
                                                                        },
                                                                        {
                                                                            Header:
                                                                                'Tipo',
                                                                            minWidth: 50,
                                                                            id:
                                                                                'Tipo',
                                                                            accessor: (
                                                                                d
                                                                            ) =>
                                                                                d.Tipo,
                                                                            filterMethod: (
                                                                                filter,
                                                                                rows
                                                                            ) =>
                                                                                matchSorter(
                                                                                    rows,
                                                                                    filter.value,
                                                                                    {
                                                                                        keys: [
                                                                                            'Tipo',
                                                                                        ],
                                                                                    }
                                                                                ),
                                                                            filterAll: true,
                                                                        },
                                                                        {
                                                                            Header:
                                                                                'Estatus',
                                                                            minWidth: 50,
                                                                            id:
                                                                                'Estatus',
                                                                            accessor: (
                                                                                d
                                                                            ) =>
                                                                                d.Estatus,
                                                                            filterMethod: (
                                                                                filter,
                                                                                rows
                                                                            ) =>
                                                                                matchSorter(
                                                                                    rows,
                                                                                    filter.value,
                                                                                    {
                                                                                        keys: [
                                                                                            'Estatus',
                                                                                        ],
                                                                                    }
                                                                                ),
                                                                            filterAll: true,
                                                                        },
                                                                    ]}
                                                                    defaultPageSize={
                                                                        10
                                                                    }
                                                                    showPagination={
                                                                        true
                                                                    }
                                                                    showPaginationTop={
                                                                        false
                                                                    }
                                                                    showPaginationBottom={
                                                                        true
                                                                    }
                                                                    manual
                                                                    canNextFromData={
                                                                        true
                                                                    }
                                                                    showTotalPages={
                                                                        true
                                                                    }
                                                                    PaginationComponent={
                                                                        Pagination
                                                                    }
                                                                    previousText="Anterior"
                                                                    nextText="Siguiente"
                                                                    ofText="de"
                                                                    total={
                                                                        totalOcupadas
                                                                    }
                                                                    loading={
                                                                        this
                                                                            .state
                                                                            .loadingOcupadas
                                                                    }
                                                                    id="tbContraPropuesta"
                                                                    onFetchData={(
                                                                        state,
                                                                        instance
                                                                    ) => {
                                                                        console.log(
                                                                            '==>state Ocupadas <===: ',
                                                                            state
                                                                        )

                                                                        const token =
                                                                            global.access_token

                                                                        var data = {}

                                                                        const Filtered =
                                                                            state
                                                                                .filtered
                                                                                .length <
                                                                            1
                                                                                ? [
                                                                                      {
                                                                                          id:
                                                                                              'Dependencia_organismo',
                                                                                          value: this
                                                                                              .state
                                                                                              .SolicitudDependencia,
                                                                                      }/*,
                                                                                      {
                                                                                          id:
                                                                                              'Estatus',
                                                                                          value:
                                                                                              'OCUPADA',
                                                                                      },*/
                                                                                  ]
                                                                                : state.filtered

                                                                        this.setState(
                                                                            {
                                                                                loadingOcupadas: true,
                                                                                firstOcupada: false,
                                                                            }
                                                                        )

                                                                        console.log(
                                                                            '==> Checar Filtered: ',
                                                                            Filtered
                                                                        )
                                                                        console.log(
                                                                            '==> Checar Filtered Tamaño: ',
                                                                            Filtered.length
                                                                        )

                                                                        data = {
                                                                            page:
                                                                                state.page,
                                                                            tipo:
                                                                                'and',
                                                                            pageSize:
                                                                                state.pageSize,
                                                                            sorted:
                                                                                state.sorted,
                                                                            filtered: Filtered,
                                                                        }

                                                                        console.log(
                                                                            '==> Buscar getPlazas Ocupadas <===: ',
                                                                            data
                                                                        )
                                                                        EjecutarApi(
                                                                            data,
                                                                            'getPlantillas',
                                                                            token
                                                                        )
                                                                            .then(
                                                                                function (
                                                                                    res
                                                                                ) {
                                                                                    return res
                                                                                }
                                                                            )
                                                                            .then(
                                                                                (
                                                                                    items
                                                                                ) => {
                                                                                    console.log(
                                                                                        '===> Data PlazasVacantes: ',
                                                                                        items
                                                                                    )

                                                                                    if (
                                                                                        items.results
                                                                                    ) {
                                                                                        const dataTable =
                                                                                            items.data

                                                                                        this.setState(
                                                                                            {
                                                                                                loadingOcupadas: false,
                                                                                                totalOcupadas:
                                                                                                    items.total,
                                                                                                PlazasOcupadas: dataTable.map(
                                                                                                    (
                                                                                                        prop,
                                                                                                        key
                                                                                                    ) => {
                                                                                                        return {
                                                                                                            id:
                                                                                                                prop.uid,
                                                                                                            key:
                                                                                                                prop.uid,
                                                                                                            label:
                                                                                                                prop.Plaza +
                                                                                                                ' - ' +
                                                                                                                prop.Denominacion_funcional,
                                                                                                            Dependencia_organismo:
                                                                                                                prop.Dependencia,
                                                                                                            Nivel:
                                                                                                                prop.Nivel,
                                                                                                            Plaza:prop.Plaza,
                                                                                                            Tipo:
                                                                                                                prop.Tipo,
                                                                                                            Nombre:
                                                                                                                prop.Nombre,
                                                                                                            Denominacion_tabular:
                                                                                                                prop.Denominacion_tabular,
                                                                                                            Denominacion_funcional:
                                                                                                                prop.Denominacion_funcional,
                                                                                                            Estatus:
                                                                                                                prop.Estatus,
                                                                                                            Municipio:
                                                                                                                prop.Municipio,
                                                                                                            FechaVacante:
                                                                                                                prop.Fecha_vacante,
                                                                                                            NombreBtn: (
                                                                                                                <Button
                                                                                                                    className="btn-link"
                                                                                                                    color="primary"
                                                                                                                    onClick={() => {
                                                                                                                        this.setBaja(
                                                                                                                            prop
                                                                                                                        )
                                                                                                                    }}
                                                                                                                >{`${prop.Nombre}`}</Button>
                                                                                                            ),
                                                                                                            data: prop,
                                                                                                        }
                                                                                                    }
                                                                                                ),
                                                                                            }
                                                                                        )
                                                                                    }
                                                                                }
                                                                            )
                                                                            .catch(
                                                                                (
                                                                                    e
                                                                                ) => {
                                                                                    console.log(
                                                                                        'error getPlazas: ',
                                                                                        e.message
                                                                                    )
                                                                                    Notify(
                                                                                        3,
                                                                                        'tc',
                                                                                        `Error al cargar plazas: ${e.message}`,
                                                                                        this
                                                                                            .refs
                                                                                    )
                                                                                    this.setState(
                                                                                        {
                                                                                            loadingOcupadas: false,
                                                                                        }
                                                                                    )
                                                                                }
                                                                            )
                                                                    }}
                                                                    /*
                                              You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                                              */
                                                                    className="-striped -highlight primary-pagination"
                                                                />
                                                            )}
                                                        {(Movimiento.value ===
                                                            4 ||
                                                            Movimiento.value ===
                                                                3) &&
                                                            Baja.uid !==
                                                                'undefined' && (
                                                                <div>
                                                                    <Card>
                                                                        <CardBody>
                                                                            <Row>
                                                                                <Col>
                                                                                    Nombre:{' '}
                                                                                    <h6>
                                                                                        {
                                                                                            this
                                                                                                .state
                                                                                                .Baja
                                                                                                .Nombre
                                                                                        }
                                                                                    </h6>
                                                                                </Col>
                                                                                <Col>
                                                                                    Puesto:{' '}
                                                                                    <h6>
                                                                                        {
                                                                                            this
                                                                                                .state
                                                                                                .Baja
                                                                                                .Denominacion_funcional
                                                                                        }
                                                                                    </h6>
                                                                                </Col>
                                                                                <Col>
                                                                                    Nivel:{' '}
                                                                                    <h6>
                                                                                        {
                                                                                            this
                                                                                                .state
                                                                                                .Baja
                                                                                                .Nivel
                                                                                        }
                                                                                    </h6>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col>
                                                                                    Municipio:{' '}
                                                                                    <h6>
                                                                                        {
                                                                                            this
                                                                                                .state
                                                                                                .Baja
                                                                                                .Municipio
                                                                                        }
                                                                                    </h6>
                                                                                </Col>
                                                                                <Col>
                                                                                    Dependencia:{' '}
                                                                                    <h6>
                                                                                        {
                                                                                            this
                                                                                                .state
                                                                                                .Baja
                                                                                                .Dependencia
                                                                                        }
                                                                                    </h6>
                                                                                </Col>
                                                                            </Row>
                                                                        </CardBody>
                                                                        <CardFooter>
                                                                            <Button
                                                                                className="btn-round"
                                                                                color="info"
                                                                                type="Button"
                                                                                onClick={() => {
                                                                                    this.setState(
                                                                                        {
                                                                                            Baja: {
                                                                                                uid:
                                                                                                    'undefined',
                                                                                            },
                                                                                        }
                                                                                    )
                                                                                    //this.getPlazasOcupadas(this.state.idDependencia.label);
                                                                                }}
                                                                            >
                                                                                Borrar
                                                                            </Button>
                                                                        </CardFooter>
                                                                    </Card>
                                                                    {Movimiento.value ===
                                                                        3 &&
                                                                        Promocion.uid !==
                                                                            'undefined' && (
                                                                            <Card>
                                                                                <CardBody>
                                                                                    <Row>
                                                                                        <Col>
                                                                                            Puesto:{' '}
                                                                                            <h6>
                                                                                                {
                                                                                                    this
                                                                                                        .state
                                                                                                        .Promocion
                                                                                                        .Denominacion_funcional
                                                                                                }
                                                                                            </h6>
                                                                                        </Col>
                                                                                        <Col>
                                                                                            Nivel:{' '}
                                                                                            <h6>
                                                                                                {
                                                                                                    this
                                                                                                        .state
                                                                                                        .Promocion
                                                                                                        .Nivel
                                                                                                }
                                                                                            </h6>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <Row>
                                                                                        <Col>
                                                                                            Municipio:{' '}
                                                                                            <h6>
                                                                                                {
                                                                                                    this
                                                                                                        .state
                                                                                                        .Promocion
                                                                                                        .Municipio
                                                                                                }
                                                                                            </h6>
                                                                                        </Col>
                                                                                        <Col>
                                                                                            Dependencia:{' '}
                                                                                            <h6>
                                                                                                {
                                                                                                    this
                                                                                                        .state
                                                                                                        .Promocion
                                                                                                        .Dependencia
                                                                                                }
                                                                                            </h6>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </CardBody>
                                                                                <CardFooter>
                                                                                    <Button
                                                                                        className="btn-round"
                                                                                        color="info"
                                                                                        type="Button"
                                                                                        onClick={() => {
                                                                                            this.setState(
                                                                                                {
                                                                                                    Promocion: {
                                                                                                        uid:
                                                                                                            'undefined',
                                                                                                    },
                                                                                                }
                                                                                            )
                                                                                            //this.getPlazasOcupadas(this.state.idDependencia.label);
                                                                                        }}
                                                                                    >
                                                                                        Borrar
                                                                                    </Button>
                                                                                </CardFooter>
                                                                            </Card>
                                                                        )}
                                                                    {Movimiento.value ===
                                                                        3 &&
                                                                        Promocion.uid ===
                                                                            'undefined' && (
                                                                            <Card>
                                                                                <CardBody>
                                                                                    <ReactTable
                                                                                        data={
                                                                                            PlazasVacantes
                                                                                        }
                                                                                        filterable
                                                                                        columns={[
                                                                                            {
                                                                                                Header:
                                                                                                    'Funcional',
                                                                                                minWidth: 150,
                                                                                                id:
                                                                                                    'Denominacion_funcional',
                                                                                                accessor: (
                                                                                                    d
                                                                                                ) =>
                                                                                                    d.NombreBtn,
                                                                                                filterMethod: (
                                                                                                    filter,
                                                                                                    rows
                                                                                                ) =>
                                                                                                    matchSorter(
                                                                                                        rows,
                                                                                                        filter.value,
                                                                                                        {
                                                                                                            keys: [
                                                                                                                'Denominacion_funcional',
                                                                                                            ],
                                                                                                        }
                                                                                                    ),
                                                                                                filterAll: true,
                                                                                            },
                                                                                            {
                                                                                                Header:
                                                                                                    'Tabular',
                                                                                                minWidth: 50,
                                                                                                id:
                                                                                                    'Denominacion_tabular',
                                                                                                accessor: (
                                                                                                    d
                                                                                                ) =>
                                                                                                    d.Denominacion_tabular,
                                                                                                filterMethod: (
                                                                                                    filter,
                                                                                                    rows
                                                                                                ) =>
                                                                                                    matchSorter(
                                                                                                        rows,
                                                                                                        filter.value,
                                                                                                        {
                                                                                                            keys: [
                                                                                                                'Denominacion_tabular',
                                                                                                            ],
                                                                                                        }
                                                                                                    ),
                                                                                                filterAll: true,
                                                                                            },
                                                                                            {
                                                                                                Header:
                                                                                                    'Nivel',
                                                                                                minWidth: 50,
                                                                                                id:
                                                                                                    'Nivel',
                                                                                                accessor: (
                                                                                                    d
                                                                                                ) =>
                                                                                                    d.Nivel,
                                                                                                filterMethod: (
                                                                                                    filter,
                                                                                                    rows
                                                                                                ) =>
                                                                                                    matchSorter(
                                                                                                        rows,
                                                                                                        filter.value,
                                                                                                        {
                                                                                                            keys: [
                                                                                                                'Nivel',
                                                                                                            ],
                                                                                                        }
                                                                                                    ),
                                                                                                filterAll: true,
                                                                                            },
                                                                                            {
                                                                                                Header:
                                                                                                    'Secuencial',
                                                                                                minWidth: 50,
                                                                                                id:
                                                                                                    'Secuencial',
                                                                                                accessor: (
                                                                                                    d
                                                                                                ) =>
                                                                                                    d.Secuencial,
                                                                                                filterMethod: (
                                                                                                    filter,
                                                                                                    rows
                                                                                                ) =>
                                                                                                    matchSorter(
                                                                                                        rows,
                                                                                                        filter.value,
                                                                                                        {
                                                                                                            keys: [
                                                                                                                'Secuencial',
                                                                                                            ],
                                                                                                        }
                                                                                                    ),
                                                                                                filterAll: true,
                                                                                            },
                                                                                            {
                                                                                                Header:
                                                                                                    'Municipio',
                                                                                                minWidth: 50,
                                                                                                id:
                                                                                                    'Municipio',
                                                                                                accessor: (
                                                                                                    d
                                                                                                ) =>
                                                                                                    d.Municipio,
                                                                                                filterMethod: (
                                                                                                    filter,
                                                                                                    rows
                                                                                                ) =>
                                                                                                    matchSorter(
                                                                                                        rows,
                                                                                                        filter.value,
                                                                                                        {
                                                                                                            keys: [
                                                                                                                'Municipio',
                                                                                                            ],
                                                                                                        }
                                                                                                    ),
                                                                                                filterAll: true,
                                                                                            },
                                                                                            {
                                                                                                Header:
                                                                                                    'Tipo',
                                                                                                minWidth: 50,
                                                                                                id:
                                                                                                    'Tipo',
                                                                                                accessor: (
                                                                                                    d
                                                                                                ) =>
                                                                                                    d.Tipo,
                                                                                                filterMethod: (
                                                                                                    filter,
                                                                                                    rows
                                                                                                ) =>
                                                                                                    matchSorter(
                                                                                                        rows,
                                                                                                        filter.value,
                                                                                                        {
                                                                                                            keys: [
                                                                                                                'Tipo',
                                                                                                            ],
                                                                                                        }
                                                                                                    ),
                                                                                                filterAll: true,
                                                                                            },
                                                                                            {
                                                                                                Header:
                                                                                                    'Estatus',
                                                                                                minWidth: 50,
                                                                                                id:
                                                                                                    'Estatus',
                                                                                                accessor: (
                                                                                                    d
                                                                                                ) =>
                                                                                                    d.Estatus,
                                                                                                filterMethod: (
                                                                                                    filter,
                                                                                                    rows
                                                                                                ) =>
                                                                                                    matchSorter(
                                                                                                        rows,
                                                                                                        filter.value,
                                                                                                        {
                                                                                                            keys: [
                                                                                                                'Estatus',
                                                                                                            ],
                                                                                                        }
                                                                                                    ),
                                                                                                filterAll: true,
                                                                                            },
                                                                                        ]}
                                                                                        defaultPageSize={10}
                                                                                        showPagination={true}
                                                                                        showPaginationTop={false}
                                                                                        showPaginationBottom={true}
                                                                                        manual
                                                                                        canNextFromData={true}
                                                                                        showTotalPages={true}
                                                                                        PaginationComponent={Pagination}
                                                                                        previousText="Anterior"
                                                                                        nextText="Siguiente"
                                                                                        ofText="de"
                                                                                        total={ totalVacantes }
                                                                                        loading={this.state.loadingVacantes}
                                                                                        id="tbContraPropuesta"
                                                                                        onFetchData={(state,instance ) => {
                                                                                            console.log(
                                                                                                '==>state Vacantes <===: ',
                                                                                                state
                                                                                            )

                                                                                            const token =global.access_token
                                                                                            var data = {}
                                                                                            const Filtered = state.filtered

                                                                                            this.setState(
                                                                                                {
                                                                                                    loadingVacantes: true,
                                                                                                    firstVacantes: false,
                                                                                                }
                                                                                            )

                                                                                            const ExisteStatus = Filtered.filter(
                                                                                                (
                                                                                                    item
                                                                                                ) =>
                                                                                                    item.id ===
                                                                                                    'Estatus'
                                                                                            )

                                                                                            console.log(
                                                                                                '==>ExisteStatus: ',
                                                                                                ExisteStatus
                                                                                            )

                                                                                            //if (ExisteStatus.length ===0 )
                                                                                                /*Filtered.push(
                                                                                                    {
                                                                                                        id:
                                                                                                            'Estatus',
                                                                                                        value:
                                                                                                            'VACANTE',
                                                                                                    }
                                                                                                )*/

                                                                                            const ExisteDependencia = Filtered.filter(
                                                                                                (
                                                                                                    item
                                                                                                ) =>
                                                                                                    item.id ===
                                                                                                    'Dependencia_organismo'
                                                                                            )

                                                                                            console.log(
                                                                                                '==>ExisteDependencia: ',
                                                                                                ExisteDependencia
                                                                                            )

                                                                                            if (
                                                                                                ExisteDependencia.length ===
                                                                                                0
                                                                                            )
                                                                                                Filtered.push(
                                                                                                    {
                                                                                                        id:
                                                                                                            'Dependencia_organismo',
                                                                                                        value: this
                                                                                                            .state
                                                                                                            .SolicitudDependencia,
                                                                                                    }
                                                                                                )

                                                                                            console.log(
                                                                                                '==> Checar Filtered: ',
                                                                                                Filtered
                                                                                            )
                                                                                            console.log(
                                                                                                '==> Checar Filtered vascantes: ',
                                                                                                Filtered.length
                                                                                            )

                                                                                            data = {
                                                                                                page:
                                                                                                    state.page,
                                                                                                tipo:
                                                                                                    'and',
                                                                                                pageSize:
                                                                                                    state.pageSize,
                                                                                                sorted:
                                                                                                    state.sorted,
                                                                                                filtered: Filtered,
                                                                                            }

                                                                                            console.log(
                                                                                                '==> Buscar getPlazas vacantes <===: ',
                                                                                                data
                                                                                            )
                                                                                            EjecutarApi(
                                                                                                data,
                                                                                                'getPlantillas',
                                                                                                token
                                                                                            )
                                                                                                .then(
                                                                                                    function (
                                                                                                        res
                                                                                                    ) {
                                                                                                        return res
                                                                                                    }
                                                                                                )
                                                                                                .then(
                                                                                                    (
                                                                                                        items
                                                                                                    ) => {
                                                                                                        console.log(
                                                                                                            '===> Data PlazasVacantes: ',
                                                                                                            items
                                                                                                        )

                                                                                                        if (
                                                                                                            items.results
                                                                                                        ) {
                                                                                                            const dataTable =
                                                                                                                items.data

                                                                                                            this.setState(
                                                                                                                {
                                                                                                                    loadingVacantes: false,
                                                                                                                    totalVacantes:
                                                                                                                        items.total,
                                                                                                                    PlazasVacantes: dataTable.map(
                                                                                                                        (
                                                                                                                            prop,
                                                                                                                            key
                                                                                                                        ) => {
                                                                                                                            return {
                                                                                                                                id:
                                                                                                                                    prop.uid,
                                                                                                                                key:
                                                                                                                                    prop.uid,
                                                                                                                                label:
                                                                                                                                    prop.Plaza +
                                                                                                                                    ' - ' +
                                                                                                                                    prop.Denominacion_funcional,
                                                                                                                                Dependencia_organismo:
                                                                                                                                    prop.Dependencia,
                                                                                                                                Nivel:
                                                                                                                                    prop.Nivel,
                                                                                                                                Tipo:
                                                                                                                                    prop.Tipo,
                                                                                                                                Nombre:
                                                                                                                                    prop.Nombre,
                                                                                                                                Denominacion_tabular:
                                                                                                                                    prop.Denominacion_tabular,
                                                                                                                                Denominacion_funcional:
                                                                                                                                    prop.Denominacion_funcional,
                                                                                                                                Estatus:
                                                                                                                                    prop.Estatus,
                                                                                                                                Municipio:
                                                                                                                                    prop.Municipio,
                                                                                                                                FechaVacante:
                                                                                                                                    prop.Fecha_vacante,
                                                                                                                                NombreBtn: (
                                                                                                                                    <Button
                                                                                                                                        className="btn-link"
                                                                                                                                        color="primary"
                                                                                                                                        onClick={() => {
                                                                                                                                            this.setPromocion(
                                                                                                                                                prop
                                                                                                                                            )
                                                                                                                                        }}
                                                                                                                                    >{`${prop.Denominacion_funcional}`}</Button>
                                                                                                                                ),
                                                                                                                                Secuencial:prop.Plaza,
                                                                                                                                data: prop,
                                                                                                                            }
                                                                                                                        }
                                                                                                                    ),
                                                                                                                }
                                                                                                            )
                                                                                                        }
                                                                                                    }
                                                                                                )
                                                                                                .catch(
                                                                                                    (
                                                                                                        e
                                                                                                    ) => {
                                                                                                        console.log(
                                                                                                            'error getPlazas: ',
                                                                                                            e.message
                                                                                                        )
                                                                                                        Notify(
                                                                                                            3,
                                                                                                            'tc',
                                                                                                            `Error al cargar plazas: ${e.message}`,
                                                                                                            this
                                                                                                                .refs
                                                                                                        )
                                                                                                        this.setState(
                                                                                                            {
                                                                                                                loadingOcupadas: false,
                                                                                                            }
                                                                                                        )
                                                                                                    }
                                                                                                )
                                                                                        }}
                                                                                        className="-striped -highlight primary-pagination"
                                                                                    />
                                                                                </CardBody>
                                                                            </Card>
                                                                        )}
                                                                </div>
                                                            )}
                                                    </CardBody>
                                                    <CardFooter>
                                                        <Button
                                                            className="btn-round"
                                                            color="info"
                                                            type="submit"
                                                        >
                                                            Guardar
                                                        </Button>
                                                    </CardFooter>
                                                </Card>
                                            </Col>
                                        )}
                                    </Row>
                                </>
                            ) : (
                                <></>
                            )}
                            {isVisible ? (
                                <Row>
                                    <Col sm="12">
                                        <Card>
                                            <CardHeader>
                                                <CardTitle tag="h6">
                                                    Listado de Propuestas
                                                </CardTitle>
                                            </CardHeader>
                                            <CardBody>
                                                <ReactTable
                                                    id="tblPropuestas"
                                                    className="-striped -highlight primary-pagination"
                                                    data={
                                                        this.state
                                                            .ListadoPropuestas
                                                    }
                                                    columns={[
                                                        {
                                                            Header: 'Eliminar',
                                                            minWidth: 150,
                                                            id: 'Eliminar',
                                                            accessor: (d) =>
                                                                d.Eliminar,
                                                        },
                                                        {
                                                            Header:
                                                                'Movimiento',
                                                            minWidth: 150,
                                                            id: 'Movimiento',
                                                            accessor: (d) =>
                                                                d.Movimiento,
                                                        },
                                                        {
                                                            Header:
                                                                'Dependencia',
                                                            minWidth: 80,
                                                            id: 'Dependencia',
                                                            accessor: (d) =>
                                                                d.Dependencia,
                                                        },
                                                        {
                                                            Header: 'Candidato',
                                                            minWidth: 80,
                                                            id: 'Candidato',
                                                            accessor: (d) =>
                                                                d.Candidato,
                                                        },
                                                        {
                                                            Header:
                                                                'Secuencial',
                                                            minWidth: 150,
                                                            id: 'Secuencial',
                                                            accessor: (d) =>
                                                                d.Secuencial,
                                                        },
                                                        {
                                                            Header: 'Nivel',
                                                            minWidth: 150,
                                                            id: 'Nivel',
                                                            accessor: (d) =>
                                                                d.Nivel,
                                                        },
                                                        {
                                                            Header:
                                                                'Denominación Funcional',
                                                            minWidth: 150,
                                                            id:
                                                                'Denominacion_funcional',
                                                            accessor: (d) =>
                                                                d.Denominacion_funcional,
                                                        },
                                                        {
                                                            Header: 'Tipo',
                                                            minWidth: 150,
                                                            id: 'Tipo',
                                                            accessor: (d) =>
                                                                d.Tipo,
                                                        },
                                                        {
                                                            Header: 'Estatus',
                                                            minWidth: 50,
                                                            id: 'Estatus',
                                                            accessor: (d) =>
                                                                d.Estatus,
                                                        },
                                                    ]}
                                                    loading={this.state.loading}
                                                    defaultPageSize={10}
                                                    showPagination={true}
                                                    showPaginationTop={false}
                                                    showPaginationBottom={true}
                                                    pageSizeOptions={[
                                                        5,
                                                        10,
                                                        20,
                                                        25,
                                                        50,
                                                        100,
                                                    ]}
                                                    total={
                                                        this.state
                                                            .totalListadoPropuesta
                                                    }
                                                    manual
                                                    canNextFromData={true}
                                                    showTotalPages={true}
                                                    PaginationComponent={
                                                        Pagination
                                                    }
                                                    previousText="Anterior"
                                                    nextText="Siguiente"
                                                    ofText="de"
                                                    onFetchData={(
                                                        state,
                                                        instance
                                                    ) => {
                                                        this.setState({
                                                            loading: true,
                                                        })
                                                        const token = getVars(
                                                            'Token'
                                                        )
                                                        console.log(
                                                            'Token->tblPropuestas: ',
                                                            token
                                                        )
                                                        var parametros = {
                                                            filtered: [
                                                                {
                                                                    id:
                                                                        'sm.idSolicitud',
                                                                    value: this
                                                                        .state
                                                                        .idSolicitud,
                                                                },
                                                            ],
                                                            tipo: 'and',
                                                            page: state.page,
                                                            pageSize: 10,
                                                        }
                                                        console.log(
                                                            'tblPropuestas->parametros: ',
                                                            JSON.stringify(
                                                                parametros
                                                            )
                                                        )
                                                        EjecutarApi(
                                                            parametros,
                                                            'getPropuestas',
                                                            token.access_token
                                                        )
                                                            .then(function (
                                                                res
                                                            ) {
                                                                return res
                                                            })
                                                            .then((res) => {
                                                                console.log(
                                                                    'EjecutarAPI->getPropuestas->res:',
                                                                    res
                                                                )
                                                                if (
                                                                    res.results
                                                                ) {
                                                                    var data =
                                                                        res.data
                                                                    this.setState(
                                                                        {
                                                                            loading: false,
                                                                            totalListadoPropuesta:
                                                                                res.total,
                                                                            ListadoPropuestas: data.map(
                                                                                (
                                                                                    item,
                                                                                    key
                                                                                ) => {
                                                                                    return {
                                                                                        id:
                                                                                            item.id,
                                                                                        Eliminar: (
                                                                                            <Button
                                                                                                onClick={() => {
                                                                                                    this.handleEliminar(
                                                                                                        item.id
                                                                                                    )
                                                                                                }}
                                                                                                color="danger"
                                                                                                size="sm"
                                                                                                className="btn-icon btn-link remove"
                                                                                            >
                                                                                                x
                                                                                            </Button>
                                                                                        ),
                                                                                        Movimiento:
                                                                                            item
                                                                                                .Movimiento
                                                                                                .label,
                                                                                        Dependencia:
                                                                                            item.Dependencia_organismo,
                                                                                        Candidato:
                                                                                            item
                                                                                                .Candidato
                                                                                                .label,
                                                                                        Secuencial:
                                                                                            item.Plaza,
                                                                                        Estatus:
                                                                                            item
                                                                                                .MovimientoStatus
                                                                                                .label,
                                                                                        Denominacion_funcional:
                                                                                            item.Denominacion_funcional,
                                                                                        Tipo:
                                                                                            item.Tipo,
                                                                                        Nivel:
                                                                                            item.Nivel,
                                                                                        data: item,
                                                                                    }
                                                                                }
                                                                            ),
                                                                        }
                                                                    )
                                                                }
                                                            })
                                                            .catch((e) => {
                                                                console.log(
                                                                    'Error->tblPropuestas: ',
                                                                    e
                                                                )
                                                                this.setState({
                                                                    loading: false,
                                                                })
                                                            })
                                                    }}
                                                />
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            ) : (
                                <></>
                            )}
                        </Form>
                    </div>
                    <Footer fluid />
                </div>
            </div>
        )
    }
}

export default oficialiaAddMovimiento
