import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

import PrivateRoute from "./auth";

import Logout from "../src/views/pages/Logout";

import Inicio from "./views/pages/Inicio"; //YA

import Dashboard from "./views/pages/Dashboard"; //YA
import Dashboardv2 from "./views/pages/Dashboard_Movimientos"; //YA

import Dash from "./views/Dashboard"; //NO pasar es de ejemplo
import Login from "./views/pages/Acceso"; // YA
import AutorizarMovmiento from "../src/views/pages/AutorizarMovmiento.jsx"; //YA
import Oficios from "../src/views/pages/Oficios.jsx"; //ya

import VoBo from "../src/views/pages/VoBo.jsx"; //ya
import CargarPlantillas from "./views/pages/CargarPlantilla.jsx";
import ReportePlantilla from "./views/pages/ReportePlantilla.jsx";
import ReporteDependencia from "./views/pages/ReporteDependencia.jsx";
import Validaciones from "./views/pages/Validaciones.jsx";
import Plantilla from "./views/pages/Plantilla.jsx";
import ValidacionesGeneral from "./views/pages/ValidacionesGeneral.jsx";
import RegularForms from "../src/views/forms/RegularForms.jsx";
import ExtendedForms from "../src/views/forms/ExtendedForms.jsx";
import ValidationForms from "../src/views/forms/ValidationForms.jsx";
import UserProfile from "../src/views/pages/UserProfile.jsx";
import ReporteGenerador from "../src/views/pages/ReporteGenerador.jsx";
import OficialiaPartes from "../src/views/pages/oficialiaListado";
import OficialiaCaptura from "../src/views/pages/oficialiaAdd";
import OficialiaEdit from "../src/views/pages/oficialiaEdit";
import OficialiaSustituir from "../src/views/pages/oficialiaSustituirFolio";

import GridSystem from "../src/views/components/GridSystem.jsx";
import Icons from "../src/views/components/Icons";
import Buttons from "../src/views/components/Buttons";
import ReactTables from "../src/views/tables/ReactTables.jsx";
import RegularTables from "../src/views/tables/RegularTables.jsx";
import SweetAlert from "../src/views/components/SweetAlert.jsx";
import Timeline from "../src/views/pages/Timeline.jsx";
import Wizard from "../src/views/forms/Wizard.jsx";
import ExtendedTables from "views/tables/ExtendedTables";
import oficialiaAddMovimiento from "views/pages/oficialiaAddMovimiento";
import cruces from "./views/pages/Cruces";

//import Plantilla from './views/pages/Plantilla.jsx';

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.1.0";
import "assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";

const hist = createBrowserHistory();

const LoadingMessage = () => "I'm loading...";

const PropuestasCap = lazy(() => import("./views/pages/PropuestasCap.jsx"));

const Propuestas = lazy(() => import("./views/pages/PropuestasVIP.jsx"));

const PropuestasOtras = lazy(() => import("./views/pages/PropuestasOtras.jsx"));

const Root = (
  <Router history={hist}>
    <Suspense fallback={<LoadingMessage />}>
      <Switch>
        <Route exact path="/gto/logout" component={Logout} />
        <PrivateRoute path="/gto/admin/perfil" component={UserProfile} />
        <PrivateRoute path="/gto/admin/inicio" component={Inicio} />
        <PrivateRoute path="/gto/admin/dashboard" component={Dashboard} />
        <PrivateRoute path="/gto/admin/dashboardv2" component={Dashboardv2} />
        <PrivateRoute
          path="/gto/admin/propuestas_cap"
          component={PropuestasCap}
        />
        <PrivateRoute path="/gto/admin/propuestas" component={Propuestas} />
        <PrivateRoute path="/gto/admin/otras" component={PropuestasOtras} />
        <PrivateRoute path="/gto/admin/dp" component={ReportePlantilla} />
        <PrivateRoute path="/gto/admin/rd" component={ReporteDependencia} />
        <PrivateRoute path="/gto/admin/vobo" component={VoBo} />
        <PrivateRoute
          path="/gto/admin/autorizar"
          component={AutorizarMovmiento}
        />
        <PrivateRoute path="/gto/admin/oficios" component={Oficios} />
        <PrivateRoute path="/gto/admin/validaciones" component={Validaciones} />
        <PrivateRoute
          path="/gto/admin/validacionesGral"
          component={ValidacionesGeneral}
        />
        <PrivateRoute path="/gto/admin/rg" component={ReporteGenerador} />

        <PrivateRoute
          path="/gto/admin/plantilla"
          component={CargarPlantillas}
        />
        <PrivateRoute path="/gto/admin/pp" component={Plantilla} />
        <PrivateRoute
          path="/gto/admin/reporte-validaciones"
          component={Dashboard}
        />
        <PrivateRoute
          path="/gto/admin/reporte-vacantes"
          component={Dashboard}
        />
        <PrivateRoute
          path="/gto/admin/reporte-plantilla"
          component={Dashboard}
        />

        <PrivateRoute path="/gto/admin/co" component={OficialiaCaptura} />
        <PrivateRoute path="/gto/admin/oficialia" component={OficialiaPartes} />
        <PrivateRoute
          path="/gto/admin/oficialiaEdit"
          component={OficialiaEdit}
        />
        <PrivateRoute
          path="/gto/admin/oficialiaSustituirFolio"
          component={OficialiaSustituir}
        />
        <PrivateRoute
          path="/gto/admin/oficialiaAddMovimiento"
          component={oficialiaAddMovimiento}
        />
        <PrivateRoute path="/gto/admin/cruces" component={cruces} />

        <Route exact path="/gto/timeline" component={Timeline} />
        <Route exact path="/gto/icons" component={Icons} />
        <Route exact path="/gto/dash" component={Dash} />
        <Route exact path="/gto/SweetAlert" component={SweetAlert} />
        <Route exact path="/gto/Buttons" component={Buttons} />
        <Route path="/gto/admin/regular-forms" component={RegularForms} />
        <Route path="/gto/admin/extended-forms" component={ExtendedForms} />
        <Route path="/gto/admin/validation-forms" component={ValidationForms} />
        <Route path="/gto/admin/regular-tables" component={RegularTables} />
        <Route path="/gto/admin/extended-tables" component={ExtendedTables} />
        <Route path="/gto/admin/react-tables" component={ReactTables} />
        <Route path="/gto/admin/grid-system" component={GridSystem} />

        <Route path="/gto/admin/wizard" component={Wizard} />

        <Route path="/" component={Login} />
      </Switch>
    </Suspense>
  </Router>
);

ReactDOM.render(Root, document.getElementById("root"));
