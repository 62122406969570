import React, { Component } from 'react';
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	CardTitle,
	Table,
	Label,
	FormGroup,
	Input,
	Row,
	Col,
	Modal,
} from 'reactstrap';
import moment from 'moment';
import { CircleSpinner } from "react-spinners-kit";
import { getVars,URL_PUBLICA } from '../../GlobalStore';

var global = '';

class ModalSearchUser extends Component {
	constructor(props) {
		super(props);

		this.state = {
			Nombre: props.Nombre.Nombre || '',
			Paterno: props.Nombre.Paterno || '',
			Materno: props.Nombre.Materno || '',
			Resultados: [],
			TotalRegistros: -1,
			loading:false,
			showGrid:false
		};
	}

	componentDidMount() {
		console.log(`===>mis  Hora: ${moment().format('H:mm:s')}, ${this.props}`);
		this.searchUser(this.props.Nombre);
		// if (this.props.Nombre) {
		// 	var data={Nombre: this.props.Nombre,
		// 		Paterno: this.props.Paterno,
		// 		Materno: this.props.Materno};
		// 	this.searchUser(data);
			
		// }
	}

	componentWillReceiveProps(nextProps) {
		console.log('nextProps',nextProps)
		if (this.props.Nombre !== nextProps.Nombre) {
			this.setState({
				Nombre: nextProps.Nombre.Nombre,
				Paterno: nextProps.Nombre.Paterno,
				Materno: nextProps.Nombre.Materno,
				Resultados: [],
				TotalRegistros: -1,
			});

		}
		
	}

	searchUser = (valor) => {
		console.log('Recibiendo datos',this.state);
		console.log('Recibiendo datos props.',this.props);
		console.log('Recibiendo datos valor.',valor);
		this.setState({...this.state, loading:true});

		var data={Nombre: this.state.Nombre,
			Paterno: this.state.Paterno,
			Materno: this.state.Materno,}
		if(!this.state.Nombre)
		{
			data = {
				Nombre: valor.Nombre,
				Paterno: valor.Paterno,
				Materno: valor.Materno,
			};
			this.setState({
				Nombre: valor.Nombre,
				Paterno: valor.Paterno,
				Materno: valor.Materno,
			});
		}
		
		
		var url = new URL(URL_PUBLICA+'candidatos');

		Object.keys(data).forEach(key => {
			const Valor = data[key];
			if (Valor !== '') {
				url.searchParams.append(key, data[key]);
			}
		});

		const token = global.access_token;

		fetch(url, { headers: new Headers({ Authorization: `Bearer ${token}` }) })
			.then(response => {
				switch (response.status) {
					case 200:
						console.log('==>Status: ', response.status);
						break;

					default:
						this.props.onError('Error al consultar candiadtos');
						break;
				}

				if (response.ok) {
					return response.json();
				}
				throw new Error('Ocurrio un error: candidatos: ', response.statusText);
			})
			.then(items => this.setState({ Resultados: items.data, TotalRegistros: items.data.length , loading:false,showGrid:true }))
			.catch(e => {
				console.log('error: ', e.message);
				this.props.onError(e.message);
				this.setState({...this.state, loading:false});
			});
	};

	setPersona = item => {
		this.props.setPersona(item);
		this.props.toggle();
		this.setState({showGrid:false});
	};

	newUser = () => {
		this.props.newUser({ Nombre: this.state.Nombre, Paterno: this.state.Paterno, Materno: this.state.Materno });
		this.props.toggle();
	};

	setClear = () => {
		this.setState({ Resultados: [], TotalRegistros: -1 });
	};
	render() {
		const { TotalRegistros, Resultados, Nombre, Materno, Paterno } = this.state;
		global = getVars('Token');

		return (
			<Modal size="lg" isOpen={this.props.isOpen} toggle={this.props.toggle}>
				<div className="modal-header">
					<button
						aria-hidden={true}
						className="close"
						data-dismiss="modal"
						type="button"
						onClick={this.props.toggle}
					>
						<i className="nc-icon nc-simple-remove" />
					</button>
					<h5 className="modal-title" id="myModalLabel">
						Buscar candidato
					</h5>
				</div>
				<div className="modal-body">
				{this.state.loading && (
                                <CircleSpinner
                                  size={30}
                                  color="#686769"
                                  loading={this.state.loading}
                                />
                              )}
					<Row>
						<Col sm="3">
							<FormGroup>
								<Label className="text-left">Nombre</Label>
								<Input
									placeholder="Nombre"
									id="Nombre"
									type="text"
									value={Nombre}
									onChange={e => this.setState({ Nombre: e.target.value })}
								/>
							</FormGroup>
						</Col>
						<Col sm="3">
							<FormGroup>
								<Label className="text-left">Paterno</Label>
								<Input
									placeholder="Paterno"
									id="Paterno"
									type="text"
									value={Paterno}
									onChange={e => this.setState({ Paterno: e.target.value })}
								/>
							</FormGroup>
						</Col>
						<Col sm="3">
							<FormGroup>
								<Label className="text-left">Materno</Label>

								<Input
									placeholder="Materno"
									id="Materno"
									type="text"
									value={Materno}
									onChange={e => this.setState({ Materno: e.target.value })}
								/>
							</FormGroup>
						</Col>
						<Col sm="1">
							<Button className="btn-round mb-1" color="warning" onClick={this.searchUser}>
								<span className="btn-label">
									<i className="nc-icon nc-zoom-split" />
								</span>
							</Button>
						</Col>
					</Row>
					{this.state.showGrid&&(
						<Row>
						<Col md="12">
							<Card>
								<CardHeader>
									{TotalRegistros !== -1 && (
										<CardTitle tag="h4">
											Registros encontrados {TotalRegistros}{' '}
											<a href="#chris" onClick={this.setClear}>
												Borrar
											</a>
										</CardTitle>
									)}
								</CardHeader>
								<CardBody>
									<Table responsive striped>
										<thead className="text-primary">
											<tr>
												<th className="text-center">#</th>
												<th>Nombre</th>
												<th>Cargo</th>
												<th>Dependencia</th>
												<th>Municipio</th>
												<th>Nivel</th>
												<th>Plaza</th>
											</tr>
										</thead>
										<tbody>
											{Resultados.length > 0 ? (
												Resultados.map((items, id) => {
													return (
														<tr key={id}>
															<td className="text-center">{id + 1}</td>
															<td>
																<a
																	href="#chris"
																	onClick={e => this.setPersona(items)}
																>{`${items.Nombre} ${items.Paterno} ${items.Materno}`}</a>
															</td>
															<td></td>
															<td></td>
															<td>{items.municipios.label}</td>
															<td>{items.NivelPropuesto}</td>
															<td></td>
														</tr>
													);
												})
											) : (
												<tr>
													<td colSpan="7"> Sin Resultados </td>
												</tr>
											)}
										</tbody>
									</Table>
								</CardBody>
							</Card>
						</Col>
					</Row>
					)}
				</div>
				{this.state.showGrid&&(
					<div className="modal-footer justify-content-center">
					<Button
						className="btn-round"
						color="info"
						data-dismiss="modal"
						type="button"
						onClick={this.newUser}
					>
						Nuevo
					</Button>
				</div>
				)}
				
			</Modal>
		);
	}
}

export default ModalSearchUser;
