import React, { Component } from "react";

import NotificationAlert from "react-notification-alert";
// reactstrap components
import { Card, CardBody, Label } from "reactstrap";

import { getVars } from "../../GlobalStore";
import Footer from "../../components/Footer/Footer.jsx";
import Sidebar from "../../components/Sidebar/MenuSidebar.jsx";

var global = "";

class Inicio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "black",
      activeColor: "info",
      sidebarMini: false,
      userData: props.location.state,
    };
  }

  componentDidMount() {
    this.setState({ loadingPorAutorizar: true });
  }

  handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      this.setState({ sidebarMini: false });
    } else {
      this.setState({ sidebarMini: true });
    }
    document.body.classList.toggle("sidebar-mini");
  };

  render() {
    global = getVars("Token");
    return (
      <div className="wrapper">
        <Sidebar
          {...this.props}
          bgColor={this.state.backgroundColor}
          activeColor={this.state.activeColor}
        />
        <div className="main-panel" ref="mainPanel">
          <NotificationAlert ref="notificationAlert" />
          <div className="content">
            <Label
              style={{
                fontSize: "45px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
                lineHeight: "2",
                color: "#6baed6", // Texto blanco
                padding: "20px", // Añadir un poco de relleno
                borderRadius: "10px", // Bordes redondeados
                fontBold: "bold",
              }}
            >
              <i
                style={{ textAlign: "center" }}
                className="fa fa-address-card fa-3x"
                aria-hidden="true"
              ></i>
              <br></br>
              ¡Hola! {global.user.Nombre + " " + global.user.Paterno}
              <br></br>
              Bienvenido al sistema de administración
              <br></br>
            </Label>
          </div>
          <Footer fluid />
        </div>
      </div>
    );
  }
}

export default Inicio;
