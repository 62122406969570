import React, { Component } from 'react';
import { Doughnut } from 'react-chartjs-2';
import NotificationAlert from 'react-notification-alert';
import Select from 'react-select';
import { CircleSpinner } from 'react-spinners-kit';
// reactstrap components
import { Card, CardHeader, CardBody, CardFooter, CardTitle, Row, Col, Button } from 'reactstrap';

import { Pastel } from 'data/PiePastel';
import EjecutarApi from '../../data/Consultas';
import getFile from '../../data/GetFile';

import DashboardNavbar from '../../components/Navbars/DashboardNavbar.jsx';
import Footer from '../../components/Footer/Footer.jsx';
import Sidebar from '../../components/Sidebar/MenuSidebar.jsx';

import { getVars } from '../../GlobalStore';

var global = '';

class DashboardPlantilla extends Component {
	constructor(props) {
		super(props);
		this.state = {
			backgroundColor: 'black',
			activeColor: 'info',
			loading: false,
			sidebarMini: false,
			catPlantillas: [],
			userData: props.location.state,
			selectDB: { value: 1, label: 'Actores Políticos' },
			dataShow: [],
			totalShow: 0,
		};
	}

	componentDidMount() {
		this.setState({ loading: true });

		const data = {
			page: 0,
			tipo: 'and',
			pageSize: 10,
			sorted: [],
			filtered: [],
		};

		const token = global.access_token; //localstorage

		console.log('=>Mostar getDatosPlantillasDB');

		EjecutarApi(data, 'getDatosPlantillasDB', token)
			.then(function(res) {
				return res;
			})
			.then(items => {
				console.log('===> Data getDatosPlantillasDB: ', items);

				if (items.results) {
					this.setState({
						loading: false,
						catPlantillas: items.data,
						totalShow: items.data.actoresPoliticos.total,
						catActive: items.data.actoresPoliticos,
						dataShow: items.data.actoresPoliticos.data.map((prop, key) => {
							return (
								<Col key={key} md="3">
									<Card>
										<CardHeader>
											<CardTitle>{prop.partido}</CardTitle>
											<p className="card-category">{prop.data.length}</p>
										</CardHeader>
										<CardBody>
											<Doughnut
												data={
													Pastel(
														prop.data.length,
														items.data.actoresPoliticos.total,
														`${(
															100 *
															(prop.data.length / items.data.actoresPoliticos.total)
														).toFixed(2)}%`,
														'En Plantilla',
														'Total'
													).data
												}
												options={
													Pastel(
														prop.data.length,
														items.data.actoresPoliticos.total,
														`${(
															100 *
															(prop.data.length / items.data.actoresPoliticos.total)
														).toFixed(2)}%`,
														'En Plantilla',
														'Total'
													).options
												}
												className="ct-chart ct-perfect-fourth"
												height={300}
												width={456}
											/>
										</CardBody>
										<CardFooter>
											<div className="legend">
												<i className="fa fa-circle text-info" />
												En Plantilla
											</div>
											<hr />
										</CardFooter>
									</Card>
								</Col>
							);
						}),
					});
				}
			})
			.catch(e => {
				console.log('error getDatosPlantillasDB: ', e.message);
				this.setState({ loading: false });
			});
	}

	handleMiniClick = () => {
		if (document.body.classList.contains('sidebar-mini')) {
			this.setState({ sidebarMini: false });
		} else {
			this.setState({ sidebarMini: true });
		}
		document.body.classList.toggle('sidebar-mini');
	};

	setDependencia = selectDB => {
		this.setState({ selectDB });

		console.log('===>va a cambiar info: ', selectDB);

		if (selectDB.value === 1)
			this.setState({
				dataShow: this.state.catPlantillas.actoresPoliticos.data.map((prop, key) => {
					return (
						<Col key={key} md="3">
							<Card>
								<CardHeader>
									<CardTitle>{prop.partido}</CardTitle>
									<p className="card-category">{prop.data.length}</p>
								</CardHeader>
								<CardBody>
									<Doughnut
										data={Pastel(60, 40, '60%', 'En Plantilla').data}
										options={Pastel(60, 40, '60%', 'En Plantilla').options}
										className="ct-chart ct-perfect-fourth"
										height={300}
										width={456}
									/>
								</CardBody>
								<CardFooter>
									<div className="legend">
										<i className="fa fa-circle text-info" />
										Plantilla
									</div>
									<hr />
									<div className="stats">
										<i className="fa fa-calendar" />
										Personas en Plantilla
									</div>
								</CardFooter>
							</Card>
						</Col>
					);
				}),
				totalShow: 0,
			});

		if (selectDB.value === 2)
			this.setState({
				dataShow: this.state.catPlantillas.electoralAbogados.data.map((prop, key) => {
					console.log('==>Prop: ', prop);
					return (
						<Col key={key} md="3">
							<Card>
								<CardHeader>
									<CardTitle>{prop.partido}</CardTitle>
									<p className="card-category">{prop.data.length}</p>
								</CardHeader>
								<CardBody>
									<Doughnut
										data={Pastel(60, 40, '60%', 'En Plantilla').data}
										options={Pastel(60, 40, '60%', 'En Plantilla').options}
										className="ct-chart ct-perfect-fourth"
										height={300}
										width={456}
									/>
								</CardBody>
								<CardFooter>
									<div className="legend">
										<i className="fa fa-circle text-info" />
										Plantilla
									</div>
									<hr />
									<div className="stats">
										<i className="fa fa-calendar" />
										Personas en Plantilla
									</div>
								</CardFooter>
							</Card>
						</Col>
					);
				}),
				totalShow: 0,
			});

		if (selectDB.value === 3)
			this.setState({
				dataShow: this.state.catPlantillas.electoralCasasAzules.data.map((prop, key) => {
					return (
						<Col key={key} md="3">
							<Card>
								<CardHeader>
									<CardTitle>{prop.partido}</CardTitle>
									<p className="card-category">{prop.data.length}</p>
								</CardHeader>
								<CardBody>
									<Doughnut
										data={Pastel(60, 40, '60%', 'En Plantilla').data}
										options={Pastel(60, 40, '60%', 'En Plantilla').options}
										className="ct-chart ct-perfect-fourth"
										height={300}
										width={456}
									/>
								</CardBody>
								<CardFooter>
									<div className="legend">
										<i className="fa fa-circle text-info" />
										Plantilla
									</div>
									<hr />
									<div className="stats">
										<i className="fa fa-calendar" />
										Personas en Plantilla
									</div>
								</CardFooter>
							</Card>
						</Col>
					);
				}),
				totalShow: 0,
			});

		if (selectDB.value === 4)
			this.setState({
				dataShow: this.state.catPlantillas.electoralrc.data.map((prop, key) => {
					return (
						<Col key={key} md="3">
							<Card>
								<CardHeader>
									<CardTitle>{prop.partido}</CardTitle>
									<p className="card-category">{prop.data.length}</p>
								</CardHeader>
								<CardBody>
									<Doughnut
										data={Pastel(60, 40, '60%', 'En Plantilla').data}
										options={Pastel(60, 40, '60%', 'En Plantilla').options}
										className="ct-chart ct-perfect-fourth"
										height={300}
										width={456}
									/>
								</CardBody>
								<CardFooter>
									<div className="legend">
										<i className="fa fa-circle text-info" />
										Plantilla
									</div>
									<hr />
									<div className="stats">
										<i className="fa fa-calendar" />
										Personas en Plantilla
									</div>
								</CardFooter>
							</Card>
						</Col>
					);
				}),
				totalShow: 0,
			});

		if (selectDB.value === 5)
			this.setState({
				dataShow: this.state.catPlantillas.electoralrg.data.map((prop, key) => {
					return (
						<Col key={key} md="3">
							<Card>
								<CardHeader>
									<CardTitle>{prop.partido}</CardTitle>
									<p className="card-category">{prop.data.length}</p>
								</CardHeader>
								<CardBody>
									<Doughnut
										data={Pastel(60, 40, '60%', 'En Plantilla').data}
										options={Pastel(60, 40, '60%', 'En Plantilla').options}
										className="ct-chart ct-perfect-fourth"
										height={300}
										width={456}
									/>
								</CardBody>
								<CardFooter>
									<div className="legend">
										<i className="fa fa-circle text-info" />
										Plantilla
									</div>
									<hr />
									<div className="stats">
										<i className="fa fa-calendar" />
										Personas en Plantilla
									</div>
								</CardFooter>
							</Card>
						</Col>
					);
				}),
				totalShow: this.state.catPlantillas.electoralrg.total,
			});

		if (selectDB.value === 6)
			this.setState({
				dataShow: this.state.catPlantillas.estpromocion.data.map((prop, key) => {
					return (
						<Col key={key} md="3">
							<Card>
								<CardHeader>
									<CardTitle>{prop.partido}</CardTitle>
									<p className="card-category">{prop.data.length}</p>
								</CardHeader>
								<CardBody>
									<Doughnut
										data={Pastel(60, 40, '60%', 'En Plantilla').data}
										options={Pastel(60, 40, '60%', 'En Plantilla').options}
										className="ct-chart ct-perfect-fourth"
										height={300}
										width={456}
									/>
								</CardBody>
								<CardFooter>
									<div className="legend">
										<i className="fa fa-circle text-info" />
										Plantilla
									</div>
									<hr />
									<div className="stats">
										<i className="fa fa-calendar" />
										Personas en Plantilla
									</div>
								</CardFooter>
							</Card>
						</Col>
					);
				}),
				totalShow: this.state.catPlantillas.estpromocion.total,
			});

		if (selectDB.value === 7)
			this.setState({
				dataShow: this.state.catPlantillas.gto.data.map((prop, key) => {
					return (
						<Col key={key} md="3">
							<Card>
								<CardHeader>
									<CardTitle>{prop.partido}</CardTitle>
									<p className="card-category">{prop.data.length}</p>
								</CardHeader>
								<CardBody>
									<Doughnut
										data={Pastel(60, 40, '60%', 'En Plantilla').data}
										options={Pastel(60, 40, '60%', 'En Plantilla').options}
										className="ct-chart ct-perfect-fourth"
										height={300}
										width={456}
									/>
								</CardBody>
								<CardFooter>
									<div className="legend">
										<i className="fa fa-circle text-info" />
										Plantilla
									</div>
									<hr />
									<div className="stats">
										<i className="fa fa-calendar" />
										Personas en Plantilla
									</div>
								</CardFooter>
							</Card>
						</Col>
					);
				}),
				totalShow: this.state.catPlantillas.gto.total,
			});

		if (selectDB.value === 8)
			this.setState({
				dataShow: this.state.catPlantillas.pangto.data.map((prop, key) => {
					return (
						<Col key={key} md="3">
							<Card>
								<CardHeader>
									<CardTitle>{prop.partido}</CardTitle>
									<p className="card-category">{prop.data.length}</p>
								</CardHeader>
								<CardBody>
									<Doughnut
										data={Pastel(60, 40, '60%', 'En Plantilla').data}
										options={Pastel(60, 40, '60%', 'En Plantilla').options}
										className="ct-chart ct-perfect-fourth"
										height={300}
										width={456}
									/>
								</CardBody>
								<CardFooter>
									<div className="legend">
										<i className="fa fa-circle text-info" />
										Plantilla
									</div>
									<hr />
									<div className="stats">
										<i className="fa fa-calendar" />
										Personas en Plantilla
									</div>
								</CardFooter>
							</Card>
						</Col>
					);
				}),
				totalShow: this.state.catPlantillas.pangto.total,
			});

		if (selectDB.value === 9)
			this.setState({
				dataShow: this.state.catPlantillas.morenagto.data.map((prop, key) => {
					return (
						<Col key={key} md="3">
							<Card>
								<CardHeader>
									<CardTitle>{prop.partido}</CardTitle>
									<p className="card-category">{prop.data.length}</p>
								</CardHeader>
								<CardBody>
									<Doughnut
										data={Pastel(60, 40, '60%', 'En Plantilla').data}
										options={Pastel(60, 40, '60%', 'En Plantilla').options}
										className="ct-chart ct-perfect-fourth"
										height={300}
										width={456}
									/>
								</CardBody>
								<CardFooter>
									<div className="legend">
										<i className="fa fa-circle text-info" />
										Plantilla
									</div>
									<hr />
									<div className="stats">
										<i className="fa fa-calendar" />
										Personas en Plantilla
									</div>
								</CardFooter>
							</Card>
						</Col>
					);
				}),
				totalShow: this.state.catPlantillas.morenagto.total,
			});

		if (selectDB.value === 10)
			this.setState({
				dataShow: this.state.catPlantillas.prdgto.data.map((prop, key) => {
					return (
						<Col key={key} md="3">
							<Card>
								<CardHeader>
									<CardTitle>{prop.partido}</CardTitle>
									<p className="card-category">{prop.data.length}</p>
								</CardHeader>
								<CardBody>
									<Doughnut
										data={Pastel(60, 40, '60%', 'En Plantilla').data}
										options={Pastel(60, 40, '60%', 'En Plantilla').options}
										className="ct-chart ct-perfect-fourth"
										height={300}
										width={456}
									/>
								</CardBody>
								<CardFooter>
									<div className="legend">
										<i className="fa fa-circle text-info" />
										Plantilla
									</div>
									<hr />
									<div className="stats">
										<i className="fa fa-calendar" />
										Personas en Plantilla
									</div>
								</CardFooter>
							</Card>
						</Col>
					);
				}),
				totalShow: this.state.catPlantillas.prdgto.total,
			});

		if (selectDB.value === 11)
			this.setState({
				dataShow: this.state.catPlantillas.prigto.data.map((prop, key) => {
					return (
						<Col key={key} md="3">
							<Card>
								<CardHeader>
									<CardTitle>{prop.partido}</CardTitle>
									<p className="card-category">{prop.data.length}</p>
								</CardHeader>
								<CardBody>
									<Doughnut
										data={Pastel(60, 40, '60%', 'En Plantilla').data}
										options={Pastel(60, 40, '60%', 'En Plantilla').options}
										className="ct-chart ct-perfect-fourth"
										height={300}
										width={456}
									/>
								</CardBody>
								<CardFooter>
									<div className="legend">
										<i className="fa fa-circle text-info" />
										Plantilla
									</div>
									<hr />
									<div className="stats">
										<i className="fa fa-calendar" />
										Personas en Plantilla
									</div>
								</CardFooter>
							</Card>
						</Col>
					);
				}),
				totalShow: this.state.catPlantillas.prigto.total,
			});

		if (selectDB.value === 12)
			this.setState({
				dataShow: this.state.catPlantillas.pvemgto.data.map((prop, key) => {
					return (
						<Col key={key} md="3">
							<Card>
								<CardHeader>
									<CardTitle>{prop.partido}</CardTitle>
									<p className="card-category">{prop.data.length}</p>
								</CardHeader>
								<CardBody>
									<Doughnut
										data={Pastel(60, 40, '60%', 'En Plantilla').data}
										options={Pastel(60, 40, '60%', 'En Plantilla').options}
										className="ct-chart ct-perfect-fourth"
										height={300}
										width={456}
									/>
								</CardBody>
								<CardFooter>
									<div className="legend">
										<i className="fa fa-circle text-info" />
										Plantilla
									</div>
									<hr />
									<div className="stats">
										<i className="fa fa-calendar" />
										Personas en Plantilla
									</div>
								</CardFooter>
							</Card>
						</Col>
					);
				}),
				totalShow: this.state.catPlantillas.pvemgto.total,
			});

		if (selectDB.value === 13)
			this.setState({
				dataShow: this.state.catPlantillas.vota.data.map((prop, key) => {
					return (
						<Col key={key} md="3">
							<Card>
								<CardHeader>
									<CardTitle>{prop.partido}</CardTitle>
									<p className="card-category">{prop.data.length}</p>
								</CardHeader>
								<CardBody>
									<Doughnut
										data={Pastel(60, 40, '60%', 'En Plantilla').data}
										options={Pastel(60, 40, '60%', 'En Plantilla').options}
										className="ct-chart ct-perfect-fourth"
										height={300}
										width={456}
									/>
								</CardBody>
								<CardFooter>
									<div className="legend">
										<i className="fa fa-circle text-info" />
										Plantilla
									</div>
									<hr />
									<div className="stats">
										<i className="fa fa-calendar" />
										Personas en Plantilla
									</div>
								</CardFooter>
							</Card>
						</Col>
					);
				}),
				totalShow: this.state.catPlantillas.vota.total,
			});

		console.log('==>termino de cambiar la info');
	};

	downloadFile = () => {
		const token = global.access_token;
		getFile({}, 'getReportePlantillasDB', token);
	};

	render() {
		console.log('=>=> Mis stats: ', this.state);

		global = getVars('Token');

		const { loading, dataShow, totalShow } = this.state;

		return (
			<div className="wrapper">
				<Sidebar {...this.props} bgColor={this.state.backgroundColor} activeColor={this.state.activeColor} />
				<div className="main-panel" ref="mainPanel">
					<DashboardNavbar {...this.props} handleMiniClick={this.handleMiniClick} />
					<NotificationAlert ref="notificationAlert" />
					<div className="content">
						{!loading && (
							<Row>
								<Col className="ml-auto mr-auto" lg="4" md="6">
									<Select
										className="react-select primary"
										classNamePrefix="react-select"
										name="SelectDB"
										value={this.state.selectDB}
										onChange={selectDB => this.setDependencia(selectDB)}
										options={[
											{ value: 1, label: 'Actores Políticos' },
											{ value: 2, label: 'Abogados' },
											{ value: 3, label: 'Casa Azul' },
											{ value: 4, label: 'RC' },
											{ value: 5, label: 'RG' },
											{ value: 6, label: 'Promoción' },
											{ value: 7, label: 'GTO' },
											{ value: 8, label: 'PAN' },
											{ value: 9, label: 'Morena' },
											{ value: 10, label: 'PRD' },
											{ value: 11, label: 'PRI' },
											{ value: 12, label: 'PVEM' },
											{ value: 13, label: 'Vota' },
										]}
										placeholder="Seleccione"
									/>

									<span> {totalShow} registros</span>
								</Col>
								<Col md="3">
									<Button color="primary" onClick={this.downloadFile}>
										Exportar
									</Button>
								</Col>
							</Row>
						)}
						{loading && <CircleSpinner size={30} color="#686769" loading={loading} />}
						{!loading && (
							<div>
								<Row>{dataShow}</Row>
							</div>
						)}
					</div>
					<Footer fluid />
				</div>
			</div>
		);
	}
}

export default DashboardPlantilla;
