import React, { Component } from 'react';
import NotificationAlert from 'react-notification-alert';
import Select from 'react-select';
import { CircleSpinner } from 'react-spinners-kit';
import ReactDatetime from 'react-datetime';
import moment from 'moment';
import { Button, Row, Col, FormGroup, Label } from 'reactstrap';

import EjecutarApi from '../../data/Consultas';
import Notify from '../../data/Notificacion';

import DashboardNavbar from '../../components/Navbars/DashboardNavbar.jsx';
import Footer from '../../components/Footer/Footer.jsx';
import Sidebar from '../../components/Sidebar/MenuSidebar.jsx';

import { getVars,URL_PUBLICA } from '../../GlobalStore';

var global = '';

class ReporteDependencia extends Component {
	constructor(props) {
		super(props);
		this.state = {
			backgroundColor: 'black',
			activeColor: 'info',
			loading: false,
			isloading: false,
			sidebarMini: false,
			catDependencias: [],
			catProponen: [],
			userData: props.location.state,
			dependencia: { value: '', label: 'Seleccione' },
			propone: { value: '', label: 'Seleccione' },
			fi: '',
			ff: '',
			dataShow: [],
			totalShow: 0,
		};
	}

	componentDidMount() {
		this.setState({ loading: true });

		const token = global.access_token; //localstorage

		EjecutarApi({}, 'getDependenciaMovimientos', token)
			.then(function(res) {
				return res;
			})
			.then(items => {
				console.log('===> Data getDependenciaMovimientos: ', items);

				if (items.results) {
					this.setState({ loading: false, total: items.total, catDependencias: items.data });
				}
			})
			.catch(e => {
				console.log('error VoBo: ', e.message);
				Notify(3, 'tc', `Error al cargar dependencias: ${e.message}`, this.refs);
				this.setState({ loading: false });
			});

		const data = {
			page: 0,
			tipo: 'and',
			pageSize: 99999,
			sorted: [],
			filtered: [],
		};

		EjecutarApi(data, 'getProponen', token)
			.then(function(res) {
				return res;
			})
			.then(items => {
				console.log('===> Data catProponen: ', items);

				if (items.results) {
					this.setState({
						loading: false,
						catProponen: items.data.map(item => {
							return { label: item.NombrePropone, value: item.id };
						}),
					});
				}
			})
			.catch(e => {
				console.log('error VoBo: ', e.message);
				Notify(3, 'tc', `Error al cargar catProponen: ${e.message}`, this.refs);
				this.setState({ loading: false });
			});
	}

	handleMiniClick = () => {
		if (document.body.classList.contains('sidebar-mini')) {
			this.setState({ sidebarMini: false });
		} else {
			this.setState({ sidebarMini: true });
		}
		document.body.classList.toggle('sidebar-mini');
	};

	setDependencia = dependencia => {
		console.log('=>Dep: ', dependencia);
		this.setState({ dependencia });
	};

	setPropone = propone => {
		console.log('=>Dep: ', propone);
		this.setState({ propone });
	};

	setFI = e => {
		this.setState({ fi: moment(e._d).format('YYYY-MM-DD') });
	};

	setFF = e => {
		this.setState({ ff: moment(e._d).format('YYYY-MM-DD') });
	};

	setClear = () => {
		this.setState({ dependencia: { value: '', label: 'Seleccione' }, propone: { value: '', label: 'Seleccione' } });
	};

	getReporte = () => {
		if (this.state.dependencia.value.length && this.state.propone.value.length) {
			Notify(3, 'tc', 'Seleccione dependencia o Propone', this.refs);
			return true;
		}

		// if(this.state.dependencia.value.length<1)
		// {
		//     Notify(3, "tc", "Seleccione dependencia", this.refs);
		//     return true;
		// }

		this.setState({ isloading: true });
		const token = global.access_token; //localstorage

		const url = URL_PUBLICA+`getReporteMovimientos?idQuienPropone=${this.state.propone.value}&dependencia=${this.state.dependencia.value}&fi=${this.state.fi}&ff=${this.state.ff}&token=${token}`;

		console.log('=>url: ', url);

		window.open(url, '_blank');

		this.setState({ isloading: false });
	};

	render() {
		console.log('=>=> Mis stats: ', this.state);
		global = getVars('Token');

		const { loading, catDependencias, catProponen, isloading } = this.state;

		return (
			<div className="wrapper">
				<Sidebar {...this.props} bgColor={this.state.backgroundColor} activeColor={this.state.activeColor} />
				<div className="main-panel" ref="mainPanel">
					<DashboardNavbar {...this.props} handleMiniClick={this.handleMiniClick} />
					<NotificationAlert ref="notificationAlert" />
					<div className="content">
						<Row>
							{!isloading && (
								<Col sm="1">
									<Button color="primary" onClick={this.setClear}>
										clear
									</Button>
								</Col>
							)}
							{isloading && <CircleSpinner size={30} color="#686769" loading={loading} />}
							{!isloading && (
								<Col sm="3">
									<FormGroup>
										<Label className="text-left">Dependencia</Label>
										<Select
											className="react-select primary"
											classNamePrefix="react-select"
											name="SelectDB"
											value={this.state.dependencia}
											onChange={selectDB => this.setDependencia(selectDB)}
											options={catDependencias}
											placeholder="Seleccione"
										/>
									</FormGroup>
								</Col>
							)}
							{isloading && <CircleSpinner size={30} color="#686769" loading={loading} />}
							{!isloading && (
								<Col sm="3">
									<FormGroup>
										<Label className="text-left">Propone</Label>
										<Select
											className="react-select primary"
											classNamePrefix="react-select"
											name="SelectDB"
											value={this.state.propone}
											onChange={selectDB => this.setPropone(selectDB)}
											options={catProponen}
											placeholder="Seleccione"
										/>
									</FormGroup>
								</Col>
							)}
							{isloading && <CircleSpinner size={30} color="#686769" loading={loading} />}
							{!isloading && (
								<Col sm="2">
									<FormGroup>
										<Label className="text-left">Fecha Inicial</Label>
										<ReactDatetime
											dateFormat="YYYY-MM-DD"
											timeFormat={false}
											closeOnSelect
											onChange={this.setFI}
											inputProps={{
												className: 'form-control',
												placeholder: 'Fecha Inicial',
											}}
										/>
									</FormGroup>
								</Col>
							)}
							{isloading && <CircleSpinner size={30} color="#686769" loading={loading} />}
							{!isloading && (
								<Col sm="2">
									<FormGroup>
										<Label className="text-left">Fecha Final</Label>
										<ReactDatetime
											dateFormat="YYYY-MM-DD"
											timeFormat={false}
											closeOnSelect
											onChange={this.setFF}
											inputProps={{
												className: 'form-control',
												placeholder: 'Fecha Final',
											}}
										/>
									</FormGroup>
								</Col>
							)}
							{isloading && <CircleSpinner size={30} color="#686769" loading={loading} />}

							{!isloading && (
								<Col sm="2">
									<br></br>
									<Button color="primary" onClick={this.getReporte}>
										Exportar
									</Button>
								</Col>
							)}
							{isloading && <CircleSpinner size={30} color="#686769" loading={loading} />}
						</Row>
						{!loading && (
							<div>
								<Row></Row>
							</div>
						)}
					</div>
					<Footer fluid />
				</div>
			</div>
		);
	}
}

export default ReporteDependencia;
