import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

import { Button,Input,FormGroup,Label } from "reactstrap";

import defaultImage from "assets/img/image_placeholder.jpg";
import defaultAvatar from "assets/img/placeholder.jpg";

class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage,
      filename:null
    };
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }
  fileListToArray(list) {
    const array = []
    for (var i = 0; i < list.length; i++) {
      array.push(list.item(i))
    }
    return array
  }
  handleImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    console.log('Archivo: ',file);
    reader.onloadend = () => {
      this.setState({
        file: file.name,
        imagePreviewUrl: reader.result
      });
    };
    this.onFilesAdded(e);
    reader.readAsDataURL(file);
  }
  handleSubmit(e) {
    e.preventDefault();
    // this.state.file is the file/image uploaded
    // in this function you can save the image (this.state.file) on form submit
    // you have to call it yourself
  }
  handleClick() {
    this.refs.fileInput.click();
  }
  handleRemove() {
    this.setState({
      file: null,
      imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage
    });
    this.refs.fileInput.value = null;
  }
  onFilesAdded = (evt) => {
    console.log('EnviandoArchivo');
    if (this.props.disabled) return
    const files = evt.target.files

    this.setState({ filename: files[0].name});
    console.log('EnviandoArchivo-> this.props: ',this.props);
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files)
      this.props.onFilesAdded(array)
    }
  }
  render() {
    const AcceptFiles = this.props.Accept === "xlsx" ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" : this.props.Accept === "img" ? "image/jpeg, image/gif, image/png" : this.props.Accept === "pdf" ? "application/pdf": "text/plain";
    return (
      <div className="form-row align-items-center">
         <FormGroup className="col-md-6 pr-0">
            <Label className="text-left">
                Buscar Archivo:
            </Label>
            <Input readOnly='true' value={this.state.file} ></Input>
            
        </FormGroup>

        <FormGroup className="col-md-2 pl-0">
            <br></br>
            <input type="file" onChange={this.handleImageChange} ref="fileInput" className="btn-round" accept={AcceptFiles}/>
            <Button color='warning' onClick={() => this.handleClick()} >
            <i className="nc-icon nc-zoom-split lg" />
            </Button>
        </FormGroup>

    </div>
    );
  }
}

ImageUpload.propTypes = {
  avatar: PropTypes.bool
};

export default ImageUpload;
