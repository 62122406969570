import React, { Component } from 'react';
import { Button, Card, CardHeader, CardBody, CardFooter, Row, Col, Form, FormGroup, Label, Input } from 'reactstrap';
import Stepper from 'react-stepper-horizontal';
import ReactTable from 'react-table';
import matchSorter from 'match-sorter';
import { PushSpinner, CircleSpinner } from 'react-spinners-kit';
import NotificationAlert from 'react-notification-alert';

import EjecutarApi from '../../data/Consultas';
import Notify from '../../data/Notificacion';
import getFile from '../../data/GetFile';

import DashboardNavbar from '../../components/Navbars/DashboardNavbar.jsx';
import Footer from '../../components/Footer/Footer.jsx';
import Sidebar from '../../components/Sidebar/MenuSidebar.jsx';
import Pagination from '../components/Paginacion/index';

import { getVars,URL_PUBLICA } from '../../GlobalStore';

var global = '';

var infoRS = [];
infoRS[0] = 'Actores Políticos';
infoRS[1] = 'Electoral Abogados';
infoRS[2] = 'Electoral Casas Azules';
infoRS[3] = 'Proceso Electoral';
infoRS[4] = 'Est Promoción';
infoRS[5] = 'Morena';
infoRS[6] = 'PAN';
infoRS[7] = 'PRD';
infoRS[8] = 'PRI';
infoRS[9] = 'PVEM';
infoRS[10] = 'Vota';
infoRS[11] = 'Plantilla';
infoRS[12] = 'isEspecial';
infoRS[13] = 'osc';
infoRS[14] = '';
infoRS[15] = 'Sian';



var catRS = [];
catRS[0] = 'isMilitante';
catRS[1] = 'isElectoralA';
catRS[2] = 'isElectoral';
catRS[3] = 'isElectoralrc';
catRS[4] = 'isElectoralrg';
catRS[5] = 'isPromocion';
catRS[6] = 'isMorena';
catRS[7] = 'isPan';
catRS[8] = 'isPrd';
catRS[9] = 'isPri';
catRS[10] = 'isPvem';
catRS[11] = 'isSocial';
catRS[12] = 'isDirectorio';
catRS[13] = 'isEspecial';
catRS[14] = 'osc';
catRS[15] = 'rcrg_ine';
catRS[16] = 'Movimientos';
catRS[17] = 'procesoElectoral';
catRS[18] = 'Sian';
catRS[19] = 'Sian';

class ValidacionesGral extends Component {
	constructor(props) {
		super(props);
		console.log('=> Validacion data: ', props.data);

		this.state = {
			menu: {
				Agregar: 0,
				Editar: 0,
				Eliminar: 0,
				Exportar: 0,
				Seguimiento:0,
			  },
			data: props.data || {},
			step: 0,
			isLoaging: false,
			showButtons: false,
			Nombre: '',
			Paterno: '',
			Materno: '',
			Current: {
				Current: '',
				id: null,
			},
			MostrarEncontrado: false,
			loading: true,
			results: [],
			setValidaciones: {
				id: null,
				NombreCompleto: '',
				Tipo: 'Otras Validaciones',
				Propone: '',
				actorespoliticos: '',
				electoralabogados: '',
				electoralCasasAzules: '',
				electoral: '',
			
				estpromocion: '',
				morenagto: '',
				pangto: '',
				prdgto: '',
				prigto: '',
				pvemgto: '',
				vota: '',
				plantilla: '',
				isEspecial: '',
				osc: '',
				movimientos: '',
				sian:''
			},
			
		};
	}

	componentDidMount() {
		console.log('token: ',global.menus);
		global.menus.forEach(item => {
			if (item.idMenu === 2) {
				const menu = item.views.filter(it =>it.name === 'Otras Validaciones');
				console.log('token-menu ',menu);
				this.setState({ menu: menu.length ? menu[0] : menu });
				
			}
		});
	}

	setStep = e => {
		console.log('===>Click Step1: ', e);
	};

	updateMovimiento = (idMovimiento, idStatus) => {
		const token = global.access_token; //localstorage

		const formData = new FormData();
		formData.append('idMovimientoStatus', idStatus);
		formData.append('id', idMovimiento);

		fetch(URL_PUBLICA+`validarMovimiento`, {
			method: 'POST',
			headers: new Headers({ Authorization: `Bearer ${token}` }),
			body: formData,
		})
			.then(response => {
				switch (response.status) {
					case 200:
						console.log('==>Error Status: ', response.status);
						break;

					default:
						console.log('==>Unknow Status: ', response.status);
						/*
                    this.props.history.push({
                        pathname: '/logout',
                        state: {token},
                        message: 'Su cuenta ya expiro'
                    });
                    */
						return;
				}

				if (response.ok) {
					return response.json();
				}
				throw new Error('Ocurrio un error: obtenerDatosCandidatos: ', response.statusText);
			})
			.then(items => {
				console.log('Generar Archivo de PDF');

				this.updateValidaciones();
			})
			.catch(e => {
				console.log('error: ', e.message);
				return e.message;
			});
	};

	encontrado = Current => {
		console.log('=>> this.state.Current.id', this.state.Current.id);
		var id = this.state.Current.id;
		id = this.state.Current.id + 1;
		
		if (id <= 15) {
			//id = this.state.Current.id + 1;
			const dataTable = this.state.InfoData[id].data;
			console.log(this.state.InfoData[id].data);
			this.setState({
				results: dataTable.map((prop, key) => {
					console.log('==>Propss: ', prop);
					return {
						id: key,
						key: key,
						btnNombre: (
							<Button
								className="btn-link"
								color="primary"
								onClick={() => {
									let obj = this.state.results.find(o => o.key === key);
									this.setEncontrado(obj);
								}}
							>
								{prop.nombreCompleto}
							</Button>
						),
						NombreCompleto: prop.nombreCompleto || '',
						Municipio: prop.municipio || '',
						Info: prop.Info || '',
						Fuente: prop.Fuente?infoRS[id]+prop.Fuente:infoRS[id],
						data: prop,
					};
				}),
			});
			
			this.setState({ Current: { id, Current: infoRS[id] }, step: id });
		
		}
		
		console.log('=>> Current', id);
		
		if (id > 15) {
			console.log('Generar Archivo de PDF');
			this.updateValidaciones();
		}
	};

	noencontrado = Current => {
		const { Nombre, Paterno, Materno } = this.state;
		var id = this.state.Current.id ;
		id = this.state.Current.id + 1;
		console.log('=>>noencontrado-> Current', id);
		if (id <= 15) {
			
			const dataTable = this.state.InfoData[id].data;
			console.log('==>dataTable', dataTable);

			this.setState({
				results: dataTable.map((prop, key) => {
					console.log('==>Props: ', prop);
					return {
						id: key,
						key: key,
						btnNombre: (
							<Button
								className="btn-link"
								color="primary"
								onClick={() => {
									let obj = this.state.results.find(o => o.key === key);
									this.setEncontrado(obj);
								}}
							>
								{prop.nombreCompleto}
							</Button>
						),
						NombreCompleto: prop.nombreCompleto || '',
						Municipio: prop.municipio || '',
						Info: prop.Info || '',
						Fuente: prop.Fuente,
						data: prop,
					};
				}),
			});

			this.setState({ Current: { id, Current: infoRS[id] }, step: id });
		}
		if (id > 15) {
			console.log('Generar Archivo de PDF');
			this.updateValidaciones();
		}
	};

	handleMiniClick = () => {
		if (document.body.classList.contains('sidebar-mini')) {
			this.setState({ sidebarMini: false });
		} else {
			this.setState({ sidebarMini: true });
		}
		document.body.classList.toggle('sidebar-mini');
	};

	setCheck = item => {
		console.log('=>Seleccionar: ', item);
		console.log('=>stats: ', this.state);

		this.encontrado(this.state.Current);
	};

	updateValidaciones = () => {
		
		var data = this.state.setValidaciones;
		const token = global.access_token;

		console.log('=>Ejecutar Api: setCandidatoValidacion ', data);
		console.log('=>Ejecutar Api: state ', this.state);

		EjecutarApi(data, 'setCandidatoValidacion', token)
			.then(function(res) {
				return res;
			})
			.then(items => {
				if(this.state.menu.Exportar===1)
				{
					console.log('===> Data setCandidatoValidacion: ', items);
					if (items.results) {
						Notify(2, 'tc', `Descargando archivo`, this.refs);
						//const token = global.access_token;
						getFile([{ id: 'uid', value: items.data.uid }], 'getCandidatosValidaciones', token);
					}
				}
				this.setState({
					setValidaciones: {
						id: null,
						NombreCompleto: '',
						Tipo: 'Otras Validaciones',
						Propone: '',
						actorespoliticos: '',
						electoralabogados: '',
						electoralCasasAzules: '',
						electoral: '',
						estpromocion: '',
						morenagto: '',
						pangto: '',
						prdgto: '',
						prigto: '',
						pvemgto: '',
						vota: '',
						plantilla: '',
						isEspecial: '',
						osc: '',
						movimientos: '',
						sian:'',
						directorio:''
					},
				});
			})
			.catch(e => {
				console.log('error setCandidatoValidacion: ', e);
				Notify(2, 'tc', `Error al setCandidatoValidacion: ${e.message}`, this.refs);
			});
	};

	setEncontrado = item => {
		console.log('=>encontrado->this.state.Current.id: ', this.state.Current.id);
		console.log('=>this.state: ', this.state);
		console.log('=>item: ', item);

		switch (this.state.Current.id) {
			case 0: //Actores Políticos
			this.setState({
				setValidaciones: {
					
					...this.state.setValidaciones,
					Tipo: 'Otras Validaciones',
					id: '',
					NombreCompleto: item.NombreCompleto, 
					Propone: '',
					actorespoliticos: `${item.NombreCompleto} ${item.Info} ${item.Municipio} ${item.Fuente}`,
				},
			});
				break;
			case 1: //electoralabogados
				this.setState({
					setValidaciones: {
						
						...this.state.setValidaciones,
						Tipo: 'Otras Validaciones',
						id: '',
						NombreCompleto: item.NombreCompleto, 
						Propone: '',
						electoralabogados: `${item.NombreCompleto} ${item.Info} ${item.Municipio} ${item.Fuente}`,
					},
				});
				break;

			case 2: //Casas Azules
				this.setState({
					setValidaciones: {
						
						...this.state.setValidaciones,
						Tipo: 'Otras Validaciones',
						id: '',
						NombreCompleto: item.NombreCompleto, 
						Propone: '',
						electoralCasasAzules: `${item.NombreCompleto} ${item.Info} ${item.Municipio} ${item.Fuente}`,
					},
				});
				break;
			case 3: //electoralrc
				this.setState({
					setValidaciones: {
						
						...this.state.setValidaciones,
						Tipo: 'Otras Validaciones',
						id: '',
						NombreCompleto: item.NombreCompleto, 
						Propone: '',
						electoral: `${item.NombreCompleto} ${item.Info} ${item.Municipio} ${item.Fuente}`,
					},
				});
				break;
			
			case 4: //estpromocion
				this.setState({
					setValidaciones: {
						
						...this.state.setValidaciones,
						Tipo: 'Otras Validaciones',
						id: '',
						NombreCompleto: item.NombreCompleto, 
						Propone: '',
						estpromocion: `${item.NombreCompleto} ${item.Info} ${item.Municipio} ${item.Fuente}`,
					},
				});
				break;

			case 5: //morenagto
				this.setState({
					setValidaciones: {
						
						...this.state.setValidaciones,
						Tipo: 'Otras Validaciones',
						id: '',
						NombreCompleto: item.NombreCompleto, 
						Propone: '',
						morenagto: `${item.NombreCompleto} ${item.Info} ${item.Municipio} ${item.Fuente}`,
					},
				});
				break;
			case 6: //pangto
				this.setState({
					setValidaciones: {
						
						...this.state.setValidaciones,
						Tipo: 'Otras Validaciones',
						id: '',
						NombreCompleto: item.NombreCompleto, 
						Propone: '',
						pangto: `${item.NombreCompleto} ${item.Info} ${item.Municipio} ${item.Fuente}`,
					},
				});
				break;
			case 7: //prdgto
				this.setState({
					setValidaciones: {
						
						...this.state.setValidaciones,
						Tipo: 'Otras Validaciones',
						id: '',
						NombreCompleto: item.NombreCompleto, 
						Propone: '',
						prdgto: `${item.NombreCompleto} ${item.Info} ${item.Municipio} ${item.Fuente}`,
					},
				});
				break;
			case 8: //prigto
				this.setState({
					setValidaciones: {
						
						...this.state.setValidaciones,
						Tipo: 'Otras Validaciones',
						id: '',
						NombreCompleto: item.NombreCompleto, 
						Propone: '',
						prigto: `${item.NombreCompleto} ${item.Info} ${item.Municipio} ${item.Fuente}`,
					},
				});
				break;
			case 9: //pvemgto
				this.setState({
					setValidaciones: {
						
						...this.state.setValidaciones,
						Tipo: 'Otras Validaciones',
						id: '',
						NombreCompleto: item.NombreCompleto, 
						Propone: '',
						pvemgto: `${item.NombreCompleto} ${item.Info} ${item.Municipio} ${item.Fuente}`,
					},
				});
				break;
			case 10: //vota
				this.setState({
					setValidaciones: {
						
						...this.state.setValidaciones,
						Tipo: 'Otras Validaciones',
						id: '',
						NombreCompleto: item.NombreCompleto, 
						Propone: '',
						vota: `${item.NombreCompleto} ${item.Info} ${item.Municipio} ${item.Fuente}`,
					},
				});
				break;
			case 11: //Plantilla
				this.setState({
					setValidaciones: {
						
						...this.state.setValidaciones,
						Tipo: 'Otras Validaciones',
						id: '',
						NombreCompleto: item.NombreCompleto, 
						Propone: '',
						directorio: `${item.NombreCompleto} ${item.Info} ${item.Municipio} ${item.Fuente}`,
					},
				});
				break;
			case 12: //isEspecial
				this.setState({
					setValidaciones: {
						
						...this.state.setValidaciones,
						Tipo: 'Otras Validaciones',
						id: '',
						NombreCompleto: item.NombreCompleto, 
						Propone: '',
						isEspecial: `${item.NombreCompleto} ${item.Info} ${item.Municipio} ${item.Fuente}`,
					},
				});
				break;
			case 13: //osc
			this.setState({
					setValidaciones: {
						
						...this.state.setValidaciones,
						Tipo: 'Otras Validaciones',
						id: '',
						NombreCompleto: item.NombreCompleto, 
						Propone: '',
						osc: `${item.NombreCompleto} ${item.Info} ${item.Municipio} ${item.Fuente}`,
					},
				});
				break;
			case 14: //movimientos
				this.setState({
					setValidaciones: {
						
						...this.state.setValidaciones,
						Tipo: 'Otras Validaciones',
						id: '',
						NombreCompleto: item.NombreCompleto, 
						Propone: '',
						movimientos: `${item.NombreCompleto} ${item.Info} ${item.Municipio} ${item.Fuente}`,
					},
				});
				break;
			case 15: //sian
				this.setState({
					setValidaciones: {
						
						...this.state.setValidaciones,
						Tipo: 'Otras Validaciones',
						id: '',
						NombreCompleto: item.NombreCompleto, 
						Propone: '',
						sian: `${item.NombreCompleto} ${item.Info} ${item.Municipio} ${item.Fuente}`,
					},
				});
			break;
			case 16: //directorio
			this.setState({
				setValidaciones: {
					
					...this.state.setValidaciones,
					Tipo: 'Otras Validaciones',
					id: '',
					NombreCompleto: item.NombreCompleto, 
					Propone: '',
					directorio: `${item.NombreCompleto} ${item.Info} ${item.Municipio} ${item.Fuente}`,
				},
			});
			break;
			default:
				break;
		}
		
		this.encontrado(this.state.Current);
	};

	handleSubmit = e => {
		e.preventDefault();

		this.setState({ isLoaging: true, loading: true });

		const Nombre = this.state.Nombre.length + this.state.Paterno.length + this.state.Materno.length;

		if (Nombre.length < 4) {
			Notify(3, 'tc', `Error: escriba el nombre`, this.refs);
			this.setState({ isLoaging: false, loading: false });
			return true;
		}
		const data = {
			Nombre: this.state.Nombre,
			Paterno: this.state.Paterno,
			Materno: this.state.Materno,
			NombreCompleto: '',
		};

		const token = global.access_token;

		EjecutarApi(data, 'validaciones', token)
			.then(function(res) {
				return res;
			})
			.then(items => {
				const InfoData = [];
				InfoData.push(items.actorespoliticos);
				InfoData.push(items.electoralabogados);
				InfoData.push(items.electoralCasasAzules);
				InfoData.push(items.electoral);
				InfoData.push(items.estpromocion);
				InfoData.push(items.morenagto);
				InfoData.push(items.pangto);
				InfoData.push(items.prdgto);
				InfoData.push(items.prigto);
				InfoData.push(items.pvemgto);
				InfoData.push(items.vota);
				InfoData.push(items.directorio);
				InfoData.push(items.isEspecial);
				InfoData.push(items.osc);
				InfoData.push(items.movimiento);
				InfoData.push(items.sian);
				
				const dataTable = items.actorespoliticos.data;
				this.setState({
					isLoaging: false,
					showButtons: true,
					results: dataTable.map((prop, key) => {
						return {
							id: key,
							key: key,

							NombreCompleto: prop.nombreCompleto || '',
							btnNombre: (
								<Button
									className="btn-link"
									color="primary"
									onClick={() => {
										let obj = this.state.results.find(o => o.key === key);
										this.setEncontrado(obj);
									}}
								>
									{prop.nombreCompleto}
								</Button>
							),
							Municipio: prop.municipio || '',
							Info: prop.Info || '',
							Fuente: 'Actores Políticos' +' - '+ prop.Fuente,
							data: prop,
						};
					}),
				});

				this.setState({
					validRS: items,
					InfoData,
					MostrarEncontrado: this.state.results.length > 0 ? true : false,
					Current: { Current: 'Actores Políticos', id: 0 },
					loading: false,
					step:0
				});
			})
			.catch(e => {
				console.log('=Error[getME]: ', e);
				Notify(3, 'tc', `Error en getME: ${e.error}`, this.refs);
				this.setState({ isLoaging: false, loading: false });
			});
	};

	render() {
		console.log('==> State validaciones: ', this.state);
		global = getVars('Token');
		const Steps = [
			{
				title: 'Actores',
				onClick: e => {
					e.preventDefault();
					console.log('onClick', 1);
					this.setStep(e);
				},
			},
			{
				title: 'Aboga2',
				onClick: e => {
					e.preventDefault();
					console.log('onClick', 2);
					this.setStep(e);
				},
			},
			{
				title: 'Casa Azul',
				onClick: e => {
					e.preventDefault();
					console.log('onClick', 3);
					this.setStep(e);
				},
			},
			{
				title: 'Electoral',
				onClick: e => {
					e.preventDefault();
					console.log('onClick', 4);
					this.setStep(e);
				},
			},
			{ title: 'Promo' },
			{ title: 'Morena' },
			{ title: 'PAN' },
			{ title: 'PRD' },
			{ title: 'PRI' },
			{ title: 'PVEM' },
			{ title: 'Vota' },
			{ title: 'Directorio' },
			{ title: 'Especial' },
			{ title: 'OSC' },
			{ title: 'Movimiento' },
			{ title: 'Sian' },
			
		];

		const { loading, showButtons, results, step, isLoaging } = this.state;
		const disEnc = this.state.results.length > 0 ? false : true;

		return (
			<div className="wrapper">
				<Sidebar {...this.props} bgColor={this.state.backgroundColor} activeColor={this.state.activeColor} />
				<div className="main-panel" ref="mainPanel">
					<DashboardNavbar {...this.props} handleMiniClick={this.handleMiniClick} />
					<NotificationAlert ref="notificationAlert" />
					<div className="content">
						<Row>
							<Col md="2">
								<Form
									className="form-horizontal"
									name="form1"
									id="form1"
									onSubmit={this.handleSubmit}
									noValidate
								>
									<Card>
										<CardHeader>
											<h5 className="title">Buscar personas</h5>
										</CardHeader>
										<CardBody>
											<Row>
												<Col>
													<FormGroup>
														<Label className="text-left">Nombre</Label>
														<Input
															placeholder="Nombre"
															id="Nombre"
															value={this.state.Nombre}
															type="text"
															onChange={e => this.setState({ Nombre: e.target.value })}
														/>
													</FormGroup>
												</Col>
											</Row>
											<Row>
												<Col>
													<FormGroup>
														<Label className="text-left">Paterno</Label>
														<Input
															placeholder="Paterno"
															id="Paterno"
															value={this.state.Paterno}
															type="text"
															onChange={e => this.setState({ Paterno: e.target.value })}
														/>
													</FormGroup>
												</Col>
											</Row>
											<Row>
												<Col>
													<FormGroup>
														<Label className="text-left">Materno</Label>
														<Input
															placeholder="Materno"
															id="Materno"
															value={this.state.Materno}
															type="text"
															onChange={e => this.setState({ Materno: e.target.value })}
														/>
													</FormGroup>
												</Col>
											</Row>
										</CardBody>
										<CardFooter>
											{!isLoaging && (
												<Button className="btn-round" color="info" type="submit">
													Buscar
												</Button>
											)}
											{isLoaging && (
												<CircleSpinner size={30} color="#686769" loading={isLoaging} />
											)}
										</CardFooter>
									</Card>
								</Form>
							</Col>
							<Col md="10">
								<Card>
									<CardHeader>
										<h5 className="title">Propuesta</h5>
									</CardHeader>
									<CardBody>
										<Row>
											<Col>
												<Stepper
													steps={Steps}
													activeStep={this.state.step}
													onClick={e => this.setStep(e)}
												/>
											</Col>
										</Row>
										{!loading && (
											<div>
												<Row>
													<Col>
														{step < 17 && (
															<h4>
																Paso {this.state.Current.id + 1}.{' '}
																{this.state.Current.Current} mostrando {results.length}{' '}
																resultados
															</h4>
														)}
														{step > 17 && <h4>Listo!</h4>}
													</Col>
												</Row>
												<Row>
													<Col>
														<div
															className="table-full-width table-responsive"
															style={{ height: '450px' }}
														>
															<ReactTable
																data={results}
																filterable
																showPagination={true}
																showPageJump={false}
																previousText="Anterior"
																nextText="Siguiente"
																canNextFromData={true}
																defaultPageSize={15}
																total={this.state.total}
																pages={Math.floor(this.state.total / 10)}
																columns={[
																	{
																		Header: 'Nombre',
																		id: 'NombreCompleto',
																		accessor: d => d.btnNombre,
																		filterMethod: (filter, rows) =>
																			matchSorter(rows, filter.value, {
																				keys: ['NombreCompleto'],
																			}),
																		filterAll: true,
																	},
																	{
																		Header: 'Municipio',
																		id: 'Municipio',
																		accessor: d => d.Municipio,
																		filterMethod: (filter, rows) =>
																			matchSorter(rows, filter.value, {
																				keys: ['Municipio'],
																			}),
																		filterAll: true,
																	},
																	{
																		Header: 'Info',
																		id: 'Info',
																		accessor: d => d.Info,
																		filterMethod: (filter, rows) =>
																			matchSorter(rows, filter.value, {
																				keys: ['Info'],
																			}),
																		filterAll: true,
																	},
																	{
																		Header: 'Fuente',
																		id: 'Fuente',
																		accessor: d => d.Fuente,
																		filterAll: false,
																	},
																]}
																PaginationComponent={Pagination}
																/*
                                        You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                                        */
																className="-striped -highlight primary-pagination"
															/>
														</div>
													</Col>
												</Row>
											</div>
										)}
										{loading && !isLoaging && (
											<PushSpinner size={30} color="#686769" loading={loading} />
										)}
									</CardBody>
									{showButtons && step < 19 && (
										<CardFooter>
											<div className="form-row  text-center">
												<Col>
													<Button
														disabled={disEnc}
														onClick={() => this.setEncontrado(this.state.results[0])}
														className="ml-3"
														color="info"
													>
														Encontrado
													</Button>
												</Col>
												<Col>
													<Button
														onClick={() => this.noencontrado(this.state.Current)}
														className="ml-3"
														color="danger"
													>
														No Encontrado
													</Button>
												</Col>
											</div>
										</CardFooter>
									)}
								</Card>
							</Col>
						</Row>
					</div>
					<Footer fluid />
				</div>
			</div>
		);
	}
}

export default ValidacionesGral;
