import React, { Component } from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Row,
  Col,
  Modal
} from "reactstrap";
import Select from "react-select";
import ReactTable from "react-table";
import matchSorter from "match-sorter";
import NotificationAlert from "react-notification-alert";

import EjecutarApi from "../../../data/Consultas";
import Notify from "../../../data/Notificacion";
import { getVars } from "../../../GlobalStore";

import Pagination from "../Paginacion/index";

var global = "";

class ModalPropone extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showNew: false,
      loading: false,
      totalProponen: 0,
      Nombre: "",
      Paterno: "",
      Materno: "",
      Cargo: "",
      OtraDependencia: "",
      ItemsDependencia: props.ItemsDependencia || [],
      Resultados: [],
      ProponenRS: [],
      TotalRegistros: -1,
      idDependencia: {
        value: "undefined",
        label: "Seleccione"
      }
    };
  }

  componentDidMount() {
    this.setState({
      ItemsDependencia: this.props.ItemsDependencia
    });
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.ItemsDependencia.length !== nextProps.ItemsDependencia.length
    ) {
      this.setState({
        ItemsDependencia: nextProps.ItemsDependencia
      });
    }
  }

  setDependencia = e => {
    console.log(e);
    this.setState({
      idDependencia: {
        value: e.id,
        label: e.label
      }
    });
  };

  setValidate = Propone => {
    console.log('Datos CLIKC',Propone);
    const data = {
      idQuienPropone: Propone.id,
      Nombre: Propone.Nombre,
      Paterno: Propone.Paterno,
      Materno: Propone.Materno,
      Puesto: Propone.Puesto,
      Dependencia: Propone.Dependencia,
      OtraDependencia: "",
      FullName: Propone.NombrePropone
    };

    this.props.newUser(data);
    this.props.toggle();
  };

  btnSavePropone = () => {
    if (this.state.Nombre.length < 3) {
      Notify(3, "tc", "Error: Especifique nombre", this.refs);
      return;
    }
    if (this.state.Paterno.length < 3) {
      Notify(3, "tc", "Error: Especifique apellido paterno", this.refs);
      return;
    }
    if (this.state.Materno.length < 3) {
      Notify(3, "tc", "Error: Especifique apellido materno", this.refs);
      return;
    }
    if (this.state.Cargo.length < 3) {
      Notify(3, "tc", "Error: Especifique Cargo", this.refs);
      return;
    }
    if (this.state.idDependencia.value < 1) {
      Notify(3, "tc", "Error: Especifique dependencia", this.refs);
      return;
    }

    if (this.state.idDependencia.value === 45) {
      if (this.state.OtraDependencia.length < 2) {
        Notify(3, "tc", "Error: Especifique Otra Dependencia", this.refs);
        return;
      }
    }

    const Propone = {
      idQuienPropone: null,
      Nombre: this.state.Nombre,
      Paterno: this.state.Paterno,
      Materno: this.state.Materno,
      Puesto: this.state.Cargo,
      Dependencia: this.state.idDependencia,
      OtraDependencia: this.state.OtraDependencia,
      FullName:
        this.state.Nombre + " " + this.state.Paterno + " " + this.state.Materno
    };

    this.props.newUser(Propone);
    this.props.toggle();
  };

  newPropone = () => {
    this.setState({
      showNew: true
    });
  };

  btnCancelar = () => {
    this.setState({
      showNew: false
    });
  };

  setClear = () => {
    this.setState({
      ProponenRS: [],
      Resultados: [],
      TotalRegistros: -1
    });
  };

  render() {
    global = getVars("Token");

    const {
      TotalRegistros,
      Nombre,
      Materno,
      Paterno,
      Cargo,
      ItemsDependencia,
      ProponenRS,
      OtraDependencia,
      idDependencia
    } = this.state;

    console.log("=>Modal Propone State: ", this.state);

    return (
      <Modal size="lg" isOpen={this.props.isOpen} toggle={this.props.toggle}>
        <NotificationAlert ref="notificationAlert" />
        <div className="modal-header">
          <button
            aria-hidden={true}
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={this.props.toggle}
          >
            <i className="nc-icon nc-simple-remove" />
          </button>
          <h5 className="modal-title" id="myModalLabel">
            Buscar quien propone
          </h5>
        </div>
        <div className="modal-body">
          {!this.state.showNew && (
            <div>
              <Row>
                <Col md="12">
                  <Card>
                    <CardHeader>
                      {TotalRegistros !== -1 && (
                        <CardTitle tag="h4">
                          Registros encontrados {TotalRegistros}
                          <a href="#chris" onClick={this.setClear}>
                            Borrar
                          </a>
                        </CardTitle>
                      )}
                    </CardHeader>
                    <CardBody>
                      <ReactTable
                        data={ProponenRS}
                        filterable
                        columns={[
                          {
                            Header: "Propone",
                            minWidth: 150,
                            id: "nombreCompleto",
                            accessor: d => d.btnPropone,
                            filterMethod: (filter, rows) =>
                              matchSorter(rows, filter.value, {
                                keys: ["Propone"]
                              }),
                            filterAll: true
                          },
                          {
                            Header: "Cargo",
                            id: "puesto",
                            accessor: d => d.Puesto,
                            filterMethod: (filter, rows) =>
                              matchSorter(rows, filter.value, {
                                keys: ["Puesto"]
                              }),
                            filterAll: true
                          },
                          {
                            Header: "Dependencia",
                            id: "dependencia",
                            accessor: d => d.Dependencia,
                            filterMethod: (filter, rows) =>
                              matchSorter(rows, filter.value, {
                                keys: ["Dependencia"]
                              }),
                            filterAll: true
                          }
                        ]}
                        loading={this.state.loading}
                        defaultPageSize={10}
                        showPagination={true}
                        showPaginationTop={false}
                        showPaginationBottom={true}
                        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                        manual
                        canNextFromData={true}
                        showTotalPages={true}
                        total={this.state.TotalRegistros}
                        PaginationComponent={Pagination}
                        previousText="Anterior"
                        nextText="Siguiente"
                        ofText="de"
                        id="tbProponen"
                        onFetchData={(state, instance) => {
                          const token = global.access_token;

                          const Filtered = this.state.firstTime
                            ? []
                            : state.filtered;
                          this.setState({
                            loading: true,
                            firstTime: false
                          });

                          const data = {
                            page: state.page,
                            tipo: "and",
                            pageSize: state.pageSize,
                            sorted: state.sorted,
                            filtered: Filtered
                          };

                          console.log("==>Filtered getProponen: ", data);

                          EjecutarApi(data, "getProponen", token)
                            .then(function(res) {
                              return res;
                            })
                            .then(items => {
                              console.log("=> Resultados proponen: ", items);

                              if (items.results) {
                                const dataTable = items.data;

                                this.setState({
                                  loading: false,
                                  TotalRegistros: items.data.length,
                                  ProponenRS: dataTable.map((prop, key) => {
                                    return {
                                      id: prop.id,
                                      idQuienPropone: prop.id,
                                      key: key,
                                      NombrePropone: prop.NombrePropone,
                                      Nombre: prop.Nombre,
                                      Paterno: prop.Paterno,
                                      Materno: prop.Materno,
                                      Puesto: prop.Puesto,
                                      data: prop,
                                      btnPropone: (
                                        <Button
                                          className="btn-link"
                                          color="primary"
                                          onClick={() => {
                                            this.setValidate(prop);
                                          }}
                                        >
                                          {`${prop.NombrePropone}`}
                                        </Button>
                                      ),
                                      Dependencia: prop.Dependencia.label,
                                      idDependencia: prop.Dependencia.value
                                    };
                                  })
                                });
                              }
                            })
                            .catch(e => {
                              console.log("error getProponen: ", e.message);
                              Notify(
                                3,
                                "tc",
                                `Error al cargar getProponen: ${e.message}`,
                                this.refs
                              );
                              this.setState({
                                loading: false
                              });
                            });
                        }}
                        /*
                        You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none-which will make the pagination buttons gray
                        */
                        className="-striped -highlight primary-pagination"
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          )}
          {this.state.showNew && (
            <div>
              <Row>
                <Col sm="3">
                  <FormGroup>
                    <Label className="text-left"> Nombre </Label>
                    <Input
                      placeholder="Nombre"
                      id="Nombre"
                      type="text"
                      value={Nombre}
                      onChange={e =>
                        this.setState({
                          Nombre: e.target.value
                        })
                      }
                    />
                  </FormGroup>
                </Col>
                <Col sm="3">
                  <FormGroup>
                    <Label className="text-left"> Paterno </Label>
                    <Input
                      placeholder="Paterno"
                      id="Paterno"
                      type="text"
                      value={Paterno}
                      onChange={e =>
                        this.setState({
                          Paterno: e.target.value
                        })
                      }
                    />
                  </FormGroup>
                </Col>
                <Col sm="3">
                  <FormGroup>
                    <Label className="text-left"> Materno </Label>
                    <Input
                      placeholder="Materno"
                      id="Materno"
                      type="text"
                      value={Materno}
                      onChange={e =>
                        this.setState({
                          Materno: e.target.value
                        })
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  <FormGroup>
                    <Label className="text-left"> Cargo </Label>
                    <Input
                      placeholder="Cargo"
                      id="Cargo"
                      type="text"
                      value={Cargo}
                      onChange={e =>
                        this.setState({
                          Cargo: e.target.value
                        })
                      }
                    />
                  </FormGroup>
                </Col>
                <Col sm="5">
                  <FormGroup>
                    <Label className="text-left"> Dependencia </Label>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="idDependencia"
                      value={this.state.idDependencia}
                      onChange={this.setDependencia}
                      options={
                        ItemsDependencia &&
                        ItemsDependencia.constructor === Array
                          ? ItemsDependencia
                          : []
                      }
                      placeholder="Seleccione Dependencia"
                    />
                  </FormGroup>
                </Col>
                {idDependencia.value === 45 && (
                  <Col sm="4">
                    <FormGroup>
                      <Label className="text-left"> Otra Dependencia </Label>
                      <Input
                        placeholder="OtraDependencia"
                        id="OtraDependencia"
                        type="text"
                        value={OtraDependencia}
                        onChange={e =>
                          this.setState({
                            OtraDependencia: e.target.value
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                )}
              </Row>
            </div>
          )}
        </div>
        {!this.state.showNew && (
          <div className="modal-footer justify-content-center">
            <Button
              className="btn-round"
              color="info"
              data-dismiss="modal"
              type="button"
              onClick={this.newPropone}
            >
              Nuevo
            </Button>
          </div>
        )}
        {this.state.showNew && (
          <div className="modal-footer justify-content-center">
            <Button
              className="btn-round"
              color="info"
              data-dismiss="modal"
              type="button"
              onClick={this.btnSavePropone}
            >
              Guardar
            </Button>
            <Button
              className="btn-round"
              color="info"
              data-dismiss="modal"
              type="button"
              onClick={this.btnCancelar}
            >
              Cancelar
            </Button>
          </div>
        )}
      </Modal>
    );
  }
}

export default ModalPropone;
