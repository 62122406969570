import {Component} from 'react';
import { setVars } from '../../GlobalStore';

export default class Logout extends Component {
    
    componentDidMount(){
        localStorage.removeItem("token");
        setVars('Token', {});
        this.props.history.push('/gto/');
    }

    render(){
        return null;
    }
}