import React from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Table,
  Row,
  Col
} from "reactstrap";
import PerfectScrollbar from "perfect-scrollbar";
import NotificationAlert from "react-notification-alert";
import Select from "react-select";
import ReactTable from "react-table";
import matchSorter from "match-sorter";
import moment from "moment";
import { PushSpinner } from "react-spinners-kit";
import SweetAlert from "react-bootstrap-sweetalert";
import EjecutarApi from "../../data/Consultas";
import Notify from "../../data/Notificacion";
import DashboardNavbar from "../../components/Navbars/DashboardNavbar.jsx";
import Footer from "../../components/Footer/Footer.jsx";
import Sidebar from "../../components/Sidebar/MenuSidebar.jsx";
import Pagination from "../components/Paginacion";

import ReLogin from "./ReLogin";

import UploadFile from "../components/Dropzone/index";
import { getVars,URL_PUBLICA } from "../../GlobalStore";

var global = "";

var ps;

class CargarPlantilla extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "black",
      activeColor: "info",
      sidebarMini: false,
      showReload: false,
      loading: false,
      Archivos: [],
      Movimiento: "",
      tipo: {
        value: "",
        label: ""
      },
      Plantillatipo:[{value: "",
      label: ""}]
    };
  }

  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(this.refs.mainPanel);
    }

    //getTipoPlantilla
    const token = global.access_token;
		console.log('getPlantillas...........')
		EjecutarApi({}, 'getTipoPlantilla', token)
			.then(function(res) {
				return res;
			})
			.then(items => {
				console.log('===> Data getTipoPlantilla: ', items);

				if (items.results) {
					this.setState({
						loadingOcupadas: false,
						Plantillatipo: items.data.map(item => {
							return { label: item.label, value: item.value };
						}),
					});
				}
			})
			.catch(e => {
				console.log('error getTipoPlantilla: ', e.message);
				Notify(3, 'tc', `Error al cargar getTipoPlantilla: ${e.message}`, this.refs);
				this.setState({ loadingOcupadas: false });
			});
    // const data = {
    //   page: 0,
    //   pageSize: 10,
    //   sorted: [],
    //   tipo: 'and',
    //   filtered: [],
    // };

    // console.log('=>Cargar Plantilla: ', data)
    // EjecutarApi(data, 'getPlantillaArchivos', getVars('Token').access_token)
    //   .then(function(res) {
    //     return res;
    //   })
    //   .then(res => {
    //     console.log('getPlantillaArchivos: ', res);

    //   })
    //   .catch(e => {
    //     console.log('=Error[getPlantillaArchivos]: ', e);
    //     Notify(3, 'tc', `Error en getPlantillaArchivos: ${e.error}`, this.refs);
    //     this.setState({ loading: false });
    //   });
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
    
  }
  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }

  toggleDependencia = () => {
    this.setState(prevState => ({
      modalDependencia: !prevState.modalDependencia
    }));
  };

  handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      this.setState({ sidebarMini: false });
    } else {
      this.setState({ sidebarMini: true });
    }
    document.body.classList.toggle("sidebar-mini");
  };

  setQuincena = e => {
    this.setState({ Quincena: moment(e._d).format("YYYY-MM-DD") });
  };

  onFilesAdded = files => {
    console.log("==>Archivos: ", files);
    this.setState({ files });
  };

  sendFile = () => {

    console.log('=>This stats: ', this.state)
    if (!this.state.files) {
      Notify(3, "tc", "Error: Debe adjuntar archivo", this.refs);
      return;
    }
    if (this.state.Movimiento.length < 8) {
      Notify(3, "tc", "Error: Debe seleccionar la quincena", this.refs);
      return;
    }

    if (this.state.tipo.value.length < 1) {
      Notify(3, "tc", "Error: Debe seleccionar tipo plantilla", this.refs);
      return;
    }

    var formData = new FormData();
    formData.append("Quincena", this.state.Movimiento);
    formData.append("Archivo", this.state.files[0], this.state.files[0].name);
    formData.append("tipo", this.state.tipo.label);

    for (var pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }
    //return true;

    const token = global.access_token;

    const requestInfo = {
      method: "POST",
      body: formData,
      headers: new Headers({
        Authorization: `Bearer ${token}`
      })
    };

    console.log("==>Invocar API");

    this.setState({ loading: true });

    fetch(URL_PUBLICA+"subirExcel", requestInfo)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Post");
      })
      .then(Results => {
        console.log("==>Results SubirExcel: ", Results);
        if (Results.results) {
          Notify(2, "tc", "Se cargo el archivo correctamente!", this.refs);
        } else {
          Notify(3, "tc", `Error: ${Results.message}`, this.refs);
        }

        this.setState({ loading: false });

        return;
      })
      .catch(e => {
        console.log("=>Error: ", e.message);
        Notify(3, "tc", `Error al cargar el archivo: ${e.message}`, this.refs);
        this.setState({ loading: false });
      });
  };

  bytesToSize = bytes => {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes == 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  };

  downloadFile = item => {
    console.log("=>Descargar: ", item);
    const token = global.access_token; //localstorage

    window.location.href = URL_PUBLICA+`getPlantillaArchivoExcel?id=${item.id}&token=${token}`;
  };

  closeReload = () => {
    this.setState({ showReload: false });
  };

  render() {
    const { loading, Archivos, showReload } = this.state;

    console.log("=>Estados: ", this.state);

    global = getVars("Token");

    return (
      <div>
        {!showReload && (
          <div className="wrapper">
            <Sidebar
              {...this.props}
              bgColor={this.state.backgroundColor}
              activeColor={this.state.activeColor}
            />
            <div className="main-panel" ref="mainPanel">
              <DashboardNavbar
                {...this.props}
                handleMiniClick={this.handleMiniClick}
              />
              <NotificationAlert ref="notificationAlert" />
              <div className="content">
                <Row>
                  <Col md="12">
                    <Card>
                      <CardBody>
                        <Row>
                          <Col sm="3">
                            <UploadFile
                              onFilesAdded={this.onFilesAdded}
                              Accept="xlsx"
                              title="Cargar excel"
                            />
                          </Col>

                          <Col sm="3">
                            <FormGroup>
                              <Label className="text-left">
                                Fecha Quincena
                              </Label>
                              <input
                                type="date"
                                name="Movimiento"
                                id="Movimiento"
                                value={this.state.Movimiento}
                                onChange={e =>
                                  this.setState({ Movimiento: e.target.value })
                                }
                                placeholder="date placeholder"
                                className="form-control"
                              />
                            </FormGroup>
                          </Col>
                          <Col sm="3">
                            <FormGroup>
                              <Label className="text-left">Tipo</Label>
                              <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                name="tipo"
                                value={this.state.tipo}
                                onChange={value =>
                                  this.setState({ tipo: value })
                                }
                                options={this.state.Plantillatipo}
                                placeholder="Tipo Plantilla"
                              />
                            </FormGroup>
                          </Col>
                          <Col sm="3">
                            <FormGroup>
                              <br />
                              {loading && (
                                <PushSpinner
                                  size={30}
                                  color="#686769"
                                  loading={loading}
                                />
                              )}
                              {!loading && (
                                <Button
                                  className="btn-round mb-3"
                                  color="warning"
                                  onClick={this.sendFile}
                                >
                                  <span className="btn-label">
                                    <i className="nc-icon nc-zoom-split" />
                                  </span>
                                  Subir archivo
                                </Button>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Card>
                      <CardHeader>
                        <CardTitle tag="h4">Quincenas subidas</CardTitle>
                      </CardHeader>
                      <CardBody>
                        <ReactTable
                          data={Archivos}
                          showPagination={true}
                          showPageJump={false}
                          previousText="Anterior"
                          nextText="Siguiente"
                          canNextFromData={true}
                          defaultPageSize={10}
                          total={this.state.total}
                          pages={Math.floor(this.state.total / 10)}
                          columns={[
                            {
                              Header: "Quincena",
                              minWidth: 60,
                              id: "Quincena",
                              accessor: d => d.Quincena,
                              filterMethod: (filter, rows) =>
                                matchSorter(rows, filter.value, {
                                  keys: ["Quincena"]
                                }),
                              filterAll: true
                            },
                            {
                              Header: "Nombre",
                              minWidth: 120,
                              id: "Nombre",
                              accessor: d => d.btnDescarga,
                              filterAll: true
                            },
                            {
                              Header: "Peso",
                              id: "Peso",
                              accessor: d => d.Peso,
                              filterMethod: (filter, rows) =>
                                matchSorter(rows, filter.value, {
                                  keys: ["Plaza"]
                                }),
                              filterAll: true
                            },
                            {
                              Header: "Tipo",
                              minWidth: 50,
                              id: "Tipo",
                              accessor: d => d.Tipo,
                              filterMethod: (filter, rows) =>
                                matchSorter(rows, filter.value, {
                                  keys: ["Tipo"]
                                }),
                              filterAll: true
                            },
                            {
                              Header: "Registros",
                              minWidth: 60,
                              id: "Registros",
                              accessor: d =>
                                new Intl.NumberFormat().format(d.Registros),
                              filterMethod: (filter, rows) =>
                                matchSorter(rows, filter.value, {
                                  keys: ["Registros"]
                                }),
                              filterAll: true
                            },
                            {
                              Header: "Fecha",
                              id: "FechaUpload",
                              accessor: d => d.FechaUpload,
                              filterMethod: (filter, rows) =>
                                matchSorter(rows, filter.value, {
                                  keys: ["FechaUpload"]
                                }),
                              filterAll: true
                            },
                            {
                              Header: "User",
                              id: "UserUpload",
                              accessor: d => d.UserUpload,
                              filterMethod: (filter, rows) =>
                                matchSorter(rows, filter.value, {
                                  keys: ["UserUpload"]
                                }),
                              filterAll: true
                            }
                          ]}
                          manual
                          loading={loading}
                          PaginationComponent={Pagination}
                          onFetchData={(stateVoBo, instance) => {
                            const token = global.access_token;

                            const data = {
                              page: stateVoBo.page,
                              tipo: "and",
                              pageSize: stateVoBo.pageSize,
                              sorted: stateVoBo.sorted,
                              filtered: this.state.Filtered
                            };

                            console.log("==>Filtered PropuestasVIP: ", data);

                            this.setState({ loading: true });

                            EjecutarApi(data, "getPlantillaArchivos", token)
                              .then(function(res) {
                                return res;
                              })
                              .then(items => {
                                console.log(
                                  "===> Data getPlantillaArchivos: ",
                                  items
                                );

                                if (items.results) {
                                  const dataTable = items.data;
                                  this.setState({
                                    loading: false,
                                    total: items.total,
                                    Archivos: dataTable.map((prop, key) => {
                                      return {
                                        id: prop.id,
                                        key: key,
                                        btnDescarga: (
                                          <Button
                                            className="btn-link"
                                            color="primary"
                                            onClick={() =>
                                              this.downloadFile(prop)
                                            }
                                          >
                                            {prop.Nombre}
                                          </Button>
                                        ),
                                        Quincena: prop.Quincena,
                                        Nombre: prop.Nombre,
                                        Peso: this.bytesToSize(prop.Peso),
                                        Tipo: prop.Tipo,
                                        Registros: prop.Registros,
                                        FechaUpload: prop.FechaUpload,
                                        UserUpload: prop.UserUpload
                                      };
                                    })
                                  });
                                }
                              })
                              .catch(e => {
                                console.log(
                                  "error getPlantillaArchivos: ",
                                  e.message
                                );
                                this.setState({ loading: false });

                                if (e.message.indexOf("Unauthorized")) {
                                  console.log("=>Sesion expirada: ");

                                  this.setState({ showReload: true });
                                }
                              });
                          }}
                          /*
                    You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                  */
                          className="-striped -highlight primary-pagination"
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
              <Footer fluid />
            </div>
          </div>
        )}
        {showReload && (
          <ReLogin close={this.closeReload} message="Sesión expiró!" />
        )}
      </div>
    );
  }
}

export default CargarPlantilla;
