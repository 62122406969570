import React from 'react';
import { Button, Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import NotificationAlert from 'react-notification-alert';
import ReactTable from 'react-table';
import matchSorter from 'match-sorter';

import EjecutarApi from '../../data/Consultas';
import Notify from '../../data/Notificacion';

import DashboardNavbar from '../../components/Navbars/DashboardNavbar.jsx';
import Footer from '../../components/Footer/Footer.jsx';
import Sidebar from '../../components/Sidebar/MenuSidebar.jsx';

import Pagination from '../components/Paginacion';

import Movimiento from '../components/Movimiento';
import { getVars , URL_PUBLICA} from '../../GlobalStore';

var global = '';
class VoBo extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			backgroundColor: 'black',
			activeColor: 'info',
			sidebarMini: false,
			firstTime: true,
			showData: true,
			loading: false,
			data: {},
			dataRS: [],
			dataRsPlus: [],
		};
	}

	componentDidMount() {
		console.log('==> Inicia VoBo');

		this.setState({ loading: false });
	}

	setValidate = obj => {
		this.setState({ data: obj, showData: false });
	};

	setBack = () => {
		this.setState({ showData: true });
	};

	btnAuthorizar = item => {
		console.log('=>Autorizar: ', item);

		const token = global.access_token; //localstorage

		const formData = new FormData();
		formData.append('idMovimientoStatus', 2);
		formData.append('id', item.id);

		fetch(URL_PUBLICA+`validarMovimiento`, {
			method: 'POST',
			headers: new Headers({ Authorization: `Bearer ${token}` }),
			body: formData,
		})
			.then(response => {
				switch (response.status) {
					case 200:
						console.log('==>Error Status: ', response.status);
						break;

					default:
						console.log('==>Unknow Status: ', response.status);
						/*
                this.props.history.push({
                    pathname: '/logout',
                    state: {token},
                    message: 'Su cuenta ya expiro'
                });
                */
						return;
				}

				if (response.ok) {
					return response.json();
				}
				throw new Error('Ocurrio un error: obtenerDatosCandidatos: ', response.statusText);
			})
			.then(items => {
				console.log('===> Data Catalogos: ', items);
				this.setState({ showData: true });

				var data = this.state.dataRsPlus;
				data.find((o, i) => {
					console.log(`==> ${o.id} === ${item.key}`);
					if (o.id === item.id) {
						// here you should add some custom code so you can delete the data
						// from this component and from your server as well
						data.splice(i, 1);
						console.log('=>[data]<= ', data);
						return true;
					}
					return false;
				});

				Notify(2, 'tc', 'Autorizado!', this.refs);

				this.setState({ dataRsPlus: data });
			})
			.catch(e => {
				console.log('error: ', e.message);
				this.setState({ showData: true });
				Notify(2, 'tc', `Error: ${e.message}`, this.refs);
			});
	};

	btnNoAuthorizar = item => {
		console.log('=>NoAuthorizar: ', item);
		this.setState({ showData: true });

		const token = global.access_token; //localstorage

		const formData = new FormData();
		formData.append('idMovimientoStatus', 5);
		formData.append('id', item.id);

		fetch(URL_PUBLICA+`validarMovimiento`, {
			method: 'POST',
			headers: new Headers({ Authorization: `Bearer ${token}` }),
			body: formData,
		})
			.then(response => {
				switch (response.status) {
					case 200:
						console.log('==>Error Status: ', response.status);
						break;

					default:
						console.log('==>Unknow Status: ', response.status);
						/*
                this.props.history.push({
                    pathname: '/logout',
                    state: {token},
                    message: 'Su cuenta ya expiro'
                });
                */
						return;
				}

				if (response.ok) {
					return response.json();
				}
				throw new Error('Ocurrio un error: obtenerDatosCandidatos: ', response.statusText);
			})
			.then(items => {
				console.log('===> Data Catalogos: ', items);
				this.setState({ showData: true });

				var data = this.state.dataRsPlus;
				data.find((o, i) => {
					console.log(`==> ${o.id} === ${item.key}`);
					if (o.id === item.id) {
						// here you should add some custom code so you can delete the data
						// from this component and from your server as well
						data.splice(i, 1);
						console.log('=>[data]<= ', data);
						return true;
					}
					return false;
				});

				Notify(2, 'tc', 'El movimiento fue rechazado', this.refs);

				this.setState({ dataRsPlus: data });
			})
			.catch(e => {
				console.log('error: ', e.message);
				this.setState({ showData: true });
				Notify(2, 'tc', `Error: ${e.message}`, this.refs);
			});
	};

	render() {
		global = getVars('Token');
		console.log('=>Mis Estados: ', this.state);
		const customProps3 = { id: 'tb3' };

		console.log('==>this.state.dataRsPlus', this.state.dataRsPlus);

		const { data } = this.state;
		return (
			<div className="wrapper">
				<Sidebar {...this.props} bgColor={this.state.backgroundColor} activeColor={this.state.activeColor} />
				<div className="main-panel" ref="mainPanel">
					<DashboardNavbar {...this.props} handleMiniClick={this.handleMiniClick} />
					<NotificationAlert ref="notificationAlert" />
					<div className="content">
						{!this.state.showData && (
							<Movimiento
								showButtons={true}
								setBack={this.setBack}
								data={data}
								autorizar={this.btnAuthorizar}
								noautorizar={this.btnNoAuthorizar}
							/>
						)}
						{this.state.showData && (
							<Card>
								<CardHeader>
									<CardTitle tag="h4">Autorizar Movimientos</CardTitle>
								</CardHeader>
								<CardBody>
									<ReactTable
										data={this.state.dataRsPlus}
										filterable
										columns={[
											{
												Header: 'Fecha Mov',
												minWidth: 80,
												id: 'fecha_movimiento',
												accessor: d => d.FechaMovimiento,
												filterMethod: (filter, rows) =>
													matchSorter(rows, filter.value, { keys: ['FechaMovimiento'] }),
												filterAll: true,
											},
											{
												Header: 'Tipo',
												minWidth: 60,
												id: 'idMovimiento',
												accessor: d => d.Movimiento,
												filterAll: true,
												Filter: ({ filter, onChange }) => {
													return (
														<select
															onChange={event => onChange(event.target.value)}
															style={{ width: '100%' }}
															value={filter ? filter.value : 'all'}
														>
															<option value="all">Todos</option>
															<option value="1">Consideración</option>
															<option value="2">Ingreso</option>
															<option value="3">Promoción</option>
															<option value="4">Baja</option>
														</select>
													);
												},
											},
											{
												Header: 'Candidato',
												minWidth: 150,
												id: 'candidato',
												accessor: d => d.btnCandidato,
												filterMethod: (filter, rows) =>
													matchSorter(rows, filter.value, { keys: ['btnCandidato'] }),
												filterAll: true,
											},
											{
												Header: 'Dependencia',
												id: 'dependencia',
												accessor: d => d.Dependencia,
												filterMethod: (filter, rows) =>
													matchSorter(rows, filter.value, { keys: ['Dependencia'] }),
												filterAll: true,
											},
											{
												Header: 'Propone',
												id: 'propone',
												accessor: d => d.QuienPropone,
												filterMethod: (filter, rows) =>
													matchSorter(rows, filter.value, { keys: ['QuienPropone'] }),
												filterAll: true,
											},

											{
												Header: 'Plaza',
												id: 'plaza',
												accessor: d => d.Plaza,
												filterMethod: (filter, rows) =>
													matchSorter(rows, filter.value, { keys: ['Plaza'] }),
												filterAll: true,
											},
											{
												Header: 'Nivel',
												minWidth: 50,
												id: 'nivel',
												accessor: d => d.Nivel,
												filterMethod: (filter, rows) =>
													matchSorter(rows, filter.value, { keys: ['Nivel'] }),
												filterAll: true,
											},
										]}
										loading={this.state.loading}
										defaultPageSize={10}
										showPagination={true}
										showPaginationTop={false}
										showPaginationBottom={true}
										pageSizeOptions={[5, 10, 20, 25, 50, 100]}
										manual
										canNextFromData={true}
										showTotalPages={true}
										PaginationComponent={Pagination}
										previousText="Anterior"
										nextText="Siguiente"
										ofText="de"
										getProps={() => customProps3}
										onFetchData={(stateVoBo, instance) => {
											const token = global.access_token;

											const Filtered = this.state.firstTime ? [] : stateVoBo.filtered;
											this.setState({ loading: true, firstTime: false });

											var Encontrado = false;
											for (let x = 0; x < Filtered.length; x++) {
												if (Filtered[x].id === 'idMovimientoStatus') Encontrado = true;
											}

											if (!Encontrado) {
												console.log('==>Filtered No Encontrado: ', Filtered);
												Filtered.push({
													id: 'idMovimientoStatus',
													value: 1,
												});
											}

											Encontrado = false;
											for (let x = 0; x < Filtered.length; x++) {
												if (Filtered[x].id === 'idMovimiento') Encontrado = true;
											}

											if (!Encontrado) {
												console.log('==>Filtered idMovimiento: ', Filtered);
												Filtered.push({
													id: 'isJefatura',
													value: 1,
												});
											}
											const data = {
												page: stateVoBo.page,
												tipo: 'and',
												pageSize: stateVoBo.pageSize,
												sorted: stateVoBo.sorted,
												filtered: Filtered,
											};

											console.log('==>Filtered: ', data);

											EjecutarApi(data, 'getPlazaMovimientos', token)
												.then(function(res) {
													return res;
												})
												.then(items => {
													console.log('===> Data Catalogos: ', items);

													if (items.results) {
														const dataTable = items.data;

														this.setState({
															loading: false,
															dataRsPlus: dataTable.map((prop, key) => {
																return {
																	id: prop.id,
																	key: key,
																	FechaMovimiento: prop.FechaMovimiento || '',
																	PuestoQuienPropone: prop.Propone.Cargo || '',
																	Movimiento: prop.movimiento.label,
																	QuienPropone:
																		`${prop.Propone.Nombre} ${prop.Propone.Paterno}, ${prop.Propone.Cargo}` ||
																		'',
																	Candidato: `${prop.candidato.Nombre} ${prop.candidato.Paterno} ${prop.candidato.Materno}`,

																	dataCandidato: prop,
																	btnCandidato: (
																		<Button
																			className="btn-link"
																			color="primary"
																			onClick={() => {
																				let obj = this.state.dataRsPlus.find(
																					o => o.key === key
																				);
																				this.setValidate(obj);
																			}}
																		>
																			{prop.movimiento.value !== 4
																				? `${prop.candidato.Nombre} ${prop.candidato.Paterno} ${prop.candidato.Materno}`
																				: `${prop.candidato.NombreCompleto}`}
																		</Button>
																	),
																	Plaza: prop.plaza.Puesto || '',
																	Municipio: prop.plaza.Municipio,
																	Nivel: prop.plaza.Nivel || '',
																	Dependencia: prop.plaza.Dependencia || '',
																	idMovimientoStatus:
																		prop.Movimiento_status.label || '',
																};
															}),
														});
													}
												})
												.catch(e => {
													console.log('error VoBo: ', e.message);
													this.props.history.push({
														pathname: '/logout',
														state: { token },
														message: e.message,
													});
												});
										}}
										/*
                                      You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                                    */
										className="-striped -highlight primary-pagination"
									/>
								</CardBody>
							</Card>
						)}
					</div>
					<Footer fluid />
				</div>
			</div>
		);
	}
}

export default VoBo;
