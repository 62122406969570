import React from "react";
import { NavLink } from "react-router-dom";
import { Nav, Collapse } from "reactstrap";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { getVars } from "../../GlobalStore";

var ps;
var Menus = [];
var User = { Foto: "", Nombre: "", Paterno: "" };

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getCollapseStates(Menus);
  }
  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse === 1) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (
        routes[i].collapse === 1 &&
        this.getCollapseInitialState(routes[i].views)
      ) {
        return true;
      } else if (window.location.pathname.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }
  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (prop.collapse === 1) {
        var st = {};
        st[prop["state"]] = !this.state[prop.state];

        return (
          <li
            className={this.getCollapseInitialState(prop.views) ? "active" : ""}
            key={key}
          >
            <a
              href="#gto"
              data-toggle="collapse"
              aria-expanded={this.state[prop.state]}
              onClick={(e) => {
                e.preventDefault();
                this.setState(st);
              }}
            >
              {prop.icon !== "undefined" ? (
                <>
                  <i className={prop.icon} />
                  <p>
                    {prop.name}
                    <b className="caret" />
                  </p>
                </>
              ) : (
                <>
                  <span className="sidebar-mini-icon">{prop.mini}</span>
                  <span className="sidebar-normal">
                    {prop.name}
                    <b className="caret" />
                  </span>
                </>
              )}
            </a>
            <Collapse isOpen={this.state[prop.state]}>
              <ul className="nav">{this.createLinks(prop.views)}</ul>
            </Collapse>
          </li>
        );
      }
      return (
        <li
          className={this.activeRoute("/gto" + prop.layout + prop.path)}
          key={key}
        >
          <NavLink
            to={{
              pathname: "/gto" + prop.layout + prop.path,
            }}
            activeClassName=""
          >
            {prop.icon !== "undefined" ? (
              <>
                <i className={prop.icon} />
                <p>{prop.name}</p>
              </>
            ) : (
              <>
                <span className="sidebar-mini-icon">{prop.mini}</span>
                <span className="sidebar-normal">{prop.name}</span>
              </>
            )}
          </NavLink>
        </li>
      );
    });
  };
  // verifies if routeName is the one active (in browser input)
  activeRoute = (routeName) => {
    return this.props.location.pathname === routeName ? "active" : "";
  };
  componentDidMount() {
    // if you are using a Windows Machine, the scrollbars will have a Mac look
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.sidebar, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }

    //console.log("=>mvar", mVar.menus);
  }
  componentWillUnmount() {
    // we need to destroy the false scrollbar when we navigate
    // to a page that doesn't have this component rendered
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }

  render() {
    const mVar = getVars("Token");
    Menus = mVar.menus;
    User = mVar.user;
    return (
      <div
        className="sidebar"
        data-color={this.props.bgColor}
        data-active-color={this.props.activeColor}
      >
        <div className="sidebar-wrapper" ref="sidebar">
          <div className="user">
            <div className="info">
              <a
                href="#gto"
                data-toggle="collapse"
                aria-expanded={this.state.openAvatar}
                onClick={() =>
                  this.setState({ openAvatar: !this.state.openAvatar })
                }
              >
                <span>
                  {`${User.Nombre} ${User.Paterno}`}
                  <b className="caret" />
                </span>
              </a>
              <Collapse isOpen={this.state.openAvatar}>
                <ul className="nav">
                  <li>
                    <NavLink to="/gto/admin/perfil" activeClassName="">
                      <span className="sidebar-mini-icon">MP</span>
                      <span className="sidebar-normal">Mi Perfil</span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/gto/logout" activeClassName="">
                      <span className="sidebar-mini-icon">S</span>
                      <span className="sidebar-normal">Terminar Sesión</span>
                    </NavLink>
                  </li>
                </ul>
              </Collapse>
            </div>
          </div>
          <Nav>
            {/* <li>
							<NavLink to="/gto/admin/dashboard" activeClassName="">
								<i className="fa fa-home" />
								<p>Dashboard</p>
							</NavLink>
						</li> */}
            {/* <li>
							<NavLink to="/gto/admin/dashboardv2" activeClassName="">
								<i className="fa fa-home" />
								<p>Dashboard v2</p>
							</NavLink> 
						</li> */}
            {this.createLinks(Menus)}
          </Nav>
        </div>
      </div>
    );
  }
}

export default Sidebar;
