import React from 'react';
import { Button, Card, Label, FormGroup, Input, Row, Col } from 'reactstrap';
import Select from 'react-select';
import PerfectScrollbar from 'perfect-scrollbar';
import NotificationAlert from 'react-notification-alert';
import ReactTable from 'react-table';
import { CircleSpinner } from "react-spinners-kit";

import EjecutarApi from '../../data/Consultas';
import Notify from '../../data/Notificacion';

import DashboardNavbar from '../../components/Navbars/DashboardNavbar.jsx';
import Footer from '../../components/Footer/Footer.jsx';
import Sidebar from '../../components/Sidebar/MenuSidebar.jsx';

import Pagination from '../components/Paginacion/index';
import OficioIndividual from '../components/OficioIndividual/index';
import { getVars , URL_PUBLICA} from '../../GlobalStore';

var global = '';
var ps;

class PlantillaScreen extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			backgroundColor: 'black',
			activeColor: 'info',
			
			multiValueEstatus: [],
			multiValueDependencia: [],
			multiValueNivel: [],
			multiValueMunicipio: [],
			multiValueClasificacion: [],
			multiValueTipo: [],
			menu: {
				id: '',
				Seguimiento: 0,
			},
			Filtered: [],
			filterTipo: [
				{ label: 'Confianza', value: 'Confianza' },
				{ label: 'HONORARIOS', value: 'HONORARIOS' },
				{ label: 'PLAZA DEFINITIVA', value: 'PLAZA DEFINITIVA' },
				{ label: 'NULL', value: 'NULL' },
			],
			filterClasificacion: [
				{ label: 'Exceptuado del SCC', value: 'Exceptuado del SCC' },
				{ label: 'Libre designación', value: 'Libre designación' },
				{ label: 'Sujeto a SCC', value: 'Sujeto a SCC' },
				{ label: 'NULL', value: 'NULL' },
			],
			filterEstatus: [
				{ value: 'OCUPADA', label: 'OCUPADA' },
				{ value: 'VACANTE', label: 'VACANTE' },
				{ value: 'VACANTE POR LICENCIA', label: 'VACANTE POR LICENCIA' },
				{ value: 'NULL', label: 'NULL' },
			],
			filterNivel: [
				{ value: '1', label: 'Nivel 1' },
				{ value: '2', label: 'Nivel 2' },
				{ value: '3', label: 'Nivel 3' },
				{ value: '4', label: 'Nivel 4' },
				{ value: '5', label: 'Nivel 5' },
				{ value: '6', label: 'Nivel 6' },
				{ value: '7', label: 'Nivel 7' },
				{ value: '8', label: 'Nivel 8' },
				{ value: '9', label: 'Nivel 9' },
				{ value: '10', label: 'Nivel 10' },
				{ value: '11', label: 'Nivel 11' },
				{ value: '12', label: 'Nivel 12' },
				{ value: '13', label: 'Nivel 13' },
				{ value: '14', label: 'Nivel 14' },
				{ value: '15', label: 'Nivel 15' },
				{ value: '16', label: 'Nivel 16' },
				{ value: '17', label: 'Nivel 17' },
				{ value: '18', label: 'Nivel 18' },
				{ value: '19', label: 'Nivel 19' },
				{ value: '20', label: 'Nivel 20' },
				{ value: '21', label: 'Nivel 21' },
			],
			Nombre: '',
			Area: '',
			Funcional: '',
			Secuencial: '',
			select2: undefined,
			sidebarMini: false,
			loadingPlazas: false,
			loadingOcupadas: false,
			visible: true,
			modalPlaza: false,
			modalCandidato: false,
			modalPropone: false,
			modalNotice: false,
			FolioDependencia: '',
			isRecomendado: false,
			showAsignacion: false,
			plazas: [],
			catMunicipios: [],
			catDependencias: [],
			totalOcupadas: 0,
			firstOcupada: true,
			plazaData: {
				id: '',
			},
		};
	}

	componentDidMount() {
		if (navigator.platform.indexOf('Win') > -1) {
			document.documentElement.className += ' perfect-scrollbar-on';
			document.documentElement.classList.remove('perfect-scrollbar-off');
			ps = new PerfectScrollbar(this.refs.mainPanel);
		}

		console.log('=>Props Menus', this.props);
		global.menus.forEach(item => {
			if (item.idMenu === 4) {
				const Filtro = dato => '/gto' + dato.layout + dato.path === this.props.match.path;
				const menu = item.views.filter(Filtro);
				console.log('=>Menu: ', menu);
				this.setState({ menu: menu.length ? menu[0] : menu });
			}
		});

		const token = global.access_token; //localstorage

		fetch(URL_PUBLICA+'obtenerDatosCandidatos', {
			headers: new Headers({ Authorization: `Bearer ${token}` }),
		})
			.then(response => {
				switch (response.status) {
					case 200:
						console.log('==>Error Status: ', response.status);
						break;

					default:
						if (navigator.platform.indexOf('Win') > -1) {
							ps.destroy();
							document.documentElement.className += ' perfect-scrollbar-off';
							document.documentElement.classList.remove('perfect-scrollbar-on');
						}
						console.log('==>Unknow Status: ', response.status);
						this.props.history.push({
							pathname: '/logout',
							state: { token },
							message: 'Su cuenta ya expiro',
						});
						break;
				}

				if (response.ok) {
					return response.json();
				}
				throw new Error('Ocurrio un error: obtenerDatosCandidatos: ', response.statusText);
			})
			.then(items => {
				console.log('===> Data Catalogos: ', items);

				this.setState({
					catalogos: {
						EstadoCivil: items.EstadoCivil,
						dependencias: items.dependencias,
						municipios: items.municipios,
						nivel: items.nivel,
					},
				});
			})
			.catch(e => {
				console.log('error: ', e.message);
				this.props.history.push({
					pathname: '/logout',
					state: { token },
					message: e.message,
				});
			});

		const data = {
			page: 0,
			tipo: 'and',
			pageSize: 9999,
			sorted: [],
			filtered: [],
		};

		console.log('=>Búscar getMunicipiosPlantilla: ', data);

		EjecutarApi(data, 'getMunicipiosPlantilla', token)
			.then(function(res) {
				return res;
			})
			.then(items => {
				console.log('===> Data getMunicipiosPlantilla: ', items);

				if (items.results) {
					this.setState({
						loadingOcupadas: false,
						catMunicipios: items.data.map(item => {
							return { label: item.Municipio, value: item.Municipio };
						}),
					});
				}
			})
			.catch(e => {
				console.log('error getMunicipiosPlantilla: ', e.message);
				Notify(3, 'tc', `Error al cargar MunicipiosPlantilla: ${e.message}`, this.refs);
				this.setState({ loadingOcupadas: false });
			});

		console.log('=>Búscar getDependenciasPlantilla: ', data);

		EjecutarApi(data, 'getDependenciasPlantilla', token)
			.then(function(res) {
				return res;
			})
			.then(items => {
				console.log('===> Data getDependenciasPlantilla: ', items);

				console.log(URL_PUBLICA+`getOficioIndividualExcel?token:${token}&uid=3`);

				if (items.results) {
					this.setState({
						loadingOcupadas: false,
						catDependencias: items.data.map(item => {
							return { label: item.DEPENDENCIA_ORGANISMO, value: item.DEPENDENCIA_ORGANISMO };
						}),
					});
				}
			})
			.catch(e => {
				console.log('error getDependenciasPlantilla: ', e.message);
				Notify(3, 'tc', `Error al cargar DependenciasPlantilla: ${e.message}`, this.refs);
				this.setState({ loadingOcupadas: false });
			});
	}

	componentDidUpdate(e) {
		if (e.history.action === 'PUSH') {
			document.documentElement.scrollTop = 0;
			document.scrollingElement.scrollTop = 0;
			this.refs.mainPanel.scrollTop = 0;
		}
	}

	setAsignar = data => {
		console.log('Asignar: ', data);
		this.setState({ plazaData: data, showAsignacion: true });
	};

	setBack = () => {
		this.setState({ showAsignacion: false });
	};

	handleMiniClick = () => {
		if (document.body.classList.contains('sidebar-mini')) {
			this.setState({ sidebarMini: false });
		} else {
			this.setState({ sidebarMini: true });
		}
		document.body.classList.toggle('sidebar-mini');
	};

	handleMultiDependencia = option => {
		this.setState(state => {
			return {
				multiValueDependencia: option,
			};
		});
	};
	handleMultiMunicipio = option => {
		this.setState(state => {
			return {
				multiValueMunicipio: option,
			};
		});
	};
	handleMultiEstatus = option => {
		this.setState(state => {
			return {
				multiValueEstatus: option,
			};
		});
	};
	handleMultiClas = option => {
		this.setState(state => {
			return {
				multiValueClasificacion: option,
			};
		});
	};
	handleMultiTipo = option => {
		this.setState(state => {
			return {
				multiValueTipo: option,
			};
		});
	};
	handleMultiNivel = option => {
		this.setState(state => {
			return {
				multiValueNivel: option,
			};
		});
	};
	handleMultiChange = option => {
		this.setState(state => {
			return {
				multiValueEstatus: option,
			};
		});
	};
	exportar = () => {
		this.setState({ isLoading: true});
		
		const token = getVars('Token').access_token;  //localstorage
	
		 var url = new URL(URL_PUBLICA+`getReportePlantillaExcel?token=${token}`);
	
		 if(this.state.Filtered.length)
		 {
		   this.state.Filtered.map(item => {
			 url.searchParams.append(`${item.id}[]`, item.value);
		   })
		   
		 }
	
		console.log('=>url: ', url);
	
		window.open(url, '_blank');
	
		this.setState({ isLoading: false});
	  }
	btnFiltrar = () => {
		/*var _state ={
			Nombre:this.state.Nombre,
			Secuencial:this.state.Secuencial,
			Area:this.state.Area,
			Funcional:this.state.Funcional,
			multiValueEstatus:this.multiValueEstatus,//?this.multiValueEstatus:0,
			multiValueDependencia:this.multiValueDependencia?this.multiValueDependencia:0,
			multiValueNivel:this.multiValueNivel?this.multiValueNivel:0,
			multiValueMunicipio:this.multiValueMunicipio?this.multiValueMunicipio:0,
			multiValueClasificacion:this.multiValueClasificacion?this.multiValueClasificacion:0,
			multiValueTipo:this.multiValueTipo?this.multiValueTipo:0,
		}; //= this.state;*/
		console.log('=>Mis Filtros state: ', this.state);
		console.log('=>Mis Filtros _state: ', this._state);
		const myFiltro = [];

		if (this.state.Nombre.length > 1) myFiltro.push({ id: 'Nombre', value: this.state.Nombre });
		if (this.state.Secuencial.length > 1) myFiltro.push({ id: 'Plaza', value: this.state.Secuencial });
		if (this.state.Area.length > 1) myFiltro.push({ id: 'Area', value: this.state.Area });
		if (this.state.Funcional.length > 1) myFiltro.push({ id: 'Denominacion_funcional', value: this.state.Funcional });

		var x = 0;
		if(this.state.multiValueEstatus!==null)
		for (x = 0; x < this.state.multiValueEstatus.length; x++)
		{
			if (this.state.multiValueEstatus[x])
			myFiltro.push({ id: 'Estatus', value: this.state.multiValueEstatus[x] });
		}
		if(this.state.multiValueDependencia!==null)
			for (x = 0; x < this.state.multiValueDependencia.length; x++)
			{
				if (this.state.multiValueDependencia[x])
				myFiltro.push({ id: 'Dependencia_organismo', value: this.state.multiValueDependencia[x] });
			}
		
		
		if (this.state.multiValueNivel!==null)
			for (x = 0; x < this.state.multiValueNivel.length; x++) 
			{
				if (this.state.multiValueNivel[x])
				myFiltro.push({ id: 'Nivel', value: this.state.multiValueNivel[x] });
			}

		if(this.state.multiValueMunicipio!==null)
			for (x = 0; x < this.state.multiValueMunicipio.length; x++)
			{
				if (this.state.multiValueMunicipio[x])
				myFiltro.push({ id: 'Municipio', value: this.state.multiValueMunicipio[x] });
			}
		
		if(this.state.multiValueClasificacion!==null)
			for (x = 0; x < this.state.multiValueClasificacion.length; x++)
			{
				if (this.state.multiValueClasificacion[x])
				myFiltro.push({ id: 'Clasificacion_SCC', value: this.state.multiValueClasificacion[x] });
			}
		if(this.state.multiValueTipo!==null)
			for (x = 0; x < this.state.multiValueTipo.length; x++) 
			{
				if (this.state.multiValueTipo[x])
				myFiltro.push({ id: 'Tipo', value: this.state.multiValueTipo[x] });

			}

		console.log('=>Mis Filtros: ', myFiltro);
		this.setState({ Filtered: myFiltro });

		const token = global.access_token;

		this.setState({ loadingOcupadas: true, firstOcupada: false });

		const data = {
			page: 0,
			tipo: 'and',
			pageSize: 10,
			sorted: [],
			filtered: myFiltro,
		};

		console.log('=>Búscar getPlantillas: ', data);

		EjecutarApi(data, 'getPlantillas', token)
			.then(function(res) {
				return res;
			})
			.then(items => {
				console.log('===> Data getPlantillasX: ', items);

				if (items.results) {
					const dataTable = items.data;

					this.setState({
						loadingOcupadas: false,
						totalOcupadas: items.total,
						plazas: dataTable.map((prop, key) => {
							return {
								id: prop.uid,
								key: key,
								label: prop.Plaza + ' - ' + prop.Denominacion_funcional,
								Dependencia_organismo: prop.Dependencia,
								FechaAlta: prop.FechaAlta,
								Sueldo_mensual_honorarios: prop.Sueldo_mensual_honorarios,
								Nivel: prop.Nivel,
								Tipo: prop.Tipo,
								Nombre: prop.Nombre,
								Area: prop.Area,
								Clasificacion_SCC: prop.Clasificacion_SCC,
								Plaza: prop.Plaza,
								Denominacion_tabular: prop.Denominacion_tabular,
								Denominacion_funcional: prop.Denominacion_funcional,
								Estatus:
									prop.Estatus.toUpperCase() === 'VACANTE' ? (
										<Button
											className="btn-link"
											color="primary"
											onClick={() => {
												let obj = this.state.plazas.find(o => o.key === key);
												console.log('===>key: ', key);
												console.log('===>Obj: ', obj);
												this.setAsignar(obj);
											}}
										>{`${prop.Estatus}`}</Button>
									) : (
										prop.Estatus
									),
								Municipio: prop.Municipio,
								FechaVacante: prop.Fecha_vacante,
								data: prop,
							};
						}),
					});
				}
			})
			.catch(e => {
				console.log('error getPlantillas: ', e.message);
				Notify(3, 'tc', `Error al cargar plazas: ${e.message}`, this.refs);
				this.setState({ loadingOcupadas: false });
			});
	};

	render() {
		console.log('==> Propuestas States: ', this.state);
		const { plazas, totalOcupadas, showAsignacion, plazaData, menu } = this.state;

		global = getVars('Token');

		return (
			<div className="wrapper">
				<Sidebar {...this.props} bgColor={this.state.backgroundColor} activeColor={this.state.activeColor} />
				<div className="main-panel" ref="mainPanel">
					<DashboardNavbar {...this.props} handleMiniClick={this.handleMiniClick} />
					<NotificationAlert ref="notificationAlert" />
					<div className="content">
						<Card>
							<Row>
								<Col md="3">
									<FormGroup>
										<Label className="text-left">Nombre</Label>
										<Input
											placeholder="Nombre"
											id="Nombre"
											value={this.state.Nombre}
											type="text"
											onChange={e => this.setState({ Nombre: e.target.value })}
										/>
									</FormGroup>
								</Col>
								<Col md="3">
									<FormGroup>
										<Label className="text-left">Secuencial</Label>
										<Input
											placeholder="Secuencial"
											id="Secuencial"
											value={this.state.Secuencial}
											type="text"
											onChange={e => this.setState({ Secuencial: e.target.value })}
										/>
									</FormGroup>
								</Col>
								<Col md="3">
									<FormGroup>
										<Label className="text-left">Area</Label>
										<Input
											placeholder="Area"
											id="Area"
											value={this.state.Area}
											type="text"
											onChange={e => this.setState({ Area: e.target.value })}
										/>
									</FormGroup>
								</Col>
								<Col md="3">
									<FormGroup>
										<Label className="text-left">Funcional</Label>
										<Input
											placeholder="Funcional"
											id="Funcional"
											value={this.state.Funcional}
											type="text"
											onChange={e => this.setState({ Funcional: e.target.value })}
										/>
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col md="4">
									<Select
										name="Dependencia"
										placeholder="Dependencia"
										options={this.state.catDependencias}
										isMulti
										onChange={(event,i) => {
											if(event){
												this.setState({multiValueDependencia : (event.map(dato =>  { return dato.value }))})
											}
											else{
												this.setState({multiValueDependencia : this.state.multiValueDependencia.map(dato => { 
													if(i.removedValue.value!==dato){
														if(dato.value) {return dato.value} else{return []}	}	}) 	})
											}
										}} 
									/>
								</Col>
								<Col md="4">
									<Select
										name="Municipio"
										placeholder="Municipio"
										options={this.state.catMunicipios}
										isMulti
										onChange={(event,i) => {
											if(event){
												this.setState({multiValueMunicipio : (event.map(dato =>  { return dato.value }))})
											}
											else{
												this.setState({multiValueMunicipio : this.state.multiValueMunicipio.map(dato => { 
													if(i.removedValue.value!==dato){return dato.value 	}	}) 	})
											}
										}} 
									/>
								</Col>
								<Col md="4">
									<Select
										name="Clasificación"
										placeholder="Clasificación"
										//value={this.state.multiValueClasificacion}
										options={this.state.filterClasificacion}
										//onChange={this.handleMultiClas}
										//isMulti
										isMulti
										onChange={(event,i) => {
											if(event){
												this.setState({multiValueClasificacion : (event.map(dato =>  { return dato.value }))})
											}
											else{
												this.setState({multiValueClasificacion : this.state.multiValueClasificacion.map(dato => { 
													if(i.removedValue.value!==dato){return dato.value 	}	}) 	})
											}
										}} 
									/>
								</Col>
							</Row>
							<Row>
								<Col md="4">
									<Select
										name="Nivel"
										placeholder="Nivel"
										//value={this.state.multiValueNivel}
										options={this.state.filterNivel}
										//onChange={this.handleMultiNivel}
										//isMulti
										isMulti
										onChange={(event,i) => {
											if(event){
												this.setState({multiValueNivel : (event.map(dato =>  { return dato.value }))})
											}
											else{
												this.setState({multiValueNivel : this.state.multiValueNivel.map(dato => { 
													if(i.removedValue.value!==dato){return dato.value 	}	}) 	})
											}
										}} 
									/>
								</Col>
								<Col md="4">
									<Select
										name="Estatus"
										placeholder="Estatus"
										//value={this.state.multiValueEstatus}
										options={this.state.filterEstatus}
										//onChange={this.handleMultiEstatus}
										//isMulti
										isMulti
										onChange={(event,i) => {
											if(event){
												this.setState({multiValueEstatus : (event.map(dato =>  { return dato.value }))})
											}
											else{
												this.setState({multiValueEstatus : this.state.multiValueEstatus.map(dato => { 
													if(i.removedValue.value!==dato){return dato.value 	}	}) 	})
											}
										}} 
									/>
								</Col>
								<Col md="4">
									<Select
										name="Tipo"
										placeholder="Tipo"
										//value={this.state.multiValueTipo}
										options={this.state.filterTipo}
										//onChange={this.handleMultiTipo}
										//isMulti
										isMulti
										onChange={(event,i) => {
											if(event){
												this.setState({multiValueTipo : (event.map(dato =>  { return dato.value }))})
											}
											else{
												this.setState({multiValueTipo : this.state.multiValueTipo.map(dato => { 
													if(i.removedValue.value!==dato){return dato.value 	}	}) 	})
											}
										}} 
									/>
								</Col>
							</Row>

							<Row>
								<Col md="3">
									<Button className="btn-primary" color="primary" onClick={this.btnFiltrar}>
										{' '}
										Filtrar
									</Button>
								</Col>
								<Col>
									<span className="text-right">
										{menu.Exportar === 1 && this.state.loadingOcupadas && (
											<CircleSpinner
											className="text-center"
											size={30}
											color="#51CBCE"
											loading={this.state.loadingOcupadas}
											/>
										)}
										{menu.Exportar === 1 && !this.state.loadingOcupadas && (
											<Button color="primary" onClick={this.exportar}>
											Exportar 
											</Button>
										)}
									</span>
								</Col>
							</Row>
						</Card>
						{!showAsignacion && (
							<Card>
								<ReactTable
									data={plazas}
									columns={[
										{
											Header: 'FechaAlta',
											minWidth: 80,
											id: 'FechaAlta',
											accessor: d => d.FechaAlta,
										},
										{
											Header: 'Nombre',
											minWidth: 80,
											id: 'Nombre',
											accessor: d => d.Nombre,
										},
										{
											Header: 'Dependencia',
											minWidth: 80,
											id: 'Dependencia_organismo',
											accessor: d => d.Dependencia_organismo,
										},
										{
											Header: 'Area',
											minWidth: 150,
											id: 'Area',
											accessor: d => d.Area,
										},
										{
											Header: 'Funcional',
											minWidth: 150,
											id: 'Denominacion_funcional',
											accessor: d => d.Denominacion_funcional,
										},
										{
											Header: 'Secuencia',
											minWidth: 50,
											id: 'Plaza',
											accessor: d => d.Plaza,
										},
										{
											Header: 'Nivel',
											minWidth: 50,
											id: 'Nivel',
											accessor: d => d.Nivel,
										},
										{
											Header: 'Sueldo',
											minWidth: 50,
											id: 'Sueldo_mensual_honorarios',
											accessor: d => d.Sueldo_mensual_honorarios,
										},
										{
											Header: 'Municipio',
											minWidth: 50,
											id: 'Municipio',
											accessor: d => d.Municipio,
										},
										{
											Header: 'CLASIFICACIÓN',
											minWidth: 50,
											id: 'Clasificacion_SCC',
											accessor: d => d.Clasificacion_SCC,
										},
										{
											Header: 'Tipo',
											minWidth: 50,
											id: 'Tipo',
											accessor: d => d.Tipo,
										},
										{
											Header: 'Estatus',
											minWidth: 50,
											id: 'Estatus',
											accessor: d => (menu.Seguimiento === 1 ? d.Estatus : d.EstatusSimple),
										},
									]}
									defaultPageSize={10}
									showPagination={true}
									showPaginationTop={false}
									showPaginationBottom={true}
									manual
									canNextFromData={true}
									showTotalPages={true}
									PaginationComponent={Pagination}
									previousText="Anterior"
									nextText="Siguiente"
									ofText="de"
									total={totalOcupadas}
									loading={this.state.loadingOcupadas}
									id="tbContraPropuesta"
									onFetchData={(state, instance) => {
										const token = global.access_token;
										this.setState({ loadingOcupadas: true, firstOcupada: false });

										const data = {
											page: state.page,
											tipo: 'and',
											pageSize: state.pageSize,
											sorted: state.sorted,
											filtered: this.state.Filtered,
										};

										console.log('=>Búscar getPlantillas: ', data);

										EjecutarApi(data, 'getPlantillas', token)
											.then(function(res) {
												return res;
											})
											.then(items => {
												console.log('===> Data PlazasVacantes: ', items);

												if (items.results) {
													const dataTable = items.data;

													this.setState({
														loadingOcupadas: false,
														totalOcupadas: items.total,
														plazas: dataTable.map((prop, key) => {
															return {
																id: prop.uid,
																key: key,
																label: prop.Plaza + ' - ' + prop.Denominacion_funcional,
																Dependencia_organismo: prop.Dependencia,
																Nivel: prop.Nivel,
																Tipo: prop.Tipo,
																Nombre: prop.Nombre,
																Area: prop.Area,
																Clasificacion_SCC: prop.Clasificacion_SCC,
																FechaAlta: prop.FechaAlta,
																Sueldo_mensual_honorarios:
																	prop.Sueldo_mensual_honorarios,
																Plaza: prop.Plaza,
																Denominacion_tabular: prop.Denominacion_tabular,
																Denominacion_funcional: prop.Denominacion_funcional,
																Estatus:
																	prop.Estatus === 'VACANTE' ? (
																		<Button
																			className="btn-link"
																			color="primary"
																			onClick={() => {
																				let obj = this.state.plazas.find(
																					o => o.key === key
																				);
																				console.log('===>key: ', key);
																				console.log('===>Obj: ', obj);
																				this.setAsignar(obj);
																			}}
																		>{`${prop.Estatus}`}</Button>
																	) : (
																		prop.Estatus
																	),
																EstatusSimple: prop.Estatus,
																Municipio: prop.Municipio,
																FechaVacante: prop.Fecha_vacante,
																data: prop,
															};
														}),
													});
												}
											})
											.catch(e => {
												console.log('error getPlantillas: ', e);
												Notify(3, 'tc', `Error al cargar plazas: ${e.message}`, this.refs);
												this.setState({ loadingOcupadas: false });
											});
									}}
									/*
                        You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                        */
									className="-striped -highlight primary-pagination"
								/>
							</Card>
						)}
						{showAsignacion && <OficioIndividual setBack={this.setBack} plaza={plazaData.data} />}
					</div>
					<Footer fluid />
				</div>
			</div>
		);
	}
}

export default PlantillaScreen;
