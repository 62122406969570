import React from 'react'
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    CardFooter,
    Row,
    Col,
    Input,
    FormGroup,
    Label,

} from 'reactstrap'
import Select from 'react-select'
import NotificationAlert from 'react-notification-alert'
import ReactTable from 'react-table'
import { CircleSpinner } from 'react-spinners-kit'
import ReactDatetime from 'react-datetime'
//import history from 'history';

import DashboardNavbar from '../../components/Navbars/DashboardNavbar.jsx'
import Footer from '../../components/Footer/Footer.jsx'
import Sidebar from '../../components/Sidebar/MenuSidebar.jsx'

import EjecutarApi from '../../data/Consultas'
import Notify from '../../data/Notificacion'

import Pagination from '../components/Paginacion/index'
import OficialiaAdd from './oficialiaAdd'

import UploadFile from '../../views/components/UploadFiles/ImageUpload'
import { getVars, setVars,URL_PUBLICA } from '../../GlobalStore'
import ModalProponeSolicitud from '../components/Modal/ModalPropone'

var global = ''
var ps = ''
class oficialiaSustituirFolio extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            backgroundColor: 'black',
            activeColor: 'info',
            loading: false,
            sidebarMini: false,
            showData: true,
            total: 0,
            data: {},
            dataRS: [],
            dataRsPlus: [],
            showAdd: false,
            TotalPendiente: 0,
            TotalOperado: 0,
            TotalConcluido: 0,
            TotalSolicitudes: 0,
            PersonaProponeSolicitud: {
                idQuienPropone: null,
                FullName:'',
                Nombre: '',
                Paterno: '',
                Materno: '',
                Puesto: '',
                Cargo: '',
                Dependencia: {
                    value: 'undefined',
                    label: '',
                },
            },
           
        }
    }

    componentDidMount() {
        this.setState({ loading: true })
        
        const token = getVars('Token').access_token
        fetch(URL_PUBLICA+'getDashboard', {
            headers: new Headers({ Authorization: `Bearer ${token}` })
        })
            .then((response) => {
                console.log('Response.getDashboard:',response);
                if (response.ok) {
                    return response.json()
                }
                throw new Error(
                    'Ocurrio un error: getDashboard: ',
                    response.statusText
                )
            })
            .then((response) => {
                console.log('-> Data getDashboard: ', response.data);
                if (response.results){
                    
                    this.setState({
                        loading: false,
                        TotalPendiente: response.data[0].Pendiente,
                        TotalOperado: response.data[0].Operado,
                        TotalConcluido: response.data[0].Concluido,
                        TotalSolicitudes: response.data[0].TotalSolicitudes,
                    })
                }
            })
            .catch((e) => {
                this.setState({ loading: false })
                console.log('error: ', e.message)
                
            })
    }

    setValidate = (obj) => {
        console.log('Mis datos: ', obj)
        this.setState({ data: obj, showData: false })
    }

    setBack = () => {
        this.setState({ showData: true })
    }

    handleAddSolicitud = () => {
        this.setState({ showAdd: true })
    }

    handleEliminar =(ID)=>{
        this.setState({ loading:true })
        console.log('vamos a eliminar',ID);
        const token = getVars('Token').access_token  
       /* 
        EjecutarApi({id:ID},'deleteSolicitudes',token)
        .then(function (res){
            return res;
        })
        .then((res)=>{
            console.log('EjecutarAPI->deleteSolicitudes->res:',res);
            if (res.results) {
                var data = res.data;
                this.setState({ loading:false })
                Notify(
                    2,
                    'tc',
                    `Registro Eliminado: `,
                    this.refs
                )
            }
            
        })
        .catch(e => {
            console.log('Error->tblPropuestas: ',e);
            this.setState({loading:false});
            Notify(
                3,
                'tc',
                `Error al eliminar la propuesta: ${e.error}`,
                this.refs
            )
        })
    */
      //grid
      this.setState({ loading: true })

      var parametros = {
          filtered: [],
          tipo: 'and',
          page: 0,
          pageSize: 99999
      }
      console.log(
          'OficialiaPartes:',
          JSON.stringify(parametros)
      )
      EjecutarApi(
          parametros,
          'getSolicitudes',
          token
      )
          .then(function (res) {
              return res
          })
          .then((res) => {
              console.log(
                  'getSolicitudes: ',
                  res
              )

              this.setState({
                  total: res.data.length,
                  InfoVIP: res.data.map(
                      (item, key) => {
                          console.log(
                              '==>item: ',
                              item
                          )
                          return {
                              id: item.id,
                              Eliminar: (<Button
                                  onClick={() => {this.handleEliminar(item.id) }}
                                  color="danger"
                                  size="sm"
                                  className="btn-icon btn-link remove"
                              >x</Button>),
                              FechaSolicitud:
                                  item.FechaSolicitud,
                              FechaRecepcion:
                                  item.FechaRecepcion,
                              Propone:
                                  item
                                      .Propone
                                      .label,
                              idPropone:
                                  item
                                      .Propone
                                      .id,
                              Dependencia:
                                  item
                                      .Dependencia
                                      .label,
                              idDependencia:
                                  item
                                      .Dependencia
                                      .id,
                              FolioOficio:
                                  item.FolioOficio,
                              Estatus:item.Estatus.label,
                              data: item,
                          }
                      }
                  ),
                  loading: false,
              })
          })
          .catch((e) => {
              console.log(
                  '=Error[getSolicitudes]: ',
                  e
              )
              Notify(
                  3,
                  'tc',
                  `Error en getSolicitudes: ${e.error}`,
                  this.refs
              )
              this.setState({
                  loading: false,
              })
          })  
      
    
      }
    
    handleEditarSolicitud=(id)=>{
       
       setVars('idSolicitud',id); 
       this.props.history.push({
            pathname: '/gto/admin/oficialiaEdit'
        });
    }
    render() {
        console.log('=>Mis Estados: ', this.state)
        global = getVars('Token')
        const ItemsDependencia = []
       // const { total, Totales, loading } = this.state
        return (
            <div className="wrapper">
                <Sidebar
                    {...this.props}
                    bgColor={this.state.backgroundColor}
                    activeColor={this.state.activeColor}
                />
                <div className="main-panel" ref="mainPanel">
                    <DashboardNavbar
                        {...this.props}
                        handleMiniClick={this.handleMiniClick}
                    />
                    <NotificationAlert ref="notificationAlert" />
                    <div className="content">
                        <Row>
                            <Col lg="2" md="6" sm="6">
                                <Card className="card-stats">
                                    <CardBody>
                                        <Row>
                                            <Col md="4" xs="5">
                                                <div className="icon-big text-center icon-warning">
                                                    <i className="nc-icon nc-globe text-warning" />
                                                </div>
                                            </Col>
                                            <Col md="8" xs="7">
                                                <div className="numbers">
                                                    <p className="card-category">
                                                        Total Solicitudes
                                                    </p>
                                                    {this.state.loading && (
                                                        <CircleSpinner
                                                            className="text-center"
                                                            size={30}
                                                            color="#686769"
                                                            loading={this.state.loading}
                                                        />
                                                    )}
                                                    <CardTitle tag="p">
                                                        {this.state.TotalSolicitudes}
                                                    </CardTitle>
                                                    <p />
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter>
                                        <hr />
                                        <div className="stats">
                                            <i className="fa fa-calendar-o" />
                                            {this.state.loading
                                                ? 'Actualizando...'
                                                : 'Actualizado'}
                                        </div>
                                    </CardFooter>
                                </Card>
                            </Col>

                            <Col lg="2" md="6" sm="6">
                                <Card className="card-stats">
                                    <CardBody>
                                        <Row>
                                            <Col md="4" xs="5">
                                                <div className="icon-big text-center icon-warning">
                                                    <i className="nc-icon nc-favourite-28 text-primary" />
                                                </div>
                                            </Col>
                                            <Col md="8" xs="7">
                                                <div className="numbers">
                                                    <p className="card-category">
                                                        Total Pendiente
                                                    </p>
                                                    {this.state.loading && (
                                                        <CircleSpinner
                                                            className="text-center"
                                                            size={30}
                                                            color="#686769"
                                                            loading={this.state.loading}
                                                        />
                                                    )}
                                                    <CardTitle tag="p">
                                                        {this.state.TotalPendiente}
                                                    </CardTitle>
                                                    <p />
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter>
                                        <hr />
                                        <div className="stats">
                                            <i className="fa fa-calendar-o" />
                                            {this.state.loading
                                                ? 'Actualizando...'
                                                : 'Actualizado'}
                                        </div>
                                    </CardFooter>
                                </Card>
                            </Col>
                            <Col lg="2" md="6" sm="6">
                                <Card className="card-stats">
                                    <CardBody>
                                        <Row>
                                            <Col md="4" xs="5">
                                                <div className="icon-big text-center icon-warning">
                                                    <i className="nc-icon nc-money-coins text-success" />
                                                </div>
                                            </Col>
                                            <Col md="8" xs="7">
                                                <div className="numbers">
                                                    <p className="card-category">
                                                        Total Operado
                                                    </p>
                                                    <CardTitle tag="p">
                                                        {this.state.loading && (
                                                            <CircleSpinner
                                                                className="text-center"
                                                                size={30}
                                                                color="#686769"
                                                                loading={
                                                                    this.state.loading
                                                                }
                                                            />
                                                        )}
                                                        {this.state.TotalOperado}
                                                    </CardTitle>
                                                    <p />
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter>
                                        <hr />
                                        <div className="stats">
                                            <i className="fa fa-calendar-o" />
                                            {this.state.loading
                                                ? 'Actualizando...'
                                                : 'Actualizado'}
                                        </div>
                                    </CardFooter>
                                </Card>
                            </Col>
                            <Col lg="2" md="6" sm="6">
                                <Card className="card-stats">
                                    <CardBody>
                                        <Row>
                                            <Col md="4" xs="5">
                                                <div className="icon-big text-center icon-warning">
                                                    <i className="nc-icon nc-vector text-danger" />
                                                </div>
                                            </Col>
                                            <Col md="8" xs="7">
                                                <div className="numbers">
                                                    <p className="card-category">
                                                        Total Concluido
                                                    </p>
                                                    <CardTitle tag="p">
                                                        {this.state.loading && (
                                                            <CircleSpinner
                                                                className="text-center"
                                                                size={30}
                                                                color="#686769"
                                                                loading={
                                                                    this.state.loading
                                                                }
                                                            />
                                                        )}
                                                        {this.state.TotalConcluido}
                                                    </CardTitle>
                                                    <p />
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter>
                                        <hr />
                                        <div className="stats">
                                            <i className="fa fa-clock-o" />
                                            {this.state.loading
                                                ? 'Actualizando...'
                                                : 'Actualizado'}
                                        </div>
                                    </CardFooter>
                                </Card>
                            </Col>
                        </Row>
                       <Row>
                       <Card>
                                        <CardHeader>
                                            <CardTitle tag="h6">
                                                Captura de Solicitud
                                            </CardTitle>
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Col sm="3">
                                                    {this.state.loading && (
                                                        <CircleSpinner
                                                            className="text-center"
                                                            size={30}
                                                            color="#686769"
                                                            loading={
                                                                this.state
                                                                    .loading
                                                            }
                                                        />
                                                    )}
                                                    Dependencia:
                                                    <Select
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        name="DepSolicitud"
                                                        onChange={this.setSolicitudDependencia}
                                                        options={
                                                            ItemsDependencia &&
                                                            ItemsDependencia.constructor ===
                                                                Array
                                                                ? ItemsDependencia
                                                                : []
                                                        }
                                                        placeholder="Seleccione Dependencia"
                                                    />
                                                </Col>
                                                <Col sm="3">
                                                    Folio de Solicitud:
                                                    <Input
                                                        name='FolioSolicitud'
                                                        //value={this.state.SolicitudFolioSolicitud.toUpperCase()}
                                                        onChange={
                                                            this
                                                                .setFolioSolicitud
                                                        }
                                                    ></Input>
                                                </Col>
                                                <Col sm="3">
                                                    Fecha Oficio:
                                                    <ReactDatetime
                                                        name='FechaOficio'
                                                        dateFormat="YYYY-MM-DD"
                                                        timeFormat={false}
                                                        closeOnSelect
                                                        onChange={
                                                            this
                                                                .setFechaOficioSolicitud
                                                        }
                                                        inputProps={{
                                                            className:
                                                                'form-control',
                                                            placeholder:
                                                                'Fecha Oficio',
                                                        }}
                                                    />
                                                </Col>
                                                <Col sm="3">
                                                    Fecha Recepción:
                                                    <ReactDatetime
                                                        name='FechaRecepcion'
                                                        dateFormat="YYYY-MM-DD"
                                                        //timeFormat={false}
                                                        closeOnSelect
                                                        onChange={
                                                            this
                                                                .setFechaRecepcionSolicitud
                                                        }
                                                        inputProps={{
                                                            className:
                                                                'form-control',
                                                            placeholder:
                                                                'Fecha Oficio',
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm="4">
                                                    <div className="form-row align-items-center">
                                                        <FormGroup className="col-md-6 pr-0">
                                                            <Label className="text-left">
                                                                Persona Propone
                                                            </Label>
                                                            <Input
                                                                disabled
                                                                id="solPropones"
                                                                defaultValue={
                                                                    this.state
                                                                        .PersonaProponeSolicitud
                                                                        .FullName
                                                                }
                                                                type="text"
                                                            />
                                                        </FormGroup>

                                                        <FormGroup className="col-md-2 pl-0">
                                                            <br></br>
                                                            <Button className='btn-round'
                                                                onClick={
                                                                    this
                                                                        .togglemodalProponeSolicitud
                                                                }
                                                                color="warning"
                                                            >
                                                                <i className="nc-icon nc-zoom-split lg" />
                                                            </Button>
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                                <Col sm="4">
                                                    <UploadFile
                                                        onFilesAdded={
                                                            this.onFilesAdded
                                                        }
                                                        Accept="pdf"
                                                        title="Cargar excel"
                                                    ></UploadFile>
                                                </Col>

                                                <Col sm="3">
                                                    Estatus:
                                                    <Select
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        name="EstatusSolicitud"
                                                        onChange={
                                                            this
                                                                .setEstatudSolicitud
                                                        }
                                                        options={[
                                                            {
                                                                value: '1',
                                                                label:
                                                                    'Pendiente',
                                                            },
                                                        ]}
                                                    />
                                                </Col>
                                                <Col>
                                                    {!this.state
                                                        .areaPropuesta ? (
                                                        <Button className ='btn-round'
                                                            color="primary"
                                                            onClick={() =>
                                                                this.saveSolicitud()
                                                            }
                                                        >
                                                            Guardar
                                                        </Button>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </Col>
                                            </Row>
                                            <Row></Row>
                                        </CardBody>
                                        <CardFooter>
                                            <ModalProponeSolicitud
                                                isOpen={
                                                    this.state
                                                        .modalProponeSolicitud
                                                }
                                                newUser={
                                                    this.setNewProponeSolicitud
                                                }
                                                toggle={
                                                    this
                                                        .togglemodalProponeSolicitud
                                                }
                                                setPropone={
                                                    this.setProponeSolicitud
                                                }
                                                onError={this.onErrorRedirect}
                                                ItemsDependencia={
                                                    ItemsDependencia
                                                }
                                            />
                                        </CardFooter>
                                    </Card>
                       </Row>
                        <Card>{this.state.showAdd ? (
                                    <OficialiaAdd
                                        props={this.props}
                                    ></OficialiaAdd>
                                ) : (<>
                            <CardHeader>
                                <CardTitle tag="h6">
                                    Listado de Movimientos por Solicitud{' '}
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                            <ReactTable
                                                    id="tblPropuestas"
                                                    className="-striped -highlight primary-pagination"
                                                    data={
                                                        this.state
                                                            .ListadoPropuestas
                                                    }
                                                    columns={[
                                                        {
                                                            Header: 'Eliminar',
                                                            minWidth: 150,
                                                            id: 'Eliminar',
                                                            accessor: (d) =>
                                                                d.Eliminar,
                                                        },
                                                        {
                                                            Header:
                                                                'Movimiento',
                                                            minWidth: 150,
                                                            id: 'Movimiento',
                                                            accessor: (d) =>
                                                                d.Movimiento,
                                                        },
                                                        {
                                                            Header:
                                                                'Dependencia',
                                                            minWidth: 80,
                                                            id: 'Dependencia',
                                                            accessor: (d) =>
                                                                d.Dependencia,
                                                        },
                                                        {
                                                            Header: 'Candidato',
                                                            minWidth: 80,
                                                            id: 'Candidato',
                                                            accessor: (d) =>
                                                                d.Candidato,
                                                        },
                                                        {
                                                            Header:
                                                                'Secuencial',
                                                            minWidth: 150,
                                                            id: 'Secuencial',
                                                            accessor: (d) =>
                                                                d.Secuencial,
                                                        },
                                                        {
                                                            Header: 'Nivel',
                                                            minWidth: 150,
                                                            id: 'Nivel',
                                                            accessor: (d) =>
                                                                d.Nivel,
                                                        },
                                                        {
                                                            Header:
                                                                'Denominación Funcional',
                                                            minWidth: 150,
                                                            id:
                                                                'Denominacion_funcional',
                                                            accessor: (d) =>
                                                                d.Denominacion_funcional,
                                                        },
                                                        {
                                                            Header: 'Tipo',
                                                            minWidth: 150,
                                                            id: 'Tipo',
                                                            accessor: (d) =>
                                                                d.Tipo,
                                                        },
                                                        {
                                                            Header: 'Estatus',
                                                            minWidth: 50,
                                                            id: 'Estatus',
                                                            accessor: (d) =>
                                                                d.Estatus,
                                                        },
                                                    ]}
                                                    loading={this.state.loading}
                                                    defaultPageSize={10}
                                                    showPagination={true}
                                                    showPaginationTop={false}
                                                    showPaginationBottom={true}
                                                    pageSizeOptions={[
                                                        5,
                                                        10,
                                                        20,
                                                        25,
                                                        50,
                                                        100,
                                                    ]}
                                                    total={
                                                        this.state
                                                            .totalListadoPropuesta
                                                    }
                                                    manual
                                                    canNextFromData={true}
                                                    showTotalPages={true}
                                                    PaginationComponent={
                                                        Pagination
                                                    }
                                                    previousText="Anterior"
                                                    nextText="Siguiente"
                                                    ofText="de"
                                                    onFetchData={(
                                                        state,
                                                        instance
                                                    ) => {
                                                        this.setState({
                                                            loading: true,
                                                        })
                                                        const token = getVars(
                                                            'Token'
                                                        )
                                                        console.log(
                                                            'Token->tblPropuestas: ',
                                                            token
                                                        )
                                                        var parametros = {
                                                            filtered: [
                                                                {
                                                                    id:
                                                                        'sm.idSolicitud',
                                                                    value: 166,// this.state.idSolicitud,
                                                                },
                                                            ],
                                                            tipo: 'and',
                                                            page: state.page,
                                                            pageSize: 10,
                                                        }
                                                        console.log(
                                                            'tblPropuestas->parametros: ',
                                                            JSON.stringify(
                                                                parametros
                                                            )
                                                        )
                                                        EjecutarApi(
                                                            parametros,
                                                            'getPropuestas',
                                                            token.access_token
                                                        )
                                                            .then(function (
                                                                res
                                                            ) {
                                                                return res
                                                            })
                                                            .then((res) => {
                                                                console.log(
                                                                    'EjecutarAPI->getPropuestas->res:',
                                                                    res
                                                                )
                                                                if (
                                                                    res.results
                                                                ) {
                                                                    var data =
                                                                        res.data
                                                                    this.setState(
                                                                        {
                                                                            loading: false,
                                                                            totalListadoPropuesta:
                                                                                res.total,
                                                                            ListadoPropuestas: data.map(
                                                                                (
                                                                                    item,
                                                                                    key
                                                                                ) => {
                                                                                    return {
                                                                                        id:
                                                                                            item.id,
                                                                                        Eliminar: (
                                                                                           
                                                                                            <FormGroup check>
                                                                                            <Label check>
                                                                                              <Input defaultChecked type="checkbox" onClick={() => {
                                                                                                    this.handleEliminar(
                                                                                                        item.id
                                                                                                    )
                                                                                                }} />
                                                                                              <span className="form-check-sign" />
                                                                                            </Label>
                                                                                          </FormGroup>
                                                                                        ),
                                                                                        Movimiento:
                                                                                            item
                                                                                                .Movimiento
                                                                                                .label,
                                                                                        Dependencia:
                                                                                            item.Dependencia_organismo,
                                                                                        Candidato:
                                                                                            item
                                                                                                .Candidato
                                                                                                .label,
                                                                                        Secuencial:
                                                                                            item.Plaza,
                                                                                        Estatus:
                                                                                            item
                                                                                                .MovimientoStatus
                                                                                                .label,
                                                                                        Denominacion_funcional:
                                                                                            item.Denominacion_funcional,
                                                                                        Tipo:
                                                                                            item.Tipo,
                                                                                        Nivel:
                                                                                            item.Nivel,
                                                                                        data: item,
                                                                                    }
                                                                                }
                                                                            ),
                                                                        }
                                                                    )
                                                                }
                                                            })
                                                            .catch((e) => {
                                                                console.log(
                                                                    'Error->tblPropuestas: ',
                                                                    e
                                                                )
                                                                this.setState({
                                                                    loading: false,
                                                                })
                                                            })
                                                    }}
                                                />
							</CardBody>  
							</>)}
                        </Card>
                    </div>
                    <Footer fluid />
                </div>
            </div>
        )
    }
}

export default oficialiaSustituirFolio

