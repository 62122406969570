import React from 'react';
import { Button, Card, CardHeader, CardBody, CardTitle, Row, Col } from 'reactstrap';
import PerfectScrollbar from 'perfect-scrollbar';
import ReactTable from 'react-table';
import matchSorter from 'match-sorter';

import NotificationAlert from 'react-notification-alert';

import EjecutarApi from '../../data/Consultas';
import Notify from '../../data/Notificacion';
import FixedPlugin from '../../components/FixedPlugin/FixedPlugin';

import DashboardNavbar from '../../components/Navbars/DashboardNavbar.jsx';
import Footer from '../../components/Footer/Footer.jsx';
import Sidebar from '../../components/Sidebar/MenuSidebar.jsx';
import Pagination from '../components/Paginacion/index';
import MovValidacion from '../components/Validaciones/index';
import { getVars,URL_PUBLICA } from '../../GlobalStore';

var global = '';
var ps;

class Validaciones extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			backgroundColor: 'black',
			activeColor: 'info',
			sidebarMini: false,
			loading: false,
			isLoading: false,
			firstTime: true,
			showData: true,
			catProponen: [],
			filterMunicipio: [],
			filterDependencia: [],
			Filtered: [],
			total: 0,
			idDependencia: {
				value: '',
				label: 'Seleccione',
			},
			Movimiento: {
				value: '',
				label: 'Seleccione',
			},
			Plaza: null,
			idEstadoCivil: {
				value: '',
				label: 'Seleccione',
			},
			idNivelEstudio: {
				value: '',
				label: 'Seleccione',
			},
			NivelPropuesto: {
				value: '',
				label: 'Seleccione',
			},
			modalDependencia: false,
			INE: '',
			CURP: '',
			Nombre: '',
			Paterno: '',
			Materno: '',
			TelCasa: '',
			Celular: '',
			CorreoElectronico: '',
			Facebook: '',
			Twitter: '',
			Instagram: '',
			GradoObtenido: '',
			formErrors: {
				Nombre: '',
				Paterno: '',
				Materno: '',
			},
		};
	}

	componentDidMount() {
		if (navigator.platform.indexOf('Win') > -1) {
			document.documentElement.className += ' perfect-scrollbar-on';
			document.documentElement.classList.remove('perfect-scrollbar-off');
			ps = new PerfectScrollbar(this.refs.mainPanel);
		}

		const data = {
			page: 0,
			tipo: 'and',
			pageSize: 99999,
			sorted: [{ id: 'Nombre', desc: false }],
			filtered: [],
		};

		const token = global.access_token; //localstorage

		console.log('=Get Propone');

		EjecutarApi(data, 'getProponen', token)
			.then(function(res) {
				return res;
			})
			.then(items => {
				console.log('===> Data getPropone: ', items);

				if (items.results) {
					this.setState({
						catProponen: items.data.map((prop, key) => {
							return { label: prop.NombrePropone, value: prop.id };
						}),
					});
				}
			})
			.catch(e => {
				console.log('error getProponen: ', e.message);
			});

		fetch(URL_PUBLICA+'obtenerDatosCandidatos', {
			headers: new Headers({ Authorization: `Bearer ${token}` }),
		})
			.then(response => {
				switch (response.status) {
					case 200:
						console.log('==>Error Status: ', response.status);
						break;

					default:
						break;
				}

				if (response.ok) {
					return response.json();
				}
				throw new Error('Ocurrio un error: obtenerDatosCandidatos: ', response.statusText);
			})
			.then(items => {
				console.log('===> Data Catalogos: ', items);

				this.setState({
					filterDependencia: items.dependencias.map((prop, key) => {
						return { label: prop.dependencia.label, value: prop.dependencia.value };
					}),
					filterMunicipio: items.municipios,
				});
			})
			.catch(e => {
				console.log('error: ', e.message);
			});
	}
	componentWillUnmount() {
		if (navigator.platform.indexOf('Win') > -1) {
			ps.destroy();
			document.documentElement.className += ' perfect-scrollbar-off';
			document.documentElement.classList.remove('perfect-scrollbar-on');
		}
	}
	componentDidUpdate(e) {
		if (e.history.action === 'PUSH') {
			document.documentElement.scrollTop = 0;
			document.scrollingElement.scrollTop = 0;
			this.refs.mainPanel.scrollTop = 0;
		}
	}

	handleMiniClick = () => {
		if (document.body.classList.contains('sidebar-mini')) {
			this.setState({ sidebarMini: false });
		} else {
			this.setState({ sidebarMini: true });
		}
		document.body.classList.toggle('sidebar-mini');
	};

	setValidate = obj => {
		this.setState({ data: obj, showData: false });
	};

	setBack = () => {
		this.setState({ showData: true });
	};

	toggleDependencia = () => {
		this.setState(prevState => ({
			modalDependencia: !prevState.modalDependencia,
		}));
	};

	searchUser = () => {
		console.log('=> Buscar usuario');
	};

	btnSaveValidate = () => {
		console.log('Guardar y validar');
	};

	setFiltered = Filtered => {
		this.setState({ Filtered, isLoading: true });

		//const {  menu } = this.state;

		const menu = {
			Edit: 1,
		};

		const token = global.access_token;

		const data = {
			page: 0,
			tipo: 'and',
			pageSize: 10,
			sorted: [],
			filtered: Filtered,
		};

		console.log('==>Filtered PropuestasVIP: ', data);

		EjecutarApi(data, 'getPlazaMovimientos', token)
			.then(function(res) {
				return res;
			})
			.then(items => {
				console.log('===> Data getPlazaMovimientos: ', items);
				this.setState({ isLoading: false });

				if (items.results) {
					const dataTable = items.data;

					console.log('==>Hay RS: ', dataTable);
					console.log('==>Menu: ', menu);

					this.setState({
						loading: false,
						total: items.total,
						dataRsPlus: dataTable.map((prop, key) => {
							return {
								id: prop.id,
								key: key,
								FechaMovimiento: prop.FechaMovimiento || '',
								PuestoQuienPropone: prop.Propone.Puesto || '',
								Movimiento: prop.movimiento.label,
								FolioDependencia: prop.FolioDependencia,
								QuienProponeConsideracion: prop.QuienProponeConsideracion,
								QuienPropone: `${prop.Propone.Nombre} ${prop.Propone.Paterno}, ${
									prop.Propone.Puesto === null ? '' : prop.Propone.Puesto
								}`,
								Candidato: (
									<div>
										{menu.Edit === 1 && (
											<Button
												className="btn-link"
												color="primary"
												onClick={() => {
													let obj = this.state.dataRsPlus.find(o => o.key === key);
													this.setEdit(obj);
												}}
											>
												<i className="fa fa-edit" />
											</Button>
										)}
										{prop.movimiento.value === 1 && (
											<Button
												className="btn-link"
												color="primary"
												onClick={() => {
													let obj = this.state.dataRsPlus.find(o => o.key === key);
													this.btnCheck(obj);
												}}
											>
												<i className="fa fa-star" />
												{prop.movimiento.value}
											</Button>
										)}
										{prop.movimiento.value === 5 && (
											<Button
												className="btn-link"
												color="secunday"
												onClick={() => {
													let obj = this.state.dataRsPlus.find(o => o.key === key);
													this.btnCheck(obj);
												}}
											>
												<i className="fa fa-check" />
												{prop.movimiento.value}
											</Button>
										)}
										{`${prop.candidato.Nombre} ${prop.candidato.Paterno} ${prop.candidato.Materno}`}
									</div>
								),

								dataCandidato: prop,
								btnCandidato: (
									<div>
										{menu.Edit === 1 && (
											<Button
												className="btn-link"
												color="primary"
												onClick={() => {
													let obj = this.state.dataRsPlus.find(o => o.key === key);
													this.setEdit(obj);
												}}
											>
												<i className="fa fa-edit" />
												{prop.movimiento.value}
											</Button>
										)}
										{prop.movimiento.value === 1 && (
											<Button
												className="btn-link"
												color="primary"
												onClick={() => {
													let obj = this.state.dataRsPlus.find(o => o.key === key);
													this.btnCheck(obj);
												}}
											>
												<i className="fa fa-star" />
												{prop.movimiento.value}
											</Button>
										)}

										{prop.movimiento.value === 5 && (
											<Button
												className="btn-link"
												color="secunday"
												onClick={() => {
													let obj = this.state.dataRsPlus.find(o => o.key === key);
													this.btnCheck(obj);
												}}
											>
												<i className="fa fa-check" />
											</Button>
										)}

										<Button
											className="btn-link"
											color="primary"
											onClick={() => {
												let obj = this.state.dataRsPlus.find(o => o.key === key);
												this.setValidate(obj);
											}}
										>
											{prop.movimiento.value !== 4
												? `${prop.candidato.Nombre} ${prop.candidato.Paterno} ${prop.candidato.Materno}`
												: prop.candidato.NombreCompleto}
										</Button>
									</div>
								),
								Plaza: prop.plaza.Puesto || '',
								Municipio: prop.plaza.Municipio,
								GradoObtenido: prop.candidato.GradoObtenido,
								NivelEstudios: prop.candidato.NivelEstudios.label,
								MunicipioCandidato: prop.candidato.Municipio.label,
								Nivel: prop.movimiento.value === 1 ? prop.candidato.NivelPropuesto : prop.plaza.Nivel,
								isActivo: prop.isActivo,
								isJefatura: prop.candidato.isJefatura,
								Estatus: prop.Movimiento_status.label,
								Dependencia: prop.plaza.Dependencia || '',
								idMovimientoStatus: prop.Movimiento_status.label || '',
							};
						}),
					});
				}
			})
			.catch(e => {
				console.log('error getPlazaMovimientos: ', e.message);
				this.setState({ isLoading: false });
			});
	};

	clearFiltered = () => {
		this.setState({ Filtered: [], isLoading: false });
	};

	render() {
		console.log('MIS ESTATES ==> ', this.state);
		global = getVars('Token');

		return (
			<div className="wrapper">
				<Sidebar {...this.props} bgColor={this.state.backgroundColor} activeColor={this.state.activeColor} />
				<div className="main-panel" ref="mainPanel">
					<DashboardNavbar {...this.props} handleMiniClick={this.handleMiniClick} />
					<NotificationAlert ref="notificationAlert" />
					<div className="content">
						<Row>
							<Col md="12">
								{!this.state.showData && (
									<MovValidacion
										showButtons={false}
										setBack={this.setBack}
										data={this.state.data}
										autorizar={this.btnAuthorizar}
										noautorizar={this.btnNoAuthorizar}
									/>
								)}
								{this.state.showData && (
									<Card>
										<CardHeader>
											<CardTitle tag="h4">Personas por validar</CardTitle>
										</CardHeader>
										<CardBody>
											<ReactTable
												data={this.state.dataRsPlus}
												showPagination={true}
												showPageJump={false}
												previousText="Anterior"
												nextText="Siguiente"
												canNextFromData={true}
												defaultPageSize={10}
												total={this.state.total}
												pages={Math.floor(this.state.total / 10)}
												columns={[
													{
														Header: 'Fecha Mov',
														minWidth: 50,
														id: 'FechaMovimiento',
														accessor: d => d.FechaMovimiento,
														filterMethod: (filter, rows) =>
															matchSorter(rows, filter.value, {
																keys: ['FechaMovimiento'],
															}),
														filterAll: true,
													},
													{
														Header: 'Folio',
														minWidth: 60,
														id: 'FolioDependencia',
														accessor: d => d.FolioDependencia,
														filterMethod: (filter, rows) =>
															matchSorter(rows, filter.value, {
																keys: ['FolioDependencia'],
															}),
														filterAll: true,
													},
													{
														Header: 'ProponeC',
														minWidth: 60,
														id: 'QuienProponeConsideracion',
														accessor: d => d.QuienProponeConsideracion,
														filterMethod: (filter, rows) =>
															matchSorter(rows, filter.value, {
																keys: ['QuienProponeConsideracion'],
															}),
														filterAll: true,
													},
													{
														Header: 'Tipo',
														minWidth: 60,
														id: 'idMovimiento',
														accessor: d => d.Movimiento,
														filterAll: true,
													},
													{
														Header: 'Candidato',
														id: 'candidato',
														accessor: d => d.btnCandidato,
														filterMethod: (filter, rows) =>
															matchSorter(rows, filter.value, { keys: ['Candidato'] }),
														filterAll: true,
													},
													{
														Header: 'Dependencia',
														id: 'Dependencia',
														accessor: d => d.Dependencia,
														filterAll: true,
													},
													{
														Header: 'Propone',
														id: 'propone',
														accessor: d => d.QuienPropone,
														filterAll: true,
													},
												]}
												manual
												loading={this.state.loading}
												id="tbValidaciones"
												PaginationComponent={Pagination}
												onFetchData={(stateVoBo, instance) => {
													console.log('==>stateVoBo: ', stateVoBo);

													const token = global.access_token;
													this.setState({ isLoading: true });

													const data = {
														page: stateVoBo.page,
														tipo: 'and',
														pageSize: stateVoBo.pageSize,
														sorted: stateVoBo.sorted,
														filtered: [{ id: 'idMovimientoStatus', value: '2' }],
													};

													console.log('==>Filtered Validaciones: ', data);

													EjecutarApi(data, 'getPlazaMovimientos', token)
														.then(function(res) {
															return res;
														})
														.then(items => {
															console.log('===> Data getPlazaMovimientos: ', items);

															if (items.results) {
																const dataTable = items.data;

																console.log('==>Hay RS: ', dataTable);

																this.setState({
																	isLoading: false,
																	total: items.total,
																	dataRsPlus: dataTable.map((prop, key) => {
																		return {
																			id: prop.id,
																			key: key,
																			FechaMovimiento: prop.FechaMovimiento || '',
																			PuestoQuienPropone:
																				prop.Propone.Puesto || '',
																			Movimiento: prop.movimiento.label,
																			QuienProponeConsideracion:
																				prop.QuienProponeConsideracion,
																			FolioDependencia: prop.FolioDependencia,
																			QuienPropone:
																				`${prop.Propone.Nombre} ${prop.Propone.Paterno}, ${prop.Propone.Puesto}` ||
																				'',
																			Candidato: `${prop.candidato.Nombre} ${prop.candidato.Paterno} ${prop.candidato.Materno}`,

																			dataCandidato: prop,
																			btnCandidato: (
																				<Button
																					className="btn-link"
																					color="primary"
																					onClick={() => {
																						let obj = this.state.dataRsPlus.find(
																							o => o.key === key
																						);
																						this.setValidate(obj);
																					}}
																				>
																					{prop.movimiento.value !== 4
																						? `${prop.candidato.Nombre} ${prop.candidato.Paterno} ${prop.candidato.Materno}`
																						: `${prop.candidato.NombreCompleto}`}
																				</Button>
																			),
																			Plaza: prop.plaza.Puesto || '',
																			Municipio: prop.plaza.Municipio,
																			Nivel: prop.plaza.Nivel || '',
																			Dependencia: prop.plaza.Dependencia || '',
																			idMovimientoStatus:
																				prop.Movimiento_status.label || '',
																		};
																	}),
																});
															}
														})
														.catch(e => {
															console.log('error getPlazaMovimientos: ', e.message);
															Notify(
																2,
																'tc',
																`Error al intentar la búsqueda: ${e.message}`,
																this.refs
															);
														});
												}}
												/*
                                        You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                                      */
												className="-striped -highlight primary-pagination"
											/>
										</CardBody>
									</Card>
								)}
							</Col>
						</Row>
					</div>
					<FixedPlugin
						idMovStatus={[{ value: '2', label: 'Por Validar' }]}
						isLoading={this.state.isLoading}
						filtrar={this.setFiltered}
						data={{
							Propone: this.state.catProponen,
							Dependencias: this.state.filterDependencia,
							Municipios: this.state.filterMunicipio,
						}}
					/>
					<Footer fluid />
				</div>
			</div>
		);
	}
}

export default Validaciones;
