import React from "react";

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

class BarChart extends React.Component {
    constructor(props){
        super(props);
        console.log('=>props: ',this.props);
        console.log('=>props: ',this.props.seriebarra);
        this.state={
            chart: {
                type: 'column'
            },
            title: {
                text: this.props.seriebarraTitle
            },
            subtitle: {
                text: this.props.seriebarra.Subtitle
            },
            xAxis: {
                type: 'category',
                labels: {
                    rotation: -45,
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: this.props.seriebarra.yAxis
                }
            },
            legend: {
                enabled: false
            },
            tooltip: {
                pointFormat: ' <b>{point.y:.1f}</b>'
            },
            series: [{
                        name: this.props.seriebarra.Series.names,
                        data: this.props.seriebarra.Series.datas,
                        dataLabels: {
                        enabled: true,
                        rotation: -90,
                        color: '#FFFFFF',
                        align: 'right',
                        format: '{point.y:.1f}', // one decimal
                        y: 10, // 10 pixels down from the top
                        style: {
                            fontSize: '13px',
                            fontFamily: 'Verdana, sans-serif'
                        }
                    }
                }]
        };
    }   
    componentDidUpdate(){
        this.setState({
            chart: {
                type: 'column'
            },
            title: {
                text: this.props.Title
            },
            subtitle: {
                text: this.props.Subtitle
            },
            xAxis: {
                type: 'category',
                labels: {
                    rotation: -45,
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: this.props.yAxis
                }
            },
            legend: {
                enabled: false
            },
            tooltip: {
                pointFormat: ' <b>{point.y:.1f} millions</b>'
            },
            series: [{
                        //name: this.props.Series.names,
                        //data: this.props.Series.datas,
                        dataLabels: {
                        enabled: true,
                        rotation: -90,
                        color: '#FFFFFF',
                        align: 'right',
                        format: '{point.y:.1f}', // one decimal
                        y: 10, // 10 pixels down from the top
                        style: {
                            fontSize: '13px',
                            fontFamily: 'Verdana, sans-serif'
                        }
                    }
                }]
        })
    }
    render() {
       
        const optionsx=this.state;
        console.log('optionsx',optionsx);
        console.log('this.state',this.state);
    return (<>
        <HighchartsReact
            highcharts={Highcharts}
            options={optionsx}
        />
    </>)
    };
};

export default BarChart;