import React, { Component } from "react";
import {Button, Card, CardHeader,CardBody, CardFooter, Label, FormGroup, Form,
  Input, Row, Col } from "reactstrap";

class Movimiento extends Component {
  constructor(props) {
    super(props);
    console.log("=>data: ", props.data);

    this.state = {
      data: props.data || ""
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.Nombre !== nextProps.Nombre) {
      this.setState({
        data: nextProps.data
      });
    }
  }

  render() {
    console.log("==> State Validate: ", this.state.data);
    const { dataCandidato } = this.state.data;

    console.log("==>dataCandidato: ", dataCandidato);
    return (
      <div>
        <Row>
          <span className="text-ltr">
            <Button className="link" color="info" onClick={this.props.setBack}>
              <span className="btn-label">
                <i className="nc-icon nc-minimal-left"></i>
              </span>
              Regresar
            </Button>
          </span>
        </Row>
        <Row>
          <Col md="4">
            <Card>
              <CardHeader>
                <h5 className="title">Movimiento</h5>
                <h6 className="title">{dataCandidato.movimiento.label}</h6>
              </CardHeader>
              <CardBody>
                <ul className="list-unstyled team-members">
                  <li>
                    <Row>
                      <Col md="12" xs="12">
                        <span className="text-muted">
                          <small>Secretaria</small>
                        </span>
                        <br />
                        {dataCandidato.plaza.Dependencia} <br />
                      </Col>
                    </Row>
                  </li>
                  <li>
                    <Row>
                      <Col md="12" xs="12">
                        <span className="text-muted">
                          <small>Tipo de Plaza</small>
                        </span>
                        <br />
                        {dataCandidato.plaza.Tipo}
                      </Col>
                    </Row>
                  </li>
                  <li>
                    <Row>
                      <Col md="12" xs="12">
                        <span className="text-muted">
                          <small>Número Serie</small>
                        </span>
                        <br />
                        {dataCandidato.plaza.NumSerie} <br />
                      </Col>
                    </Row>
                  </li>
                  <li>
                    <Row>
                      <Col md="12" xs="12">
                        <span className="text-muted">
                          <small>Puesto</small>
                        </span>
                        <br />
                        {dataCandidato.plaza.PuestoFuncional} <br />
                      </Col>
                    </Row>
                  </li>
                  <li>
                    <Row>
                      <Col md="12" xs="12">
                        <span className="text-muted">
                          <small>Nivel</small>
                        </span>
                        <br />
                        Nivel {dataCandidato.plaza.Nivel}
                      </Col>
                    </Row>
                  </li>
                  <li>
                    <Row>
                      <Col md="12" xs="12">
                        <span className="text-muted">
                          <small>Municipio</small>
                        </span>
                        <br />
                        {dataCandidato.plaza.Municipio}
                      </Col>
                    </Row>
                  </li>
                  <li>
                    <Row>
                      <Col md="12" xs="12">
                        <span className="text-muted">
                          <small>Fecha para Movimiento</small>
                        </span>
                        <br />
                        {dataCandidato.FechaMovimiento}
                      </Col>
                    </Row>
                  </li>
                </ul>
              </CardBody>
            </Card>
          </Col>
          <Col md="8">
            <Card>
              <CardHeader>
                <h5 className="title">Propuesta</h5>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col className="pr-1" md="5">
                      <FormGroup>
                        <label>Nombre</label>
                        <Input
                          defaultValue={`${dataCandidato.candidato.Nombre} ${dataCandidato.candidato.Paterno} ${dataCandidato.candidato.Materno}`}
                          disabled
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-1" md="5">
                      <FormGroup>
                        <label>Propone</label>
                        <Input
                          defaultValue={`${dataCandidato.Propone.Nombre} ${dataCandidato.Propone.Paterno} ${dataCandidato.Propone.Materno}`}
                          disabled
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="3">
                      <FormGroup>
                        <Label className="text-left">Sexo</Label>
                        <Input
                          defaultValue={dataCandidato.candidato.Sexo.label}
                          disabled
                          type="text"
                        />
                      </FormGroup>
                    </Col>

                    <Col sm="3">
                      <FormGroup>
                        <Label className="text-left">Estado Civil</Label>
                        <Input
                          defaultValue={
                            dataCandidato.candidato.EstadoCivil.label
                          }
                          disabled
                          type="text"
                        />
                      </FormGroup>
                    </Col>

                    <Col sm="3">
                      <FormGroup>
                        <Label className="text-left">INE</Label>
                        <Input
                          defaultValue={dataCandidato.candidato.INE}
                          disabled
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="3">
                      <FormGroup>
                        <Label className="text-left">CURP</Label>
                        <Input
                          defaultValue={dataCandidato.candidato.CURP}
                          disabled
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="3">
                      <FormGroup>
                        <Label className="text-left">Municipio </Label>
                        <Input
                          defaultValue={dataCandidato.candidato.Municipio.label}
                          disabled
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="3">
                      <FormGroup>
                        <Label className="text-left">Último Empleo</Label>
                        <Input
                          defaultValue={dataCandidato.candidato.UltimoTrabajo}
                          disabled
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="3">
                      <FormGroup>
                        <Label className="text-left">Nivel de Estudios</Label>
                        <Input
                          defaultValue={
                            dataCandidato.candidato.NivelEstudios.label
                          }
                          disabled
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="3">
                      <FormGroup>
                        <Label className="text-left">Grado Obtenido</Label>
                        <Input
                          defaultValue={dataCandidato.candidato.GradoObtenido}
                          disabled
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="4">
                      <FormGroup>
                        <Label className="text-left">Celular</Label>
                        <Input
                          defaultValue={dataCandidato.candidato.Celular1}
                          disabled
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="4">
                      <FormGroup>
                        <Label className="text-left">Tel Casa</Label>
                        <Input
                          defaultValue={dataCandidato.candidato.TelCasa}
                          disabled
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="4">
                      <FormGroup>
                        <Label className="text-left">Correo Electrónico</Label>
                        <Input
                          defaultValue={
                            dataCandidato.candidato.CorreoElectronico
                          }
                          disabled
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="4">
                      <FormGroup>
                        <Label className="text-left">Facebook</Label>
                        <Input
                          defaultValue={dataCandidato.candidato.Facebook}
                          disabled
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="4">
                      <FormGroup>
                        <Label className="text-left">Twitter</Label>
                        <Input
                          defaultValue={dataCandidato.candidato.Twitter}
                          disabled
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="4">
                      <FormGroup>
                        <Label className="text-left">Instagram</Label>
                        <Input
                          defaultValue={dataCandidato.candidato.Instagram}
                          disabled
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
              {this.props.showButtons && (
                <CardFooter>
                  <div className="form-row  text-center">
                    <Col>
                      <Button
                        onClick={() => this.props.autorizar(this.state.data)}
                        className="ml-3"
                        color="info"
                      >
                        Visto Bueno
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        onClick={() => this.props.noautorizar(this.state.data)}
                        className="ml-3"
                        color="danger"
                      >
                        Rechazado
                      </Button>
                    </Col>
                  </div>
                </CardFooter>
              )}
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Movimiento;
