import React, { Component } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  FormGroup,
  Label,
  CardTitle
} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import ReactTable from "react-table";
import ReactDatetime from "react-datetime";
import moment from "moment";

import matchSorter from "match-sorter";
import EjecutarApi from "../../../data/Consultas";
import Notify from "../../../data/Notificacion";

import Pagination from "../Paginacion/index";

import { getVars,URL_PUBLICA } from "../../../GlobalStore";

var global = "";

class OficioIndividual extends Component {
  constructor(props) {
    super(props);
    console.log("=>data: ", props.data);

    this.state = {
      data: props.data || "",
      toAsignar: false,
      loading: false,
      firstTime: true,
      sidebarMini: false,
      firstCommentTime: true,
      showButton: true,
      FechaMovimiento: "",
      FolioDependencia: "",
      Candidatos: [],
      totalCandidatos: 0,
      catProponen: [],
      catMunicipios: [],
      setCandidato: {
        id: ""
      },
      total: 0,
      filtered: [],
      Estatus: {
        value: 1,
        label: "Proceso"
      },
      setOficio: {
        uid: ""
      },
      Comentario: "",
      Plaza: {
        Clasificacion_SCC: "",
        Denominacion_tabular: "",
        Dependencia: "",
        Estatus: "",
        Fecha_vacate: "",
        Municipio: "",
        Nivel: "",
        Plaza: "",
        Tipo: "",
        uid: ""
      },
      dataRsPlus: []
    };
  }

  componentDidMount() {
    const token = global.access_token;
    const data = {
      page: 0,
      pageSize: 20,
      sorted: [],
      filtered: [],
      tipo: "and"
    };
    EjecutarApi(data, "getProponen", token)
      .then(function(res) {
        return res;
      })
      .then(items => {
        console.log("===> Data getProponen: ", items);
        this.setState({ loading: false, catProponen: items.data });
      })
      .catch(e => {
        console.log("error getProponen: ", e.message);
        Notify(3, "tc", `Error al cargar getProponen: ${e.message}`, this.refs);
        this.setState({ loading: false });
      });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.Nombre !== nextProps.Nombre) {
      this.setState({
        data: nextProps.data
      });
    }
  }

  handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      this.setState({ sidebarMini: false });
    } else {
      this.setState({ sidebarMini: true });
    }
    document.body.classList.toggle("sidebar-mini");
  };

  setFechaMovimiento = e => {
    this.setState({ FechaMovimiento: moment(e._d).format("YYYY-MM-DD") });
  };

  setAsignar = setCandidato => {
    console.log("==>Asignar Candidato: ", setCandidato);
    this.setState({ setCandidato, toAsignar: true });
  };

  btnCancelar = () => {
    console.log("==> Cancelar");
    this.setState({ toAsignar: false });
  };

  btnSubmit = () => {
    const { FechaMovimiento } = this.state;

    if (FechaMovimiento.length < 10) {
      Notify(3, "tc", "Error: específicar Fecha Movimiento", this.refs);
      return;
    }

    const data = {
      idMovimiento: 2,
      FechaMovimiento,
      Candidato: this.state.setCandidato.data,
      Plaza: this.props.plaza
    };

    const token = global.access_token; //localstorage

    console.log("=> setOficioIndividual: ", data);

    EjecutarApi(data, "setOficioIndividual", token)
      .then(function(res) {
        return res;
      })
      .then(items => {
        console.log("===> Results setOficioIndividual: ", items);
        this.getOficio(items.data.id);
      })
      .catch(e => {
        console.log("error setOficioIndividual: ", e.message);
        Notify(
          3,
          "tc",
          `Error al cargar setOficioIndividual: ${e.message}`,
          this.refs
        );
        this.setState({ loadingOcupadas: false });
      });
  };

  getOficio = Item => {
    console.log("=>Descargar Excel: ", Item);

    const token = global.access_token; //localstorage

    window.location.href = URL_PUBLICA+`getOficioIndividualExcel?id=${Item}&token=${token}`;
  };

  render() {
    const { Candidatos, toAsignar } = this.state;

    global = getVars("Token");

    console.log("==>Mis States Perfil: ", this.state);
    console.log("=>Props: ", this.props);

    return (
      <div>
        <NotificationAlert ref="notificationAlert" />
        <Row>
          <span className="text-ltr">
            <Button className="link" color="info" onClick={this.props.setBack}>
              <span className="btn-label">
                <i className="nc-icon nc-minimal-left"></i>
              </span>
              Regresar
            </Button>
          </span>
        </Row>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Asignar propuesta</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="3">
                    <span className="text-muted">
                      <small>Área</small>
                    </span>
                    <br />
                    <h6 className="title"> {`${this.props.plaza.Area}`}</h6>
                  </Col>
                  <Col md="3">
                    <span className="text-muted">
                      <small>Dependencia</small>
                    </span>
                    <br />
                    <h6 className="title">
                      {" "}
                      {`${this.props.plaza.Dependencia}`}
                    </h6>
                  </Col>
                  <Col md="3">
                    <span className="text-muted">
                      <small>Municipio</small>
                    </span>
                    <br />
                    <h6 className="title">
                      {" "}
                      {`${this.props.plaza.Municipio}`}
                    </h6>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                      <Label className="text-left">Fecha Movimiento</Label>
                      <ReactDatetime
                        dateFormat="YYYY-MM-DD"
                        timeFormat={false}
                        closeOnSelect
                        onChange={this.setFechaMovimiento}
                        inputProps={{
                          className: "form-control",
                          placeholder: "Fecha Iniciar Cargo"
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="3">
                    <span className="text-muted">
                      <small>Secuencial</small>
                    </span>
                    <br />
                    <h6 className="title"> {`${this.props.plaza.Plaza}`}</h6>
                  </Col>
                  <Col md="3">
                    <span className="text-muted">
                      <small>Denominación Funcional</small>
                    </span>
                    <br />
                    <h6 className="title">
                      {" "}
                      {`${this.props.plaza.Denominacion_funcional}`}
                    </h6>
                  </Col>
                  <Col md="3">
                    <span className="text-muted">
                      <small>Nivel</small>
                    </span>
                    <br />
                    <h6 className="title"> {`${this.props.plaza.Nivel}`}</h6>
                  </Col>
                  <Col md="3">
                    <span className="text-muted">
                      <small>Estatus</small>
                    </span>
                    <br />
                    <h6 className="title"> {`${this.props.plaza.Estatus}`}</h6>
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <span className="text-muted">
                      <small>Fecha Vacante</small>
                    </span>
                    <br />
                    <h6 className="title">
                      {" "}
                      {`${this.props.plaza.Fecha_vacante}`}
                    </h6>
                  </Col>
                  <Col md="4">
                    <span className="text-muted">
                      <small>Clasificación</small>
                    </span>
                    <br />
                    <h6 className="title">
                      {" "}
                      {`${this.props.plaza.Clasificacion_SCC}`}
                    </h6>
                  </Col>
                  <Col md="4">
                    <span className="text-muted">
                      <small>Tipo</small>
                    </span>
                    <br />
                    <h6 className="title"> {`${this.props.plaza.Tipo}`}</h6>
                  </Col>
                </Row>
                {!toAsignar && (
                  <Row>
                    <Col>
                      <ReactTable
                        data={Candidatos}
                        filterable
                        showPagination={true}
                        showPageJump={false}
                        previousText="Anterior"
                        nextText="Siguiente"
                        canNextFromData={true}
                        defaultPageSize={10}
                        total={this.state.totalCandidatos}
                        pages={Math.floor(this.state.totalCandidatos / 10)}
                        manual
                        PaginationComponent={Pagination}
                        columns={[
                          {
                            Header: "Nombre",
                            minWidth: 40,
                            id: "Nombre",
                            accessor: d => d.btnNombre,
                            filterMethod: (filter, rows) =>
                              matchSorter(rows, filter.value, {
                                keys: ["NombreCompleto"]
                              }),
                            filterAll: true
                          },
                          {
                            Header: "Municipio",
                            minWidth: 80,
                            id: "idMunicipio",
                            accessor: d => d.Municipio,
                            filterMethod: (filter, rows) =>
                              matchSorter(rows, filter.value, {
                                keys: ["Municipio"]
                              }),
                            filterAll: false
                          },
                          {
                            Header: "Propone",
                            minWidth: 50,
                            id: "idPropone",
                            accessor: d => d.NombrePropone,
                            filterAll: true,
                            Filter: ({ filter, onChange }) => {
                              return (
                                <select
                                  onChange={event =>
                                    onChange(event.target.value)
                                  }
                                  style={{ width: "100%" }}
                                  value={filter ? filter.value : "all"}
                                >
                                  <option value="all">Todos</option>
                                  {this.state.catProponen.map(item => {
                                    return (
                                      <option value={item.id}>
                                        {" "}
                                        {item.NombrePropone.toUpperCase()}{" "}
                                      </option>
                                    );
                                  })}
                                </select>
                              );
                            }
                          },
                          {
                            Header: "Jefatura",
                            minWidth: 50,
                            id: "isJefatura",
                            accessor: d => d.isJefatura,
                            Cell: ({ value }) => (value === 1 ? "Si" : "No"),
                            filterAll: true,
                            Filter: ({ filter, onChange }) => {
                              return (
                                <select
                                  onChange={event =>
                                    onChange(event.target.value)
                                  }
                                  style={{ width: "100%" }}
                                  value={filter ? filter.value : "all"}
                                >
                                  <option value="all">Todos</option>
                                  <option value="1">Si</option>
                                  <option value="2">No</option>
                                </select>
                              );
                            }
                          },
                          {
                            Header: "Comentarios",
                            minWidth: 40,
                            id: "Comentarios",
                            accessor: d => d.Comentarios,
                            filterMethod: (filter, rows) =>
                              matchSorter(rows, filter.value, {
                                keys: ["Comentarios"]
                              }),
                            filterAll: true
                          }
                        ]}
                        loading={this.state.loading}
                        id="tbCandidatos"
                        onFetchData={(state, instance) => {
                          const token = global.access_token;

                          const Filtered = this.state.firstCommentTime
                            ? []
                            : state.filtered;
                          const Sorted = this.state.firstCommentTime
                            ? []
                            : state.sorted;

                          this.setState({
                            loading: true,
                            firstCommentTime: false
                          });

                          console.log("=>Filtered: ", Filtered);

                          var Encontrado = false;
                          for (let x = 0; x < Filtered.length; x++) {
                            if (Filtered[x].id === "isJefatura") {
                              Encontrado = true;
                            }
                          }

                          if (!Encontrado) {
                            console.log("==>Filtered isJefatura: ", Filtered);
                            Filtered.push({
                              id: "isJefatura",
                              value: 1
                            });
                          }

                          Encontrado = false;
                          for (let x = 0; x < Filtered.length; x++) {
                            if (Filtered[x].id === "isConsideracion") {
                              Encontrado = true;
                            }
                          }

                          if (!Encontrado) {
                            console.log(
                              "==>Filtered isConsideracion: ",
                              Filtered
                            );
                            Filtered.push({
                              id: "isConsideracion",
                              value: 1
                            });
                          }

                          for (let x = 0; x < Filtered.length; x++) {
                            if (Filtered[x].id === "isJefatura") {
                              if (Filtered[x].value === "all") {
                                Filtered.splice(x, 1);
                              }
                            }
                          }

                          const data = {
                            page: state.page,
                            pageSize: state.pageSize,
                            tipo: "and",
                            sorted: Sorted,
                            filtered: Filtered
                          };

                          console.log(
                            "===> Buscar getCandidatosJefatura: ",
                            data
                          );

                          EjecutarApi(data, "getCandidatosJefatura", token)
                            .then(function(res) {
                              return res;
                            })
                            .then(items => {
                              const dataTable = items.data;
                              console.log(
                                "===> Data getCandidatosJefatura: ",
                                items
                              );
                              this.setState({
                                loading: false,
                                totalCandidatos: items.total,
                                Candidatos: dataTable.map((prop, key) => {
                                  return {
                                    id: prop.id,
                                    key: key,
                                    Comentarios: prop.Comentarios,
                                    Movimiento: prop.Movimiento,
                                    Municipio: prop.Municipio,
                                    NombreCompleto: prop.NombreCompleto,
                                    btnNombre: (
                                      <Button
                                        className="btn-link"
                                        color="primary"
                                        onClick={() => {
                                          let obj = this.state.Candidatos.find(
                                            o => o.key === key
                                          );
                                          this.setAsignar(obj);
                                        }}
                                      >{`${prop.NombreCompleto}`}</Button>
                                    ),
                                    NombrePropone: prop.NombrePropone,
                                    idJefatura: prop.isJefatura,
                                    data: prop
                                  };
                                })
                              });
                            })
                            .catch(e => {
                              console.log(
                                "error getCandidatosJefatura: ",
                                e.message
                              );
                              Notify(
                                3,
                                "tc",
                                `Error al cargar getCandidatosJefatura: ${e.message}`,
                                this.refs
                              );
                              this.setState({ loading: false });
                            });

                          this.setState({ loading: false });
                        }}
                        className="-striped -highlight primary-pagination"
                      />
                    </Col>
                  </Row>
                )}
                {toAsignar && (
                  <div>
                    <CardTitle tag="h4">Datos Candidato</CardTitle>
                    <Row>
                      <Col md="3">
                        <span className="text-muted">
                          <small>Nombre</small>
                        </span>
                        <br />
                        <h6 className="title">
                          {" "}
                          {`${this.state.setCandidato.NombreCompleto}`}
                        </h6>
                      </Col>
                      <Col md="3">
                        <span className="text-muted">
                          <small>Municipio</small>
                        </span>
                        <br />
                        <h6 className="title">
                          {" "}
                          {`${this.state.setCandidato.Municipio}`}
                        </h6>
                      </Col>
                      <Col md="3">
                        <span className="text-muted">
                          <small>Jefatura</small>
                        </span>
                        <br />
                        <h6 className="title">
                          {" "}
                          {`${
                            this.state.setCandidato.isJefatura === 1
                              ? "Si"
                              : "No"
                          }`}
                        </h6>
                      </Col>
                      <Col md="3">
                        <span className="text-muted">
                          <small>Propone</small>
                        </span>
                        <br />
                        <h6 className="title">
                          {" "}
                          {`${this.state.setCandidato.NombrePropone}`}
                        </h6>
                      </Col>
                    </Row>
                    <Row>
                      <Button
                        className="btn-round"
                        color="success"
                        onClick={() => this.btnSubmit()}
                      >
                        Asignar
                      </Button>
                      <Button
                        className="btn-round"
                        color="danger"
                        onClick={() => this.btnCancelar()}
                      >
                        Cancelar
                      </Button>
                    </Row>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default OficioIndividual;
