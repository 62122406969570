import React from 'react'
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    CardFooter,
    Label,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    Nav,
    TabContent,
    TabPane,
    NavItem,
    NavLink,

} from 'reactstrap'
import Select from 'react-select'
import PerfectScrollbar from 'perfect-scrollbar'
import NotificationAlert from 'react-notification-alert'
import ReactTable from 'react-table'
import ReactDatetime from 'react-datetime'
import moment from 'moment'
import { CircleSpinner } from 'react-spinners-kit'
import InfiniteScroll from "react-infinite-scroll-component";

import EjecutarApi from '../../data/Consultas'
import Notify from '../../data/Notificacion'
import { getVars, setVars, URL_PUBLICA } from '../../GlobalStore'

import DashboardNavbar from '../../components/Navbars/DashboardNavbar.jsx'
import Footer from '../../components/Footer/Footer.jsx'
import Sidebar from '../../components/Sidebar/MenuSidebar.jsx'
import ModalProponeSolicitud from '../components/Modal/ModalPropone'
import UploadFile from '../../views/components/UploadFiles/ImageUpload'
import MovimientoVobo from '../components/MovimientoVobo/index'
import MovValidacion from '../components/MovimientoValidaciones/index'
import MovimientoAutorizar from '../components/MovimientoAutorizar/index'


var ps;
var global = '';
var dataPropuestas;
//const dataSolicitud = getVars('idSolicitud');
class oficialiaEdit extends React.Component {
    constructor(props) {
        super(props)
        //idSolicitud=props.location.state.id
        this.state = {
            //idSolicitud:props.location.state.id,
            showAdd: false,
            Validacion1: true,
            isVisible: false,
            backgroundColor: 'black',
            activeColor: 'info',
            sidebarMini: false,
            //Solicitud:[],//this.props.location.state?this.props.location.state.detail:[],//dataSolicitud,
            Solicitud: {
                Dependencia: { label: '', value: 0 },
                Estatus: { label: '', value: 0 },
                FechaCreate: '',
                FechaLastUpdate: '',
                FechaRecepcion: '',
                FechaSolicitud: '',
                FolioOficio: '',
                Propone: { label: '', value: 0 },
                UserCreate: { label: '', value: 0 },
                UserUpdated: { label: '', value: 0 },
                id: 0
            },
            loading: false,
            file: null,
            PorAutorizarPage: 0,
            hasMore: true,
            loadingPorAutorizar: false,
            showData: false,
            showVobo: false,
            showValidaciones: false,
            showAcep: false,
            showFinanzas: false,
            showAutorizar: false,
            showDescarga: false,
            //CandidatoRSidMovimiento:0,
            CandidatoRSid: 0,
            CandidatoRSINE: '',
            CandidatoRSCURP: '',
            CandidatoRSNombre: '',
            CandidatoRSPaterno: '',
            CandidatoRSMaterno: '',
            CandidatoRSGradoObtenido: '',
            CandidatoRSCelular1: '',
            CandidatoRSCelular2: '',
            CandidatoRSTelCasa: '',
            CandidatoRSCorreoElectronico: '',
            CandidatoRSUltimoTrabajo: '',
            CandidatoRSNivelPopuesto: 0,
            CandidatoRSmunicipios: { value: 1, label: 'No definido' },
            CandidatoRSestudios: {
                value: 1,
                label: 'No definido'
            },
            CandidatoRSestado_civil: {
                value: 1,
                label: 'No definido'
            },
            CandidatoRSestatus: {
                value: 1,
                label: 'No definido'
            },
            CandidatoRSsexo: {
                value: 1,
                label: 'No definido'
            },
            CandidatoRSPerfilCarrera: { value: 1, label: 'No definido' },
            CandidatoRSComentarios: '',
            CandidatoRSFacebook: '',
            CandidatoRSTwitter: '',
            CandidatoRSInstagram: '',
            CandidatoRStieneCV: false,
            CandidatoRSkeyarray: 0,
            horizontalTabs: 'home',
            ItemsEstadoCivil: [],
            ItemsMunicipio: [],
            ItemsNivelEstudios: [],
            ItemsPerfilCarrera: [],
            totalListadoPropuesta: 0,
            ListadoPropuestas: [{
                id: 0,
                idSolicitud: 0,
                Plaza: '',
                Nivel: 0,
                Denominacion_funcional: '',
                Denominacion_tabular: '',
                Dependencia_organismo: '',
                Tipo: '',
                Municipio: '',
                SUELDO_MENSUAL_HONORARIOS: '',
                FechaAlta: '',
                Movimiento: {
                    value: 0,
                    label: ''
                },
                MovimientoStatus: {
                    value: 0,
                    label: ''
                },
                SolicitudMovimientoStatus: {
                    value: 0,
                    label: ''
                },
                Candidato: {
                    value: 0,
                    label: ''
                },
                Comentarios: [{ Comentario: '', FechaHora: '' }],
                FinanzasFechaEnvio: '',
                FinanzasFechaAviso: '',
                Validaciones: []
            }],
            FinanzasFechaEnvio: '',
            FinanzasFechaAviso: '',
            FinanzasObservaciones: '',
            FinanzasComentarios: []
        }
    }

    llenarListadoMovimiento() {
        const token = getVars('Token').access_token; //localstorage
        var _idSolicitud = this.props.location.state.id.id;
        var parametros = {
            filtered: [
                {
                    id:
                        'sm.idSolicitud',
                    value: _idSolicitud,
                },
            ],
            tipo: 'and',
            page: 0,
            pageSize: 10,
        }

        EjecutarApi(
            parametros,
            'getPropuestas',
            token
        )
            .then(function (
                res
            ) {
                return res
            })
            .then((res) => {
                if (
                    res.results
                ) {
                    var data = res.data;
                    this.setState(
                        {
                            loading: false,
                            totalListadoPropuesta: res.total,
                            ListadoPropuestas: res.data,
                            hasMore: true,
                            PorAutorizarPage: 0
                        }
                    )
                }
            })
            .catch((e) => {
                this.setState({
                    loading: false,
                })
            })
    }
    componentDidMount() {
        this.setState({ loading: true })
        if (navigator.platform.indexOf('Win') > -1) {
            document.documentElement.className += ' perfect-scrollbar-on'
            document.documentElement.classList.remove('perfect-scrollbar-off')
            ps = new PerfectScrollbar(this.refs.mainPanel)
        }
        //
        this.setState({ Solicitud: this.props.location.state.id });
        const token = getVars('Token').access_token; //localstorage
        var _idSolicitud = this.props.location.state.id.id;
        var parametros = {
            filtered: [
                {
                    id:
                        'sm.idSolicitud',
                    value: _idSolicitud,
                },
            ],
            tipo: 'and',
            page: 0,
            pageSize: 10,
        }
        EjecutarApi(
            parametros,
            'getPropuestas',
            token
        )
            .then(function (
                res
            ) {
                return res
            })
            .then((res) => {
                if (
                    res.results
                ) {
                    var data = res.data;
                    this.setState(
                        {
                            loading: false,
                            totalListadoPropuesta:
                                res.total,
                            ListadoPropuestas: res.data,

                        }
                    )
                }
            })
            .catch((e) => {
                this.setState({
                    loading: false,
                })
            })
        this.setState({ loading: true });
        fetch(URL_PUBLICA, 'getCatalogosSolicitud', {
            headers: new Headers({ Authorization: `Bearer ${token}` }),
        })
            .then(response => {
                switch (response.status) {
                    case 200:
                        break;

                    default:
                        break;
                }

                if (response.ok) {
                    return response.json();
                }
                throw new Error('Ocurrio un error: obtenerDatosCandidatos: ', response.statusText);
            })
            .then(items => {

                this.setState({
                    ItemsEstadoCivil: items.EstadoCivil,
                    ItemsMunicipio: items.municipios,
                    ItemsNivelEstudios: items.nivel,
                    ItemsPerfilCarrera: items.PerfilCarrera || { value: 1, label: 'No definido' },

                });
                this.setState({ loading: false });
            })
            .catch(e => {
                // 
            });
    }

    componentDidUpdate(e) {
        if (e.history.action === 'PUSH') {
            document.documentElement.scrollTop = 0
            document.scrollingElement.scrollTop = 0
            this.refs.mainPanel.scrollTop = 0
        }
    }

    togglemodalCandidato = () => {
        this.setState({
            modalCandidato: !this.state.modalCandidato,
        })
    }

    togglemodalPropone = () => {
        this.setState({
            modalPropone: !this.state.modalPropone,
        })
    }
    togglemodalProponeSolicitud = () => {
        this.setState({
            modalProponeSolicitud: !this.state.modalProponeSolicitud,
        })
    }
    toggleNuevaPlaza = () => {
        this.setState({
            modalPlaza: !this.state.modalPlaza,
        })
    }

    searchUser = () => {
        this.togglemodalCandidato()
    }

    handleMiniClick = () => {
        if (document.body.classList.contains('sidebar-mini')) {
            this.setState({ sidebarMini: false })
        } else {
            this.setState({ sidebarMini: true })
        }
        document.body.classList.toggle('sidebar-mini')
    }

    btnSaveValidate = () => {
        // 
    }

    setFechaRecepcion = (e) => {
        this.setState({
            FechaRecepcion: moment(e._d).format('YYYY-MM-DD HH:ii:ss'),
        })
    }

    setFechaMovimiento = (e) => {
        this.setState({ FechaMovimiento: moment(e._d).format('YYYY-MM-DD') })
    }

    handleSubmit = (e) => {
        e.preventDefault()
        this.setState({ loading: true })
        /*
        if (this.state.PersonaPropone.Nombre.length < 3) {
            Notify(
                3,
                'tc',
                'Error: Debe seleccionar quien propone',
                this.refs
            )
            return
        }

        if (this.state.Nombre.length < 3) {
            

            Notify(3, 'tc', 'Error: Escriba un nombre', this.refs)
            return
        }

        if (this.state.Paterno.length < 3) {
            
            Notify(3, 'tc', 'Error: Escriba un apellido Paterno', this.refs)
            return
        }

        if (this.state.Materno.length < 1) {
            

            this.notify(
                3,
                'tc',
                'Error: Escriba un apellido Materno',
                this.refs
            )
            return
        }

        if (this.state.idEstadoCivil.value === 'undefined') {
            this.notify(3, 'tc', 'Error: Escriba estado civil', this.refs)
            return
        }

        if (this.state.idNivelEstudio.value === 'undefined') {
            this.notify(
                3,
                'tc',
                'Error: Escriba nivel de estudios',
                this.refs
            )
            return
        }
        */

        const token = global.access_token

        let Candidato = {}

        let Propone = {}

        if (this.state.PersonaPropone.idQuienPropone === null) {
            Propone = this.state.PersonaPropone
        } else {
            Propone = {
                idQuienPropone: this.state.PersonaPropone.idQuienPropone,
                Dependencia: this.state.PersonaPropone.Dependencia,
                OtraDependencia: this.state.PersonaPropone.OtraDependencia,
            }
        }

        let idPlantilla = null
        let PLAZA = ''
        let DENOMINACION_TABULAR = ''
        let NIVEL = ''
        let MUNICIPIO = ''
        let TIPO = ''
        let DEPENDENCIA_ORGANISMO = ''

        if (this.state.Movimiento.value > 1) {
            if (this.state.Plaza.id === '999999') {
                idPlantilla = null
                PLAZA = this.state.NuevaPlaza.NumSerie
                DENOMINACION_TABULAR = this.state.NuevaPlaza.Puesto
                NIVEL = this.state.NuevaPlaza.Nivel.value
                MUNICIPIO = this.state.NuevaPlaza.Municipio.value
                TIPO = this.state.NuevaPlaza.TipoPlaza.value
                DEPENDENCIA_ORGANISMO = this.state.idDependencia.value
            } else {
                idPlantilla = this.state.Plaza.id
            }
        } else {
            idPlantilla = 1
        }

        if (this.state.id === null) {
            Candidato = {
                idCandidato: null,
                INE: this.state.INE,
                CURP: this.state.CURP,
                Nombre: this.state.Nombre,
                Paterno: this.state.Paterno,
                Materno: this.state.Materno,
                idNivelEstudios: this.state.idNivelEstudio.value,
                idPerfilCarrera: this.state.idPerfilCarrera.value,
                idEstadoCivil: this.state.idEstadoCivil.value,
                GradoObtenido: this.state.GradoObtenido,
                GradoMaestria: this.state.GradoMaestria,
                Celular1: this.state.Celular,
                TelCasa: this.state.TelCasa,
                CorreoElectronico: this.state.CorreoElectronico,
                UltimoTrabajo: this.state.UltimoTrabajo,
                idMunicipio: this.state.Municipio.value,
                Facebook: this.state.Facebook,
                Twitter: this.state.Twitter,
                Instagram: this.state.Instagram,
                idSexo: this.state.Sexo.value,
                Comentarios: this.state.Comentarios,
                IdValidado: 0,
                isJefatura: this.state.Movimiento.value > 1 ? 0 : 1,
                Propone:
                    this.state.PersonaPropone.Nombre +
                    '' +
                    this.state.PersonaPropone.Paterno +
                    ' ' +
                    this.state.PersonaPropone.Materno,
                PuestoPropone: this.state.PersonaPropone.Puesto,
                DependenciaPropone: this.state.PersonaPropone.Dependencia,
                isRecomendado: this.state.isRecomendado ? 1 : 0,
                NivelPropuesto:
                    this.state.Movimiento.value === 1
                        ? this.state.NivelPropuesto.value
                        : this.state.Plaza.value === 999999
                            ? this.state.NuevaPlaza.Nivel.value
                            : this.state.Plaza.Nivel,
            }
        } else {
            Candidato = {
                idCandidato: this.state.id,
                isRecomendado: this.state.isRecomendado ? 1 : 0,
            }
        }

        const DataSend =
            this.state.Movimiento.value < 3
                ? {
                    idSolicitud: this.state.idSolicitud,
                    idPlantilla,
                    PLAZA,
                    DENOMINACION_TABULAR,
                    NIVEL,
                    MUNICIPIO,
                    TIPO,
                    DEPENDENCIA_ORGANISMO,
                    Candidato,
                    idMovimiento: this.state.Movimiento.value,
                    FechaRecepcion: this.state.FechaRecepcion,
                    FechaMovimiento: this.state.FechaMovimiento,
                    FolioDependencia: this.state.FolioDependencia,
                    idMovimientoStatus:
                        this.state.Movimiento.value === 1 ? 1 : 2,
                    Propone,
                }
                : this.state.Movimiento.value === 3
                    ? {
                        Baja: this.state.Baja,
                        idSolicitud: this.state.idSolicitud,
                        Promocion: this.state.Promocion,
                        idMovimiento: this.state.Movimiento.value,
                        FechaRecepcion: this.state.FechaRecepcion,
                        FechaMovimiento: this.state.FechaMovimiento,
                        FolioDependencia: this.state.FolioDependencia,
                        idMovimientoStatus:
                            this.state.Movimiento.value === 1 ? 1 : 2,
                        Propone,
                    }
                    : {
                        Baja: this.state.Baja,
                        idSolicitud: this.state.idSolicitud,
                        idMovimiento: this.state.Movimiento.value,
                        FechaRecepcion: this.state.FechaRecepcion,
                        FechaMovimiento: this.state.FechaMovimiento,
                        FolioDependencia: this.state.FolioDependencia,
                        idMovimientoStatus:
                            this.state.Movimiento.value === 1 ? 1 : 2,
                        Propone,
                    }

        EjecutarApi(DataSend, 'setPropuesta', token)
            .then(function (res) {
                return res
            })
            .then((res) => {
                if (res.success) {
                    this.setState({ loading: false, isVisible: true })
                    Notify(
                        2,
                        'tc',
                        'La porpuesta ha sido capturada con exito!',
                        this.refs
                    )
                    this.clearForm()
                }
            })
            .catch((e) => {
                this.setState({ isVisible: false, loading: false })
                Notify(3, 'tc', e.message, this.refs)
                return
            })

        var parametros = {
            filtered: [{ id: 'sm.idSolicitud', value: this.state.idSolicitud }],
            tipo: 'and',
            page: 0,
            pageSize: 10,
        }
        EjecutarApi(parametros, 'getPropuestas', token)
            .then(function (res) {
                return res
            })
            .then((res) => {
                if (res.results) {
                    var data = res.data
                    this.setState({

                        ListadoPropuestas: data.map((item, key) => {
                            return {
                                id: item.id,
                                Eliminar: (
                                    <Button
                                        onClick={() => {
                                            this.handleEliminar(item.id)
                                        }}
                                        color="danger"
                                        size="sm"
                                        className="btn-icon btn-link remove"
                                    >
                                        x
                                    </Button>
                                ),
                                Movimiento: item.Movimiento.label,
                                Dependencia: item.Dependencia_organismo,
                                Candidato: item.Candidato.label,
                                Secuencial: item.Plaza,
                                Estatus: item.MovimientoStatus.label,
                                Denominacion_funcional:
                                    item.Denominacion_funcional,
                                Tipo: item.Tipo,
                                Nivel: item.Nivel,
                                data: item,
                            }
                        }),
                    })
                }
            })
            .catch((e) => {
                this.setState({ loading: false })
            })
    }

    clearForm = () => {
        this.setState({
            FolioDependencia: '',
            isRecomendado: false,
            catPlazas: {
                value: '',
                label: 'Seleccione plaza',
            },
            Movimiento: {
                value: 'undefined',
                label: 'Seleccione',
            },
            Plaza: {
                value: 'undefined',
                label: '',
            },
            NuevaPlaza: {
                NumSerie: '',
                Puesto: '',
                Municipio: {
                    value: 0,
                    label: '',
                },
                Nivel: {
                    value: 0,
                    label: '',
                },
                TipoPlaza: {
                    value: 0,
                    lanel: '',
                },
            },
            idEstadoCivil: {
                value: 'undefined',
                label: 'Seleccione',
            },
            idPerfilCarrera: {
                value: 1,
                label: 'No definido',
            },
            NivelPropuesto: {
                value: 'undefined',
                label: 'Seleccione',
            },
            PersonaPropone: {
                id: null,
                Nombre: '',
                Paterno: '',
                Materno: '',
                Puesto: '',
                Cargo: '',
                Dependencia: {
                    value: null,
                    label: '',
                },
            },
            INE: '',
            CURP: '',
            id: null,
            Nombre: '',
            Paterno: '',
            Materno: '',
            TelCasa: '',
            Celular: '',
            CorreoElectronico: '',
            Facebook: '',
            Twitter: '',
            Instagram: '',
            GradoObtenido: '',
            GradoMaestria: '',
            UltimoTrabajo: '',
            Municipio: {
                value: 61,
                label: 'No Definido',
            },
            Sexo: {
                value: 1,
                label: 'No Definido',
            },
            showDatosPersonales: false,
            readPersonales: false,
        })
    }

    setMovimiento = (e) => {

        this.setState({ Movimiento: e })


        if (this.state.idDependencia.value !== '') {
            this.getPlazas(e.value, this.state.idDependencia.label)
        }
    }

    getPlazasOcupadas = (Dependencia) => {
        const token = global.access_token

        var data = {}

        this.setState({ loadingOcupadas: true, firstOcupada: false })

        data = {
            page: 0,
            tipo: 'and',
            pageSize: 10,
            sorted: [],
            filtered: [
                {
                    id: 'Dependencia_organismo',
                    value: Dependencia,
                },
                {
                    id: 'Estatus',
                    value: 'OCUPADA',
                },
            ],
        }


        EjecutarApi(data, 'getPlantillas', token)
            .then(function (res) {
                return res
            })
            .then((items) => {


                if (items.results) {
                    const dataTable = items.data

                    this.setState({
                        loadingOcupadas: false,
                        loading: false,
                        totalOcupadas: items.total,
                        PlazasOcupadas: dataTable.map((prop, key) => {
                            return {
                                id: prop.uid,
                                key: prop.uid,
                                label:
                                    prop.Plaza +
                                    ' - ' +
                                    prop.Denominacion_funcional,
                                Dependencia_organismo: prop.Dependencia,
                                Nivel: prop.Nivel,
                                Tipo: prop.Tipo,
                                Nombre: prop.Nombre,
                                Denominacion_tabular: prop.Denominacion_tabular,
                                Denominacion_funcional:
                                    prop.Denominacion_funcional,
                                Estatus: prop.Estatus,
                                Municipio: prop.Municipio,
                                FechaVacante: prop.Fecha_vacante,
                                NombreBtn: (
                                    <Button
                                        className="btn-link"
                                        color="primary"
                                        onClick={() => {
                                            this.setBaja(prop)
                                        }}
                                    >{`${prop.Nombre}`}</Button>
                                ),
                                data: prop,
                            }
                        }),
                    })
                }
            })
            .catch((e) => {

                Notify(
                    3,
                    'tc',
                    `Error al cargar plazas: ${e.message}`,
                    this.refs
                )
                this.setState({ loadingOcupadas: false })
            })
    }
    getPlazasVacantes = (Dependencia) => {
        const token = global.access_token

        var data = {}

        this.setState({ loadingVacantes: true, firstVacante: false })

        data = {
            page: 0,
            tipo: 'and',
            pageSize: 10,
            sorted: [],
            filtered: [
                {
                    id: 'Dependencia_organismo',
                    value: Dependencia,
                },
                {
                    id: 'Estatus',
                    value: 'VACANTE',
                },
            ],
        }


        EjecutarApi(data, 'getPlantillas', token)
            .then(function (res) {
                return res
            })
            .then((items) => {


                if (items.results) {
                    const dataTable = items.data

                    this.setState({
                        loadingVacantes: false,
                        loading: false,
                        totalVacantes: items.total,
                        PlazasVacantes: dataTable.map((prop, key) => {
                            return {
                                id: prop.uid,
                                key: prop.uid,
                                label:
                                    prop.Plaza +
                                    ' - ' +
                                    prop.Denominacion_funcional,
                                Dependencia_organismo: prop.Dependencia,
                                Nivel: prop.Nivel,
                                Tipo: prop.Tipo,
                                Nombre: prop.Nombre,
                                Denominacion_tabular: prop.Denominacion_tabular,
                                Denominacion_funcional:
                                    prop.Denominacion_funcional,
                                Estatus: prop.Estatus,
                                Municipio: prop.Municipio,
                                FechaVacante: prop.Fecha_vacante,
                                NombreBtn: (
                                    <Button
                                        className="btn-link"
                                        color="primary"
                                        onClick={() => {
                                            this.setBaja(prop)
                                        }}
                                    >{`${prop.Nombre}`}</Button>
                                ),
                                data: prop,
                            }
                        }),
                    })
                }
            })
            .catch((e) => {

                Notify(
                    3,
                    'tc',
                    `Error al cargar plazas: ${e.message}`,
                    this.refs
                )
                this.setState({ loadingVacantes: false })
            })
    }

    getPlazas = (Movimiento, Dependencia) => {
        const token = global.access_token

        var data = {}

        this.setState({ loadingPlazas: true })



        if (Movimiento === 2 || Movimiento === 3) {
            data = {
                page: 0,
                tipo: 'and',
                pageSize: 99999,
                sorted: [],
                filtered: [
                    {
                        id: 'Dependencia_organismo',
                        value: Dependencia,
                    },
                    {
                        id: 'Estatus',
                        value: 'VACANTE',
                    },
                ],
            }
        } else {
            data = {
                page: 0,
                tipo: 'and',
                pageSize: 99999,
                sorted: [],
                filtered: [
                    {
                        id: 'Dependencia_organismo',
                        value: Dependencia,
                    },
                    {
                        id: 'Estatus',
                        value: 'OCUPADA',
                    },
                ],
            }
        }


        EjecutarApi(data, 'getPlantillas', token)
            .then(function (res) {
                return res
            })
            .then((items) => {


                if (items.results) {
                    const dataTable = items.data

                    this.setState(
                        {
                            loadingPlazas: false,
                            loading: false,
                            total: items.total,
                            catPlazas: dataTable.map((prop, key) => {
                                return {
                                    id: prop.uid,
                                    key: prop.uid,
                                    label:
                                        prop.Plaza +
                                        ' - ' +
                                        prop.Denominacion_funcional,
                                    Dependencia_organismo: prop.Dependencia,
                                    Nivel: prop.Nivel,
                                    Tipo: prop.Tipo,
                                    Denominacion_tabular:
                                        prop.Denominacion_tabular,
                                    Denominacion_funcional:
                                        prop.Denominacion_funcional,
                                    Estatus: prop.Estatus,
                                    Municipio: prop.Municipio,
                                    FechaVacante: prop.Fecha_vacante,
                                    data: prop,
                                }
                            }),
                        },
                        () => {
                            const Data = this.state.catPlazas
                            Data.push({ id: '999999', label: 'Nueva Plaza' })
                            this.setState({ catPlazas: Data })


                        }
                    )
                }
            })
            .catch((e) => {

                Notify(
                    3,
                    'tc',
                    `Error al cargar plazas: ${e.message}`,
                    this.refs
                )
                this.setState({ loadingPlazas: false })
            })
    }

    setDependencia = (e) => {


        this.setState({
            Plaza: { value: 'undefined', label: '' },
            idDependencia: {
                value: e.id,
                label: e.label,
            },
        })

        if (this.state.Movimiento.value >= 3) {

            this.getPlazasOcupadas(e.label)
        } else {
            this.setState({
                loadingPlazas: true,
                Plaza: { value: 'undefined', label: '' },
                idDependencia: {
                    value: e.id,
                    label: e.label,
                },
            })
            this.getPlazas(this.state.Movimiento.value, e.label)
        }
    }

    setSexo = (e) => {


        this.setState({
            sexo: {
                value: e.value,
                label: e.label,
            },
        })
    }

    setEstadoCivil = (e) => {


        this.setState({
            CandidatoRSestado_civil: {
                value: e.value,
                label: e.label,
            },
        })
    }
    setNivelEstudios = (e) => {


        this.setState({
            CandidatoRSestudios: {
                value: e.value,
                label: e.label,
            },
        })
    }

    setPerfilCarrera = (e) => {


        this.setState({
            CandidatoRSPerfilCarrera: {
                value: e.value,
                label: e.label,
            },
        })
    }

    onErrorRedirect = (error) => {

    }

    setPlaza = (Plaza) => {


        if (Plaza.id === '999999') {

            this.toggleNuevaPlaza()
        }

        this.setState({ Plaza })
    }

    setNuevaPlaza = (Plaza) => {
        this.setState({ NuevaPlaza: Plaza })
        this.toggleNuevaPlaza()
    }

    setNewProponeSolicitud = (Persona) => {

        this.setState({
            PersonaProponeSolicitud: Persona,
            PersonaPropone: Persona,
        })
    }

    setNewPerson = (Persona) => {
        this.setState({
            INE: '',
            CURP: '',
            id: null,
            Nombre: Persona.Nombre,
            Paterno: Persona.Paterno,
            Materno: Persona.Materno,
            TelCasa: '',
            Celular: '',
            CorreoElectronico: '',
            Facebook: '',
            Twitter: '',
            Instagram: '',
            GradoObtenido: '',
            GradoMaestria: '',
            UltimoTrabajo: '',
            idEstadoCivil: {
                value: 'undefined',
                label: 'Seleccione',
            },
            idNivelEstudio: {
                value: 'undefined',
                label: 'Seleccione',
            },
            Municipio: {
                value: 61,
                label: 'No Definido',
            },
            Sexo: {
                value: 1,
                label: 'No Definido',
            },
            idStatus: {
                value: 'undefined',
                label: 'undefined',
            },
            showDatosPersonales: true,
            readPersonales: false,
        })
    }

    setPropone = (PersonaPropone) => {

        this.setState({ PersonaPropone })
    }
    setProponeSolicitud = (PersonaPropone) => {

        this.setState({
            dataSolicitud: { dataSolicitud: PersonaPropone },
            PersonaPropone: PersonaPropone,
        })
        // this.setState({ PersonaPropone : PersonaPropone });
    }

    setPersona = (perfil) => {

        this.setState({
            id: perfil.id,
            Nombre: perfil.Nombre,
            Paterno: perfil.Paterno,
            Materno: perfil.Materno,
            INE: perfil.INE,
            CURP: perfil.CURP,
            Celular1: perfil.Celular1,
            Celular2: perfil.Celular2,
            CorreoElectronico: perfil.CorreoElectronico,
            Facebook: perfil.Facebook,
            Instagram: perfil.Instagram,
            Twitter: perfil.Twitter,
            GradoObtenido: perfil.GradoObtenido,
            GradoMaestria: perfil.GradoMaestria || '',
            Telcasa: perfil.TelCasa,
            UltimoTrabajo: perfil.UltimoTrabajo,
            idStatus: perfil.estatus,
            idEstadoCivil: perfil.estado_civil,
            idNivelEstudio: perfil.estudios,
            Municipio: perfil.municipios,
            Sexo: perfil.sexo,
            showDatosPersonales: true,
            readPersonales: true,
        })
    }

    setBaja = (Baja) => {
        this.setState({ Baja })
    }

    setPromocion = (Promocion) => {
        this.setState({ Promocion })
    }
    setSolicitudDependencia = (e) => {


        this.setState({
            SolicitudidDependencia: e.id,
            SolicitudDependencia: e.label,
            Plaza: { value: 'undefined', label: '' },
            idDependencia: {
                value: e.id,
                label: e.label,
            },
        })
    }
    setFolioSolicitud = (e) => {

        this.setState({ SolicitudFolioSolicitud: e.target.value.toUpperCase() })
    }
    setFechaOficioSolicitud = (e) => {

        this.setState({
            SolicitudFechaOficio: moment(e._d).format('YYYY-MM-DD'),
        })
    }
    setFechaRecepcionSolicitud = (e) => {

        this.setState({
            SolicitudFechaRecepcion: moment(e._d).format('YYYY-MM-DD'),
        })
    }
    saveSolicitud = () => {
        this.setState({
            loading: true,
        })
        const token = global.access_token

        var parametros = {}
        if (this.state.PersonaProponeSolicitud.idQuienPropone) {
            parametros = {
                Archivo: formData,
                idQuienPropone: this.state.PersonaProponeSolicitud
                    .idQuienPropone,
                FechaSolicitud: this.state.SolicitudFechaOficio,
                FechaRecepcion: this.state.SolicitudFechaRecepcion,
                FolioOficio: this.state.SolicitudFolioSolicitud,
                idDependencia: this.state.SolicitudidDependencia,
                propone: {
                    Nombre: this.state.PersonaProponeSolicitud.Nombre,
                    Paterno: this.state.PersonaProponeSolicitud.Paterno,
                    Materno: this.state.PersonaProponeSolicitud.Materno,
                    Puesto: this.state.PersonaProponeSolicitud.Puesto,
                    idDependencia: this.state.PersonaProponeSolicitud
                        .Dependencia.value,
                },
            }
        } else {
            parametros = {
                Archivo: formData,
                FechaSolicitud: this.state.SolicitudFechaOficio,
                FechaRecepcion: this.state.SolicitudFechaRecepcion,
                FolioOficio: this.state.SolicitudFolioSolicitud,
                idDependencia: this.state.SolicitudidDependencia,
                propone: {
                    Nombre: this.state.PersonaProponeSolicitud.Nombre,
                    Paterno: this.state.PersonaProponeSolicitud.Paterno,
                    Materno: this.state.PersonaProponeSolicitud.Materno,
                    Puesto: this.state.PersonaProponeSolicitud.Puesto,
                    idDependencia: this.state.PersonaProponeSolicitud
                        .Dependencia.value,
                },
            }
        }
        var propone = {
            propone: {
                Nombre: this.state.PersonaProponeSolicitud.Nombre,
                Paterno: this.state.PersonaProponeSolicitud.Paterno,
                Materno: this.state.PersonaProponeSolicitud.Materno,
                Puesto: this.state.PersonaProponeSolicitud.Puesto,
                idDependenciaPropone: this.state.PersonaProponeSolicitud
                    .Dependencia.value,
            },
        }

        var formData = new FormData()
        formData.append(
            'idQuienPropone',
            this.state.PersonaProponeSolicitud.idQuienPropone
        )
        formData.append('FechaSolicitud', this.state.SolicitudFechaOficio)
        formData.append('FechaRecepcion', this.state.SolicitudFechaRecepcion)
        formData.append('FolioOficio', this.state.SolicitudFolioSolicitud)
        formData.append('idDependencia', this.state.SolicitudidDependencia)
        formData.append('Archivo', this.state.file[0], this.state.file[0].name)
        formData.append('Nombre', this.state.PersonaProponeSolicitud.Nombre)
        formData.append('Paterno', this.state.PersonaProponeSolicitud.Paterno)
        formData.append('Materno', this.state.PersonaProponeSolicitud.Materno)
        formData.append('Puesto', this.state.PersonaProponeSolicitud.Puesto)
        formData.append(
            'idDependenciaPropone',
            this.state.PersonaProponeSolicitud.Dependencia.value
        )

        const requestInfo = {
            method: 'POST',
            body: formData,
            headers: new Headers({
                Authorization: `Bearer ${token}`,
            }),
        }

        fetch(URL_PUBLICA + 'setSolicitudes', requestInfo)
            .then((res) => {
                if (res.ok) {
                    return res.json()
                }
            })
            .then((res) => {

                if (res.success) {
                    this.setState({
                        loading: false,
                        areaPropuesta: true,
                        idSolicitud: res.idSolicitud,
                    })

                    Notify(
                        2,
                        'tc',
                        `!! El Registro fue guardado exitosamente!!`,
                        this.refs
                    )
                }
            })
            .catch((e) => {

                Notify(
                    3,
                    'tc',
                    `Error en Agregar Solicitud: ${e.error}`,
                    this.refs
                )
                this.setState({
                    loading: false,
                })
            })
    }

    handleVerificaCURP = (e) => {

    }
    handleVerificaINE = (e) => {

    }
    handleImageChange(e) {
        e.preventDefault()
        let reader = new FileReader()
        let file = e.target.files[0]

        reader.onloadend = () => {
            this.setState({ file: file })
        }
        reader.readAsDataURL(file)
    }
    handleClick() {
        this.refs.fileInput.click()
    }
    onFilesAdded = (file) => {

        this.setState({ file });

    }
    handleEliminar = (ID) => {
        this.setState({ loading: true })

        const token = getVars('Token').access_token

        EjecutarApi({ id: ID }, 'deletePropuesta', token)
            .then(function (res) {
                return res
            })
            .then((res) => {

                if (res.results) {
                    var data = res.data
                    this.setState({ loading: false })
                    Notify(2, 'tc', `Registro Eliminado: `, this.refs)
                }
            })
            .catch((e) => {

                this.setState({ loading: false })
                Notify(
                    3,
                    'tc',
                    `Error al eliminar la propuesta: ${e.error}`,
                    this.refs
                )
            })

        var parametros = {
            filtered: [{ id: 'sm.idSolicitud', value: this.state.idSolicitud }],
            tipo: 'and',
            page: 0,
            pageSize: 10,
        }

        EjecutarApi(parametros, 'getPropuestas', token)
            .then(function (res) {
                return res
            })
            .then((res) => {

                if (res.results) {
                    var data = res.data
                    this.setState({
                        totalListadoPropuesta: res.total,
                        ListadoPropuestas: data.map((item, key) => {
                            return {
                                id: item.id,
                                Eliminar: (
                                    <Button
                                        onClick={() => {
                                            this.handleEliminar(item.id)
                                        }}
                                        color="danger"
                                        size="sm"
                                        className="btn-icon btn-link remove"
                                    >
                                        x
                                    </Button>
                                ),
                                Movimiento: item.Movimiento.label,
                                Dependencia: item.Dependencia_organismo,
                                Candidato: item.Candidato.label,
                                Secuencial: item.Plaza,
                                Estatus: item.MovimientoStatus.label,
                                Denominacion_funcional:
                                    item.Denominacion_funcional,
                                Denominacion_tabular: item.Denominacion_tabular,
                                Municipio: item.Municipio,
                                Tipo: item.Tipo,
                                Nivel: item.Nivel,

                                data: item,
                            }
                        }),
                    })
                }
            })
            .catch((e) => {

                this.setState({ loading: false })
            })
    }
    getCURP = () => {
        this.setState({ loading: true });

        if (!this.state.CURP) {
            this.setState({ loading: false });
            Notify(
                3,
                'tc',
                `Error: CURP inválida!`,
                this.refs
            );
            return true
        }

        if (this.state.CURP.length != 18) {
            this.setState({ loading: false });
            Notify(
                3,
                'tc',
                `Error: CURP inválida!`,
                this.refs
            );

            return true
        }

        const CURP = this.state.CURP.toUpperCase();
        var sexo_obtenido

        fetch(
            `https://seguimiento.guanajuato.gob.mx/apiinformacionsocial/api/renapo/porcurp/pL@t_1n|Run$28/${CURP}`
        )
            .then((res) => res.json())
            .then((dataRS) => {
                this.setState({ loading: false });

                if (dataRS.Mensaje == 'OK') {
                    //aqui
                    var FN = dataRS.Resultado.fechNac.split('/');

                    sexo_obtenido = dataRS.Resultado.sexo;
                    this.setState({
                        Nombre: dataRS.Resultado.nombres,
                        Paterno: dataRS.Resultado.apellido1,
                        Materno: dataRS.Resultado.apellido2,
                        Sexo: dataRS.Resultado.sexo === 'H' ? '2' : '1', // { value: "M", label:"MASCULINO"} : { value: "F", label:"FEMENINO"} , //{ label: dataRS.Resultado.sexo, value: dataRS.Resultado.sexo === 'H' ? 'M' : 'F' },//"M",//dataRS.Resultado.sexo === 'H' ? 'M' : 'F',
                        FechaNacimiento: FN[2] + '-' + FN[1] + '-' + FN[0]
                    });

                } else {
                    this.setState({ loading: false });
                    if (
                        dataRS.Mensaje ===
                        'La CURP no se encuentra en la base de datos'
                    ) {

                        Notify(
                            3,
                            'tc',
                            `Error en CURP...! ${dataRS.Mensaje}`,
                            this.refs
                        );

                    } else if (
                        dataRS.Mensaje ===
                        'El campo CURP: No cumple con el formato especificado.'
                    ) {
                        Notify(
                            3,
                            'tc',
                            `Error en CURP...! ${dataRS.Mensaje}`,
                            this.refs
                        );
                    } else {

                    }
                    return true
                }

            })
            .catch((err) => {
                this.setState({ loading: false });

            })
        //
    }
    fetchMore = () => {

        if (this.state.PorAutorizarPage * 10 >= this.state.totalListadoPropuesta) {

            this.setState({ hasMore: false });
            return;
        }

        this.setState({
            hasMore: true
        });

        var parametros = {
            filtered: [
                {
                    id:
                        'sm.idSolicitud',
                    value: this.props.location.state.id.id,
                },
            ],
            tipo: 'and',
            page: this.state.PorAutorizarPage + 1,
            pageSize: 10,

        }

        const token = global.access_token; //localstorage



        EjecutarApi(
            parametros,
            'getPropuestas',
            token
        )
            .then(function (
                res
            ) {
                return res
            })
            .then((res) => {
                if (res.results) {
                    var data =
                        res.data
                    this.setState(
                        {
                            loading: false,
                            ListadoPropuestas: this.state.ListadoPropuestas.concat(res.data),
                            PorAutorizarPage: this.state.PorAutorizarPage + 1,
                            totalListadoPropuesta: res.total,

                        }
                    )
                }
            })
            .catch((e) => {
                this.setState({
                    loading: false,
                })
            })

    };
    handleCandidatoEdit = (idCandidato, KeyArray) => {


        const token = global.access_token;
        // if(this.ListadoPropuestas[KeyArray].Movimiento.value!==4)
        // {
        this.setState({ loading: true, showData: true, CandidatoRSkeyarray: KeyArray });
        const data = {
            id: idCandidato
        };

        var url = new URL(URL_PUBLICA + 'candidatos');

        Object.keys(data).forEach(key => {
            const Valor = data[key];
            if (Valor !== '') {
                url.searchParams.append(key, data[key]);
            }
        });

        fetch(url, { headers: new Headers({ Authorization: `Bearer ${token}` }) })
            .then(response => {
                switch (response.status) {
                    case 200:

                        break;

                    default:
                        this.props.onError('Error al consultar candiadtos');
                        break;
                }

                if (response.ok) {
                    return response.json();
                }
                throw new Error('Ocurrio un error: candidatos: ', response.statusText);
            })
            .then(items => {
                if (items.success) {

                    this.setState({
                        showData: false,
                        showVobo: false,
                        showValidaciones: false,
                        showAutorizar: false,
                        horizontalTabs: 'home',
                        showFinanzas: false,
                        CandidatoRSid: items.data[0].id,
                        CandidatoRSINE: items.data[0].INE,
                        CandidatoRSCURP: items.data[0].CURP,
                        CandidatoRSNombre: items.data[0].Nombre,
                        CandidatoRSPaterno: items.data[0].Paterno,
                        CandidatoRSMaterno: items.data[0].Materno,
                        CandidatoRSGradoObtenido: items.data[0].GradoObtenido ? items.data[0].GradoObtenido : '',
                        CandidatoRSCelular1: items.data[0].Celular1,
                        CandidatoRSCelular2: items.data[0].Celular2,
                        CandidatoRSTelCasa: items.data[0].TelCasa,
                        CandidatoRSCorreoElectronico: items.data[0].CorreoElectronico,
                        CandidatoRSUltimoTrabajo: items.data[0].UltimoTrabajo,
                        CandidatoRSNivelPopuesto: items.data[0].NivelPopuesto,
                        CandidatoRSmunicipios: { value: items.data[0].municipios.value, label: items.data[0].municipios.label },
                        CandidatoRSestudios: {
                            value: items.data[0].estudios.value,
                            label: items.data[0].estudios.label,
                        },
                        CandidatoRSestado_civil: {
                            value: items.data[0].estado_civil.value,
                            label: items.data[0].estado_civil.label
                        },
                        CandidatoRSestatus: {
                            value: items.data[0].estatus.value,
                            label: items.data[0].estatus.label
                        },
                        CandidatoRSsexo: {
                            value: items.data[0].sexo.value,
                            label: items.data[0].sexo.label
                        },
                        CandidatoRSPerfilCarrera: {
                            value: items.data[0].PerfilCarrera.value,
                            label: items.data[0].PerfilCarrera.label
                        },
                        CandidatoRSFacebook: items.data[0].Facebook,
                        CandidatoRSTwitter: items.data[0].Twitter,
                        CandidatoRSInstagram: items.data[0].Instagram,
                        CandidatoRSComentarios: items.data[0].Comentarios,
                        CandidatoRSkeyarray: KeyArray,
                        FinanzasFechaEnvio: this.state.ListadoPropuestas[KeyArray].FinanzasFechaEnvio,
                        FinanzasFechaAviso: this.state.ListadoPropuestas[KeyArray].FinanzasFechaAviso,
                        FinanzasComentarios: this.state.ListadoPropuestas[KeyArray].Comentarios,
                        CandidatoRSMovimientoStatus: this.state.ListadoPropuestas[KeyArray].MovimientoStatus,
                        loading: false
                    });

                }
            })
            .catch(e => {


                this.setState({ loading: false });
            });


    }
    setBack = () => {
        this.setState({ showData: false });
    };
    searchUser = () => {
        const data = {
            Nombre: this.state.Nombre,
            Paterno: this.state.Paterno,
            Materno: this.state.Materno,
        };

        var url = new URL(URL_PUBLICA + 'candidatos');

        Object.keys(data).forEach(key => {
            const Valor = data[key];
            if (Valor !== '') {
                url.searchParams.append(key, data[key]);
            }
        });

        const token = global.access_token;

        fetch(url, { headers: new Headers({ Authorization: `Bearer ${token}` }) })
            .then(response => {
                switch (response.status) {
                    case 200:

                        break;

                    default:
                        this.props.onError('Error al consultar candiadtos');
                        break;
                }

                if (response.ok) {
                    return response.json();
                }
                throw new Error('Ocurrio un error: candidatos: ', response.statusText);
            })
            .then(items => this.setState({ Resultados: items.data, TotalRegistros: items.data.length }))
            .catch(e => {

                this.props.onError(e.message);
            });
    };
    handleSustituirFolio = () => {
        //localStorage.removeItem('idSolicitud');
        setVars('idSolicitud', this.state.Solicitud.id);
        this.props.history.push({
            pathname: '/gto/admin/oficialiaSustituirFolio'
        });
    }
    handleAgregarMovimiento = (e) => {

        //this.setState({showAdd:true});
        var cat = sessionStorage.getItem('idSolicitud');

        if (cat) {
            sessionStorage.removeItem('idSolicitud');
        }
        setVars('idSolicitud', this.state.Solicitud.id);
        cat = sessionStorage.getItem('idSolicitud');

        this.props.history.push({
            pathname: '/gto/admin/oficialiaAddMovimiento',
            IDSolicitud: this.state.Solicitud.id
        }); ///gto/admin/oficialiaEdit"
    }
    paso1Guardar = () => {
        this.setState({ loading: true });
        this.setState({
            dataVoBo: {
                Candidato: `${this.state.CandidatoRSNombre} ${this.state.CandidatoRSPaterno} ${this.state.CandidatoRSMaterno}`,
                Dependencia: '',
                FechaMovimiento: '',
                Movimiento: '',
                Municipio: '',
                Nivel: '',
                Plaza: '',
                PuestoQuienPropone: '',
                QuienPropone: "dependencia dependencia, No definido",
                QuienProponeConsideracion: null,
                btnCandidato: {},
                dataCandidato: [],
                id: 0,
                idMovimientoStatus: "Por Autorizar",
                key: 0

            }
        })
        ///Carga de CV
        this.sendFile();
        ///llenado para guardar datos

        if (this.state.CandidatoRSNombre.length < 3) {
            Notify(3, 'tc', 'Error: Nombre requerido', this.refs);
            return true;
        }

        if (this.state.CandidatoRSPaterno.length < 3) {
            Notify(3, 'tc', 'Error: Apellido Paterno requerido', this.refs);
            return true;
        }

        if (this.state.CandidatoRSMaterno.length < 3) {
            Notify(3, 'tc', 'Error: Apellido Materno requerido', this.refs);
            return true;
        }

        const token = global.access_token;

        this.setState({ loading: true });

        const data = {
            INE: this.state.CandidatoRSINE,
            CURP: this.state.CandidatoRSCURP,
            id: this.state.CandidatoRSid,
            Nombre: this.state.CandidatoRSNombre,
            Paterno: this.state.CandidatoRSPaterno,
            Materno: this.state.CandidatoRSMaterno,
            TelCasa: this.state.CandidatoRSTelCasa,
            Celular1: this.state.CandidatoRSCelular1,
            CorreoElectronico: this.state.CandidatoRSCorreoElectronico,
            Facebook: this.state.CandidatoRSFacebook,
            Twitter: this.state.CandidatoRSTwitter,
            Instagram: this.state.CandidatoRSInstagram,
            GradoObtenido: this.state.CandidatoRSGradoObtenido,
            GradoMaestria: this.state.CandidatoRSGradoMaestria,
            UltimoTrabajo: this.state.CandidatoRSUltimoTrabajo,
            idEstadoCivil: this.state.CandidatoRSestado_civil.value,
            idNivelEstudios: this.state.CandidatoRSestudios.value,
            idMunicipio: this.state.CandidatoRSmunicipios.value,
            idPerfilCarrera: this.state.CandidatoRSPerfilCarrera.value,
            idSexo: this.state.CandidatoRSsexo.value,
            Comentarios: this.state.CandidatoRSComentarios,
            tieneCV: this.state.CandidatoRStieneCV
        };



        EjecutarApi(data, 'updCandidato', token)
            .then(function (res) {
                return res;
            })
            .then(items => {


                this.setState({ loading: false });



            })
            .catch(e => {

                Notify(3, 'tc', `Error: al actualizar datos: ${e.message}`, this.refs);
                this.setState({ loading: false });
            });


    }

    btnAuthorizarValidar = item => {


        const token = global.access_token; //localstorage
        this.setState({ loading: true });
        const formData = new FormData();
        formData.append('idMovimientoStatus', 3);
        formData.append('id', item.ListadoPropuestas[this.state.CandidatoRSkeyarray].id);

        fetch(URL_PUBLICA + `seguimientoPropuesta`, {
            method: 'POST',
            headers: new Headers({ Authorization: `Bearer ${token}` }),
            body: formData,
        })
            .then(response => {

                if (response.ok) {
                    return response.json();
                }
                throw new Error('Ocurrio un error al generar la validación: ', response.statusText);
            })
            .then(items => {

                this.setState({ showData: true, loading: false });

                /*var data = this.state.dataRsPlus;
                data.find((o, i) => {
            	
                    if (o.id === item.id) {
                        // here you should add some custom code so you can delete the data
                        // from this component and from your server as well
                        data.splice(i, 1);
                	
                        return true;
                    }
                    return false;
                });*/

                Notify(2, 'tc', 'Registro Autorizado!', this.refs);

                //this.setState({ dataRsPlus: data });
            })
            .catch(e => {

                this.setState({ showData: true, loading: false });
                Notify(2, 'tc', `Error: ${e.message}`, this.refs);
            });
    };

    btnAuthorizarVoBo = item => {


        const token = global.access_token; //localstorage

        const formData = new FormData();
        formData.append('idMovimientoStatus', 2);
        formData.append('id', item.ListadoPropuestas[this.state.CandidatoRSkeyarray].id);

        fetch(URL_PUBLICA + `seguimientoPropuesta`, {
            method: 'POST',
            headers: new Headers({ Authorization: `Bearer ${token}` }),
            body: formData,
        })
            .then(response => {

                if (response.ok) {
                    return response.json();
                }
                throw new Error('Ocurrio un error: obtenerDatosCandidatos: ', response.statusText);
            })
            .then(items => {

                this.setState({ showData: true });

                /*var data = this.state.dataRsPlus;
                data.find((o, i) => {
            	
                    if (o.id === item.id) {
                        // here you should add some custom code so you can delete the data
                        // from this component and from your server as well
                        data.splice(i, 1);
                	
                        return true;
                    }
                    return false;
                });*/

                Notify(2, 'tc', 'Registro Autorizado!', this.refs);

                //this.setState({ dataRsPlus: data });
            })
            .catch(e => {

                this.setState({ showData: true });
                Notify(2, 'tc', `Error: ${e.message}`, this.refs);
            });
    };

    btnNoAuthorizarVoBo = item => {

        this.setState({ showData: true, loading: true });

        const token = global.access_token; //localstorage

        const formData = new FormData();
        formData.append('idMovimientoStatus', 5);
        formData.append('id', item.ListadoPropuestas[this.state.CandidatoRSkeyarray].id);

        fetch(URL_PUBLICA + `seguimientoPropuesta`, {
            method: 'POST',
            headers: new Headers({ Authorization: `Bearer ${token}` }),
            body: formData,
        })
            .then(response => {


                if (response.ok) {
                    return response.json();
                }
                throw new Error('Ocurrio un error: obtenerDatosCandidatos: ', response.statusText);
            })
            .then(items => {

                this.setState({ showData: true, loading: false });

                Notify(2, 'tc', 'El movimiento fue rechazado', this.refs);

            })
            .catch(e => {

                this.setState({ showData: true, loading: false });
                Notify(2, 'tc', `Error: ${e.message}`, this.refs);
            });
    };

    btnAuthorizarMovimiento = item => {

        this.setState({ loading: true });
        const token = global.access_token; //localstorage

        var formData = {
            'idMovimientoStatus': 4,
            'id': item.ListadoPropuestas[this.state.CandidatoRSkeyarray].id
        };



        EjecutarApi(formData, 'seguimientoPropuesta', token)
            .then(function (res) {
                return res;
            })
            .then(items => {

                this.setState({ showData: true, loading: false });
                this.llenarListadoMovimiento();
                Notify(2, 'tc', 'Movimiento Autorizado!', this.refs);

            })
            .catch(e => {

                this.setState({ showData: true, loading: false });
                Notify(2, 'tc', `Error: ${e.message}`, this.refs);
            });
    };

    btnNoAuthorizarMovimiento = item => {

        this.setState({ showData: true, loading: true });

        const token = global.access_token; //localstorage

        const formData = {
            'idMovimientoStatus': 5,
            'id': item.ListadoPropuestas[this.state.CandidatoRSkeyarray].id
        };




        EjecutarApi(formData, 'seguimientoPropuesta', token)
            .then(function (res) {
                return res;
            })
            .then(items => {

                this.setState({ showData: true, loading: false });
                this.llenarListadoMovimiento();
                Notify(2, 'tc', 'El movimiento fue rechazado', this.refs);


            })
            .catch(e => {

                this.setState({ showData: true, loading: false });
                Notify(2, 'tc', `Error: ${e.message}`, this.refs);
            });
    };

    setBack = () => {
        this.setState({ showAdd: false });
    };
    sendFile = () => {


        if (!this.state.file) {
            Notify(3, "tc", "Error: Debe adjuntar archivo", this.refs);
            return;
        }

        var formData = new FormData();
        formData.append("idCandidato", this.state.CandidatoRSid);
        formData.append("Archivo", this.state.file[0], this.state.file[0].name);


        for (var pair of formData.entries()) {

        }
        //return true;

        const token = global.access_token;

        const requestInfo = {
            method: "POST",
            body: formData,
            headers: new Headers({
                Authorization: `Bearer ${token}`
            })
        };



        this.setState({ loading: true });

        fetch(URL_PUBLICA + "subirCV", requestInfo)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error("Post");
            })
            .then(Results => {

                if (Results.results) {
                    Notify(2, "tc", "Se cargo el archivo correctamente!", this.refs);
                } else {
                    Notify(3, "tc", `Error: ${Results.message}`, this.refs);
                }

                this.setState({ loading: false });

                return;
            })
            .catch(e => {

                Notify(3, "tc", `Error al cargar el archivo: ${e.message}`, this.refs);
                this.setState({ loading: false });
            });
    };
    setEnvioFinanzas = () => {
        this.setState({ loading: true })
        const token = getVars('Token').access_token;
        var parametros = {
            "id": this.state.ListadoPropuestas[this.state.CandidatoRSkeyarray].id,
            "FechaEnvioFinanzas": this.state.FinanzasFechaEnvio,
            "FechaAvisoDependencia": this.state.FinanzasFechaAviso,
            "Comentario": this.state.FinanzasObservaciones
        }
        EjecutarApi(parametros, 'sendFinanzas', token)
            .then(function (res) {
                return res
            })
            .then((res) => {

                if (res.success) {

                    if (res.results) {
                        this.setState({
                            loading: false, ListadoPropuestas: res.data,
                            FinanzasComentarios: res.data[this.state.CandidatoRSkeyarray].Comentarios,
                            FinanzasFechaAviso: '', FinanzasFechaEnvio: '', FinanzasObservaciones: ''
                        })
                        Notify(
                            2,
                            'tc',
                            'El movimiento se actualizo con exito !',
                            this.refs
                        )
                    } else {
                        this.setState({ loading: false, FinanzasFechaAviso: '', FinanzasFechaEnvio: '', FinanzasObservaciones: '' })
                        Notify(
                            2,
                            'tc',
                            'Error en la actualización de la vista !',
                            this.refs
                        )

                    }
                }
                else {
                    this.setState({ loading: false, FinanzasFechaAviso: '', FinanzasFechaEnvio: '', FinanzasObservaciones: '' })
                    Notify(
                        3,
                        'tc',
                        'Error en la actualización del registro!',
                        this.refs
                    )
                }
            })
            .catch((e) => {

                this.setState({ loading: false, FinanzasFechaAviso: '', FinanzasFechaEnvio: '', FinanzasObservaciones: '' })
                Notify(3, 'tc', e.message, this.refs)
                return
            })


    }
    setFinanzasFechaEnvio = (e) => {
        this.setState({ FinanzasFechaEnvio: moment(e._d).format('YYYY-MM-DD') })
    }
    setFinanzasFechaAviso = (e) => {
        this.setState({ FinanzasFechaAviso: moment(e._d).format('YYYY-MM-DD') })
    }
    downloadfile = (e) => {
        let token = getVars('Token').access_token;
        window.location.href = URL_PUBLICA + `downloadArchivoSolicitudPDF_Exccel?id=${this.state.Solicitud.id}&token=${token}`
    }
    render() {


        global = getVars('Token')
        // var dataPropuestas2 = dataPropuestas
        const {
            catalogos,
            Solicitud,
            CandidatoRSid,
            CandidatoRSINE,
            CandidatoRSCURP,
            CandidatoRSNombre,
            CandidatoRSPaterno,
            CandidatoRSMaterno,
            CandidatoRSGradoObtenido,
            CandidatoRSCelular1,
            CandidatoRSCelular2,
            CandidatoRSTelCasa,
            CandidatoRSCorreoElectronico,
            CandidatoRSUltimoTrabajo,
            CandidatoRSNivelPopuesto,
            CandidatoRSmunicipios,
            CandidatoRSestudios,
            CandidatoRSestado_civil,
            CandidatoRSestatus,
            CandidatoRSsexo,
            CandidatoRSPerfilCarrera,
            CandidatoRSComentarios,
            CandidatoRSFacebook,
            CandidatoRSTwitter,
            CandidatoRSInstagram,
            CandidatoRStieneCV,
            CandidatoRSkeyarray,
            hasMore
        } = this.state
        //const { municipios, EstadoCivil, nivel, dependencias } = this.state.catalogos;
        const ItemsDependencia = []
        var ItemsEstadoCivil = []
        var ItemsNivelEstudios = []
        var ItemsPerfilCarrera = []
        var ItemsMunicipio = []

        if (catalogos) {
            catalogos.dependencias.map((item) => {
                ItemsDependencia.push({
                    id: item.value,
                    label: item.label,
                });
                //ItemsDependencia = catalogos.dependencias
                ItemsEstadoCivil = catalogos.EstadoCivil
                ItemsNivelEstudios = catalogos.nivel
                ItemsPerfilCarrera = catalogos.perfilcarrera
                ItemsMunicipio = catalogos.municipios
            })

        }

        return (
            <div className="wrapper">
                <Sidebar
                    {...this.props}
                    bgColor={this.state.backgroundColor}
                    activeColor={this.state.activeColor}
                />
                <div className="main-panel" ref="mainPanel">
                    <DashboardNavbar
                        {...this.props}
                        handleMiniClick={this.handleMiniClick}
                    />
                    <NotificationAlert ref="notificationAlert" />
                    <div className="content">
                        <Form
                            className="form-horizontal"
                            name="form1"
                            id="form1"
                            onSubmit={this.handleCandidatoEdit}
                            noValidate
                        >
                            <Row>
                                <Col sm="12">
                                    <Card>
                                        <CardHeader>
                                            <CardTitle tag="h6">
                                                Datos de Solicitud
                                            </CardTitle>
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Col sm="4">
                                                    {this.state.loading && (
                                                        <CircleSpinner
                                                            className="text-center"
                                                            size={30}
                                                            color="#686769"
                                                            loading={
                                                                this.state
                                                                    .loading
                                                            }
                                                        />
                                                    )}
                                                    Dependencia:
                                                    <Input
                                                        name='FolioDependencia'
                                                        value={Solicitud.Dependencia.label}
                                                        disabled
                                                        type="text"
                                                    ></Input>

                                                </Col>
                                                <Col sm="2">
                                                    Folio de Solicitud:
                                                    <Input
                                                        name='FolioSolicitud'
                                                        value={Solicitud.FolioOficio}
                                                        disabled
                                                        type="text"
                                                    ></Input>
                                                </Col>
                                                <Col sm="2">
                                                    Fecha Oficio:
                                                    <Input
                                                        name='FechaOficio'
                                                        value={Solicitud.FechaSolicitud}
                                                        disabled
                                                        type="text"
                                                    />
                                                </Col>
                                                <Col sm="2">
                                                    Fecha Recepción:
                                                    <Input
                                                        name='FechaRecepcion'
                                                        value={Solicitud.FechaRecepcion.substring(0, 10)}
                                                        disabled
                                                        type="text"
                                                    />
                                                </Col>
                                                <Col sm="2">
                                                    Fecha Captura:
                                                    <Input
                                                        name='FechaCreate'
                                                        value={Solicitud.FechaCreate.substring(0, 10)}
                                                        disabled
                                                        type="text"
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm="4">
                                                    <div className="form-row align-items-center">
                                                        <FormGroup className="col-md-6 pr-0">
                                                            Persona Propone
                                                            <Input
                                                                disabled
                                                                id="solPropones"
                                                                defaultValue={Solicitud
                                                                    .Propone.label
                                                                }
                                                                type="text"
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </Col>


                                                <Col sm="3">
                                                    Estatus:
                                                    <Input
                                                        name="EstatusSolicitud"
                                                        value={Solicitud.Estatus.label}
                                                        readOnly={true}
                                                    />
                                                </Col>
                                                <Col sm="2">
                                                    Archivo:

                                                    <Button className="btn-link" color="primary" onClick={this.downloadfile}>
                                                        <i className="nc-icon nc-cloud-download-93" />
                                                    </Button>
                                                </Col>
                                            </Row>
                                            <Row></Row>
                                        </CardBody>
                                        <CardFooter>
                                            <ModalProponeSolicitud
                                                isOpen={
                                                    this.state
                                                        .modalProponeSolicitud
                                                }
                                                newUser={
                                                    this.setNewProponeSolicitud
                                                }
                                                toggle={
                                                    this
                                                        .togglemodalProponeSolicitud
                                                }
                                                setPropone={
                                                    this.setProponeSolicitud
                                                }
                                                onError={this.onErrorRedirect}
                                                ItemsDependencia={
                                                    ItemsDependencia
                                                }
                                            />
                                        </CardFooter>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="5">
                                    <Button color='primary' size="sm" onClick={() => this.handleSustituirFolio()}>Sustituir Folio</Button><Button color='primary' size="sm" onClick={this.handleAgregarMovimiento}> + Agregar</Button>
                                </Col>
                            </Row>
                            {this.state.showAdd ? <>
                                <oficialiaModalAdd
                                    showButtons={true}
                                    setBack={this.setBack}
                                    data={this.state.Solicitud}
                                //autorizar={this.btnAuthorizar}
                                //noautorizar={this.btnNoAuthorizar}
                                />
                                <Card>
                                    <CardTitle>Agregando</CardTitle>
                                    <CardBody>
                                        <oficialiaModalAdd
                                            showButtons={true}
                                            setBack={this.setBack}
                                            data={this.state.Solicitud}
                                        //autorizar={this.btnAuthorizar}
                                        //noautorizar={this.btnNoAuthorizar}
                                        />
                                    </CardBody>
                                </Card>
                            </> : <><Row>
                                <Col md="5">
                                    <Card>
                                        <CardHeader></CardHeader>
                                        <CardHeader>
                                            <CardTitle><h6 style={{ textAlign: "center" }}>Listado de Propuestas</h6></CardTitle>
                                            <h6 className="card-category">{`Mostrando ${this.state.ListadoPropuestas.length} de ${this.state.totalListadoPropuesta} registros`}</h6>
                                        </CardHeader>
                                        <CardBody>
                                            {this.state.loading && (
                                                <CircleSpinner
                                                    className="text-center"
                                                    size={30}
                                                    color="#686769"
                                                    loading={this.state.loading}
                                                />
                                            )}
                                            {
                                                <InfiniteScroll
                                                    dataLength={this.state.ListadoPropuestas.length}
                                                    next={this.fetchMore}
                                                    hasMore={hasMore}
                                                    loader={<h4>cargando...</h4>}
                                                    height={400}
                                                    endMessage={
                                                        <p style={{ textAlign: "center" }}>
                                                            <b>Listo! Esos son todos!</b>
                                                        </p>
                                                    }
                                                >
                                                    {
                                                        this.state.ListadoPropuestas.map((item, i) =>
                                                        (
                                                            //
                                                            <div key={i}>
                                                                <div><h6>Movimiento {i + 1}</h6></div>
                                                                <div>Movimiento: <b>{' '}{item.Movimiento.label}</b></div>
                                                                <div>Dependencia:<b>{' '}{item.Dependencia_organismo}</b></div>
                                                                <div>Puesto:<b>{' '}{item.Denominacion_funcional}</b></div>
                                                                <div>Nivel:<b>{' '}{item.Nivel}</b></div>
                                                                <div>Plaza:<b>{' '}{item.Plaza}</b></div>
                                                                <div>Municipio:<b>{' '}{item.Municipio}</b></div>
                                                                <div>Candidato: {item.SolicitudMovimientoStatus.value < 4 ? <Button
                                                                    onClick={() => { this.handleCandidatoEdit(item.Candidato.value, i) }}
                                                                    color="info"
                                                                    size="sm"
                                                                    className="btn-link"
                                                                >{item.Candidato ? item.Candidato.label ? item.Candidato.label.toUpperCase() : '' : ''}</Button>
                                                                    : item.Candidato ? item.Candidato.label ? item.Candidato.label.toUpperCase() : '' : ''}
                                                                </div>
                                                                <div>Estatus:<b>{' '}{item.MovimientoStatus.label}</b></div>
                                                                <div>Estatus Oficialia:<b>{' '}{item.SolicitudMovimientoStatus.label}</b></div>
                                                                <hr />
                                                            </div>
                                                        )
                                                        )
                                                    }
                                                </InfiniteScroll>
                                            }
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md="7">
                                    <Card>
                                        <CardHeader>
                                            <CardTitle>
                                                <h6 style={{ textAlign: "center" }}>Validaciones</h6>
                                            </CardTitle>
                                        </CardHeader>
                                        <CardBody>
                                            <div className="nav-tabs-navigation">
                                                <div className="nav-tabs-wrapper">
                                                    <Nav id="tabs" role="tablist" tabs>
                                                        <NavItem>
                                                            <NavLink
                                                                aria-expanded={this.state.horizontalTabs === "home"}
                                                                data-toggle="tab"
                                                                //href="#pablo"
                                                                role="tab"
                                                                className={
                                                                    this.state.horizontalTabs === "home"
                                                                        ? "active"
                                                                        : ""
                                                                }
                                                                onClick={() =>
                                                                    this.setState({ showData: true, horizontalTabs: "home" })
                                                                }

                                                            >
                                                                Datos Candidato
                                                            </NavLink>
                                                        </NavItem>
                                                        <NavItem>
                                                            <NavLink
                                                                aria-expanded={
                                                                    this.state.horizontalTabs === "vobo"
                                                                }
                                                                data-toggle="tab"
                                                                role="tab"
                                                                className={
                                                                    this.state.horizontalTabs === "vobo"
                                                                        ? "active"
                                                                        : ""
                                                                }
                                                                onClick={() =>
                                                                    this.setState({ showVobo: true, horizontalTabs: "vobo" })
                                                                }
                                                            >
                                                                Visto Bueno
                                                            </NavLink>
                                                        </NavItem>
                                                        <NavItem>
                                                            <NavLink
                                                                aria-expanded={
                                                                    this.state.horizontalTabs === "validaciones"
                                                                }
                                                                data-toggle="tab"
                                                                role="tab"
                                                                className={
                                                                    this.state.horizontalTabs === "validaciones"
                                                                        ? "active"
                                                                        : ""
                                                                }
                                                                onClick={() =>
                                                                    this.setState({ showValidaciones: true, horizontalTabs: "validaciones" })
                                                                }

                                                            >
                                                                Validaciones
                                                            </NavLink>
                                                        </NavItem>
                                                        <NavItem>
                                                            <NavLink
                                                                aria-expanded={
                                                                    this.state.horizontalTabs === "autorizar"
                                                                }
                                                                data-toggle="tab"
                                                                role="tab"
                                                                className={
                                                                    this.state.horizontalTabs === "autorizar"
                                                                        ? "active"
                                                                        : ""
                                                                }
                                                                onClick={() =>
                                                                    this.setState({ showAutorizar: true, horizontalTabs: "autorizar" })
                                                                }
                                                            >
                                                                Autorizar
                                                            </NavLink>
                                                        </NavItem>
                                                        <NavItem>
                                                            <NavLink
                                                                aria-expanded={
                                                                    this.state.horizontalTabs === "finanzas"
                                                                }
                                                                data-toggle="tab"
                                                                role="tab"
                                                                className={
                                                                    this.state.horizontalTabs === "finanzas"
                                                                        ? "active"
                                                                        : ""
                                                                }
                                                                onClick={() =>
                                                                    this.setState({ showFinanzas: true, horizontalTabs: "finanzas" })
                                                                }
                                                            >
                                                                Finanzas
                                                            </NavLink>
                                                        </NavItem>
                                                    </Nav>
                                                </div>
                                            </div>
                                            <TabContent
                                                className="text-center"
                                                id="my-tab-content"
                                                activeTab={this.state.horizontalTabs}
                                            >
                                                <TabPane tabId="home" role="tabpanel">
                                                    {this.state.showData ?
                                                        <div>
                                                            <Row>
                                                                <Col sm="12">
                                                                    <FormGroup>
                                                                        <h7 className='text-muted'>{`${CandidatoRSNombre} ${CandidatoRSPaterno} ${CandidatoRSMaterno}`} </h7>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col sm="4">
                                                                    <FormGroup>
                                                                        <Label className="text-left">
                                                                            Sexo
                                                                        </Label>
                                                                        <Select
                                                                            className="react-select primary"
                                                                            classNamePrefix="react-select"
                                                                            name="Sexo"
                                                                            value={
                                                                                CandidatoRSsexo
                                                                            }
                                                                            onChange={e => this.setState({ CandidatoRSsexo: e })}
                                                                            options={[
                                                                                {
                                                                                    value: 1,
                                                                                    label:
                                                                                        'No Definido',
                                                                                },
                                                                                {
                                                                                    value: 2,
                                                                                    label:
                                                                                        'Hombre',
                                                                                },
                                                                                {
                                                                                    value: 3,
                                                                                    label:
                                                                                        'Mujer',
                                                                                },
                                                                            ]}
                                                                            placeholder="Seleccione Sexo"
                                                                        />
                                                                    </FormGroup>
                                                                </Col>

                                                                <Col sm="4">
                                                                    <FormGroup>
                                                                        <Label className="text-left">
                                                                            Estado
                                                                            Civil
                                                                        </Label>
                                                                        <Select
                                                                            className="react-select primary"
                                                                            classNamePrefix="react-select"
                                                                            name="idEstadoCivil"
                                                                            value={
                                                                                this
                                                                                    .state.CandidatoRSestado_civil
                                                                            }
                                                                            onChange={
                                                                                this
                                                                                    .setEstadoCivil
                                                                            }
                                                                            options={this.state.ItemsEstadoCivil}

                                                                            placeholder="Seleccione Estado Civil"
                                                                        />
                                                                    </FormGroup>
                                                                </Col>

                                                                <Col sm="4">
                                                                    <FormGroup>
                                                                        <Label className="text-left">
                                                                            INE
                                                                        </Label>
                                                                        <Input
                                                                            placeholder="INE"
                                                                            id="INE"
                                                                            type="text"
                                                                            value={
                                                                                this
                                                                                    .state.CandidatoRSINE
                                                                            }
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                this.setState(
                                                                                    {
                                                                                        CandidatoRSINE:
                                                                                            e
                                                                                                .target
                                                                                                .value,
                                                                                    }
                                                                                )
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>

                                                            </Row>
                                                            <Row>
                                                                <Col sm="4">
                                                                    <FormGroup>
                                                                        <Label className="text-left">
                                                                            CURP
                                                                        </Label>
                                                                        <Input
                                                                            placeholder="CURP"
                                                                            id="CURP"
                                                                            value={
                                                                                this
                                                                                    .state.CandidatoRSCURP
                                                                            }
                                                                            type="text"
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                this.setState(
                                                                                    {
                                                                                        CandidatoRSCURP:
                                                                                            e
                                                                                                .target
                                                                                                .value,
                                                                                    }
                                                                                )
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col
                                                                    sm="2"
                                                                    className=" absolute-center"
                                                                >
                                                                    <Button size='sm'
                                                                        name="btnCurp"
                                                                        color="warning"
                                                                        className='btn-round'
                                                                        onClick={() => this.getCURP}>
                                                                        <span className="btn-label">
                                                                            <i className="nc-icon nc-zoom-split fa-lg" />
                                                                        </span>
                                                                        ...
                                                                    </Button>
                                                                </Col>
                                                                <Col
                                                                    sm="5"
                                                                    className=" absolute-center"
                                                                >
                                                                    <UploadFile
                                                                        onFilesAdded={
                                                                            this.onFilesAdded
                                                                        }
                                                                        Accept="pdf"
                                                                        title="adjuntaCV"
                                                                    ></UploadFile>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col sm="3">
                                                                    <FormGroup>
                                                                        <Label className="text-left">
                                                                            Municipio{' '}
                                                                        </Label>
                                                                        <Select
                                                                            className="react-select primary"
                                                                            classNamePrefix="react-select"
                                                                            name="Municipio"
                                                                            value={
                                                                                this
                                                                                    .state.CandidatoRSmunicipios
                                                                            }
                                                                            onChange={(
                                                                                value
                                                                            ) =>
                                                                                this.setState(
                                                                                    {
                                                                                        CandidatoRSmunicipios: value,
                                                                                    }
                                                                                )
                                                                            }
                                                                            options={this.state.ItemsMunicipio}

                                                                            placeholder="Seleccione municipio"
                                                                        />
                                                                    </FormGroup>
                                                                </Col>

                                                                <Col sm="3">
                                                                    <FormGroup>
                                                                        <Label className="text-left">
                                                                            Último
                                                                            Empleo
                                                                        </Label>
                                                                        <Input
                                                                            placeholder="último Trabajo"
                                                                            id="UltimoTrabajo"
                                                                            value={
                                                                                this
                                                                                    .state.CandidatoRSUltimoTrabajo
                                                                            }
                                                                            type="text"
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                this.setState(
                                                                                    {
                                                                                        CandidatoRSUltimoTrabajo:
                                                                                            e
                                                                                                .target
                                                                                                .value,
                                                                                    }
                                                                                )
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col sm="3">
                                                                    <FormGroup>
                                                                        <Label className="text-left">
                                                                            Nivel
                                                                            de
                                                                            Estudios
                                                                        </Label>
                                                                        <Select
                                                                            className="react-select primary"
                                                                            classNamePrefix="react-select"
                                                                            name="idNivelEstudio"
                                                                            value={
                                                                                this
                                                                                    .state.CandidatoRSestudios
                                                                            }
                                                                            onChange={
                                                                                this
                                                                                    .setNivelEstudios
                                                                            }
                                                                            options={this.state.ItemsNivelEstudios}
                                                                            placeholder="Seleccione Estudios"
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>

                                                                {/*this
                                                                .state
                                                                .CandidatoRS.estudios
                                                                .value >
                                                                8 ?(
                                                                <Col sm="3">
                                                                    <FormGroup>
                                                                        <Label className="text-left">
                                                                            Grado
                                                                            Obtenido
                                                                        </Label>
                                                                        <Input
                                                                            placeholder="Grado Obtenido"
                                                                            id="GradoObtenido"
                                                                            value={this.state.CandidatoRS.GradoObtenido}
                                                                            type="text"
                                                                            //onChange={(
                                                                            //    e
                                                                            //) =>
                                                                            //    this.setState(
                                                                            //        {
                                                                            //            CandidatoRS: {GradoObtenido:
                                                                            //                e.target.label   }
                                                                            //        }
                                                                            //    )
                                                                            //}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            ):<></>*/}
                                                                {/*(this
                                                                .state.CandidatoRS
                                                                .estudios
                                                                .value >
                                                                11 ||
                                                                this
                                                                    .state
                                                                    .idNivelEstudio
                                                                    .value <
                                                                    14) ?(
                                                                <Col sm="3">
                                                                    <FormGroup>
                                                                        <Label className="text-left">
                                                                            Grado
                                                                            Obtenido
                                                                            Maestría
                                                                        </Label>
                                                                        <Input
                                                                            placeholder="Grado Maestria"
                                                                            id="GradoMaestria"
                                                                            value={
                                                                                this
                                                                                    .state
                                                                                    .GradoMaestria
                                                                            }
                                                                            type="text"
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                this.setState(
                                                                                    {
                                                                                        GradoMaestria:
                                                                                            e
                                                                                                .target
                                                                                                .value,
                                                                                    }
                                                                                )
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            ):<></>*/}
                                                                <Col sm="3">
                                                                    <FormGroup>
                                                                        <Label className="text-left">
                                                                            Perfil
                                                                            Carrera
                                                                        </Label>
                                                                        <Select
                                                                            className="react-select primary"
                                                                            classNamePrefix="react-select"
                                                                            name="idPerfilCarrera"
                                                                            value={
                                                                                this
                                                                                    .state.CandidatoRSPerfilCarrera
                                                                            }
                                                                            onChange={
                                                                                this
                                                                                    .setPerfilCarrera
                                                                            }
                                                                            options={this.state.ItemsPerfilCarrera}
                                                                            placeholder="Perfil Carrera"
                                                                        />
                                                                    </FormGroup>
                                                                </Col>

                                                                <Col sm="3">
                                                                    <FormGroup>
                                                                        <Label className="text-left">
                                                                            Celular
                                                                        </Label>
                                                                        <Input
                                                                            placeholder="Celular"
                                                                            id="Celular"
                                                                            value={this.state.CandidatoRSCelular1}
                                                                            type="text"
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                this.setState(
                                                                                    {
                                                                                        CandidatoRSCelular1:
                                                                                            e
                                                                                                .target
                                                                                                .value
                                                                                    }
                                                                                )
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col sm="3">
                                                                    <FormGroup>
                                                                        <Label className="text-left">
                                                                            Tel
                                                                            Casa
                                                                        </Label>
                                                                        <Input
                                                                            placeholder="TelCasa"
                                                                            id="TelCasa"
                                                                            value={
                                                                                this
                                                                                    .state.CandidatoRSTelCasa
                                                                            }
                                                                            type="text"
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                this.setState(
                                                                                    {
                                                                                        CandidatoRSTelCasa:
                                                                                            e
                                                                                                .target
                                                                                                .value
                                                                                    }
                                                                                )
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col sm="4">
                                                                    <FormGroup>
                                                                        <Label className="text-left">
                                                                            Correo
                                                                            Electrónico
                                                                        </Label>
                                                                        <Input
                                                                            placeholder="Correo"
                                                                            id="Correo"
                                                                            value={
                                                                                this
                                                                                    .state.CandidatoRSCorreoElectronico
                                                                            }
                                                                            type="text"
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                this.setState(
                                                                                    {
                                                                                        CandidatoRSCorreoElectronico:
                                                                                            e
                                                                                                .target
                                                                                                .value,
                                                                                    }
                                                                                )
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col sm="4">
                                                                    <FormGroup>
                                                                        <Label className="text-left">
                                                                            Facebook
                                                                        </Label>
                                                                        <Input
                                                                            placeholder="Facebook"
                                                                            id="Facebook"
                                                                            value={
                                                                                this
                                                                                    .state.CandidatoRSFacebook
                                                                            }
                                                                            type="text"
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                this.setState(
                                                                                    {
                                                                                        CandidatoRSFacebook:
                                                                                            e
                                                                                                .target
                                                                                                .value,
                                                                                    }
                                                                                )
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>

                                                                <Col sm="4">
                                                                    <FormGroup>
                                                                        <Label className="text-left">
                                                                            Twitter
                                                                        </Label>
                                                                        <Input
                                                                            placeholder="Twitter"
                                                                            id="Twitter"
                                                                            value={
                                                                                this
                                                                                    .state.CandidatoRSTwitter
                                                                            }
                                                                            type="text"
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                this.setState(
                                                                                    {
                                                                                        CandidatoRSTwitter:
                                                                                            e
                                                                                                .target
                                                                                                .value,
                                                                                    }
                                                                                )
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col sm="4">
                                                                    <FormGroup>
                                                                        <Label className="text-left">
                                                                            Instagram
                                                                        </Label>
                                                                        <Input
                                                                            placeholder="Instagram"
                                                                            id="Instagram"
                                                                            value={
                                                                                this
                                                                                    .state.CandidatoRSInstagram
                                                                            }
                                                                            type="text"
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                this.setState(
                                                                                    {
                                                                                        CandidatoRSInstagram:
                                                                                            e
                                                                                                .target
                                                                                                .value,
                                                                                    }
                                                                                )
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col sm="8">
                                                                    <FormGroup>
                                                                        <Label className="text-left">
                                                                            Comentarios
                                                                        </Label>
                                                                        <Input
                                                                            placeholder="Comentarios"
                                                                            id="Comentarios"
                                                                            value={
                                                                                this
                                                                                    .state.CandidatoRSComentarios
                                                                            }
                                                                            type="textarea"
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                this.setState(
                                                                                    {
                                                                                        CandidatoRSComentarios:
                                                                                            e
                                                                                                .target
                                                                                                .value,
                                                                                    }
                                                                                )
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col sm="4">
                                                                    <Button color='primary' size='sm' className='-btn btn-primary' onClick={this.paso1Guardar}>Guardar</Button>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        : <></>}
                                                </TabPane>
                                                <TabPane tabId="vobo" role="tabpanel">
                                                    {this.state.showVobo && (
                                                        <MovimientoVobo
                                                            showButtons={false}
                                                            data={this.state}
                                                            autorizar={this.btnAuthorizarVoBo}
                                                            noautorizar={this.btnNoAuthorizarVoBo}
                                                        />
                                                    )}
                                                </TabPane>
                                                <TabPane tabId="validaciones" role="tabpanel">
                                                    {this.state.showValidaciones && (
                                                        <MovValidacion
                                                            showButtons={false}
                                                            data={this.state}
                                                            autorizar={this.btnAuthorizarValidar}
                                                            noautorizar={this.btnNoAuthorizar}
                                                        />
                                                    )}
                                                </TabPane>
                                                <TabPane tabId="autorizar" role="tabpanel">
                                                    {this.state.showAutorizar && (
                                                        <MovimientoAutorizar
                                                            showButtons={false}
                                                            data={this.state}
                                                            autorizar={this.btnAuthorizarMovimiento}
                                                            noautorizar={this.btnNoAuthorizarMovimiento}
                                                        />
                                                    )}
                                                </TabPane>
                                                <TabPane tabId="finanzas" role="tabpanel">
                                                    {this.state.showFinanzas ? <>
                                                        <Row><Col>{this.state.CandidatoRSNombre} {' '}{this.state.CandidatoRSPaterno}{' '} {this.state.CandidatoRSMaterno}</Col></Row>
                                                        <Row>
                                                            <Col>*Fecha Envio a Finanzas
                                                                <ReactDatetime
                                                                    name='FechaEnvio'
                                                                    dateFormat="YYYY-MM-DD"
                                                                    timeFormat={false}
                                                                    closeOnSelect
                                                                    onChange={
                                                                        this
                                                                            .setFinanzasFechaEnvio
                                                                    }
                                                                    inputProps={{
                                                                        className:
                                                                            'form-control',
                                                                        placeholder:
                                                                            'Fecha Oficio',
                                                                    }}
                                                                    value={this.state.FinanzasFechaEnvio}
                                                                />
                                                            </Col>
                                                            <Col>*Fecha de Aviso a dependencia
                                                                <ReactDatetime
                                                                    name='Fechadep'
                                                                    dateFormat="YYYY-MM-DD"
                                                                    timeFormat={false}
                                                                    closeOnSelect
                                                                    onChange={
                                                                        this
                                                                            .setFinanzasFechaAviso
                                                                    }
                                                                    inputProps={{
                                                                        className:
                                                                            'form-control',
                                                                        placeholder:
                                                                            'Fecha Oficio',
                                                                    }}
                                                                    value={this.state.FinanzasFechaAviso}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>*Observaciones
                                                                <Input name='obser'
                                                                    type='text'
                                                                    value={this.state.FinanzasObservaciones}
                                                                    onChange={e => this.setState({ FinanzasObservaciones: e.target.value })}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <Button className='primary' color='primary' onClick={this.setEnvioFinanzas} > Guardar </Button>
                                                            </Col>

                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <ReactTable
                                                                    data={this.state.FinanzasComentarios}
                                                                    showPagination={false}
                                                                    showPageJump={false}
                                                                    className="-striped -highlight primary-pagination"
                                                                    defaultPageSize={10}
                                                                    total={this.state.FinanzasComentarios.length}
                                                                    //pages={Math.floor(this.state.total / 10)}
                                                                    columns={[
                                                                        {
                                                                            Header: 'Fecha',
                                                                            id: 'Fecha',
                                                                            accessor: d => d.FechaHora,
                                                                            minWidth: 120,
                                                                        },
                                                                        {
                                                                            Header: 'Comentario',
                                                                            id: 'Comentario',
                                                                            accessor: d => d.Comentario,
                                                                            minWidth: 300,
                                                                        }
                                                                    ]}
                                                                />
                                                            </Col>
                                                        </Row>

                                                    </>
                                                        : <></>}
                                                </TabPane>
                                            </TabContent>

                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                                <Row>
                                    <Col md="5">
                                        <Card>
                                            <CardHeader>
                                                <CardTitle tag="h4">Validaciones</CardTitle>
                                            </CardHeader>
                                            <CardBody>
                                                {/*this.state.showData?
                                                <ul className="list-unstyled team-members">
                                            <li>
                                                <Row>
                                                <Col md="4" xs="4">
                                                    Electoral
                                                </Col>
                                                <Col className="text-right" md="2" xs="2">
                                                    {this.state.ListadoPropuestas[this.CandidatoRSkeyarray].Validaciones.isElectoral === 1 && (
                                                    <i className="fa fa-check text-success h5" />
                                                    )}
                                                    {this.state.ListadoPropuestas[this.CandidatoRSkeyarray].Validaciones.isElectoral === 2 && (
                                                    <i className="fa fa-times text-danger h5" />
                                                    )}
                                                </Col>
                                                <Col md="4" xs="4">
                                                    Abogados
                                                </Col>
                                                <Col className="text-right" md="2" xs="2">
                                                    {this.state.ListadoPropuestas[this.CandidatoRSkeyarray].Validaciones.isElectoralA === 1 && (
                                                    <i className="fa fa-check text-success h5" />
                                                    )}
                                                    {this.state.ListadoPropuestas[this.CandidatoRSkeyarray].Validaciones.isElectoralA === 2 && (
                                                    <i className="fa fa-times text-danger h5" />
                                                    )}
                                                </Col>
                                                </Row>
                                            </li>
                                            <li>
                                                <Row>
                                                <Col md="4" xs="4">
                                                    RGs
                                                </Col>
                                                <Col className="text-right" md="2" xs="2">
                                                    {this.state.ListadoPropuestas[this.CandidatoRSkeyarray].Validaciones.isElectoralrg === 1 && (
                                                    <i className="fa fa-check text-success h5" />
                                                    )}
                                                    {this.state.ListadoPropuestas[this.CandidatoRSkeyarray].Validaciones.isElectoralrg === 2 && (
                                                    <i className="fa fa-times text-danger h5" />
                                                    )}
                                                </Col>
                                                <Col md="4" xs="4">
                                                    RCs
                                                </Col>
                                                <Col className="text-right" md="2" xs="2">
                                                    {this.state.ListadoPropuestas[this.CandidatoRSkeyarray].Validaciones.isElectoralrc === 1 && (
                                                    <i className="fa fa-check text-success h5" />
                                                    )}
                                                    {this.state.ListadoPropuestas[this.CandidatoRSkeyarray].Validaciones.isElectoralrc === 2 && (
                                                    <i className="fa fa-times text-danger h5" />
                                                    )}
                                                </Col>
                                                </Row>
                                            </li>
                                            <li>
                                                <Row>
                                                <Col md="4" xs="4">
                                                    Militante
                                                </Col>
                                                <Col className="text-right" md="2" xs="2">
                                                    {this.state.ListadoPropuestas[this.CandidatoRSkeyarray].Validaciones.isMilitante === 1 && (
                                                    <i className="fa fa-check text-success h5" />
                                                    )}
                                                    {this.state.ListadoPropuestas[this.CandidatoRSkeyarray].Validaciones.isMilitante === 2 && (
                                                    <i className="fa fa-times text-danger h5" />
                                                    )}
                                                </Col>
                                                <Col md="4" xs="4">
                                                    Social
                                                </Col>
                                                <Col className="text-right" md="2" xs="2">
                                                    {this.state.ListadoPropuestas[this.CandidatoRSkeyarray].Validaciones.isSocial === 1 && (
                                                    <i className="fa fa-check text-success h5" />
                                                    )}
                                                    {this.state.ListadoPropuestas[this.CandidatoRSkeyarray].Validaciones.isSocial === 2 && (
                                                    <i className="fa fa-times text-danger h5" />
                                                    )}
                                                </Col>
                                                </Row>
                                            </li>
                                            <li>
                                                <Row>
                                                <Col md="4" xs="4">
                                                    Vota
                                                </Col>
                                                <Col className="text-right" md="2" xs="2">
                                                    {this.state.ListadoPropuestas[this.CandidatoRSkeyarray].Validaciones.isVota === 1 && (
                                                    <i className="fa fa-check text-success h5" />
                                                    )}
                                                    {this.state.ListadoPropuestas[this.CandidatoRSkeyarray].Validaciones.isVota === 2 && (
                                                    <i className="fa fa-times text-danger h5" />
                                                    )}
                                                </Col>
                                                <Col md="4" xs="4">
                                                    Morena
                                                </Col>
                                                <Col className="text-right" md="2" xs="2">
                                                    {this.state.ListadoPropuestas[this.CandidatoRSkeyarray].Validaciones.isMorena === 1 && (
                                                    <i className="fa fa-check text-success h5" />
                                                    )}
                                                    {this.state.ListadoPropuestas[this.CandidatoRSkeyarray].Validaciones.isMorena === 2 && (
                                                    <i className="fa fa-times text-danger h5" />
                                                    )}
                                                </Col>
                                                </Row>
                                            </li>

                                            <li>
                                                <Row>
                                                <Col md="4" xs="4">
                                                    Pan
                                                </Col>
                                                <Col className="text-right" md="2" xs="2">
                                                    {this.state.ListadoPropuestas[this.CandidatoRSkeyarray].Validaciones.isPan === 1 && (
                                                    <i className="fa fa-check text-success h5" />
                                                    )}
                                                    {this.state.ListadoPropuestas[this.CandidatoRSkeyarray].Validaciones.isPan === 2 && (
                                                    <i className="fa fa-times text-danger h5" />
                                                    )}
                                                </Col>
                                                <Col md="4" xs="4">
                                                    Prd
                                                </Col>
                                                <Col className="text-right" md="2" xs="2">
                                                    {this.state.ListadoPropuestas[this.CandidatoRSkeyarray].Validaciones.isPrd === 1 && (
                                                    <i className="fa fa-check text-success h5" />
                                                    )}
                                                    {this.state.ListadoPropuestas[this.CandidatoRSkeyarray].Validaciones.isPrd === 2 && (
                                                    <i className="fa fa-times text-danger h5" />
                                                    )}
                                                </Col>
                                                </Row>
                                            </li>

                                            <li>
                                                <Row>
                                                <Col md="4" xs="4">
                                                    Pri
                                                </Col>
                                                <Col className="text-right" md="2" xs="2">
                                                    {this.state.ListadoPropuestas[this.CandidatoRSkeyarray].Validaciones.isPri === 1 && (
                                                    <i className="fa fa-check text-success h5" />
                                                    )}
                                                    {this.state.ListadoPropuestas[this.CandidatoRSkeyarray].Validaciones.isPri === 2 && (
                                                    <i className="fa fa-times text-danger h5" />
                                                    )}
                                                </Col>
                                                <Col md="4" xs="4">
                                                    Pvem
                                                </Col>
                                                <Col className="text-right" md="2" xs="2">
                                                    {this.state.ListadoPropuestas[this.CandidatoRSkeyarray].Validaciones.isPvem === 1 && (
                                                    <i className="fa fa-check text-success h5" />
                                                    )}
                                                    {this.state.ListadoPropuestas[this.CandidatoRSkeyarray].Validaciones.isPvem === 2 && (
                                                    <i className="fa fa-times text-danger h5" />
                                                    )}
                                                </Col>
                                                </Row>
                                            </li>
                                            </ul>
                                                :<></>*/}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </>}


                        </Form>
                    </div>
                    <Footer fluid />
                </div>
            </div>
        )
    }
}

export default oficialiaEdit
